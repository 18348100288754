import {all, debounce, takeEvery, takeLatest} from "redux-saga/effects";

import {FORCE_LOGOUT, LOGIN_REQUEST, LOGOUT_REQUEST} from "./containers/login/user-actions";
import {forceLogout, login, logout} from "./containers/login/login-saga";
import {LOAD_TOP_WORDS_REQUEST} from "./containers/charts/charts-action-types";
import {loadTopWordsRequest} from "./containers/charts/load-top-words-saga";
import {ADD_WORDS_REQUEST, GET_TAXONOMY_LIST_REQUEST} from "./containers/add-words/add-words-action-types";
import {addWordsRequest, taxonomyListRequest} from "./containers/add-words/add-words-saga";
import {LOAD_HB_INDEX_CHART_DATA_REQUEST} from "./containers/hb-index-chart/hb-index-chart-action-creators";
import {loadHbIndexChartData} from "./containers/hb-index-chart/hb-index-chart-saga";
import {LOAD_REPORT_INFO_REQUEST} from "./containers/report-info/report-info-action-types";
import {loadReportInfoRequest} from "./containers/report-info/load-report-info-request-saga";
import {SEARCH_REQUEST} from "./containers/ngram-search/ngram-search-actions";
import {performNgramSearch} from "./containers/ngram-search/ngram-search-saga";

import {SINGLE_RESPONSE_REQUEST} from "./containers/response-single/response-single-actions";
import {getSingleResponse} from "./containers/response-single/response-single-saga";
import {DATA_APPEND_INITIATE_DOWNLOAD} from "./containers/data-append/data-append-actions";
import {dataAppendDownload} from "./containers/data-append/data-append-saga";
import {
  LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST,
  LOAD_SPLIT_CHART_SENTIMENT_REQUEST
} from "./containers/split-charts/split-charts-actions";
import {
  performSplitChartPrimaryEmotionsRequest,
  performSplitChartSentimentRequest
} from "./containers/split-charts/split-charts-saga";
import {LIVE_REPORT_START} from "./containers/live/live-report-actions";
import {startLiveReport} from "./containers/live/live-report-saga";

import historySagas from './containers/main/history/history-sagas';
import reportSagas from "./containers/report/report-saga";
import reportsSagas from './containers/reports/reports-sagas';
import responsesSagas from './containers/responses/responses-sagas';
import passwordResetSagas from './containers/password-reset/password-reset-sagas';
import editSingleReportSagas from './containers/edit-single-response/edit-single-response-sagas';
import requestSupportSagas from './containers/request-support/request-support-sagas';
import createReportSagas from './containers/create-report/create-report-saga';
import fullReportTopWordsSagas from './containers/full-report-top-words/full-report-top-words-sagas';
import notesSagas from './containers/responses/notes/notes-sagas';
import reportRouterSagas from './containers/report-router/report-router-sagas';
import usersSagas from './containers/users/users-sagas';
import chartSagas from './containers/charts/charts-sagas';
import chatSagas from './containers/chat/chat-sagas';
import fullReportSagas from './containers/full-report-charts/full-report-charts-sagas';
import mainSagas from './containers/main/main-sagas';
import filtersSagas from './containers/filters/filters-sagas';
import metricsSagas from './containers/report-metrics/report-metrics-saga';
import purchaseStatsSagas from './containers/purchase-statistics/purchase-statistics-sagas';
import taxonomySagas from './containers/taxonomy/taxonomy-sagas';
import articlesSagas from "./containers/articles/articles-sagas";

export default function* ({history}) {
  yield takeEvery(LOGIN_REQUEST, login);
  yield takeEvery(LOGOUT_REQUEST, logout);
  yield takeLatest(LOAD_TOP_WORDS_REQUEST, loadTopWordsRequest);
  yield takeEvery(GET_TAXONOMY_LIST_REQUEST, taxonomyListRequest);
  yield takeEvery(ADD_WORDS_REQUEST, addWordsRequest);
  yield takeEvery(LOAD_HB_INDEX_CHART_DATA_REQUEST, loadHbIndexChartData);
  yield takeEvery(LOAD_REPORT_INFO_REQUEST, loadReportInfoRequest);
  yield takeEvery(SEARCH_REQUEST, performNgramSearch);
  yield debounce(300, SINGLE_RESPONSE_REQUEST, getSingleResponse);
  yield takeEvery(DATA_APPEND_INITIATE_DOWNLOAD, dataAppendDownload);
  yield takeEvery(LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST, performSplitChartPrimaryEmotionsRequest);
  yield takeEvery(LOAD_SPLIT_CHART_SENTIMENT_REQUEST, performSplitChartSentimentRequest);
  yield takeEvery(LIVE_REPORT_START, startLiveReport);
  yield debounce(300, FORCE_LOGOUT, forceLogout);

  yield all([
    ...historySagas(history),
    ...reportsSagas,
    ...reportSagas,
    ...responsesSagas,
    ...createReportSagas,
    ...passwordResetSagas,
    ...editSingleReportSagas,
    ...requestSupportSagas,
    ...fullReportTopWordsSagas,
    ...notesSagas,
    ...reportRouterSagas,
    ...usersSagas,
    ...chartSagas,
    ...chatSagas,
    ...fullReportSagas,
    ...mainSagas,
    ...filtersSagas,
    ...metricsSagas,
    ...purchaseStatsSagas,
    ...taxonomySagas,
    ...articlesSagas,
  ]);
}
