import React, {Component, createRef, Fragment, PureComponent} from 'react';
import {withTranslation} from "react-i18next";
import {SPLIT_CHART_MODE_RELATIVE} from "../../../containers/split-charts/split-chart-wrapper";
import Tooltip from "@material-ui/core/Tooltip";
import {getLegendWidth} from "../charts-utils";

import './split-chart.css';

const ANIMATION_TIMEOUT = 300;

class SplitChartBar extends Component {
  componentDidMount() {
    if (this.props.showLabels) {
      this.startLabelsToggling();
    }
  }

  componentWillUnmount() {
    this.endLabelsToggling();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showLabels !== this.props.showLabels) {
      if (this.props.showLabels) {
        this.startLabelsToggling();
      } else {
        this.endLabelsToggling();
      }
    }

    if (prevProps.mode !== this.props.mode) {
      setTimeout(this.showOrHideLabels, ANIMATION_TIMEOUT);
    }
  }

  startLabelsToggling = () => {
    window.addEventListener('resize', this.showOrHideLabels);
    setTimeout(this.showOrHideLabels, ANIMATION_TIMEOUT);
  };

  endLabelsToggling = () => {
    this.showOrHideLabels();
    window.removeEventListener('resize', this.showOrHideLabels);
  };

  showOrHideLabels = () => {
    this.divRefs.forEach(ref => {
      const div = ref.current;

      if (!div) return;
      const span = div.children[0];

      if (div.offsetWidth < span.offsetWidth || !this.props.showLabels) {
        span.style.visibility = 'hidden';
      } else {
        span.style.visibility = 'visible';
      }
    });
  };

  render() {
    const {row, rowTitle, colTitle, t} = this.props;

    this.divRefs = [];

    const colBars = row.cols.map(col => {
      const tooltipTitle = (
        <div className="split-chart-tooltip">
          <span><strong>{rowTitle}:</strong> {row.value}</span>
          <span>
            <strong>{colTitle}:</strong>
            <span
              style={{backgroundColor: col.color}}
              className="split-chart-tooltip-tag"
            >
              {col.value}
            </span>
          </span>
          {/*<span><strong>{t('count', 'Count')}:</strong> {col.count}</span>*/}
          {/*<span><strong>{t('percent', 'Percent')}:</strong> {(col.percent * 100).toFixed(2)}%</span>*/}
        </div>
      );

      const itemRef = createRef();
      this.divRefs.push(itemRef);

      const percent = col.widthPercent * 100;

      return (
        <Tooltip arrow title={tooltipTitle} key={row.value + col.value}>
          <div
            className="bar"
            ref={itemRef}
            style={{
              width: (percent) + "%",
              background: col.color
            }}
          >
            <span className="bar-label" style={{visibility: 'hidden'}}>
              {(col.percent * 100).toFixed() + '%'}
            </span>
          </div>
        </Tooltip>
      );
    });

    return (
      <div className="chart-bar">
        <div className="chart-bar-title" style={{width: this.props.legendWidth + 'px'}}>
          <Tooltip title={row.value}><span>{row.value}</span></Tooltip>
        </div>
        <div className="chart-bar-pieces">{colBars}</div>
      </div>
    );
  }
}

const SplitChartBarWT = withTranslation()(SplitChartBar);

class SplitChart extends Component {
  render() {
    const {data, mode} = this.props;

    if (data.length === 0) return null;

    const maxCount = Math.max(...data.map(row => row.totalCount));

    const legendWidth = getLegendWidth(data, 'value');

    const transformedData = data.map(row => {
      const cols = row.cols.reduce((filtered, col) => {
        if (col.count > 0) {
          let percent = col.count / row.totalCount;
          let widthPercent;

          if (mode === SPLIT_CHART_MODE_RELATIVE) {
            widthPercent = col.count / maxCount;
          } else {
            widthPercent = percent;
          }

          filtered.push({
            ...col,
            percent,
            widthPercent
          });
        }

        return filtered;
      }, []);

      return {
        ...row,
        cols
      }
    });

    return (
      <Fragment>
        <div className="split-chart">
          {transformedData.map(row => (
            <SplitChartBarWT
              legendWidth={legendWidth}
              showLabels={this.props.showLabels}
              mode={this.props.mode}
              key={row.value}
              row={row}
              rowTitle={this.props.rowTitle}
              colTitle={this.props.colTitle}
            />
          ))}
        </div>

        <div className="split-chart-index">
          {data[0].cols.map(col => (
            <div key={col.value}>
              <div style={{backgroundColor: col.color}}/>
              <span>{col.value}</span>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

export default SplitChart;
