import React from 'react';
import Grid from "@material-ui/core/Grid";
import {getSentimentColor} from "../../../utilities/getColor";
import WordCloudLegendItem from "./word-cloud-legend-item";
import {useTranslation} from "react-i18next";

const responseTypes = ["Negative", "Positive", "Void"];

const WordCloudLegendComponent = props => {
  const {t} = useTranslation();

  return (
    <Grid container justify="center" alignItems="center">
      {responseTypes.map((type) => (
        <WordCloudLegendItem
          key={type}
          label={t('sentiment_' + type, type)}
          color={getSentimentColor(type)}
          checked={props[type]}
          onChange={() => props.onChange(type)}
        />
      ))}
    </Grid>
  );
};

export default WordCloudLegendComponent;