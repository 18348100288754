export const LOAD_SINGLE_RESPONSE_REQUEST = '[EDIT_SINGLE_RESPONSE] LOAD_SINGLE_RESPONSE_REQUEST';
export const LOAD_SINGLE_RESPONSE_SUCCESS = '[EDIT_SINGLE_RESPONSE] LOAD_SINGLE_RESPONSE_SUCCESS';

export const RESPONSE_FIELD_CHANGED = '[EDIT_SINGLE_RESPONSE] RESPONSE_FIELD_CHANGED';

export const EDIT_SINGLE_RESPONSE_REQUEST = '[EDIT_SINGLE_RESPONSE] EDIT_SINGLE_RESPONSE_REQUEST';

export const loadSingleResponseRequest = rowNumber => ({type: LOAD_SINGLE_RESPONSE_REQUEST, rowNumber});
export const loadSingleResponseSuccess = response => ({type: LOAD_SINGLE_RESPONSE_SUCCESS, response});

export const responseFieldChanged = (name, value) => ({type: RESPONSE_FIELD_CHANGED, name, value});
export const editSingleResponseRequest = history => ({type: EDIT_SINGLE_RESPONSE_REQUEST, history});
