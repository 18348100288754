export const CHANGE_REPORT_ID = "[REPORT] CHANGE_REPORT_ID";

export const SET_VALUE_SYSTEM = "[REPORT] SET_VALUE_SYSTEM";

export const TOGGLE_SHARE_MODAL = "[REPORT] TOGGLE_SHARE_MODAL";
export const CHANGE_PUBLICITY_OF_REPORT = "[REPORT] CHANGE_PUBLICITY_OF_REPORT";
export const CHANGE_PUBLICITY_OF_REPORT_DONE = "[REPORT] CHANGE_PUBLICITY_OF_REPORT_DONE";

export const SET_REPORT_MODE = '[REPORT] SET_REPORT_MODE';

export const SET_TOPICS_MODAL_OPEN = '[REPORT] SET_TOPICS_MODAL_OPEN';
export const FETCH_TOPIC_NAMESPACES_REQUEST = '[REPORT] FETCH_TOPIC_NAMESPACES_REQUEST';
export const FETCH_TOPIC_NAMESPACES_SUCCESS = '[REPORT] FETCH_TOPIC_NAMESPACES_SUCCESS';
export const FETCH_TOPIC_NAMESPACES_FAILURE = '[REPORT] FETCH_TOPIC_NAMESPACES_FAILURE';
export const ADD_TOPICS_REQUEST = '[REPORT] ADD_TOPICS_REQUEST';
export const ADD_TOPICS_DONE = '[REPORT] ADD_TOPICS_DONE';


export const changeReportId = reportId => ({ type: CHANGE_REPORT_ID, reportId });
export const setValueSystem = valueSystem => ({type: SET_VALUE_SYSTEM, valueSystem});

export const toggleShareModal = () => ({type: TOGGLE_SHARE_MODAL});
export const changePublicityOfReport = () => ({type: CHANGE_PUBLICITY_OF_REPORT});
export const changePublicityOfReportDone = () => ({type: CHANGE_PUBLICITY_OF_REPORT_DONE});

export const setReportMode = mode => ({type: SET_REPORT_MODE, mode});

export const setTopicsModalOpen = isOpen => ({type: SET_TOPICS_MODAL_OPEN, payload: isOpen});
export const fetchTopicNamespacesRequest = () => ({type: FETCH_TOPIC_NAMESPACES_REQUEST});
export const fetchTopicNamespacesSuccess = namespaces => ({type: FETCH_TOPIC_NAMESPACES_SUCCESS, payload: namespaces});
export const fetchTopicNamespacesFailure = () => ({type: FETCH_TOPIC_NAMESPACES_FAILURE});

export const addTopicsRequest = namespace => ({type: ADD_TOPICS_REQUEST, payload: namespace});
export const addTopicsDone = () => ({type: ADD_TOPICS_DONE});