import React from "react";
import {useSelector} from "react-redux";

const HideFrom = ({children, guest, staff}) => {
  const user = useSelector(state => state.user);

  if (!user) return null;

  if (guest && user.is_guest) return null;

  if (staff && user.is_staff) return null;

  return children;
};

export default React.forwardRef((props, ref) => (
  <HideFrom {...props} innerRef={ref} />
));
