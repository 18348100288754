import React, { Component } from 'react';
import {connect} from "react-redux";
import HBIndexChartComponent from "./hb-index-chart-component";
import {setSelectedFilterName, clearHbIndexChartData, loadHbIndexChartDataRequest} from "./hb-index-chart-action-creators";
import {addNotification} from "../notifications/notifications-actions";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";

class HBIndexChartContainer extends Component {
  onFiltersChange = () => {
    this.props.loadHbIndexChartDataRequest();
  };

  componentDidMount() {
    this.onFiltersChange();
    this.props.subscribeToFilters(this.onFiltersChange);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromFilters(this.onFiltersChange);
    this.props.clearData();
  }

  render() {
    return (
      <HBIndexChartComponent
        {...this.props}
        loadData={this.onFiltersChange}
      />
    )
  }
}

const mapStateToProps = state => ({
  filters: state.filters.flatFilters,
  data: state.hbIndexChart.data,
  selectedFilterName: state.hbIndexChart.selectedFilterName,
  secondVariableName: state.hbIndexChart.secondVariableName,
  error: state.hbIndexChart.error,
  loading: state.hbIndexChart.loading
});

const mapDispatchToProps = dispatch => ({
  loadHbIndexChartDataRequest: () => dispatch(loadHbIndexChartDataRequest()),
  setSelectedFilterName: filterName => dispatch(setSelectedFilterName(filterName)),
  clearData: () => dispatch(clearHbIndexChartData()),
  subscribeToFilters: subscription => dispatch(subscribeToFilters(subscription)),
  unsubscribeFromFilters: subscription => dispatch(unsubscribeFromFilters(subscription)),
  addNotificationRequest: (status, message) => dispatch(addNotification({status, message: [message]}))
});

export default connect(mapStateToProps, mapDispatchToProps)(HBIndexChartContainer);
