import React from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


const CheckCircle = ({active}) => (
  <CheckCircleOutlineIcon
    style={{
      color: active ? 'black' : 'green',
    }}
    fontSize="large"
  />
);

export default CheckCircle;