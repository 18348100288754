import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import TermCell from "./TermCell";
import {CONTEXTS, SEARCH_TABLE_PER_PAGE} from "../taxonomy-constants";
import Pagination from "./Pagination";
import {Alert} from "@material-ui/lab";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CommentIcon from "@material-ui/icons/Comment";
import {
  setDeleteWordsDialogOpen,
  setExamplesDialogOpen,
  setTermForExamples,
  setWordsToDelete
} from "../taxonomy-actions";

const useStyles = makeStyles(theme => ({
  checkboxCol: {
    width: 1,
  },
  termCol: {
    width: '30%',
  },
  emoCol: {
    width: '70%'
  },
  selectAllLink: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1)
  },
  translationList: {
    paddingLeft: theme.spacing(2),
  },
}));

const SearchWordsTable = ({searchWords, selectedWordsIds, setSelectedWordsIds, showTranslation}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const total = searchWords.length;
  const plannedTo = page * SEARCH_TABLE_PER_PAGE;
  const to = plannedTo > searchWords.length ? searchWords.length : plannedTo;
  const from = SEARCH_TABLE_PER_PAGE * (page - 1);

  const pageWords = searchWords.slice(from, to);

  const actualCountOnPage = to - from;

  const toggleAllOnPage = e => {
    if (e.target.checked) {
      setSelectedWordsIds(prev => {
        return [...prev, ...pageWords.filter(w => !prev.includes(w._id.$oid)).map(w => w._id.$oid)]
      });
    } else {
      setSelectedWordsIds(prev => {
        const currentPageIds = pageWords.map(w => w._id.$oid);
        return prev.filter(prevId => !currentPageIds.includes(prevId))
      });
    }
  };

  const toggleWord = id => {
    setSelectedWordsIds(prev => {
      if (prev.includes(id)) {
        return prev.filter(_id => _id !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const selectAllWords = () => {
    setSelectedWordsIds(searchWords.map(w => w._id.$oid));
  };

  const deselectAllWords = () => {
    setSelectedWordsIds([]);
  };

  const pageWordsCheckedNumber = pageWords.filter(w => selectedWordsIds.includes(w._id.$oid)).length;
  const allTermsOnPageAreChecked = pageWordsCheckedNumber === actualCountOnPage || pageWordsCheckedNumber === total;

  const someTermsOnOtherPagesAreNotChecked = allTermsOnPageAreChecked && selectedWordsIds.length < total;

  const selectAllWordsAlert = (
    <Grid item>
      <Alert severity="info">
        {t('all_terms_on_page_are_selected', 'All {{actualCountOnPage}} terms on current page are selected. There are no selected terms on other pages.')}
        <Link className={classes.selectAllLink} onClick={selectAllWords}>
          {t('select_all_terms', 'Select all terms')}
        </Link>
      </Alert>
    </Grid>
  );

  const resultingAlert = (
    <Grid item>
      <Alert severity="info">
        {t('x_out_of_y_terms_are_selected', '{{selectedWords}} out of {{total}} terms are selected.', {selectedWords: selectedWordsIds.length, total})}
        <Link className={classes.selectAllLink} onClick={deselectAllWords}>
          {t('deselect_all_terms', 'Deselect all terms')}
        </Link>
      </Alert>
    </Grid>
  )

  return (
    <Grid container direction="column" spacing={2} wrap="nowrap">
      <Pagination
        from={from}
        to={to}
        page={page}
        setPage={setPage}
        total={total}
      />
      {someTermsOnOtherPagesAreNotChecked && selectAllWordsAlert}
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.checkboxCol}>
                <Checkbox
                  onChange={toggleAllOnPage}
                  checked={allTermsOnPageAreChecked}
                  indeterminate={!allTermsOnPageAreChecked && pageWords.filter(w => selectedWordsIds.includes(w._id.$oid)).length > 0}
                />
              </TableCell>
              <TableCell className={classes.termCol}>{t('term', 'Term')}</TableCell>
              {showTranslation && (
                <TableCell>{t('original_words', 'Original words')}</TableCell>
              )}
              <TableCell className={classes.emoCol}>{t('contexts', 'Contexts')}</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageWords.map(word => {
              const showExamples = e => {
                e.stopPropagation();
                dispatch(setTermForExamples(word.term));
                dispatch(setExamplesDialogOpen(true))
              };

              const deleteWord = e => {
                e.stopPropagation();
                dispatch(setWordsToDelete([word]));
                dispatch(setDeleteWordsDialogOpen(true));
              };

              return (
                <TableRow key={word._id.$oid} hover onClick={() => toggleWord(word._id.$oid)}>
                  <TableCell>
                    <Checkbox
                      onChange={() => toggleWord(word._id.$oid)}
                      onClick={e => e.stopPropagation()}
                      value={word._id.$oid}
                      checked={selectedWordsIds.includes(word._id.$oid)}
                      disableRipple
                    />
                  </TableCell>
                  <TermCell {...word} />
                  {showTranslation && (
                    <TableCell>
                      {word.original_words && (
                        <ul className={classes.translationList}>
                          {word.original_words.map(w => (
                            <li key={w}>{w}</li>
                          ))}
                        </ul>
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    {word.contexts.map(ctx => (
                      <div key={ctx.context_name}>
                        <strong>{CONTEXTS[ctx.context_name].label}</strong>{': '}
                        {ctx.coding.length > 0 ? ctx.coding.join(', ') : t('none', 'None')}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell style={{whiteSpace: 'nowrap'}}>
                    <IconButton onClick={deleteWord}>
                      <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton onClick={showExamples}>
                      <CommentIcon/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Pagination
        from={from}
        to={to}
        page={page}
        setPage={setPage}
        total={total}
      />
      {selectedWordsIds.length > 0 && resultingAlert}
    </Grid>
  );
};

export default SearchWordsTable;