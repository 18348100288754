
export const getSelectedText = () => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  const sentenceContainer = findAncestor(range.commonAncestorContainer, 'div');

  let start = 0;
  for (let c of sentenceContainer.children) {
    if (!c.contains(range.startContainer)) {
      start += c.textContent.length;
    } else {
      start += range.startOffset;
      break;
    }
  }

  const text = selection.toString();
  const end = start + text.length;

  return {start, end, text};
};

function findAncestor (el, sel) {
  while (true) {
    if (el.nodeType !== Node.TEXT_NODE && (el.matches || el.matchesSelector).call(el,sel)) {
       break;
    }

    el = el.parentElement;
  }

  return el;
}