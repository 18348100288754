import {
  SINGLE_RESPONSE_MODAL_HIDE,
  SINGLE_RESPONSE_MODAL_SHOW,
  SINGLE_RESPONSE_REQUEST, SINGLE_RESPONSE_SUCCESS
} from "./response-single-actions";

const initialState = {
  modalIsShown: false,
  responses: null,
  responsesTotal: null,
  loading: false,
  reportId: null
};

const responseSingleReducer = (state=initialState, action) => {
  switch (action.type) {
    case SINGLE_RESPONSE_MODAL_SHOW:
      return {...state, modalIsShown: true, responsesTotal: null};
    case SINGLE_RESPONSE_MODAL_HIDE:
      return {...state, modalIsShown: false};
    case SINGLE_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true,
        responseNumber: action.responseNumber,
        responses: null
      };
    case SINGLE_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        responses: action.responses,
        responsesTotal: action.responsesTotal
      };
    default:
      return state;
  }
};

export default responseSingleReducer;