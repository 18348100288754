import React, {useEffect, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, Route, Switch, useParams} from "react-router-dom";
import {Box, Grid, Paper, Tab, Tabs, Typography} from "@material-ui/core";

import MuiResponsiveDiv from "../../components/MuiResponsiveDiv/MuiResponsiveDiv";
import EditUploadFile from "./containers/EditUploadFile";
import EditColumns from "./containers/EditColumns";
import AddNewColumns from "./containers/AddNewColumns";
import EditTaxonomies from "./containers/EditTaxonomies";

import EditBasicInfo from "./containers/EditBasicInfo";
import {preLoadCreateReportState, resetState} from "./create-report-action-creators";
import EditColumnsNames from "./containers/EditColumnsNames";


const EditReport = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const mode = params.mode || '';

  const EDIT_MODES_ROUTER = useMemo(() => [
    {path: '', title: t('basic_info', 'Basic info'), component: EditBasicInfo},
    {path: 'add-replace-rows', title: t('add_replace_rows', 'Add/replace rows'), component: EditUploadFile},
    {path: 'edit-columns', title: t('configure_columns', 'Configure columns'), component: EditColumns},
    {path: 'edit-taxonomies', title: t('taxonomies_ignore_words', 'Taxonomies/ignore words'), component: EditTaxonomies},
    {path: 'add-new-columns', title: t('add_new_columns', 'Add new columns'), component: AddNewColumns},
    {path: 'columns-names', title: t('edit_column_names', 'Edit column names'), component: EditColumnsNames},
  ], [t]);

  const reportId = useSelector(state => state.report.reportId);

  useEffect(() => {
    dispatch(preLoadCreateReportState({reportId}));

    return () => {
      dispatch(resetState());
    }
  }, [dispatch, reportId]);

  return (
    <MuiResponsiveDiv>
      <Paper>
        <Grid container direction="column">
          <Grid item xs>
            <Typography variant="h4" component={Box} p={2}>{t('edit_report', 'Edit report')}</Typography>
          </Grid>
          <Grid item xs>
            <Box p={2}>
              <Paper elevation={2}>
                <Tabs
                  variant="scrollable"
                  value={mode}
                  indicatorColor="primary"
                >
                  {EDIT_MODES_ROUTER.map(mode => (
                    <Tab
                      key={mode.path}
                      label={mode.title}
                      value={mode.path}
                      component={Link}
                      to={`/report/${reportId}/edit/${mode.path}`}
                    />
                  ))}
                </Tabs>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs>
            <Switch>
              {EDIT_MODES_ROUTER.map(mode => (
                <Route
                  key={mode.path}
                  path={`/report/${reportId}/edit/${mode.path}`}
                  exact
                  component={mode.component}
                />
              ))}
            </Switch>
          </Grid>
        </Grid>
      </Paper>
    </MuiResponsiveDiv>
  );
};

export default EditReport;