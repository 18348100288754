import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {CircularProgress, Grid, IconButton, makeStyles, Paper, TextField, Typography} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PrimaryEmotionWords from "../components/PrimaryEmotionWords";
import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import {createAndCopyTable} from "../../charts/functions";
import {createColor} from "../../../utilities/getColor";
import {addNotification} from "../../notifications/notifications-actions";
import {subscribeToFilters, unsubscribeFromFilters} from "../../filters/filters-actions";
import {getPrimaryEmotionsFullReportRequest, primarySearchChange} from "../full-report-charts-actions";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  p2: {
    padding: theme.spacing(2)
  },
  centerProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  line: {
    width: '10px',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  terms: {
    lineHeight: '30px',
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& > a:hover': {
      textDecoration: 'underline',
      color: 'blue'
    }
  },
  emotionName: {
    textAlign: 'center',
    marginBottom: theme.spacing(1)
  }
}));

const PrimaryEmotionsFullReport = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const SORT_MODES = {
    'count': {
      title: t('copy_sorted_by_count', 'Copy sorted by count'),
      sortedBy: t('count_u', 'count'),
      sortFunction: (a, b) => a.count > b.count ? -1 : 1,
    },
    'term': {
      title: t('copy_sorted_by_term', 'Copy sorted by term'),
      sortedBy: t('term_u', 'term'),
      sortFunction:(a, b) => a.term > b.term ? 1 : -1,
    },
    'primary': {
      title: t('copy_sorted_by_primary', 'Copy sorted by primary'),
      sortedBy: t('primary_emotion_u', 'primary emotion'),
      sortFunction: (a, b) => a.primary > b.primary ? 1 : -1
    }
  };

  const {
    data, loading, searchValue
  } = useSelector(state => ({
    data: state.fullReport.primaryEmotionsFullReportProcessed,
    loading: state.fullReport.primaryEmotionsFullReportLoading,
    searchValue: state.fullReport.primaryEmotionsFullReportSearchValue
  }), shallowEqual);

  const reportId = useSelector(state => state.report.reportId);
  const valueSystem = useSelector(state => state.report.valueSystem);

  const primaryColors = valueSystem['primary_color'];

  const onFiltersChange = () => {
    dispatch(getPrimaryEmotionsFullReportRequest());
  };

  useEffect(() => {
    onFiltersChange();
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    }
  }, []);

  const copyByPrimary = primaryName => {
    const primaryData = data[primaryName];
    const rows = Object.keys(primaryData).map(term => ({term, count: primaryData[term]}));
    const columns = [
      {name: t('term', 'Term'), key: 'term'},
      {name: t('count', 'Count'), key: 'count'},
    ];

    createAndCopyTable(rows, columns);
    dispatch(addNotification({
      status: "Success",
      message: [t('term_counts_is_copied_to_clipboard', `Term counts for "{{primaryName}}" is copied to clipboard`, {primaryName})]
    }));
  };

  const copyAll = sortMode => {
    const rows = Object.keys(data).reduce((acc, primaryName) => {
      const primaryData = data[primaryName];

      Object.keys(primaryData).forEach(term => {
        acc.push({primary: primaryName, term, count: primaryData[term]});
      });

      return acc;
    }, []);

    rows.sort(SORT_MODES[sortMode].sortFunction);

    const columns = [
      {name: t('primary_emotion', 'Primary emotion'), key: 'primary'},
      {name: t('term', 'Term'), key: 'term'},
      {name: t('count', 'Count'), key: 'count'},
    ];

    createAndCopyTable(rows, columns);
    dispatch(addNotification({
      status: "Success",
      message: [t(
        'all_term_counts_sorted_by_is_copied_to_clipboard',
        `All term counts sorted by {{sortedBy}} is copied to clipboard`,
        {sortedBy: SORT_MODES[sortMode].sortedBy}
      )]
    }));
  };

  const menuItems = Object.keys(SORT_MODES).map(modeName => ({
    text: SORT_MODES[modeName].title,
    icon: <FileCopyIcon/>,
    onClick: () => copyAll(modeName)
  }));

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs>
        <Paper elevation={2} className={classes.p2}>
          <Typography variant="h5" className={classes.emotionName}>{t('primary_emotions_full_report', 'Primary Emotions Full Report')}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                label={t('search_for_words', 'Search for words')}
                placeholder={t('type_to_search_for_words', "Type to search for words")}
                value={searchValue}
                onChange={e => dispatch(primarySearchChange(e.target.value))}
              />
            </Grid>
            <Grid item>
              <DropdownMenu items={menuItems}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {loading && (
        <Grid item xs>
          <Paper elevation={2} className={`${classes.p2} ${classes.centerProgress}`}>
            <CircularProgress />
          </Paper>
        </Grid>
      )}

      {data && Object.keys(data).map(primaryName => (
        <Grid item xs key={primaryName}>
          <Paper elevation={2}>
            <Grid container>
              <Grid item className={classes.line} style={{background: createColor(primaryColors[primaryName])}}/>
              <Grid item xs className={`${classes.p2} ${classes.terms}`}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h5" className={classes.emotionName}>{primaryName}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      title={t('copy_primary_data_to_clipboard', `Copy "{{primaryName}}" data to clipboard`, {primaryName})}
                      onClick={() => copyByPrimary(primaryName)}
                    >
                      <FileCopyIcon/>
                    </IconButton>
                  </Grid>
                </Grid>

                <PrimaryEmotionWords data={data[primaryName]} reportId={reportId} isSearch={!!searchValue}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default PrimaryEmotionsFullReport;