import React from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  progressContainer: {
    position: 'relative'
  },
  progressLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 40
  }
}));

const InProgress = ({progress}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  progress = progress || 0;

  return (
    <>
      <Grid item className={classes.progressContainer}>
        <CircularProgress size={150} variant={progress === 0 ? 'indeterminate' : 'determinate'} value={progress} />
        <Typography variant="caption" component="div" color="textSecondary" className={classes.progressLabel}>{`${Math.floor(progress)}%`}</Typography>
      </Grid>
      <Grid item xs>
        <Typography variant="h4">
          {t('report_almost_ready', 'Your report is almost ready...')}
        </Typography>
      </Grid>
    </>
  );
};

export default InProgress;