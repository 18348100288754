import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {createQueryString, parseQueryString} from "../reports-functions";
import {Scrollbars} from "rc-scrollbars";
import {CircularProgress, Grid, MenuItem, MenuList, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  title: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`
  },
  loading: {
    padding: theme.spacing(2)
  }
}));

const TagsMenu = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  let tags = useSelector(state => state.reports.viewingArchived ? state.reports.tags : state.reports.filteredTags);
  const loading = useSelector(state => state.reports.loading);
  const location = useLocation();
  const params = parseQueryString(location.search);
  const selectedTags = params.tags ? params.tags.split('|') : [];

  delete params.tags;
  delete params.page;

  const searchString = createQueryString(params);

  return (
    <Scrollbars>
      <Typography variant="h5" className={classes.title}>{t('tags', 'Tags')}</Typography>
      <Divider/>
      {loading ? (
        <Grid container justify="center" className={classes.loading}>
          <CircularProgress/>
        </Grid>
      ) : (
        <MenuList>
          {tags.map(t => (
            <MenuItem selected={selectedTags.includes(t)} key={t} component={Link} to={"/?" + searchString + '&tags=' + t}>
              <LabelOutlinedIcon style={{padding: '5px'}}/> {t}
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Scrollbars>
  );
};

export default TagsMenu;