import React, {Fragment, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

import {makeStyles} from "@material-ui/core/styles";
import {Box, CircularProgress, Grid, Link as MuiLink, Paper, Typography} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ArchiveIcon from '@material-ui/icons/Archive';

import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import ReportCard from "./components/ReportCard";
import DrawerWithContent from "../../components/Drawer/DrawerWithContent";
import TagsMenu from "./components/TagsMenu";
import ReportsPagination from "./components/ReportsPagination";

import {requestSupportModalShow} from "../request-support/request-support-actions";
import {progressBarCancelAll} from "../create-report/create-report-action-creators";

import {createGrid} from "./styles";

import './reports.css';

const useStyles = makeStyles(theme => ({
  ...createGrid(theme, 300),
  topAndBottomBar: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  }
}));

const ReportsContainer = props => {
  const {t} = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    return () => props.progressBarCancelAll();
  }, [props.progressBarCancelAll]);

  let reports;

  if (props.reports.length > 0) {
    reports = (
      <div className={classes.reportsGrid}>
        {props.reports.map((report, i) => (
          <ReportCard
            key={report['report_id']}
            targetIndex={i}
            report={report}
            user={props.user}
            progress={props.progress[report['report_id']]}
          />
        ))}
      </div>
    );
  } else if (props.loading) {
    reports = (
      <Grid item xs container alignItems="center" justify="center" spacing={2}>
        <Box p={4}>
          <CircularProgress/>
        </Box>
      </Grid>
    );
  } else {
    reports = (
      <Grid item xs>
        <Paper component={Box} p={4}>
          {t('sorry_no_reports_exist_by_search', 'Sorry, no reports exist by this search criteria.')}
        </Paper>
      </Grid>
    )
  }

  const commonMenuActions = [
    {
      text: t('create_report', 'Create report'),
      icon: <AddIcon/>,
      component: Link,
      props: {
        to: "/create-report"
      }
    },
    {
      text: t('request_support', 'Request Support'),
      icon: <ContactSupportIcon/>,
      onClick: props.requestSupportModalShow
    }
  ];

  let pageTitle = t('reports', 'Reports');
  let title = t('reports', 'Reports');

  if (props.viewingArchived) {
    pageTitle = t('archived_reports', 'Archived reports');

    title = (
      <Fragment>
        <MuiLink component={Link} to="/">{t('reports', 'Reports')}</MuiLink> / {t('archived_reports', 'Archived reports')}
      </Fragment>
    );
  } else {
    commonMenuActions.push({
      text: t('show_archived_items', 'Show archived items'),
      icon: <ArchiveIcon/>,
      component: Link,
      props: {
        to: "/?archived"
      }
    });
  }

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <DrawerWithContent drawerContent={<TagsMenu/>}>
        <Grid container direction="column" spacing={1} justify="flex-start">
          <Grid item xs>
            <Paper className={classes.topAndBottomBar}>
              <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h5">
                    {title}
                  </Typography>
                </Grid>

                <Grid item>
                  <ReportsPagination/>
                </Grid>

                <Grid item>
                  <DropdownMenu items={commonMenuActions}/>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {reports}

          {props.reports.length > 0 && !props.viewingArchived && (
            <Grid item xs>
              <Paper className={classes.topAndBottomBar}>
                <Grid container justify="center">
                  <Grid item>
                    <ReportsPagination/>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

        </Grid>
      </DrawerWithContent>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  reports: state.reports.reports,
  loading: state.reports.loading || state.reports.searchLoading,
  viewingArchived: state.reports.viewingArchived,
  progress: state.createReport.progress,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  progressBarCancelAll: () => dispatch(progressBarCancelAll()),
  requestSupportModalShow: () => dispatch(requestSupportModalShow()),
});


export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
