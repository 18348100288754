import {all, call, cancel, delay, fork, put, select, take, takeEvery} from 'redux-saga/effects';
import {addNotification} from "../notifications/notifications-actions";
import API from "../../API";
import reportAPI from "../../API-report";

import {
  ACCEPT_COLUMNS_REQUEST,
  acceptColumnsError,
  acceptColumnsSuccess,
  changeCompany,
  changeDomain, changeLanguage,
  changeValueSystem,
  EDIT_ADD_COLUMNS_CONFIGURE_REQUEST,
  EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST,
  EDIT_COMPANY_REQUEST,
  EDIT_REPORT_INFORMATION_REQUEST,
  EDIT_UPDATE_COLUMNS_REQUEST,
  EDIT_UPDATE_TAXONOMIES_REQUEST,
  EDIT_UPLOAD_FILE_REQUEST,
  editAddColumnsConfigureFailure,
  editAddColumnsConfigureSuccess,
  editAddColumnsUploadFileFailure,
  editAddColumnsUploadFileSuccess,
  editCompanySuccess,
  editReportInformationDone,
  editToggleCompany,
  editUpdateColumnsError,
  editUpdateColumnsSuccess,
  editUpdateTaxonomiesError,
  editUpdateTaxonomiesSuccess,
  editUploadFileError,
  editUploadFileSuccess,
  GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST,
  getColumnsNamesMapConfigFailure,
  getColumnsNamesMapConfigSuccess,
  LOAD_COLUMNS_REQUEST,
  LOAD_COMPANIES_REQUEST,
  LOAD_DATA_FOR_STEP_THREE_REQUEST,
  LOAD_DOMAINS_REQUEST, LOAD_LANGUAGES_REQUEST,
  LOAD_PROGRESS_BAR_REQUEST,
  LOAD_VALUE_SYSTEMS_REQUEST,
  loadColumnsError,
  loadColumnsSuccess,
  loadCompaniesError,
  loadCompaniesSuccess,
  loadDataForStepThreeError,
  loadDataForStepThreeSuccess,
  loadDomainsError,
  loadDomainsSuccess, loadLanguagesError, loadLanguagesSuccess,
  loadValueSystemsError,
  loadValueSystemsSuccess,
  PROGRESS_BAR_CANCEL,
  PROGRESS_BAR_CANCEL_ALL,
  PROGRESS_BAR_COMPLETE,
  progressBarCancel,
  progressBarComplete,
  progressBarProgress,
  RENAME_REPORT_REQUEST,
  renameReportError,
  renameReportSuccess,
  STEP_THREE_SAVE_REQUEST,
  stepThreeActivate,
  stepThreeSaveError,
  stepThreeSaveSuccess,
  stepTwoActivate,
  toggleRenameEditing,
  UPDATE_COLUMNS_NAMES_MAP_REQUEST,
  updateColumnsNamesMapDone,
  UPLOAD_REQUEST,
  uploadError,
  uploadSuccess,
  WRITE_TAGS_REQUEST
} from "./create-report-action-creators";
import deepCopy from "../../utilities/deepCopy";

import {loadReportInfoRequest} from "../report-info/load-report-info-request-saga";
import {updateSingleReport} from "../reports/reports-actions";
import {normalizeSingleReportInfo} from "../reports/reports-sagas";
import {
  LOAD_COLUMNS_MODE_ADDING_COLUMNS,
  LOAD_COLUMNS_MODE_CREATING,
  LOAD_COLUMNS_MODE_EDITING,
  MODE_EDITING
} from "./constants";
import i18n from "../../i18n";
import {createErrorMessage} from "../../utilities/createErrorMessage";


export function* loadCompanies() {
  try {
    const result = yield API.showCompaniesOfUser();
    const companies = result.data;
    yield put(loadCompaniesSuccess(companies));

    const firstCompany = companies[0];
    if (firstCompany) {
      yield put(changeCompany(firstCompany));
    }
  } catch (e) {
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_load_user_companies','Could not load companies of user')]}));
    yield put(loadCompaniesError());
  }
}

export function* loadValueSystems() {
  try {
    const domain = yield select(state => state.createReport.domain);

    if (!domain) {
      return;
    }

    const result = yield API.getValueSystemList(domain);
    const valueSystems = result.data;

    yield put(loadValueSystemsSuccess(valueSystems));

    const firstValueSystem = valueSystems[0];
    if (firstValueSystem) {
      yield put(changeValueSystem(firstValueSystem));
    }
  } catch (error) {
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_load_value_syst_list','Could not load value systems list')]}));
    yield put(loadValueSystemsError());
  }
}

export function* loadDomains() {
  try {
    const result = yield API.getDomains();
    const domains = result.data;

    yield put(loadDomainsSuccess(domains));

    const firstDomain = domains[0];

    if (firstDomain) {
      yield put(changeDomain(firstDomain));
    }

  } catch (error) {
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_load_domains','Could not load domains')]}));
    yield put(loadDomainsError());
  }
}

export function* loadLanguages({company}) {
  try {
    const result = yield API.showLanguageChoices(company);
    const languages = result.data;

    yield put(loadLanguagesSuccess(languages));

    const firstLanguage = languages[0];

    if (firstLanguage) {
      yield put(changeLanguage(firstLanguage));
    }

  } catch (error) {
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_load_languages','Could not load languages')]}));
    yield put(loadLanguagesError());
  }
}

export function* upload() {
  const {
    file,
    reportName,
    company,
    valueSystem,
    domain,
    language,
    logoFile,
    description,
    reportId
  } = yield select(state => state.createReport);

  try {
    const response = yield API.uploadCSVForCelery({
      file,
      reportName,
      company,
      valueSystem,
      domain,
      language,
      logoFile,
      description,
      reportId
    });
    const newReportId = response.data.report_id.$oid;
    yield put(uploadSuccess({reportId: newReportId, filename: file.name}));
    yield put(stepTwoActivate());
  } catch (error) {
    yield put(addNotification({status: "Error", message: [getErrorMessage(error)]}));
    yield put(uploadError());
  }
}

export function* loadColumns({mode}) {
  const reportId = yield select(state => state.createReport.reportId);

  try {
    const result = yield call(API.configureReport, reportId);
    const columns = normalizeColumns(result.data, mode);
    yield put(loadColumnsSuccess(columns));
  } catch (error) {
    yield put(loadColumnsError());
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('error_loading_cols_reload_page','Error occured while loading columns. Please reload the page.')]
    }))
  }
}

export function* acceptColumns() {
  const reportId = yield select(state => state.createReport.reportId);
  const columns = yield select(state => state.createReport.columns);

  try {
    const denormalized = denormalizeColumns(columns);
    const response = yield API.writeConfigToReport({reportId, config: denormalized});

    if (response.data['ERROR']) {
      yield put(acceptColumnsError());
      return yield put(addNotification({status: "Error", message: [response.data['ERROR']]}));
    }

    yield put(acceptColumnsSuccess());
    yield put(stepThreeActivate());
  } catch (error) {
    yield put(acceptColumnsError());
    yield put(addNotification({status: "Error", message: [getErrorMessage(error)]}));
  }
}

export function* loadDataForStepThree({mode}) {
  const reportId = yield select(state => state.createReport.reportId);
  try {
    const [taxonomyResult, ignoreWordsResult, columnsResult, ppIgnoreWordsResult] = yield all([
      call(API.showTaxonomy, reportId),
      call(API.showIgnoreWords, reportId),
      call(API.configureReport, reportId),
      call(API.prepopulateIgnoreWords, reportId),
    ]);

    let AVUCount = 0;

    if (mode !== MODE_EDITING) {
      const AVUCountResponse = yield reportAPI.predictAVUCount(reportId, columnsResult.data['existing_config']);
      AVUCount = AVUCountResponse.data['SUCCESS'];
    }

    const existingIgnoreWords = normalizeIgnoreWords(ignoreWordsResult.data['ignore_words']);

    let ignoreWords = ppIgnoreWordsResult.data['SUCCESS'];

    if (mode === MODE_EDITING) {
      ignoreWords = existingIgnoreWords;
    }

    yield put(loadDataForStepThreeSuccess({
      taxonomies: normalizeTaxonomies(taxonomyResult.data['taxonomy']),
      allTaxonomies: taxonomyResult.data['all_taxonomies'],
      ignoreWords,
      predictedAVUCount: AVUCount
    }));
  } catch (error) {
    console.error('ERROR IN loadDataForStepThree', error);

    if (error.response && error.response.data && error.response.data['ERROR']) {
      yield put(loadDataForStepThreeError(error.response.data['ERROR']));
    } else {
      yield put(loadDataForStepThreeError());
    }

    yield put(addNotification({status: "Error", message: [createErrorMessage(error, i18n.t('smthg_went_wrong_reload_page','Something went wrong. Please reload the page.'))]}));
  }
}

export function* saveTaxonomies() {
  const reportId = yield select(state => state.createReport.reportId);
  const taxonomies = yield select(state => state.createReport.taxonomies);
  const ignoreWords = yield select(state => state.createReport.ignoreWords);

  const denormalizedTaxonomies = getValues(taxonomies);
  const denormalizedIgnoreWords = denormalizeIgnoreWords(ignoreWords);

  if (hasDuplicates(denormalizedTaxonomies)) {
    throw new Error(i18n.t('found_dupes_in_taxonomies','Found duplicates in taxonomies'));
  }

  yield all([
    call(API.setTaxonomy, {reportId, taxonomies: denormalizedTaxonomies}),
    call(API.setIgnoreWords, {reportId, ignoreWords: denormalizedIgnoreWords}),
  ]);
}

export function* stepThreeSave({history}) {
  const reportId = yield select(state => state.createReport.reportId);

  try {
    yield saveTaxonomies();
    yield writeTags();

    yield call(API.createPrecookedTaxonomy, reportId);
    yield put(stepThreeSaveSuccess());
    history.push(`/create-report/success?reportId=${reportId}`);

  } catch (error) {
    yield put(stepThreeSaveError());
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('something_went_wrong_reload_page','Something went wrong. Please reload the page.') + error.message]
    }));
  }
}

export function* writeTags() {
  const reportId = yield select(state => state.createReport.reportId);
  const tags = yield select(state => state.createReport.tags);

  try {
    yield API.writeTags(reportId, tags);
    yield put(addNotification({status: "Success", message: [i18n.t('tags_saved_success','Tags saved successfully')]}));
  } catch (error) {
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_write_tags','Could not write tags')]}));
  }
}

const TIME_INTERVAL = 3000;

function* runProgressBar(reportId) {
  try {
    while (true) {
      const response = yield call(API.progressBarCalculation, reportId);
      const progress = response.data.progress;
      yield put(progressBarProgress(reportId, progress));

      if (progress === 100) {
        break;
      }

      yield delay(TIME_INTERVAL);
    }

    let reportInfoResponse;

    while (true) {
      reportInfoResponse = yield call(reportAPI.loadReportInfo, {reportId});

      if (reportInfoResponse.data.status === 'finished') {
        break;
      }

      yield delay(TIME_INTERVAL);
    }

    yield put(updateSingleReport(reportId, normalizeSingleReportInfo(reportInfoResponse.data)));
    yield put(progressBarComplete(reportId));
  } catch (e) {
    console.error('ERROR WHILE RUNNING PROGRESS BAR');
  }
}

export function* startProgressBar({reportId}) {
  const task = yield fork(runProgressBar, reportId);

  const action = yield take(action => {
    return (action.type === PROGRESS_BAR_CANCEL || action.type === PROGRESS_BAR_COMPLETE) && action.reportId === reportId
  });

  yield cancel(task);

  return action;
}

export function* cancelAllProgressBars() {
  const progress = yield select(state => state.createReport.progress);
  const reportIds = Object.keys(progress);

  for (let reportId of reportIds) {
    yield put(progressBarCancel(reportId));
  }
}

export function* editReportInformation({reportInfo}) {
  const reportId = yield select(state => state.report.reportId);

  try {
    yield reportAPI.editReportInformation(reportId, reportInfo.name, reportInfo.description, reportInfo.logoFile, reportInfo.company, reportInfo.domain, reportInfo.clearLogo);
    yield loadReportInfoRequest();
    yield put(addNotification({status: "Success", message: [i18n.t('report_info_update_success','Report info successfully updated')]}));
  } catch (e) {
    console.error('ERROR IN EDIT REPORT INFORMATION', e);
    yield put(addNotification({status: "Error", message: [i18n.t('something_went_wrong_try_again','Something went wrong. Please try again.')]}));
  } finally {
    yield put(editReportInformationDone());
  }
}

export function* renameReportRequest({reportName}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    yield call(API.renameReport, {reportId, reportName});

    yield loadReportInfoRequest();

    yield put(toggleRenameEditing());
    yield put(renameReportSuccess());
  } catch (e) {
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('could_not_rename_report','Something went wrong. Could not rename the report.')]
    }));
    yield put(renameReportError());
  }
}

export function* editCompanyRequest({company}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    yield API.editReportCompany(reportId, company);

    yield loadReportInfoRequest();
  } catch (e) {
    yield put(addNotification({
      status: 'Error',
      message: [i18n.t('could_not_edit_report_company_try_again','Could not edit report company. Please try again later.')]
    }));
  }

  yield put(editToggleCompany());
  yield put(editCompanySuccess());
}

export function* editUploadFileRequest({file, fileType, replaceExisting, history}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    yield call(API.addOrReplaceRowsToReport, {reportId, file, fileType, replaceExisting});
    yield put(editUploadFileSuccess());
    history.push(`/create-report/success?reportId=${reportId}`);
  } catch (e) {
    yield put(addNotification({status: "Error", message: [getErrorMessage(e)]}));
    yield put(editUploadFileError());
  }
}

export function* updateColumns({history, forceDataRecalculation}) {
  const reportId = yield select(state => state.report.reportId);
  const columns = yield select(state => state.createReport.columns);

  try {
    const denormalized = denormalizeColumns(columns);

    yield reportAPI.predictAVUCount(reportId, denormalized);

    const response = yield call(API.writeConfigToReport, {reportId, config: denormalized});

    if (response.data['ERROR']) {
      yield put(editUpdateColumnsError());
      return yield put(addNotification({status: "Error", message: [response.data['ERROR']]}));
    }

    yield call(API.recalcConfigured, {reportId, forceDataRecalculation});
    yield put(editUpdateColumnsSuccess());
    history.push(`/create-report/success?reportId=${reportId}`);
  } catch (error) {
    yield put(editUpdateColumnsError());
    yield put(addNotification({
      status: "Error",
      message: [createErrorMessage(error, i18n.t('error_while_accepting_cols_reload_page','Error occured while accepting columns. Please reload the page.'))]
    }));
  }
}

export function* updateTaxonomies({history}) {
  const reportId = yield select(state => state.report.reportId);
  try {
    yield saveTaxonomies();
    yield call(API.recalcPosTagged, reportId);
    yield put(editUpdateTaxonomiesSuccess());
    history.push(`/create-report/success?reportId=${reportId}`);
  } catch (e) {
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('something_went_wrong_reload_page','Something went wrong. Please reload the page.') + e.message]
    }));
    yield put(editUpdateTaxonomiesError());
  }
}

export function* addColumnsUploadFile({file}) {
  const reportId = yield select(state => state.report.reportId);
  try {
    yield reportAPI.addColumns(reportId, file);
    yield put(editAddColumnsUploadFileSuccess());
  } catch (e) {
    yield put(addNotification({
      status: "Error",
      message: [getErrorMessage(e)]
    }));
    yield put(editAddColumnsUploadFileFailure());
  }
}

export function* addColumnsConfigure({history}) {
  const reportId = yield select(state => state.report.reportId);
  const columns = yield select(state => state.createReport.columns);

  try {
    const denormalized = denormalizeColumns(columns);
    const response = yield API.writeConfigToReport({reportId, config: denormalized});

    if (response.data['ERROR']) {
      yield put(editAddColumnsConfigureFailure());
      return yield put(addNotification({status: "Error", message: [response.data['ERROR']]}));
    }

    yield API.createPrecookedTaxonomy(reportId);
    yield put(editAddColumnsConfigureSuccess());
    history.push(`/create-report/success?reportId=${reportId}`);
  } catch (error) {
    yield put(editAddColumnsConfigureFailure());
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('error_accepting_cols_reload_page','Error occured while accepting columns. Please reload the page.')]
    }))
  }
}

export function* getColumnsNamesMapConfig() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const response = yield reportAPI.getColumnNamesMapConfig(reportId);

    const columnsMap = response.data['column_names_map_config'];

    yield put(getColumnsNamesMapConfigSuccess(columnsMap));
  } catch (e) {
    console.error('ERROR IN GET_COLUMNS_NAMES_MAP_CONFIG', e);

    let message = i18n.t('error_getting_cols_names_config_reload_page','Error occurred while getting columns names map config. Please reload the page.');

    if (e.response && e.response.data['ERROR']) {
      message += i18n.t('server_msg',' Server message: ') + e.response.data['ERROR'];
    }

    yield put(addNotification({status: "Error", message: [message]}));
    yield put(getColumnsNamesMapConfigFailure());
  }
}

export function* updateColumnsNamesMap() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const columnsMap = yield select(state => state.createReport.columnsNamesMapConfig);

    yield reportAPI.updateReportColumnNamesMap(reportId, columnsMap);
    yield put(addNotification({status: "Success", message: ["Successfully updated column names"]}));
  } catch (e) {
    console.error('ERROR IN UPDATE_COLUMNS_NAMES_MAP', e);

    let message = i18n.t('error_updating_cols_name_config_reload_page',"Error occurred while updating column names config. Please reload the page.");

    if (e.response && e.response.data['ERROR']) {
      message += i18n.t('server_msg',' Server message: ')+ e.response.data['ERROR'];
    }

    yield put(addNotification({status: "Error", message: [message]}));
  } finally {
    yield put(updateColumnsNamesMapDone());
  }
}

const normalizeColumns = (data, mode) => {
  let config = [];

  if (mode === LOAD_COLUMNS_MODE_CREATING) {
    config = data['existing_config'].length > 0 ? data['existing_config'] : data['proposed_config'];
  } else if (mode === LOAD_COLUMNS_MODE_EDITING) {
    config = data['existing_config'];
  } else if (mode === LOAD_COLUMNS_MODE_ADDING_COLUMNS) {
    config = data['proposed_config'];
  }

  return config.map(d => {
    const i = data.unique_info.findIndex(u => u.column_name === d.column_name);
    if (i === -1) throw new Error(i18n.t('inconsistent_data_error', "Inconsistent data"));
    return {
      ...d,
      count: data.unique_info[i].count,
      unique: data.unique_info[i].first_20_unique_values,
      dropdownIsShown: false,
      editColumnTypeActive: false,
      columnTypeLimbo: null
    }
  })
};

const denormalizeColumns = data => {
  const copy = deepCopy(data);
  return copy.map(d => {
    return {
      column_type: d.column_type,
      column_name: d.column_name
    }
  })
};

const normalizeTaxonomies = data => data.map(d => ({
  value: d['taxonomy_name'],
  dropdownIsShown: false
}));

const getValues = data => data.map(d => d.value);

const normalizeIgnoreWords = data => {
  const copy = deepCopy(data);
  return copy.join("\n");
};

const denormalizeIgnoreWords = data => {
  return splitWords(data);
};

const getErrorMessage = e => {
  if (e.response && e.response.data.ERROR) {
    return e.response.data.ERROR;
  }

  return i18n.t('something_went_wrong_try_again',"Something went wrong. Please try again.");
};

const splitWords = words => {
  if (!words) return [];
  const preresult = words.split(/\r\n/).join("\n");
  return preresult.split(/[\f\n\r\t\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]/);
};

const hasDuplicates = array => {
  return (new Set(array)).size !== array.length;
};

export default [
  takeEvery(LOAD_COMPANIES_REQUEST, loadCompanies),
  takeEvery(LOAD_VALUE_SYSTEMS_REQUEST, loadValueSystems),
  takeEvery(LOAD_DOMAINS_REQUEST, loadDomains),
  takeEvery(LOAD_LANGUAGES_REQUEST, loadLanguages),
  takeEvery(UPLOAD_REQUEST, upload),
  takeEvery(LOAD_COLUMNS_REQUEST, loadColumns),
  takeEvery(ACCEPT_COLUMNS_REQUEST, acceptColumns),
  takeEvery(LOAD_DATA_FOR_STEP_THREE_REQUEST, loadDataForStepThree),
  takeEvery(STEP_THREE_SAVE_REQUEST, stepThreeSave),
  takeEvery(WRITE_TAGS_REQUEST, writeTags),
  takeEvery(LOAD_PROGRESS_BAR_REQUEST, startProgressBar),
  takeEvery(PROGRESS_BAR_CANCEL_ALL, cancelAllProgressBars),
  takeEvery(RENAME_REPORT_REQUEST, renameReportRequest),
  takeEvery(EDIT_UPLOAD_FILE_REQUEST, editUploadFileRequest),
  takeEvery(EDIT_UPDATE_COLUMNS_REQUEST, updateColumns),
  takeEvery(EDIT_UPDATE_TAXONOMIES_REQUEST, updateTaxonomies),
  takeEvery(EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST, addColumnsUploadFile),
  takeEvery(EDIT_ADD_COLUMNS_CONFIGURE_REQUEST, addColumnsConfigure),
  takeEvery(EDIT_COMPANY_REQUEST, editCompanyRequest),
  takeEvery(EDIT_REPORT_INFORMATION_REQUEST, editReportInformation),
  takeEvery(GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST, getColumnsNamesMapConfig),
  takeEvery(UPDATE_COLUMNS_NAMES_MAP_REQUEST, updateColumnsNamesMap),
];