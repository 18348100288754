import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

import {changeReportId} from "../report/report-actions";
import {loadReportInfoRequest} from "../report-info/report-info-action-creators";

import NgramSearchResults from "../ngram-search/ngram-search-container";
import EditReport from "../create-report/edit-report";
import LiveReport from "../live/live-report";
import FullReport from "../full-report-charts/full-report-charts-container";
import Report from "../report/report-container";
import SplitCharts from "../split-charts/split-charts";
import AddWords from "../add-words/add-words-container";
import PrivateRoute from "../../hoc/private-route/privateRoute";
import BubbleChart from '../bubble-chart/bubble-chart';
import EditSingleReport from "../edit-single-response/edit-single-response";
import NoteModal from "../responses/notes/note-modal";
import NotesMenu from "../responses/notes/notes-menu";
import CreateFiltersFromNotesModal from "../responses/notes/CreateFiltersFromNotesModal";
import {startRegularChecks, stopRegularChecks} from "./report-router-actions";
import ReportNeedsRecalculationMessage from "./components/ReportNeedsRecalculationMessage";
import {clearDrawerState} from "../main/main-actions";
import ShareModal from "../report/components/ShareModal";
import {cleanFiltersState} from "../filters/filters-actions";
import ExpiredUrlModal from "../filters/components/ExpiredUrlModal";
import ReportMetrics from "../report-metrics/report-metrics";
import TopicsModal from "../report/components/TopicsModal";
import {reset} from "../responses/responses-action-creators";

class ReportRouter extends Component {
  initReport = () => {
    const reportId = this.props.match.params.id;

    this.props.changeReportId(reportId);
    this.props.loadReportInfo();
    this.props.startRegularChecks();
    this.props.clearDrawerState();
  };

  componentDidMount() {
    this.initReport();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.resetResponses();
      this.initReport();
    }
  }

  componentWillUnmount() {
    this.props.changeReportId(null);
    this.props.stopRegularChecks();
    this.props.cleanFilters();
    this.props.clearDrawerState();
  }

  render() {
    if (!this.props.reportId || !this.props.valueSystem || !this.props.report) return null;

    if (this.props.report.status === 'unfinished') {
      return <Redirect to={"/create-report/success?reportId=" + this.props.reportId}/>;
    }

    const user = this.props.user;

    return (
      <Fragment>
        <Helmet>
          <title>{this.props.report.report_name}</title>
        </Helmet>
        <Switch>
          <Route path="/report/:id" exact component={Report}/>
          <Route path="/report/:id/full/:reportName" component={FullReport}/>
          <Route path="/report/:id/search/:searchWord/:head/:tail" component={NgramSearchResults}/>
          <PrivateRoute isAllowed={user && !user.is_guest} path="/report/:id/edit/:mode?" component={EditReport}/>
          <Route path="/report/:id/live" component={LiveReport}/>
          <Route path="/report/:id/bubble" component={BubbleChart}/>
          <Route path="/report/:id/split-charts" component={SplitCharts}/>
          <Route path="/report/:id/metrics" component={ReportMetrics}/>
          <PrivateRoute isAllowed={user && !user.is_guest} path="/report/:id/edit-single-report/:rowNumber" component={EditSingleReport}/>
        </Switch>
        <AddWords/>
        <NoteModal/>
        <NotesMenu/>
        <CreateFiltersFromNotesModal/>
        <ReportNeedsRecalculationMessage/>
        <ShareModal/>
        <ExpiredUrlModal/>
        <TopicsModal/>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  reportId: state.report.reportId,
  report: state.reportInfo.report,
  valueSystem: state.report.valueSystem,
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  changeReportId: reportId => dispatch(changeReportId(reportId)),
  startRegularChecks: () => dispatch(startRegularChecks()),
  stopRegularChecks: () => dispatch(stopRegularChecks()),
  loadReportInfo: () => dispatch(loadReportInfoRequest()),
  cleanFilters: () => dispatch(cleanFiltersState()),
  clearDrawerState: () => dispatch(clearDrawerState()),
  resetResponses: () => dispatch(reset()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportRouter));
