import React from 'react';
import UiModal from "../../../components/UiModal/UiModal";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, Table, TableBody, TableRow} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import TermCell from "./TermCell";
import {deleteWordsRequest, setDeleteWordsDialogOpen} from "../taxonomy-actions";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const DeleteWordsDialog = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isOpen, loading, wordsToDelete} = useSelector(state => ({
    isOpen: state.taxonomy.deleteWordsDialogOpen,
    loading: state.taxonomy.deleteLoading,
    wordsToDelete: state.taxonomy.wordsToDelete,
  }), shallowEqual);

  const onClose = () => {
    dispatch(setDeleteWordsDialogOpen(false));
  }

  return (
    <UiModal
      title={t('delete_terms_confirmation', 'Delete terms confirmation')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent dividers>
        <Alert severity="warning">
          {t('do_you_really_want_to_delete_terms', 'Do you really want to delete the following terms? You cannot undo this.')}
        </Alert>
        <Table>
          <TableBody>
            {wordsToDelete.map(w => (
              <TableRow key={w._id.$oid}>
                <TermCell {...w}/>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <ButtonWithProgress
          color="primary"
          disabled={loading}
          loading={loading}
          onClick={() => dispatch(deleteWordsRequest())}
        >
          {t('delete', 'Delete')}
        </ButtonWithProgress>
        <Button onClick={onClose}>{t('cancel', 'Cancel')}</Button>
      </DialogActions>
    </UiModal>
  );
};

export default DeleteWordsDialog;