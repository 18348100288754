import React from 'react';
import HxIndex from "./hx-index/hx-index-component";
import Filters from "./filters";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'hidden',
  }
}));

const FiltersContainer = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <HxIndex/>
      </Grid>
      <Grid item xs>
        <Filters/>
      </Grid>
    </Grid>
  );
};

export default FiltersContainer;