import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {fetchValueSystemsRequest, setCurrentValueSystem} from "../taxonomy-actions";

const ValueSystemSelector = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {valueSystemsLoading, valueSystems, valueSystem} = useSelector(state => ({
    valueSystems: state.taxonomy.valueSystems,
    valueSystemsLoading: state.taxonomy.valueSystemsLoading,
    valueSystem: state.taxonomy.valueSystem
  }), shallowEqual);

  useEffect(() => {
    dispatch(fetchValueSystemsRequest());
  }, [dispatch]);

  return (
    <TextField
      select
      variant="outlined"
      fullWidth
      onChange={e => dispatch(setCurrentValueSystem(e.target.value))}
      disabled={valueSystemsLoading}
      InputProps={{
        startAdornment: valueSystemsLoading && (
          <InputAdornment  position="start"><CircularProgress size="small"/></InputAdornment>
        )
      }}
      label={t('value_system', 'Value system')}
      value={valueSystemsLoading ? '' : valueSystem}
    >
      {valueSystemsLoading ? (
        <MenuItem>{t('loading', 'Loading')}...</MenuItem>
      ) : valueSystems.map(valueSystemName => (
        <MenuItem key={valueSystemName} value={valueSystemName}>{valueSystemName}</MenuItem>
      ))}
    </TextField>
  );
};

export default ValueSystemSelector;