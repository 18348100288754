export const INIT_CHAT = '[CHAT} INIT_CHAT';

export const START_REQUESTING_LIVE_CHAT = '[CHAT] START_REQUESTING_LIVE_CHAT';
export const STOP_REQUESTING_LIVE_CHAT = '[CHAT] STOP_REQUESTING_LIVE_CHAT';

export const SEND_MESSAGE_REQUEST = '[CHAT] SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_DONE = '[CHAT] SEND_MESSAGE_DONE';

export const NEW_MESSAGES = '[CHAT] NEW_MESSAGES';
export const SET_UNSEEN_MESSAGES = '[CHAT] SET_UNSEEN_MESSAGES';

export const SET_HELP_ARTICLES = '[CHAT] SET_HELP_ARTICLES';

export const initChat = () => ({type: INIT_CHAT});

export const startRequestingLiveChat = mode => ({type: START_REQUESTING_LIVE_CHAT, mode});
export const stopRequestingLiveChat = () => ({type: STOP_REQUESTING_LIVE_CHAT});

export const sendMessageRequest = message => ({type: SEND_MESSAGE_REQUEST, message});
export const sendMessageDone = () => ({type: SEND_MESSAGE_DONE});

export const newMessages = messages => ({type: NEW_MESSAGES, messages});
export const setUnseenMessages = number => ({type: SET_UNSEEN_MESSAGES, number});

export const setHelpArticles = helpArticles => ({type: SET_HELP_ARTICLES, helpArticles});