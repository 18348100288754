import {combineReducers} from "redux";

import loginReducer from "./containers/login/login-reducer";
import userReducer from "./containers/login/user-reducer";
import notificationsReducer from "./containers/notifications/notifications-reducer";
import reportsReducer from "./containers/reports/reports-reducer";
import reportReducer from "./containers/report/report-reducer";
import chartsReducer from "./containers/charts/charts-reducer";
import responsesReducer from "./containers/responses/responses-reducer";
import contextMenuReducer from "./containers/context-menu/context-menu-reducer";
import selectLabelReducer from "./containers/select-label/select-label-reducer";
import fullReportTopWordsReducer from "./containers/full-report-top-words/full-report-top-words-reducer";
import addWordsReducer from "./containers/add-words/add-words-reducer";
import hbIndexChartReducer from "./containers/hb-index-chart/hb-index-chart-reducer";
import createReportReducer from "./containers/create-report/create-report-reducer";
import reportInfoReducer from "./containers/report-info/report-info-reducer";
import ngramSearchReducer from "./containers/ngram-search/ngram-search-reducer";
import responseSingleReducer from "./containers/response-single/response-single-reducer";
import dataAppendReducer from "./containers/data-append/data-append-reducer";
import splitChartsReducer from "./containers/split-charts/split-charts-reducer";
import liveReportReducer from './containers/live/live-report-reducer';
import passwordResetReducer from "./containers/password-reset/password-reset-reducer";
import editSingleResponseReducer from "./containers/edit-single-response/edit-single-response-reducer";
import requestSupportReducer from "./containers/request-support/request-support-reducer";
import notesReducer from "./containers/responses/notes/notes-reducer";
import reportRouterReducer from "./containers/report-router/report-router-reducer";
import mainReducer from "./containers/main/main-reducer";
import usersReducer from "./containers/users/users-reducer";
import chatReducer from "./containers/chat/chat-reducer";
import fullReportReducer from "./containers/full-report-charts/full-report-charts-reducer";
import filtersReducer from "./containers/filters/filters-reducer";
import reportMetricsReducer from "./containers/report-metrics/report-metrics-reducer";
import purchaseStatsReducer from "./containers/purchase-statistics/purchase-statistics-reducer";
import taxonomyReducer from "./containers/taxonomy/taxonomy-reducer";
import articlesReducer from "./containers/articles/articles-reducer";

const rootReducer = combineReducers({
  main: mainReducer,
  login: loginReducer,
  user: userReducer,
  notifications: notificationsReducer,
  reports: reportsReducer,
  report: reportReducer,
  charts: chartsReducer,
  responses: responsesReducer,
  notes: notesReducer,
  contextMenu: contextMenuReducer,
  selectLabel: selectLabelReducer,
  filters: filtersReducer,
  fullReportTopWords: fullReportTopWordsReducer,
  addWords: addWordsReducer,
  hbIndexChart: hbIndexChartReducer,
  createReport: createReportReducer,
  reportInfo: reportInfoReducer,
  ngramSearch: ngramSearchReducer,
  responseSingle: responseSingleReducer,
  dataAppend: dataAppendReducer,
  splitCharts: splitChartsReducer,
  live: liveReportReducer,
  passwordReset: passwordResetReducer,
  editSingleResponse: editSingleResponseReducer,
  requestSupport: requestSupportReducer,
  reportRouter: reportRouterReducer,
  users: usersReducer,
  chat: chatReducer,
  fullReport: fullReportReducer,
  reportMetrics: reportMetricsReducer,
  purchaseStats: purchaseStatsReducer,
  taxonomy: taxonomyReducer,
  articles: articlesReducer,
});

export default rootReducer;
