import React, {useRef, useState} from 'react';
import {createFilterNames} from "../split-charts/split-charts-utils";
import ChartBox from "../../components/Charts/chart-box";
import {createAndCopyMultiVariableTable, createAndCopyTable} from "../charts/functions";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {useDispatch, useSelector} from "react-redux";
import {Grid, makeStyles, MenuItem, TextField} from "@material-ui/core";
import HxIndexChart2 from "../../components/Charts/split-chart/hx-index-chart2";
import ImageIcon from "@material-ui/icons/Image";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import {setSecondVariableName} from "./hb-index-chart-action-creators";
import HxIndexChartCombiner from "../../components/Charts/split-chart/hx-index-chart-combiner";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  controls: {
    marginBottom: theme.spacing(1)
  }
}))

const HBIndexChartComponent = ({
                                 data,
                                 loading,
                                 error,
                                 setSelectedFilterName,
                                 loadData,
                                 filters,
                                 addNotificationRequest,
                                 selectedFilterName,
                                 secondVariableName,
                               }) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [secondVariableMode, setSecondVariableMode] = useState(false);

  const chartRefs = useRef([]);

  const onSelectChange = event => {
    setSelectedFilterName(event.target.value);
    loadData();
  };

  const onSecondVariableChange = e => {
    const newSecondVariable = e.target.value;
    dispatch(setSecondVariableName(newSecondVariable));
    loadData();
  }

  const switchSecondVariableMode = () => {
    setSecondVariableMode(mode => {
      if (mode) {
        dispatch(setSecondVariableName(undefined));
        loadData();
      }

      return !mode;
    });
  };

  const hxTitle = useSelector(state => state.main.title);

  const filterNames = createFilterNames(filters);

  const columns = [
    {name: t('col_name', 'Column name'), key: 'score_column_name'},
    {name: t('count', 'Count'), key: 'count'},
    {name: hxTitle + t('index', 'Index'), key: 'hb_index', transform: value => value.toFixed(3)}
  ];

  const menuItems = [{
    onClick: () => {
      if (secondVariableMode) {
        createAndCopyMultiVariableTable(data, columns);
      } else {
        createAndCopyTable(data[0].data, columns);
      }

      addNotificationRequest('Success', t('copied_to_clipboard_notification', 'Copied to clipboard!'));
    },
    icon: <FileCopyIcon fontSize="small"/>,
    text: t('copy_to_clipboard', 'Copy to clipboard')
  }, {
    text: t('download_as_PNG', 'Download as PNG'),
    onClick: () => {
      const image = document.createElement('canvas');
      let totalHeight = 0;
      let width = 0;

      chartRefs.current.forEach(r => {
        totalHeight += r.chartInstance.canvas.height;
        width = r.chartInstance.canvas.width;
      });

      image.height = totalHeight;
      image.width = width;
      const imageCtx = image.getContext('2d');
      let currentYOffset = 0;

      chartRefs.current.forEach(r => {
        const {width, height} = r.chartInstance.canvas;
        const ctx = r.chartInstance.canvas.getContext('2d');
        const part = ctx.getImageData(0, 0, width, height);
        imageCtx.putImageData(part, 0, currentYOffset);
        currentYOffset += height;
      });

      image.toBlob(function (blob) {
        saveAs(blob, 'HX Index.png');
      });
    },
    icon: <ImageIcon fontSize="small"/>,
  }, {
    text: secondVariableMode ? t('remove_second_variable', 'Remove second variable') : t('add_second_variable', 'Add second variable'),
    icon: <CompareArrowsIcon fontSize="small"/>,
    onClick: switchSecondVariableMode
  }];

  return (
    <ChartBox
      title={hxTitle + ' ' + t("index_values", 'Index Values')}
      error={error}
      loading={loading}
      actionsMenuItems={menuItems}
    >
      {filterNames.length > 0 && (
        <Grid container spacing={1} className={classes.controls}>
          <Grid item xs>
            <TextField
              select
              variant="outlined"
              size="small"
              label={t("select_filters_split_chart_label", "Comparison Group")}
              fullWidth
              onChange={onSelectChange}
              value={selectedFilterName || filterNames[0].value}
            >
              {filterNames.map((filterName, i) => (
                <MenuItem
                  key={i}
                  value={filterName.value}
                  disabled={filterName.value === secondVariableName}
                >
                  {filterName.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {secondVariableMode && (
            <Grid item xs>
              <TextField
                select
                variant="outlined"
                size="small"
                fullWidth
                SelectProps={{displayEmpty: true}}
                onChange={onSecondVariableChange}
                value={secondVariableName || ''}
              >
                <MenuItem value="" disabled>
                  <em>{t('choose_second_variable', 'Choose second variable')}</em>
                </MenuItem>
                {filterNames.map((filterName, i) => (
                  <MenuItem
                    key={i}
                    value={filterName.value}
                    disabled={filterName.value === selectedFilterName}
                  >
                    {filterName.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
      )}

      {!error && data && data.length > 0 && (
        <HxIndexChartCombiner
          chartRefs={chartRefs}
          data={data}
          secondVariableMode={secondVariableMode}
        />
      )}

    </ChartBox>
  );
};


export default HBIndexChartComponent;
