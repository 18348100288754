import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Chip from "@material-ui/core/Chip";
import {makeStyles, TextField} from "@material-ui/core";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import NewTaxonomyDialog from "./NewTaxonomyDialog";
import {setDeleteTaxonomyDialogOpen, setNewTaxonomyDialogOpen, setSelectedTaxonomies} from "../taxonomy-actions";
import DeleteTaxonomyDialog from "./DeleteTaxonomyDialog";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginRight: theme.spacing(1)
  },
  addIcon: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  itemTitle: {
    flex: '1 1 100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TaxonomySelector = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newTaxonomy, setNewTaxonomy] = useState('');
  const [taxonomyToDelete, setTaxonomyToDelete] = useState('');

  const taxonomies = useSelector(state => state.taxonomy.taxonomies);
  const selectedTaxonomies = useSelector(state => state.taxonomy.selectedTaxonomies);
  const taxonomiesInfo = useSelector(state => state.taxonomy.taxonomiesInfo);
  const language = useSelector(state => state.taxonomy.language);

  const showDeleteDialog = (event, taxonomyName) => {
    event.stopPropagation();
    setTaxonomyToDelete(taxonomyName);
    dispatch(setDeleteTaxonomyDialogOpen(true));
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '' && !taxonomies.includes(params.inputValue)) {
      filtered.push({
        inputValue: params.inputValue,
        title: t('create_some_taxonomy', 'Create "{{name}}" taxonomy', {name: params.inputValue}),
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    if (option.inputValue) {
      return option.title;
    }

    return option;
  };

  const renderOption = (option, {selected}) => {
    if (option.inputValue) {
      return (
        <>
          <IconButton
            size="small"
            className={classes.addIcon}
          >
            <AddIcon/>
          </IconButton>
          {option.title}
        </>
      )
    }

    return (
      <div className={classes.itemContainer}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          className={classes.checkbox}
          checked={selected}
        />
        <div className={classes.itemTitle}>
          {option} ({taxonomiesInfo[option]['rows_count']})
        </div>
        <div>
          <IconButton size="small" onClick={e => showDeleteDialog(e, option)}>
            <DeleteForeverIcon fontSize="small"/>
          </IconButton>
        </div>
      </div>
    );
  };

  const onChange = (event, newValue) => {
    const lastValue = newValue[newValue.length - 1];

    if (lastValue && lastValue.inputValue) {
      setNewTaxonomy(lastValue.inputValue);
      dispatch(setNewTaxonomyDialogOpen(true));
      newValue.pop();
    }

    dispatch(setSelectedTaxonomies(newValue));
  };

  return (
    <>
      <Autocomplete
        multiple
        disableCloseOnSelect
        value={selectedTaxonomies}
        options={taxonomies}
        onChange={onChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" size="small" label={option} {...getTagProps({index})} />
          ))
        }
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label={t('taxonomies', 'Taxonomies')}
            fullWidth
          />
        )}
      />
      <NewTaxonomyDialog
        language={language}
        value={newTaxonomy}
        setValue={setNewTaxonomy}
      />
      <DeleteTaxonomyDialog
        taxonomyName={taxonomyToDelete}
      />
    </>
  );
};

export default TaxonomySelector;