import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import UiModal from "../../../components/UiModal/UiModal";
import {TextField, Button, Grid, DialogContent, DialogActions} from "@material-ui/core";
import {addNoteRequest, hideNoteModal} from "./notes-actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IGNORE_BOUNDARIES, NOTE_MODAL_MODE_IGNORE_BOUNDARIES} from "./notes-constants";

const NoteModal = ({modalShown, hideNoteModal, selectedText, boundaries, addNoteRequest, tabs, reportInfo}) => {
  const {t} = useTranslation();
  const [tabName, setTabName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const protectedTabs = reportInfo['protected_note_tabs'] || [];
  const ignoreBoundariesName = reportInfo['ignore_boundaries_tab_name'] || IGNORE_BOUNDARIES;

  useEffect(() => {
    if (modalShown === NOTE_MODAL_MODE_IGNORE_BOUNDARIES) {
      setTabName(ignoreBoundariesName);
    }
  }, [modalShown]);

  const onSubmit = e => {
    e.preventDefault();
    addNoteRequest(tabName, title, description);
  };

  const shownTabs = useMemo(() => {
    const newTabs = [];

    tabs.forEach(tab => {
      if (!protectedTabs.includes(tab)) {
        newTabs.push(tab);
      }
    });

    const ignoreBoundariesExist = tabs.findIndex(tab => tab === ignoreBoundariesName) > -1;

    if (!ignoreBoundariesExist) {
      newTabs.push(ignoreBoundariesName);
    }

    return newTabs;
  }, [tabs]);

  return (
    <UiModal open={!!modalShown} onClose={hideNoteModal} title={t('add_note', "Add Note")}>
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Autocomplete
                freeSolo
                options={shownTabs}
                value={tabName}
                onChange={(e, newValue) => setTabName(newValue)}
                onInputChange={(e, newValue) => setTabName(newValue)}
                renderInput={params => {
                  params = {...params, inputProps: {...params.inputProps, maxLength: 100}};

                  return (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      label={t('tab_name', 'Tab Name')}
                      variant="outlined"
                      helperText={t('tab_name_help', 'This will be a tab name in verbatim explorer and also a meta filter name, so please keep it short')}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                required
                inputProps={{maxLength: 100}}
                label={t('note_title', 'Note Title')}
                helperText={t('note_title_help', 'This will be in verbatim explorer and also a meta filter item, so please keep it short')}
                variant="outlined"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                multiline
                rows="3"
                fullWidth
                label={t('description', 'Description')}
                helperText={t('note_description_help', "Write something here if 'Note Title' is not descriptive enough")}
                variant="outlined"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                multiline
                disabled
                fullWidth
                label={t('selected_text', 'Selected text')}
                variant="outlined"
                helperText={t('selected_text_boundaries', 'Selected text boundaries') + ' : [' + boundaries[0] + ", " + boundaries[1] + "]"}
                defaultValue={selectedText}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="button" color="default" onClick={hideNoteModal}>{t('cancel', 'Cancel')}</Button>
          <Button type="submit" color="primary">{t('add_note', 'Add note')}</Button>
        </DialogActions>
      </form>
    </UiModal>
  );
};

const mapStateToProps = state => ({
  modalShown: state.notes.modalShown,
  selectedText: state.contextMenu.selectedText,
  boundaries: state.contextMenu.boundaries,
  tabs: state.responses.tabs,
  reportInfo: state.reportInfo.report,
});

const mapDispatchToProps = dispatch => ({
  hideNoteModal: () => dispatch(hideNoteModal()),
  addNoteRequest: (tabName, title, description) => dispatch(addNoteRequest(tabName, title, description)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);