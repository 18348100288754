import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {printer} from "../../utilities/functions";
import API from "../../API";
import reportAPI from '../../API-report';

import {
  ADVANCED_SEARCH_REQUEST,
  advancedSearchDone,
  closeCreateSnapshotModal,
  CREATE_SNAPSHOT_REQUEST,
  createSnapshotDone,
  DELETE_REPORT_REQUEST,
  deleteReportError,
  deleteReportSuccess,
  FETCH_CSV_FROM_REPORT,
  fetchCsvFromReportFinish,
  GET_TAGS_REQUEST,
  getTagsSuccess,
  hideAdvancedSearchPanel,
  LOAD_REPORTS_REQUEST,
  loadReportsError,
  loadReportsSuccess,
  LOCK_UNLOCK_REPORT_REQUEST,
  lockUnlockReportFinished,
  MOVE_TO_TRASH_BIN_REQUEST,
  moveToTrashBinError,
  moveToTrashBinSuccess,
  RECALC_REQUEST,
  recalcError,
  recalcSuccess,
  RESTORE_FROM_TRASH_BIN_REQUEST,
  restoreFromTrashBinError,
  restoreFromTrashBinSuccess,
  SHOW_TRASH_BIN_REQUEST,
  updateSingleReport
} from "./reports-actions";
import {addNotification} from "../notifications/notifications-actions";
import {startProgressBar} from "../create-report/create-report-saga";
import saveAs from "file-saver";
import i18n from "../../i18n";

export function* loadReports() {
  try {
    const response = yield call(API.loadReports);
    yield startReportListProgressBars(response.data);
  } catch(error) {
    printer(error, 'ERROR FROM LOAD REPORTS');
    yield put(loadReportsError());
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_load_reports', "Couldn't load the reports. Please reload the page")]}));
  }
}

export function* recalc({targetIndex, reportId}) {
  try {
    yield call(API.recalc, reportId);
    let reportInfoResponse = yield call(reportAPI.loadReportInfo, {reportId});
    yield put(recalcSuccess(targetIndex));
    yield put(updateSingleReport(reportId, normalizeSingleReportInfo(reportInfoResponse.data)));
    yield startProgressBar({reportId});
  } catch(error) {
    printer(error, 'ERROR FROM RECALC');
    yield put(recalcError(targetIndex));
  }
}

export function* deleteReport({reportId, parentId}) {
  try {
    yield call(API.deleteReport, reportId);
    yield put(deleteReportSuccess(reportId, parentId));
  } catch (error) {
    printer(error, 'ERROR FROM DELETE');
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_delete_report', 'Could not delete this report')]}));
    yield put(deleteReportError(reportId, parentId));
  }
}

export function* moveToTrashBin({reportId}) {
  try {
    yield API.moveReportToTrashBin(reportId);
    yield put(addNotification({status: 'Success', message: [i18n.t('report_archived', 'Report has been successfully archived')]}));
    yield put(moveToTrashBinSuccess(reportId));
  } catch (error) {
    console.error('ERROR FROM MOVE_TO_TRASH_BIN', error);
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_archive_report', 'Could not archive report. Please try again later.')]}));
    yield put(moveToTrashBinError(reportId));
  }
}

export function* showTrashBin() {
  try {
    const response = yield API.showTrashBinForCurUser();
    const reports = response.data;
    reports.forEach(r => r.archived = true);
    yield startReportListProgressBars(reports);
    const normalized = normalizeReports(reports);
    yield put(loadReportsSuccess(normalized, 0, []));
  } catch (e) {
    console.error('ERROR FROM SHOW_TRASH_BIN', e);
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_load_archived_reports', 'Could not load archived reports, please try again later.')]}));
    yield put(loadReportsError());
  }
}

export function* restoreFromTrashBin({reportId}) {
  try {
    yield API.restoreReportFromTrashBin(reportId);
    yield put(addNotification({status: 'Success', message: [i18n.t('report_restored', 'Report has been successfully restored')]}));
    yield put(restoreFromTrashBinSuccess(reportId));
  } catch (error) {
    console.error('ERROR FROM MOVE_TO_TRASH_BIN', error);
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_restore_report', 'Could not restore report. Please try again later.')]}));
    yield put(restoreFromTrashBinError(reportId));
  }
}

export function* fetchCSVFromReport({reportId, reportName}) {
  try {
    const response = yield call(API.getCSVFromReport, reportId);
    saveAs(response.data, reportName + '.csv');
  } catch (e) {
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_fetch_csv', 'Could not fetch CSV for this report')]}));
  }

  yield put(fetchCsvFromReportFinish(reportId));
}

export function* lockUnlockReport({reportId}) {
  try {
    yield call(reportAPI.lockUnlockReport, reportId);

    const reportResponse = yield call(reportAPI.loadReportInfo, {reportId});
    yield put(updateSingleReport(reportId, normalizeSingleReportInfo(reportResponse.data)));
  } catch (e) {
    console.error('ERROR FROM LOCK/UNLOCK REPORT', e);
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_lock_unlock_report', 'Could not lock/unlock this report')]}));
  }

  yield put(lockUnlockReportFinished(reportId));
}

export function* getTags() {
  try {
    const response = yield API.getTags();
    yield put(getTagsSuccess(response.data));
  } catch (e) {
    console.log('Error getting tags', e);
  }
}

export function* reportAdvancedSearch({requestData}) {
  try {
    requestData.limit = yield select(state => state.reports.limit);
    const response = yield API.reportAdvancedSearch(requestData);

    const {results, total, tags} = response.data;

    const normalized = normalizeReports(results);

    yield put(loadReportsSuccess(normalized, total, tags));
    yield put(advancedSearchDone());
    yield put(hideAdvancedSearchPanel());
    yield startReportListProgressBars(normalized);
  } catch (e) {
    console.error('ERROR FROM REPORT SEARCH', e);
    yield put(advancedSearchDone());
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_search', 'Could not search')]}));
  }
}

export function* createSnapshot({reportId, name}) {
  try {
    yield reportAPI.duplicateReport(reportId, name);
    let reportInfoResponse = yield call(reportAPI.loadReportInfo, {reportId});
    const reportData = normalizeSingleReportInfo(reportInfoResponse.data);
    yield put(updateSingleReport(reportId, reportData));
    yield put(closeCreateSnapshotModal());
  } catch (e) {
    console.error('ERROR FROM CREATE SNAPSHOT', e);
  } finally {
    yield put(createSnapshotDone());
  }
}

function* startReportListProgressBars(reports) {
  for (let report of reports) {
    if (report.status === 'unfinished') {
      yield fork(startProgressBar, {reportId: report.report_id});
    }
  }
}

export const normalizeReports = reports => {
  return reports.map(normalizeSingleReportInfo);
};

export const normalizeSingleReportInfo = report => ({
  ...report,
  report_id: report.report_id.$oid,
  snapshots: report.snapshots ? normalizeReports(report.snapshots) : [],
  loading: false
});

export default [
  takeEvery(LOAD_REPORTS_REQUEST, loadReports),
  takeEvery(RECALC_REQUEST, recalc),
  takeEvery(DELETE_REPORT_REQUEST, deleteReport),
  takeEvery(MOVE_TO_TRASH_BIN_REQUEST, moveToTrashBin),
  takeEvery(FETCH_CSV_FROM_REPORT, fetchCSVFromReport),
  takeEvery(LOCK_UNLOCK_REPORT_REQUEST, lockUnlockReport),
  takeEvery(GET_TAGS_REQUEST, getTags),
  takeEvery(ADVANCED_SEARCH_REQUEST, reportAdvancedSearch),
  takeEvery(SHOW_TRASH_BIN_REQUEST, showTrashBin),
  takeEvery(RESTORE_FROM_TRASH_BIN_REQUEST, restoreFromTrashBin),
  takeEvery(CREATE_SNAPSHOT_REQUEST, createSnapshot),
]