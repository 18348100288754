import React, {useMemo, useState} from 'react';
import {Button, makeStyles, TextField} from "@material-ui/core";

const SHOW_LINES = 30;

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
  }
}))

const DataField = props => {
  const classes = useStyles();
  const lines = useMemo(() => props.value.split(/\r?\n/).length, [props.value]);

  const [isFull, setIsFull] = useState(false);

  const rows = useMemo(() => {
    if (!isFull && lines > SHOW_LINES) {
      return SHOW_LINES;
    }

    return undefined;
  }, [isFull, lines]);

  console.log(rows);

  return (
    <>
      <TextField
        {...props}
        fullWidth
        type="textarea"
        multiline
        rows={rows}
      />
      {!isFull && lines > SHOW_LINES && (
        <Button
          className={classes.button}
          variant="outlined"
          fullWidth
          size="small"
          onClick={() => setIsFull(prev => !prev)}
        >
          Show all
        </Button>
      )}
    </>
  );
};

export default DataField;