import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Button, Chip, ListItem, makeStyles, Menu, MenuItem, Popper, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import AddIcon from '@material-ui/icons/Add';
import NestedMenuItem from "../../../components/NestedMenuItem/NestedMenuItem";
import {addEmotionColumn} from "../taxonomy-actions";
import {CONTEXTS} from "../taxonomy-constants";

const useStyles = makeStyles(theme => ({
  circle: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  },
  primaryEmotionChip: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(0.5)
  },
  contextChip: {
    marginRight: theme.spacing(0.5)
  }
}))

const AddEmotion = ({valueSystemInfo}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchText('');
    setTimeout(() => {
      searchRef.current.click();
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addEmotion = (code, context) => {
    dispatch(addEmotionColumn(code, context));
    handleClose();
  };

  const isParentOpen = Boolean(anchorEl);

  const searchRef = useRef();

  const autocompleteOptions = useMemo(() => {
    return valueSystemInfo.raw.reduce((acc, e) => {
      Object.keys(CONTEXTS).forEach(context => {
        acc.push({
          description: e.description,
          code: e.code,
          primary: e.primary,
          primaryColor: valueSystemInfo.primaryColors[e.primary],
          context,
          contextColor: CONTEXTS[context].bgColors[0],
          contextLabel: CONTEXTS[context].label[0],
        });
      });

      return acc;
    }, [])
  }, [valueSystemInfo])

  const handleInputChange = (event, newInputValue) => {
    setSearchText(newInputValue);
    if (newInputValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        startIcon={<AddIcon/>}
      >
        {t('add_emotion', 'Add emotion')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={isParentOpen}
        onClose={handleClose}
      >
        <ListItem>
          <Autocomplete
            openOnFocus={false}
            open={open}
            onKeyDown={e => e.stopPropagation()}
            fullWidth
            size="small"
            PopperComponent={props => (
              <Popper {...props} placement="right-start" style={{marginTop: '-20px', marginLeft: '16px', width: 'auto'}}/>
            )}
            autoHighlight
            inputValue={searchText}
            onInputChange={handleInputChange}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: undefined,
                }}
                fullWidth
                ref={searchRef}
                variant="outlined"
              />
            )}
            value={null}
            onChange={(e, v) => addEmotion(v.code, v.context)}
            options={autocompleteOptions}
            renderOption={o => (
              <>
                <Chip size="small" style={{background: o.primaryColor}} className={classes.primaryEmotionChip} label={o.primary}/>
                <Chip size="small" style={{background: o.contextColor}} className={classes.contextChip} label={o.contextLabel}/>
                {o.description}
              </>
            )}
            getOptionLabel={o => o.description}
          />
        </ListItem>

        {Object.keys(valueSystemInfo.byPrimary).map(primaryName => (
          <NestedMenuItem
            key={primaryName}
            label={(
              <>
                <div className={classes.circle} style={{backgroundColor: valueSystemInfo.primaryColors[primaryName]}}/>
                {primaryName}
              </>
            )}
            isParentOpen={isParentOpen}
          >
            {valueSystemInfo.byPrimary[primaryName].map(emotion => (
              <NestedMenuItem key={emotion.code} label={emotion.description} isParentOpen={isParentOpen}>
                {Object.keys(CONTEXTS).map(context => (
                  <MenuItem
                    key={context}
                    onClick={() => addEmotion(emotion.code, context)}
                  >
                    {CONTEXTS[context].label}
                  </MenuItem>
                ))}
              </NestedMenuItem>
            ))}
          </NestedMenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddEmotion;