import API from '../../API-report';
import {call, delay, put, select} from "redux-saga/effects";
import {singleResponseSuccess} from "./response-single-actions";


export function* getSingleResponse({responseNumber}) {
  yield delay(300); // debounce 300 ms (wait for another input)
  const reportId = yield select(state => state.report.reportId);

  const criteria = {reportId, responseNumber};
  const response = yield call(API.loadSingleResponse, criteria);
  const responsesTotal = response.data.responses_total;

  yield put(singleResponseSuccess(response.data.rows, responsesTotal));
}
