import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {connect} from 'react-redux';
import {Link, withRouter} from "react-router-dom";
import {inputChange, loginRequest} from "./user-actions";

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from "@material-ui/core/Typography";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import {withTranslation} from "react-i18next";


class LoginContainer extends Component {
  login = event => {
    event.preventDefault();
    this.props.login();
  };

  render() {
    const {t} = this.props;

    return (
      <Container component="main" maxWidth="sm" style={{height: 'calc(100vh - 50px)'}}>
        <Helmet>
          <title>{t('login', 'Login')}</title>
        </Helmet>

        <Grid container direction="column" alignItems="stretch" justify="center" style={{height: '100%'}}>
          <Grid item component="form" onSubmit={this.login}>
            <Paper elevation={2} component={Box} p={4}>
              <Grid container direction="column" alignItems="stretch" spacing={2}>
                <Grid item container direction="column" alignItems="center">
                  <Avatar style={{marginBottom: '10px'}}>
                    <LockOutlinedIcon/>
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    {t('sign_in', 'Sign in')}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    field="Username"
                    label={t('username', "Username")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={this.props.username.value}
                    error={!!this.props.username.error}
                    helperText={this.props.username.error}
                    autoComplete="current-username"
                    onChange={event => this.props.inputChange({field: 'username', value: event.target.value})}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    field="Password"
                    label={t('password', "Password")}
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={this.props.password.value}
                    error={!!this.props.password.error}
                    helperText={this.props.username.error}
                    autoComplete="current-password"
                    onChange={event => this.props.inputChange({field: 'password', value: event.target.value})}
                  />
                </Grid>
                <Grid item>
                  <ButtonWithProgress
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    loading={this.props.loading}
                    disabled={this.props.loading}
                  >
                    {t('login', 'Login')}
                  </ButtonWithProgress>
                </Grid>
                <Grid item>
                  <Button
                    fullWidth
                    variant="outlined"
                    component={Link}
                    to={"/forgot-password"}
                  >
                    {t('forgot_password', 'Forgot password?')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  username: state.login.username,
  password: state.login.password,
  loading: state.login.loading
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  inputChange: (...args) => dispatch(inputChange(...args)),
  login: () => dispatch(loginRequest(ownProps.history))
});


export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer)));
