import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {tryToDetermineChanges} from "./main-functions";
import {newContentAvailableStore} from "./main-actions";

const CHECK_INTERVAL = 10000;

export const useCheckForNewContentInterval = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') return;

  const interval = useRef(0);
  const dispatch = useDispatch();

  useEffect(() => {
    interval.current = setInterval(async () => {
      const result = await tryToDetermineChanges();

      if (result) {
        dispatch(newContentAvailableStore());
        clearInterval(interval.current);
      }
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(interval.current);
    }
  }, []);
};