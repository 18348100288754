import {LOAD_SINGLE_RESPONSE_SUCCESS, RESPONSE_FIELD_CHANGED} from "./edit-single-response-actions";

const initialState = {
  response: null
};

const editSingleResponseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SINGLE_RESPONSE_SUCCESS:
      return {...state, response: action.response};
    case RESPONSE_FIELD_CHANGED:
      const columns = [...state.response.columns];
      const columnIdx = columns.findIndex(col => col.header === action.name);
      const column = {
        ...columns[columnIdx],
        input_value: action.value
      };

      columns[columnIdx] = column;

      return (
        {
          ...state,
          response: {
            ...state.response,
            columns
          }
        }
      );
    default:
      return state;
  }
};

export default editSingleResponseReducer;
