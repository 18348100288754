import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  deleteNoteRequest,
  hideDeleteNoteConfirmation,
  hideNoteContextMenu,
  showDeleteNoteConfirmation
} from "./notes-actions";

const NotesMenu = ({mousePosition, noteId, confirmationOpen, deleteLoading, deleteNoteRequest, hideNoteContextMenu, showDeleteConfirmation, hideDeleteConfirmation}) => {
  const {t} = useTranslation();

  const handleDelete = () => {
    hideNoteContextMenu();
    showDeleteConfirmation();
  };

  return (
    <Fragment>
      <Menu
        keepMounted
        open={!!mousePosition}
        onClose={hideNoteContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={mousePosition}
      >
        <MenuItem onClick={handleDelete}>
          {t('delete_note', 'Delete note')}
        </MenuItem>
      </Menu>

      <Dialog open={confirmationOpen} onClose={hideDeleteConfirmation}>
        <DialogTitle>{t('do_you_want_to_delete_note', 'Do you really want to delete this note?')}</DialogTitle>
        <DialogActions>
          <Button onClick={hideDeleteConfirmation} color="default">
            {t('cancel', 'Cancel')}
          </Button>
          <Button
            onClick={() => {deleteNoteRequest(noteId)}}
            color="primary"
            autoFocus
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress size={12}/> : t('ok', 'OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  mousePosition: state.notes.notesMenuMousePosition,
  noteId: state.notes.noteId,
  confirmationOpen: state.notes.deleteNoteConfirmationOpen,
  deleteLoading: state.notes.deleteNoteLoading,
});

const mapDispatchToProps = dispatch => ({
  deleteNoteRequest: noteId => dispatch(deleteNoteRequest(noteId)),
  hideNoteContextMenu: () => dispatch(hideNoteContextMenu()),
  showDeleteConfirmation: () => dispatch(showDeleteNoteConfirmation()),
  hideDeleteConfirmation: () => dispatch(hideDeleteNoteConfirmation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesMenu);