import React from 'react';
import Grid from "@material-ui/core/Grid";
import withFilter from "../../hoc/with-filter/with-filter-hoc";
import PrimaryEmotionsSplitChart from "./primary-emotions-split-chart";
import SentimentsSplitChart from "./sentiments-split-chart";
import HBIndexChartContainer from "../hb-index-chart/hb-index-chart-container";

import './split-charts.css';
import TwoColumnsStickyLayout from "../report/components/TwoColumnsStickyLayout";
import Responses from "../responses/responses-container";

const SplitCharts = () => (
  <TwoColumnsStickyLayout
    firstColumn={(
      <Grid container spacing={1} direction="column">
        <Grid item xs>
          <HBIndexChartContainer/>
        </Grid>
        <Grid item xs>
          <PrimaryEmotionsSplitChart/>
        </Grid>
        <Grid item xs>
          <SentimentsSplitChart/>
        </Grid>
      </Grid>
    )}
    secondColumn={<Responses/>}
  />
);

export default withFilter(SplitCharts);
