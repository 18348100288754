import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import UiModal from "../../../components/UiModal/UiModal";
import {deleteTaxonomyRequest, setDeleteTaxonomyDialogOpen} from "../taxonomy-actions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import TextField from "@material-ui/core/TextField";

const DeleteTaxonomyDialog = ({taxonomyName}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.taxonomy.deleteTaxonomyDialogOpen);
  const loading = useSelector(state => state.taxonomy.deleteTaxonomyLoading);

  const [controlValue, setControlValue] = useState('');

  useEffect(() => {
    if (isOpen) {
      setControlValue('');
    }
  }, [isOpen])

  const onClose = () => {
    dispatch(setDeleteTaxonomyDialogOpen(false));
  }

  const onSubmit = event => {
    event.preventDefault();
    dispatch(deleteTaxonomyRequest(taxonomyName));
  }

  const deletable = taxonomyName === controlValue;

  return (
    <UiModal
      title={t('delete_taxonomy', 'Delete taxonomy')}
      open={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Alert severity="warning">
                <Trans i18nKey="do_you_want_to_delete_taxonomy" taxonomyName={taxonomyName}>
                  Do you really want to delete "{{taxonomyName}}" taxonomy? This action could be dangerous and is
                  irreversible. Please, <strong>type the full name of the taxonomy</strong> in the text field below to
                  delete it.
                </Trans>
              </Alert>
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                variant="outlined"
                fullWidth
                label={t('taxonomy_name', "Taxonomy name")}
                value={controlValue}
                onChange={e => setControlValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonWithProgress
            type="submit"
            color="primary"
            disabled={!deletable || loading}
            loading={loading}
          >
            {t('delete', 'Delete')}
          </ButtonWithProgress>
          <Button onClick={onClose}>{t('cancel', 'Cancel')}</Button>
        </DialogActions>
      </form>
    </UiModal>
);
};

export default DeleteTaxonomyDialog;