import {
  CHANGE_TAB,
  LOAD_RESPONSES_ERROR,
  LOAD_RESPONSES_REQUEST,
  LOAD_RESPONSES_SUCCESS, RESET,
} from "./responses-action-types";

export const loadResponsesRequest = (page, keepTab) => ({type: LOAD_RESPONSES_REQUEST, page, keepTab});
export const loadResponsesSuccess = ({responses, page, totalPages, tabs, keepTab, hiddenVerbatims}) => ({
  type: LOAD_RESPONSES_SUCCESS,
  responses,
  page,
  totalPages,
  tabs,
  keepTab,
  hiddenVerbatims
});
export const loadResponsesError = error => ({type: LOAD_RESPONSES_ERROR, error});

export const changeTab = tabName => ({type: CHANGE_TAB, tabName});

export const reset = () => ({type: RESET});