export const START_REGULAR_CHECKS = '[REPORT_ROUTER] START_REGULAR_CHECKS';
export const STOP_REGULAR_CHECKS = '[REPORT_ROUTER] STOP_REGULAR_CHECKS';

export const REPORT_GOT_EDITED_ROWS_SUCCESS = '[REPORT_ROUTER] REPORT_GOT_EDITED_ROWS_SUCCESS';

export const RECALCULATE_EDITED_ROWS_REQUEST = '[REPORT_ROUTER] RECALCULATE_EDITED_ROWS_REQUEST';
export const RECALCULATE_EDITED_ROWS_DONE = '[REPORT_ROUTER] RECALCULATE_EDITED_ROWS_DONE';

export const SET_TOPICS_PROGRESS = '[REPORT_ROUTER] SET_TOPICS_PROGRESS';

export const startRegularChecks = () => ({type: START_REGULAR_CHECKS});
export const stopRegularChecks = () => ({type: STOP_REGULAR_CHECKS});

export const recalculateEditedRowsRequest = history => ({type: RECALCULATE_EDITED_ROWS_REQUEST, history});
export const recalculateEditedRowsDone = () => ({type: RECALCULATE_EDITED_ROWS_DONE});

export const reportGotEditedRowsSuccess = editedRows => ({type: REPORT_GOT_EDITED_ROWS_SUCCESS, editedRows});

export const setTopicsProgress = (inProgress, progress) => ({type: SET_TOPICS_PROGRESS, payload: {inProgress, progress}});