import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

const Success = ({reportId}) => {
  const {t} = useTranslation();
  return (

    <>
      <Grid item xs>
        <CheckIcon style={{color: 'green', fontSize: 150}}/>
      </Grid>
      <Grid item xs>
        <Typography variant="h4">
          {t('success_report_configured_calculated', 'Success! Your report was configured and calculated!')}
        </Typography>
      </Grid>
      <Grid item xs>
        <Button variant="contained" size="large" color="primary" component={Link} to={`/report/${reportId}`}>
          {t('go_to_the_report', 'Go to the report')}
        </Button>
      </Grid>
    </>
  );
};

export default Success;