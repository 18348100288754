import {createColor} from "../../utilities/getColor";

export const normalizeValueSystemInfo = valueSystemInfo => {
  const byCoding = {};
  const byPrimary = {};
  const primaryColors = {};

  valueSystemInfo.forEach(emotion => {
    byCoding[emotion.code] = emotion;

    if (emotion.primary in byPrimary) {
      byPrimary[emotion.primary].push(emotion);
    } else {
      byPrimary[emotion.primary] = [emotion];
    }

    if (!(emotion.primary in primaryColors)) {
      primaryColors[emotion.primary] = createColor(emotion['primary_color']);
    }
  });

  return {byCoding, byPrimary, primaryColors, raw: valueSystemInfo};
};

export const normalizeWords = words => {
  return words.map(w => {
    const word = {...w};

    if (!word._id) {
      word.tempId = `${word.term}-${word.taxonomy}-${word.language}`;
    }

    word.contexts = word.contexts.reduce((acc, ctx) => {
      acc[ctx.context_name] = [...ctx.coding];
      return acc;
    }, {});

    word.originalContexts = Object.keys(word.contexts).reduce((acc, ctxName) => {
      if (!word._id) {
        acc[ctxName] = []; // if there is no id, it's new word so originally doesn't have any emotions
      } else {
        acc[ctxName] = [...word.contexts[ctxName]]; // manual copy
      }

      return acc;
    }, {});

    return word;
  });
};

export const denormalizeWords = words => {
  return words.map(wordInBasket => {
    const word = {...wordInBasket};

    if (!word._id) {
      delete word.tempId;
    }

    word.contexts = Object.keys(word.contexts).map(ctxName => {
      return {context_name: ctxName, coding: word.contexts[ctxName]};
    });

    delete word.originalContexts;

    return word;
  });
};

export const denormalizeTaxonomyResponse = data => {
  const taxonomies = [];
  const taxonomiesInfo = {};

  data.forEach(t => {
    taxonomies.push(t['taxonomy_name']);
    taxonomiesInfo[t['taxonomy_name']] = t;
  });

  return {taxonomies, taxonomiesInfo};
}