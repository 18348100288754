import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const NgramSearchResults = ({results, setSelectedText, showContextMenu, showSingleResponseModal, getSingleResponse}) => {
  const {t} = useTranslation();

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedText();
    showContextMenu({top: e.clientY, left: e.clientX});
  };

  const openSingleResponse = (event, responseNumber) => {
    event.preventDefault();

    showSingleResponseModal();
    getSingleResponse(responseNumber);
  };

  const getJoinedSentence = runs => {
    return runs.map((run, index) => {
      let classes = [];

      if (run.type === 'found_result') {
        classes.push('ngram-search_result-highlight');
      }

      if (run.emotional) {
        classes.push('ngram-search_result-emotional')
      }

      return (
        <span key={index}>
          {run.prefix}
          <span className={classes.join(' ')}>{run.joined}</span>
        </span>
      );
    });
  };

  const getPluralSentences = (number) => {
    return number === 1 ? 'sentence' : 'sentences'
  };

  return (
    <Fragment>
      {results.results_found === 0 ?
        <h4>{t('no_results_with_given_criteria', 'No results with given criteria!')}</h4>
        :
        <h4>{t('total_results_found', 'Total results found')}: {results.results_found}</h4>
      }
      {results.results.map((result, index) => (
        <Accordion key={index} onContextMenu={handleContextMenu}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <strong>{result.collocation}</strong>
            ({result.count} {getPluralSentences(result.count)})
          </AccordionSummary>
          <Grid container spacing={2} direction="column" key={index} component={AccordionDetails}>
            {result.sentences.map((sentence, index) => (
              <Grid item key={index}>
                <strong style={{marginRight: "5px"}}>
                  <a style={{color:"blue",textDecoration:"underline"}}
                     tabIndex="0"
                     onClick={e => openSingleResponse(e, sentence.row_number)}
                  >
                    {t('row', 'Row')} {sentence.row_number}
                  </a>
                  :
                </strong>
                <span>{getJoinedSentence(sentence.runs)}</span>
              </Grid>
            ))}
          </Grid>
        </Accordion>
      ))}
    </Fragment>
  )
};

export default NgramSearchResults;
