import {
  COMPANY_STATS_FAILURE,
  COMPANY_STATS_REQUEST, COMPANY_STATS_SUCCESS,
  USER_COMPANIES_FAILURE,
  USER_COMPANIES_REQUEST,
  USER_COMPANIES_SUCCESS, USER_STATS_FAILURE, USER_STATS_REQUEST, USER_STATS_SUCCESS
} from "./purchase-statistics-actions";

const initialState = {
  companies: [],
  companiesLoading: false,
  companyStats: null,
  companyStatsLoading: false,
  userStats: null,
  userStatsLoading: false,
};

const purchaseStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_COMPANIES_REQUEST:
      return {...state, companies: [], companiesLoading: true};
    case USER_COMPANIES_SUCCESS:
      return {...state, companies: action.companies, companiesLoading: false};
    case USER_COMPANIES_FAILURE:
      return {...state, companiesLoading: false};
    case COMPANY_STATS_REQUEST:
      return {...state, companyStats: null, companyStatsLoading: true};
    case COMPANY_STATS_SUCCESS:
      return {...state, companyStats: action.stats, companyStatsLoading: false};
    case COMPANY_STATS_FAILURE:
      return {...state, companyStatsLoading: false};
    case USER_STATS_REQUEST:
      return {...state, userStats: null, userStatsLoading: true};
    case USER_STATS_SUCCESS:
      return {...state, userStats: action.userStats, userStatsLoading: false};
    case USER_STATS_FAILURE:
      return {...state, userStatsLoading: false};
    default:
      return state;
  }
};

export default purchaseStatsReducer;