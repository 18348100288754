import React, {Fragment, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

import {Box, Chip, CircularProgress, Paper} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import PieChartFooter from "./pie-chart-footer/pie-chart-footer-component";
import DropdownMenu from "../DropdownMenu/DropdownMenu";

import {groupSelectedFiltersByParent} from "../../containers/filters/filters-utils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  titleDiv: {
    margin: '0px 10px 20px 10px',
    textAlign: 'center',
    '& h3': {
      fontWeight: 300,
      margin: '5px 0 0'
    }
  },
  filterChips: {
    '& strong': {
      fontSize: '14px'
    },
    '& *': {
      verticalAlign: 'middle'
    }
  },
  filterChip: {
    margin: theme.spacing(0.5)
  },
  filterChipsContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  progress: {
    position: 'absolute'
  },
  actions: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  error: {
    height: 200,
    padding: theme.spacing(6),
  },
  chartBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  }
}));

const ChartBox = ({
  title,
  subTitle,
  loading,
  error,
  footerLink,
  actions,
  height,
  children,
  actionsMenuItems,
}) => {
  const {t} = useTranslation();
  const [filtersShown, setFiltersShown] = useState(false);
  const {selectedFilters, flatFilters} = useSelector(state => state.filters, shallowEqual);
  const classes = useStyles();

  const SHOW_SELECTED_FILTERS = t('show_selected_filters', "Show selected filters");

  let spinner = loading ? (
    <CircularProgress className={classes.progress}/>
  ) : null;

  let chart = (
    <Fragment>
      {actions && <span className={classes.actions}>{actions}</span>}
      {children}
      {footerLink && !loading && <PieChartFooter link={footerLink} />}
    </Fragment>
  );

  if (error) {
    chart = (
      <div className={classes.error}>
        <h3>{t('chart_not_available', 'Chart not available')}</h3>
        <p>{t('no_emotional_words_found_with_filters', 'No emotional words were found in current filters selection.')}</p>
        <p>{t('review_your_selection', 'Please review your selection.')}</p>
      </div>
    );
  }

  if (!actionsMenuItems) {
    actionsMenuItems = [];
  }

  let existingMenuItemIndex = actionsMenuItems.findIndex(i => i.text === SHOW_SELECTED_FILTERS);

  const menuItem = {
    text: SHOW_SELECTED_FILTERS,
    icon: filtersShown ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>,
    onClick: () => setFiltersShown(prevState => !prevState)
  };

  if (existingMenuItemIndex === -1) {
    actionsMenuItems.push(menuItem);
  } else {
    actionsMenuItems[existingMenuItemIndex] = menuItem;
  }

  let filtersChips = null;

  if (filtersShown) {
    let filtersChipsInner = (
      <Chip variant="outlined" label={t('no_filters_applied', "No filters applied")} />
    );

    if (selectedFilters.length > 0) {
      const groupedFilters = groupSelectedFiltersByParent(selectedFilters, flatFilters);

      filtersChipsInner = Object.keys(groupedFilters).map(groupName => (
        <div className={classes.filterChips} key={groupName}>
          <strong>{groupName}:</strong>
          {groupedFilters[groupName].map(filter => (
            <Chip
              className={classes.filterChip}
              key={filter.title}
              variant="outlined"
              label={(
                <span>
                  {filter.title}
                  {filter.type === 'range' && ` (${filter.value[0]} ... ${filter.value[1]})`}
                </span>
              )}
            />
          ))}
        </div>
      ))
    }

    filtersChips = (
      <Box className={classes.filterChipsContainer}>
        {filtersChipsInner}
      </Box>
    );
  }

  return (
    <Paper style={{height: height}} className={classes.chartBox}>
      {spinner}

      <div className={classes.titleDiv}>
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
      </div>

      {filtersChips}
      {actionsMenuItems &&
        <div className={classes.actions}>
          <DropdownMenu icon={<MoreVertIcon/>} items={actionsMenuItems}/>
        </div>
      }
      {chart}
    </Paper>
  )
};

export default ChartBox;
