import {HISTORY_PUSH, HISTORY_REPLACE} from "./history-actions";
import {takeEvery} from "redux-saga/effects";

export default history => {

  return [
    takeEvery(HISTORY_PUSH, function*({payload}) {
      yield history.push(payload);
    }),
    takeEvery(HISTORY_REPLACE, function*({payload}) {
      yield history.replace(payload);
    })
  ];
}