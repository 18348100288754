import {takeEvery, put} from 'redux-saga/effects';

import API from "../../API";
import {
  PASSWORD_RESET_CHECK_REQUEST, PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUBMIT_REQUEST, passwordResetCheckError, passwordResetCheckSuccess, passwordResetError,
  passwordResetSubmitError,
  passwordResetSubmitSuccess, passwordResetSuccess
} from "./password-reset-actions";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";

export function* passwordResetSubmit({email}) {
  try {
    const response = yield API.passwordResetSubmit(email);

    if (response.data['SUCCESS']) {
      yield put(passwordResetSubmitSuccess(response.data['SUCCESS']));
    } else if (response.data['ERROR']) {
      yield put(passwordResetSubmitError(response.data['ERROR']));
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response);
    }
  } catch(e) {
    console.error('ERROR IN RESET_PASSWORD_SUBMIT', e);
    yield put(passwordResetSubmitError(i18n.t('unknown_error_please_try_again_later', 'Unknown error, please try again later')));
  }
}

export function* passwordResetCheck({token}) {
  try {
    const response = yield API.passwordResetCheck(token);

    if (response.data['SUCCESS']) {
      yield put(passwordResetCheckSuccess());
    } else if (response.data['ERROR']) {
      yield put(passwordResetCheckError(response.data['ERROR']));
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response);
    }
  } catch(e) {
    console.error('ERROR IN RESET_PASSWORD_CHECK', e);
    yield put(passwordResetCheckError(i18n.t('unknown_error_please_try_again_later', 'Unknown error, please try again later')));
  }
}

export function* passwordReset({token, newPassword, history}) {
  try {
    const response = yield API.passwordReset(token, newPassword);

    if (response.data['SUCCESS']) {
      yield put(passwordResetSuccess());
      yield put(addNotification({status: "Success", message: [i18n.t('your_password_was_changed', 'Your password was changed, you can login using new credentials.')]}));
      history.push('/login');
    } else if (response.data['ERROR']) {
      yield put(passwordResetError(response.data['ERROR']));
    } else {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response);
    }
  } catch(e) {
    console.error('ERROR IN RESET_PASSWORD', e);
    yield put(passwordResetError(i18n.t('unknown_error_please_try_again_later', 'Unknown error, please try again later')));
  }
}

export default [
  takeEvery(PASSWORD_RESET_SUBMIT_REQUEST, passwordResetSubmit),
  takeEvery(PASSWORD_RESET_CHECK_REQUEST, passwordResetCheck),
  takeEvery(PASSWORD_RESET_REQUEST, passwordReset),
];
