import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {Box, CircularProgress, Container, Grid, Paper, TextField, Typography} from "@material-ui/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import {parseQueryString} from "../reports/reports-functions";
import {
  passwordResetCheckError,
  passwordResetCheckRequest,
  passwordResetError,
  passwordResetRequest
} from "./password-reset-actions";

const ResetPassword = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const {checkLoading, checkSuccess, checkError, resetLoading, resetSuccess, resetError} = useSelector(state => ({
    checkLoading: state.passwordReset.checkLoading,
    checkSuccess: state.passwordReset.checkSuccess,
    checkError: state.passwordReset.checkError,
    resetLoading: state.passwordReset.resetLoading,
    resetSuccess: state.passwordReset.resetSuccess,
    resetError: state.passwordReset.resetError
  }), shallowEqual);

  const token = useMemo(() => {
    const queryParams = parseQueryString(location.search);

    return queryParams.token;
  }, [location]);

  useEffect(() => {
    if (token) {
      dispatch(passwordResetCheckRequest(token));
    } else {
      dispatch(passwordResetCheckError(t('invalid_reset_url', 'Invalid reset URL. Please follow the link exactly from email.')));
    }
  }, [dispatch, token]);

  const submitFormHandler = event => {
    event.preventDefault();

    if (password !== passwordRepeat) {
      return dispatch(passwordResetError(t('passwords_dont_match', "Passwords don't match!")));
    }

    dispatch(passwordResetRequest(token, password, history));
  };

  let content = (
    <Grid item container direction="column" spacing={2} component="form" onSubmit={submitFormHandler} style={{width: '100%'}}>
      <Grid item>
        <TextField
          name="password"
          label={t('new_password', 'New password')}
          helperText={t('enter_new_password', 'Enter new password')}
          type="password"
          value={password}
          required
          variant="outlined"
          fullWidth
          onChange={e => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item xs>
        <TextField
          name="passwordRepeat"
          label={t('repeat_new_password', 'Repeat new password')}
          helperText={t('enter_new_password_one_more_time', 'Enter new password one more time')}
          type="password"
          value={passwordRepeat}
          required
          error={Boolean(resetError)}
          variant="outlined"
          fullWidth
          onChange={e => setPasswordRepeat(e.target.value)}
        />
      </Grid>
      <Grid item xs>
        <ButtonWithProgress
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={resetLoading}
          loading={resetLoading}
        >
          {t('reset_password', 'Reset password')}
        </ButtonWithProgress>
      </Grid>
    </Grid>
  );

  if (checkError) {
    content = (
      <Grid item>
        <Typography>
          {checkError}
        </Typography>
      </Grid>
    );
  }

  if (checkLoading) {
    content = <Grid item><CircularProgress/></Grid>;
  }

  return (
    <Container component="main" maxWidth="sm" style={{marginTop: '20%'}}>
      <Helmet>
        <title>{t('reset_password', 'Reset password')}</title>
      </Helmet>
      <Paper component={Box} p={2}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <h2>{t('reset_your_password', 'Reset your password')}</h2>
          </Grid>

          {content}
        </Grid>
      </Paper>
    </Container>
  );
}

export default ResetPassword;