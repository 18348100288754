import React, {Fragment, useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Link, matchPath, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {AppBar, Button, Grid, Hidden, IconButton, Snackbar, Tab, Tabs, Toolbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import MenuIcon from '@material-ui/icons/Menu';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExposureIcon from '@material-ui/icons/Exposure';
import GroupIcon from '@material-ui/icons/Group';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import HideFrom from "../../hoc/authorization/HideFrom";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import ReportsSearch from "../reports/reports-search";
import {requestSupportModalShow} from "../request-support/request-support-actions";
import {logoutRequest} from "../login/user-actions";

import logoImage from '../../assets/images/logo.png';
import mobileLogoImage from '../../assets/images/mobile-logo.png';
import {toggleDesktopDrawer, toggleMobileDrawer, whitelabelRequest} from "./main-actions";
import {useCheckForNewContentInterval} from "./main-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportToolsMenu from "./components/ReportToolsMenu";
import LanguageIcon from '@material-ui/icons/Language';
import {useTranslation} from "react-i18next";


const getReportIdFromSearchRoute = url => {
  const result = matchPath(url, { path:'/report/:id'});
  return result ? result.params.id : null;
};

const useStyles = makeStyles(theme => ({
  mainLogo: {
    height: 74,
    '& div': {
      height: '100%',
      padding: theme.spacing(1)
    },
    '& img': {
      height: '100%',
      transition: 'all 0.5s',
    },
    '& img:hover': {
      filter: 'brightness(1.3)'
    }
  },
  mainToolbar: {
    padding: '0 16px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logoLoading: {
    marginTop: theme.spacing(1)
  }
}));

const reportNavStyles = makeStyles(theme => ({
  tabs: {
    margin: -theme.spacing(1) + 'px 0'
  },
  tabItem: {
    height: 74,
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      minWidth: 72
    }
  }
}));

const tabStyles = makeStyles(theme => ({
  wrapper: {
    '& > svg': {
      marginTop: theme.spacing(1)
    }
  }
}));

const UserMenu = props => {
  const {t, i18n} = useTranslation();

  const items = [
    {
      onClick: props.requestSupportModalShow,
      icon: <ContactSupportIcon/>,
      text: t('request_support', 'Request support'),
    },
    {
      text: t('taxonomy_management', 'Taxonomy Management'),
      icon: <ExposureIcon/>,
      restrict: !(props.user['is_superuser'] || props.user['is_translator']),
      component: Link,
      props: {
        to: '/taxonomy'
      }
    },
    {
      text: t('articles', 'Articles'),
      icon: <DescriptionIcon/>,
      restrict: !props.user['is_superuser'],
      component: Link,
      props: {
        to: '/articles',
      }
    },
    {
      text: t('users', 'Users'),
      icon: <GroupIcon/>,
      restrict: !props.user['is_superuser'],
      component: Link,
      props: {
        to: '/users'
      }
    },
    {
      text: t('user_cabinet', 'User Cabinet'),
      icon: <MonetizationOnIcon/>,
      restrict: props.user['is_guest'],
      component: Link,
      props: {
        to: '/purchase-statistics'
      }
    },
    {
      divider: true,
    },
    {
      text: 'English',
      icon: <LanguageIcon/>,
      onClick: () => {
        i18n.changeLanguage('en');
        location.reload();
      }
    },
    {
      text: 'Русский',
      icon: <LanguageIcon/>,
      onClick: () => {
        i18n.changeLanguage('ru');
        location.reload();
      }
    },
    {
      divider: true,
    },
    {
      onClick: () => props.logoutRequest(props.history),
      icon: <ExitToAppIcon/>,
      text: t('log_out', 'Log out')
    }
  ];

  if (props.user.username) {
    items.unshift({
      props: {
        disabled: true
      },
      icon: <AccountCircleIcon/>,
      text: props.user.username
    },
    {
      divider: true,
    });
  }

  return (
    <DropdownMenu
      icon={<AccountCircleIcon color="primary"/>}
      items={items}
    />
  );
};

const AnonymousMenu = () => {
  const {t} = useTranslation();

  const items = [{
    props: {
      disabled: true
    },
    icon: <AccountCircleIcon/>,
    text: t('anonymous_user', 'Anonymous user'),
  }, {
    text: t('login', 'Login'),
    icon: <LockOpenIcon/>,
    component: Link,
    props: {
      to: '/login'
    }
  }];

  return (
    <DropdownMenu
      icon={<AccountCircleIcon/>}
      items={items}
    />
  );
};

const ReportNav = ({reportId, location}) => {
  const {t} = useTranslation();
  const classes = reportNavStyles();
  const tabClasses = tabStyles();

  const reportPath = '/report/' + reportId;

  const links = [
    {
      path: reportPath,
      title: <Fragment><DescriptionIcon/> <Hidden smDown implementation="css">{t('main_report', 'Main report')}</Hidden></Fragment>,
    },
    {
      path: reportPath + '/split-Charts',
      title: <Fragment><EqualizerIcon/> <Hidden smDown implementation="css">{t('split_charts', 'Split charts')}</Hidden></Fragment>
    },
    {
      path: reportPath + '/bubble',
      title: <Fragment><BubbleChartIcon/> <Hidden smDown implementation="css">{t('emotions_map', 'Emotions map')}</Hidden></Fragment>
    }
  ];

  const valueExists = links.find(l => l.path === location.pathname);

  const getFinalPath = path => {
    if (location.search) {
      path += location.search;
    }

    return path;
  }

  return (
    <Tabs value={valueExists ? location.pathname : false} className={classes.tabs} indicatorColor="primary">
      {links.map(l => (
        <Tab className={classes.tabItem} classes={tabClasses} key={l.path} value={l.path} component={Link} to={getFinalPath(l.path)} label={l.title}/>
      ))}
    </Tabs>
  )
};

const Nav = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const reportId = getReportIdFromSearchRoute(props.location.pathname);
  const searchQuery = props.user && (props.user.searchQuery || '');

  const splitPath = props.location.pathname.split('/');

  const isOnBubblePage = splitPath[3] === 'bubble';
  const isOnLiveViewPage = splitPath[3] === 'live';

  const isOnReportsPage = props.location.pathname === "/";

  const isOnReportPage = !!reportId;
  
  useCheckForNewContentInterval();

  useEffect(() => {
    dispatch(whitelabelRequest(window.location.host));
  }, [dispatch]);

  const {logoLoading, logoUrl} = useSelector(state => state.main);

  return (
    <Fragment>
      <Toolbar/>
      <AppBar position="fixed" color="default" elevation={2} style={{backgroundColor: 'white'}} className={classes.appBar}>
        <Toolbar disableGutters className={classes.mainToolbar}>
          <Grid container spacing={1} justify="space-between" alignItems="center">
            {(isOnReportsPage || isOnReportPage) && (
              <Fragment>
                <Hidden implementation="css" smDown>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="menu"
                      onClick={() => {
                        isOnBubblePage || isOnLiveViewPage ? props.toggleMobileDrawer() : props.toggleDesktopDrawer();
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
                <Hidden implementation="css" mdUp>
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="menu"
                      onClick={() => props.toggleMobileDrawer()}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
              </Fragment>
            )}

            <Grid item className={classes.mainLogo}>
              <Link to={'/' + searchQuery}>
                {logoLoading ? (
                  <CircularProgress className={classes.logoLoading} />
                ) : (
                  <Fragment>
                    <Hidden implementation="css" smDown>
                      <img src={logoUrl || logoImage} alt="HX" />
                    </Hidden>
                    <Hidden implementation="css" mdUp>
                      <img src={logoUrl || mobileLogoImage} alt="HX" />
                    </Hidden>
                  </Fragment>
                )}

              </Link>
            </Grid>

            <Grid item xs container justify="center">
              {isOnReportsPage && props.user && (
                <Grid item xs md={10} lg={8} xl={6}><ReportsSearch/></Grid>
              )}

              {isOnReportPage && (
                <ReportNav reportId={reportId} location={props.location}/>
              )}
            </Grid>

            <Grid item>
              <Grid container spacing={1} alignItems="center">
                {props.location.pathname === "/" && (
                  <HideFrom guest>
                    <Hidden implementation="css" smDown>
                      <Grid item>
                        <Button
                          component={Link}
                          to="/create-report"
                        >
                          <AddBoxOutlinedIcon/>&nbsp;{t('create_report', 'Create report')}
                        </Button>
                      </Grid>
                    </Hidden>
                    <Hidden implementation="css" mdUp>
                      <IconButton
                        component={Link}
                        to="/create-report"
                      >
                        <AddBoxOutlinedIcon/>
                      </IconButton>
                    </Hidden>
                  </HideFrom>
                )}

                {reportId && (
                  <ReportToolsMenu/>
                )}

                <Grid item>
                  {props.user ?
                    <UserMenu {...props}/>
                    :
                    <AnonymousMenu/>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Snackbar open={props.newContentAvailable} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert elevation={6} variant="filled" severity="info">
          <Grid container direction="column" spacing={1}>
            <Grid item>
              {t('we_got_updates', "We've got updates! Please refresh the page to see an actual version.")}
            </Grid>
            <Grid item>
              <Button color="inherit" variant="outlined" size="small" onClick={() => window.location.reload()}>
                {t('refresh_the_page', 'Refresh the page')}
              </Button>
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  history: ownProps.history,
  user: state.user,
  newContentAvailable: state.main.newContentAvailable,
});

const mapDispatchToProps = dispatch => ({
  logoutRequest: history => dispatch(logoutRequest(history)),
  requestSupportModalShow: () => dispatch(requestSupportModalShow()),
  toggleMobileDrawer: () => dispatch(toggleMobileDrawer()),
  toggleDesktopDrawer: () => dispatch(toggleDesktopDrawer()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
