const PRIMARY_EMOTIONS_COLORS_RAW = {
  "Love": {r: 191, g: 63, b: 63},
  "Joy": {r: 191, g: 159, b: 63},
  "Trust": {r: 128, g: 191, b: 63},
  "Surprise": {r: 63, g: 191, b: 96},
  "Sadness": {r: 63, g: 191, b: 191},
  "Fear": {r: 63, g: 95, b: 191},
  "Anger": {r: 127, g: 63, b: 191},
  "Disgust": {r: 191, g: 63, b: 159},
  "Void": {r: 127, g: 127, b: 127},
  "Body Sense": {r: 159, g: 95, b: 95},
};

const SENTIMENT_COLORS_RAW = {
  "Positive": {r: 44, g: 160, b: 44},
  "Negative": {r: 214, g: 39, b: 40},
  "Void": {r:127, g: 127, b: 127}
};

const TOP_COLORS = [
  'rgb(31, 119, 180)',
  'rgb(255, 127, 14)',
  'rgb(44, 160, 44)',
  'rgb(214, 39, 40)',
  'rgb(148, 103, 189)',
  'rgb(140, 86, 75)',
  'rgb(227, 119, 194)',
  'rgb(127, 127, 127)',
  'rgb(188, 189, 34)',
  'rgb(23, 190, 207)',
  'rgb(160, 160, 160)'
];

const DEFAULT_UNKNOWN_COLOR = {r: 200, g: 200, b: 200};

export const createColor = colorObj => {
  return colorObj ? `rgb(${colorObj.r}, ${colorObj.g}, ${colorObj.b})` : 'white';
};

export const createTintedColor = (colorObj, tintFactor) => {
  const color = {
    r: colorObj.r + (255 - colorObj.r) * tintFactor,
    g: colorObj.g + (255 - colorObj.g) * tintFactor,
    b: colorObj.b + (255 - colorObj.b) * tintFactor,
  };

  return createColor(color);
};

export const getPrimaryEmotionColor = emotion => {
  const color = PRIMARY_EMOTIONS_COLORS_RAW[emotion] || DEFAULT_UNKNOWN_COLOR;

  return createColor(color);
};

export const getPrimaryEmotionHighlightColor = emotion => {
  const color = PRIMARY_EMOTIONS_COLORS_RAW[emotion] || DEFAULT_UNKNOWN_COLOR;

  return createTintedColor(color, 0.25);
};

export const getSentimentColor = sentiment => {
  const color = SENTIMENT_COLORS_RAW[sentiment] || DEFAULT_UNKNOWN_COLOR;

  return createColor(color);
};

export const getSentimentHighlightColor = sentiment => {
  const color = SENTIMENT_COLORS_RAW[sentiment] || DEFAULT_UNKNOWN_COLOR;

  return createTintedColor(color, 0.25);
};

export const getTopColor = i => {
  return TOP_COLORS[i]
};

export const getColor = ({d, i, top, sentiment, primary, fromd3}) => {
  if (top) {
    return TOP_COLORS[i];
  }

  if (sentiment) {
    const sentiment = fromd3 ? d.data.sentiment : d.sentiment;
    return getSentimentColor(sentiment);
  }

  if (primary) {
    const emotion = fromd3 ? d.data.primary : d.primary;
    return getPrimaryEmotionColor(emotion);
  }
};

