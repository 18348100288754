import {
  LOAD_PRIMARY_EMOTIONS_BREAKDOWN_ERROR,
  LOAD_PRIMARY_EMOTIONS_BREAKDOWN_REQUEST, LOAD_PRIMARY_EMOTIONS_BREAKDOWN_SUCCESS,
  LOAD_SENTIMENT_BREAKDOWN_ERROR,
  LOAD_SENTIMENT_BREAKDOWN_REQUEST, LOAD_SENTIMENT_BREAKDOWN_SUCCESS, LOAD_TOP_WORDS_ERROR, LOAD_TOP_WORDS_REQUEST,
  LOAD_TOP_WORDS_SUCCESS, SWITCH_TOP_WORDS_CHART_MODE
} from "./charts-action-types";

export const LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST = "[CHARTS] LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST";
export const LOAD_PRIMARY_SECONDARY_SENTIMENT_SUCCESS = "[CHARTS] LOAD_PRIMARY_SECONDARY_SENTIMENT_SUCCESS";
export const LOAD_PRIMARY_SECONDARY_SENTIMENT_ERROR = "[CHARTS] LOAD_PRIMARY_SECONDARY_SENTIMENT_ERROR";

export const loadPrimarySecondarySentimentRequest = () => ({type: LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST});
export const loadPrimarySecondarySentimentSuccess = (primary, sentiment) => ({type: LOAD_PRIMARY_SECONDARY_SENTIMENT_SUCCESS, primary, sentiment});
export const loadPrimarySecondarySentimentError = (error) => ({type: LOAD_PRIMARY_SECONDARY_SENTIMENT_ERROR, error});

export const loadTopWordsRequest = () => ({ type: LOAD_TOP_WORDS_REQUEST });
export const loadTopWordsSuccess = (topWords) => (
  { type: LOAD_TOP_WORDS_SUCCESS, topWords }
  );
export const loadTopWordsError = error => ({ type: LOAD_TOP_WORDS_ERROR, error });

export const loadSentimentBreakdownRequest = () => ({ type: LOAD_SENTIMENT_BREAKDOWN_REQUEST });
export const loadSentimentBreakdownSuccess = sentimentBreakdown => (
  { type: LOAD_SENTIMENT_BREAKDOWN_SUCCESS, sentimentBreakdown}
  );
export const loadSentimentBreakdownError = error => ({ type: LOAD_SENTIMENT_BREAKDOWN_ERROR, error});
export const loadPrimaryEmotionsBreakdownRequest = () => ({ type: LOAD_PRIMARY_EMOTIONS_BREAKDOWN_REQUEST });
export const loadPrimaryEmotionsBreakdownSuccess = primaryEmotionsBreakdown => (
  { type: LOAD_PRIMARY_EMOTIONS_BREAKDOWN_SUCCESS, primaryEmotionsBreakdown }
  );
export const loadPrimaryEmotionsBreakdownError = error => ({ type: LOAD_PRIMARY_EMOTIONS_BREAKDOWN_ERROR, error });

export const switchTopWordsChartMode = mode => ({type: SWITCH_TOP_WORDS_CHART_MODE, mode});

