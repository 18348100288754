import React from 'react';
import {styles} from '@material-ui/core/Tooltip/Tooltip';
import {makeStyles} from "@material-ui/core";

const tooltipStyles = makeStyles(styles);

const additionalStyles = makeStyles(() => ({
  tooltipInner: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    maxWidth: 'auto',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
    transform: 'translate(-50%, -100%)',
    '& > $arrow': {
      left: '50% !important',
      marginLeft: '-6px !important'
    }
  },
  arrow: {},
}))

const BubbleChartTooltip = ({children}) => {
  const classes = tooltipStyles();
  const addClasses = additionalStyles();

  return (
    <div className={addClasses.tooltipInner}>
      <div role="tooltip" className={`${classes.tooltip} ${classes.popperArrow} ${addClasses.tooltip}`} x-placement="top">
        {children}
        <span className={`${classes.arrow} ${addClasses.arrow}`}/>
      </div>
    </div>
  );
};

export default BubbleChartTooltip;