import {delay} from "redux-saga/effects";
import {call, cancel, fork, select, take, put} from "redux-saga/effects";

import API from "../../API-report";
import {LIVE_REPORT_STOP, liveReportSuccess} from "./live-report-actions";

import {
  loadPrimaryEmotionsBreakdownRequest,
  loadSentimentBreakdownRequest,
  loadTopWordsRequest
} from "../charts/charts-action-creators";
import {loadHxIndex} from "../filters/filters-sagas";

const TIME_INTERVAL = 3000;
const LATEST_MESSAGES_NUMBER = 20;
const NEXT_MESSAGES = 5;

function* runLiveReport(reportId) {
  try {
    let responsesNumber = null;

    while (true) {
      const hxData = yield select(state => state.filters.hxData);
      const responsesAfter = hxData['responses_after_meta_filter'];
      let empty = hxData['empty_lines_count'] || 0;

      let skip, limit;

      if (responsesNumber === null) {
        limit = LATEST_MESSAGES_NUMBER;
        skip = responsesAfter <= LATEST_MESSAGES_NUMBER ? 0 : responsesAfter - LATEST_MESSAGES_NUMBER;
        skip -= empty;
      } else {
        limit = responsesNumber + NEXT_MESSAGES;
        skip = responsesNumber - empty;
      }

      let response;

      try {
        const filters = yield select(state => state.filters.selectedFilters);
        const operator = yield select(state => state.filters.operator);

        response = yield call(API.loadResponses, {
          reportId,
          filters,
          operator,
          skip,
          limit,
          viaMapping: true
        });
      } catch (e) {
        console.error('ERROR IN LIVE REPORT.', e);
        yield delay(TIME_INTERVAL);
        continue;
      }

      const responses = response.data.rows;

      if (responsesNumber === null) {
        responsesNumber = responsesAfter;
      } else {
        responsesNumber = responsesNumber + responses.length;
      }

      empty = response.data['empty_verbatims_hidden'];

      if (responses.length > 0) {
        yield loadHxIndex();
        yield put(loadSentimentBreakdownRequest());
        yield put(loadPrimaryEmotionsBreakdownRequest());
        yield put(loadTopWordsRequest());

        const hxData = yield select(state => state.filters.hxData);
        const hxIndex = hxData['results'];
        const responsesTotal = hxData['responses_after_meta_filter'];

        yield put(liveReportSuccess(hxIndex, responsesTotal, responses));
      }

      yield delay(TIME_INTERVAL);
    }
  } catch (e) {
    console.error('ERROR WHILE RUNNING LIVE REPORT', e);
  }
}

export function* startLiveReport() {
  const reportId = yield select(state => state.report.reportId);
  yield loadHxIndex();

  const task = yield fork(runLiveReport, reportId);

  yield take(LIVE_REPORT_STOP);
  yield cancel(task);
}
