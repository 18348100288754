import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {SEARCH_TABLE_PER_PAGE} from "../taxonomy-constants";
import {Grid, IconButton, makeStyles, MenuItem, TextField} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles(theme => ({
  paginationContainer: {
    width: 'auto'
  },
  resultsContainer: {
    marginLeft: theme.spacing(0.5)
  }
}))

const Pagination = ({from, to, total, page, setPage, className}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const displayFrom = from + 1;
  const displayTo = to >= (total - 1) ? total : to;
  const pagesTotal = Math.ceil(total / SEARCH_TABLE_PER_PAGE);

  return (
    <Grid item container justify="space-between" alignItems="center" wrap="nowrap" spacing={1} className={className}>
      <Grid item className={classes.resultsContainer}>
        <Trans i18nKey="pagination_results">
          Showing <strong>{{displayFrom}} - {{displayTo}}</strong> out of <strong>{{total}}</strong> results
        </Trans>
      </Grid>
      {pagesTotal > 1 && (
        <Grid item container wrap="nowrap" className={classes.paginationContainer} spacing={1} alignItems="center">
          <Grid item>
            <IconButton
              disabled={page === 1}
              onClick={() => setPage(page => page === 1 ? page : page - 1)}
            >
              <ChevronLeftIcon/>
            </IconButton>
          </Grid>
          <Grid item>
            <TextField
              select
              variant="outlined"
              value={page}
              onChange={e => setPage(Number(e.target.value))}
              label={t('page', 'Page')}
            >
              {Array(pagesTotal).fill(0).map((_, i) => (
                <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <IconButton
              disabled={page === pagesTotal}
              onClick={() => setPage(page => page === pagesTotal ? page : page + 1)}
            >
              <ChevronRightIcon/>
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Pagination;