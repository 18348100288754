import {cancel, delay, fork, select, takeEvery, take, put} from 'redux-saga/effects';
import moment from "moment";

import API from "../../API";
import {
  BEGINNING_OF_TIME,
  LAST_SEEN_DATE_KEY,
  NUMBER_OF_MESSAGES,
  OPEN_MODE,
  TIME_INTERVALS
} from "./chat-constants";
import {
  INIT_CHAT,
  newMessages,
  SEND_MESSAGE_REQUEST,
  sendMessageDone, setHelpArticles, setUnseenMessages,
  START_REQUESTING_LIVE_CHAT,
  STOP_REQUESTING_LIVE_CHAT
} from "./chat-actions";
import {addNotification} from "../notifications/notifications-actions";
import {savePersistentData, selectPersistentData} from "../../utilities/localStorageMiddleware";
import i18n from "../../i18n";

const createNextSecondDate = date => {
  const momentDate = moment(date).add(1, 'second');

  return momentDate.format(momentDate._f);
};

function* getRepliesForLiveChat(mode) {
  try {
    const existingMessages = yield select(state => state.chat.messages);

    let date = BEGINNING_OF_TIME;

    if (existingMessages.length > 0) {
      const lastExistingMessageDate = existingMessages[existingMessages.length - 1]['date_created'];
      date = createNextSecondDate(lastExistingMessageDate);

      if (mode === OPEN_MODE) {
        savePersistentData(LAST_SEEN_DATE_KEY, lastExistingMessageDate);
        yield put(setUnseenMessages(0));
      }
    }

    while (true) {
      const lastSeenDate = selectPersistentData(LAST_SEEN_DATE_KEY) || BEGINNING_OF_TIME;
      const response = yield API.getRepliesForLiveChat(NUMBER_OF_MESSAGES, date);
      const messages = response.data['new_messages'];

      if (messages.length > 0) {
        const lastDate = messages[0]['date_created'];

        date = createNextSecondDate(lastDate);
        yield put(newMessages(messages.reverse()));

        if (mode === OPEN_MODE) {
          savePersistentData(LAST_SEEN_DATE_KEY, lastDate);
        } else {
          let numberOfUnseenMessages = 0;

          messages.every(message => {
            const isAfterLastSeen = message['date_created'] > lastSeenDate;

            isAfterLastSeen && numberOfUnseenMessages++;
            return isAfterLastSeen;
          });

          yield put(setUnseenMessages(numberOfUnseenMessages));
        }
      }

      yield delay(TIME_INTERVALS[mode]);
    }
  } catch (e) {
    console.error('ERROR IN GET_REPLY_LIVE_CHAT', e);
  }
}

function* startRequestingLiveChat({mode}) {
  const task = yield fork(getRepliesForLiveChat, mode);

  yield take(STOP_REQUESTING_LIVE_CHAT);

  yield cancel(task);
}

function* sendMessageToLiveChat({message}) {
  try {
    yield API.sendMessageToLiveChat(message);

  } catch (e) {
    console.error('ERROR IN SEND_MESSAGE_LIVE_CHAT', e);

    yield put(addNotification({status: "Error", message: [i18n.t('cant_send_msg_try_again', "Could not send message. Please try again.")]}));
  } finally {
    yield put(sendMessageDone());
  }
}

function* initChat() {
  try {
    const response = yield API.getHelpArticles();
    const articlesWithCommand = response.data.map(a => '!help ' + a);
    yield put(setHelpArticles(articlesWithCommand));
  } catch (e) {
    console.error('ERROR IN INIT_CHAT', e);
  }
}

export default [
  takeEvery(START_REQUESTING_LIVE_CHAT, startRequestingLiveChat),
  takeEvery(SEND_MESSAGE_REQUEST, sendMessageToLiveChat),
  takeEvery(INIT_CHAT, initChat),
];