export const LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST = '[SPLIT CHARTS] LOAD SPLIT CHART PRIMARY EMOTIONS REQUEST';
export const LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_SUCCESS = '[SPLIT CHARTS] LOAD SPLIT CHART PRIMARY EMOTIONS SUCCESS';
export const LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_ERROR = '[SPLIT CHARTS] LOAD SPLIT CHART PRIMARY EMOTIONS ERROR';

export const LOAD_SPLIT_CHART_SENTIMENT_REQUEST = '[SPLIT CHARTS] LOAD SPLIT CHART SENTIMENT REQUEST';
export const LOAD_SPLIT_CHART_SENTIMENT_SUCCESS = '[SPLIT CHARTS] LOAD SPLIT CHART SENTIMENT SUCCESS';
export const LOAD_SPLIT_CHART_SENTIMENT_ERROR = '[SPLIT CHARTS] LOAD SPLIT CHART SENTIMENT ERROR';

export const loadSplitChartPrimaryEmotionsRequest = filterName => ({type: LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST, filterName});

export const loadSplitChartPrimaryEmotionsSuccess = (data, statisticalSignificanceData) => ({type: LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_SUCCESS, data, statisticalSignificanceData});

export const loadSplitChartPrimaryEmotionsError = () => ({type: LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_ERROR});


export const loadSplitChartSentimentRequest = filterName => ({type: LOAD_SPLIT_CHART_SENTIMENT_REQUEST, filterName});

export const loadSplitChartSentimentSuccess = (data, statisticalSignificanceData) => ({type: LOAD_SPLIT_CHART_SENTIMENT_SUCCESS, data, statisticalSignificanceData});

export const loadSplitChartSentimentError = () => ({type: LOAD_SPLIT_CHART_SENTIMENT_ERROR});
