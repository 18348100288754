import {
  DATA_APPEND_DOWNLOAD_COMPLETE,
  DATA_APPEND_DOWNLOAD_PROGRESS,
  DATA_APPEND_HIDE_MODAL,
  DATA_APPEND_INITIATE_DOWNLOAD,
  DATA_APPEND_SET_DOWNLOAD_OPTIONS,
  DATA_APPEND_SHOW_MODAL
} from "./data-append-actions";

const initialState = {
  modalIsShown: false,
  report: null,
  downloadOptions: null,
  linksData: null
};

const createLinksData = (report, downloadOptions) => {
  const linksData = [];

  for (let number = 0; number < downloadOptions.linksNumber; number++) {
    linksData.push({percent: 0, inProgress: false});
  }

  return linksData;
};

const modifyLinkData = (state, number, what) => {
  const linksData = [...state.linksData];
  linksData[number] = {...linksData[number], ...what};
  return linksData;
};

const dataAppendReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_APPEND_SHOW_MODAL:
      return {...state, modalIsShown: true, report: action.report, downloadOptions: null};
    case DATA_APPEND_HIDE_MODAL:
      return {...state, modalIsShown: false, report: null};
    case DATA_APPEND_SET_DOWNLOAD_OPTIONS:
      return {
        ...state,
        downloadOptions: action.downloadOptions,
        linksData: createLinksData(state.report, action.downloadOptions)
      };
    case DATA_APPEND_INITIATE_DOWNLOAD:
      return {
        ...state,
        linksData: modifyLinkData(state, action.number, {inProgress: true})
      };
    case DATA_APPEND_DOWNLOAD_PROGRESS:
      return {
        ...state,
        linksData: modifyLinkData(state, action.number, {percent: action.percent})
      };
    case DATA_APPEND_DOWNLOAD_COMPLETE:
      return {
        ...state,
        linksData: modifyLinkData(state, action.number, {percent: 0, inProgress: false})
      };
    default:
      return state;
  }
};


export default dataAppendReducer;