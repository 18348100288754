import {
  RECALCULATE_EDITED_ROWS_DONE,
  RECALCULATE_EDITED_ROWS_REQUEST,
  REPORT_GOT_EDITED_ROWS_SUCCESS, SET_TOPICS_PROGRESS
} from "./report-router-actions";

const initialState = {
  editedRows: 0,
  recalculateEditedRowsLoading: false,
  topicsInProgress: false,
  topicsProgress: 100,
};

const reportRouterReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_GOT_EDITED_ROWS_SUCCESS:
      return {...state, editedRows: action.editedRows};
    case RECALCULATE_EDITED_ROWS_REQUEST:
      return {...state, recalculateEditedRowsLoading: true};
    case RECALCULATE_EDITED_ROWS_DONE:
      return {...state, recalculateEditedRowsLoading: false};
    case SET_TOPICS_PROGRESS:
      return {...state, topicsInProgress: action.payload.inProgress, topicsProgress: action.payload.progress};
    default:
      return state;
  }
};

export default reportRouterReducer;