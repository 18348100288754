import React from 'react';
import {useTranslation} from "react-i18next";
import UiModal from "../../../components/UiModal/UiModal";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {closeCreateSnapshotModal, createSnapshotRequest} from "../reports-actions";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import Alert from "@material-ui/lab/Alert";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const CreateSnapshotModal = ({reportId}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    createSnapshotModalOpen: isOpen,
    createSnapshotLoading: loading
  } = useSelector(state => state.reports, shallowEqual);

  const close = () => dispatch(closeCreateSnapshotModal());

  const onSubmit = e => {
    e.preventDefault();
    dispatch(createSnapshotRequest(reportId));
  }

  return (
    <UiModal
      title={t('confirm_new_snapshot', "Confirm new snapshot")}
      open={isOpen}
      onClose={close}
    >
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Alert severity="warning" icon={<HelpOutlineIcon fontSize="inherit"/>}>
            {t('do_you_really_want_to_create_new_snapshot', 'Do you really want to create a new snapshot for this report?')}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t('cancel', 'Cancel')}</Button>
          <ButtonWithProgress
            type="submit"
            loading={loading}
            disabled={loading}
            color="primary"
          >
            {t('create', 'Create')}
          </ButtonWithProgress>
        </DialogActions>
      </form>
    </UiModal>
  );
};

export default CreateSnapshotModal;