import {
  CHANGE_PAGE, CHANGE_TARGET_PAGE, DOWNLOAD_TOP_WORDS, DOWNLOAD_TOP_WORDS_DONE, LOAD_TOP_WORDS_PAGINATED, SEARCH_CHANGE
} from "./full-report-top-words-action-types";

export const changePage = ({next, previous, targetPage}) => ({ type: CHANGE_PAGE, next, previous, targetPage});
export const loadTopWordsPaginated = topWordsPaginated => ({ type: LOAD_TOP_WORDS_PAGINATED, topWordsPaginated});
export const searchChange = searchValue => ({ type: SEARCH_CHANGE, searchValue });

export const changeTargetPage = targetPage => ({ type: CHANGE_TARGET_PAGE, targetPage });

export const downloadTopWords = () => ({type: DOWNLOAD_TOP_WORDS});
export const downloadTopWordsDone = () => ({type: DOWNLOAD_TOP_WORDS_DONE});