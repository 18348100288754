import {
  ADD_INPUT, ADD_WORDS_ERROR, ADD_WORDS_REQUEST, ADD_WORDS_SUCCESS, CLOSE_ADD_WORDS_MODAL, ERASE_ADD_WORDS_INPUTS,
  GET_TAXONOMY_LIST_ERROR,
  GET_TAXONOMY_LIST_REQUEST,
  GET_TAXONOMY_LIST_SUCCESS, REMOVE_INPUT, REMOVE_WHITESPACES, SHOW_ADD_WORDS_MODAL, TAXONOMY_CHANGE,
  TEXT_AREA_WORDS_CHANGE,
  WORD_CHANGE, SET_WORDS_ARE_EMPTY, WRONG_VALIDATION, COMMENT_CHANGE
} from "./add-words-action-types";

export const getTaxonomyListRequest = () => ({
  type: GET_TAXONOMY_LIST_REQUEST
});
export const getTaxonomyListSuccess = taxonomyList => ({
  type: GET_TAXONOMY_LIST_SUCCESS,
  taxonomyList
});
export const getTaxonomyListError = error => ({
  type: GET_TAXONOMY_LIST_ERROR,
  error
});

export const wordChange = (word, index) => ({ type: WORD_CHANGE, word, index });
export const addInput = () => ({ type: ADD_INPUT });
export const removeInput = index => ({ type: REMOVE_INPUT, index});

export const taxonomyChange = taxonomy => ({ type: TAXONOMY_CHANGE, taxonomy});

export const addWordsRequest = () => ({ type: ADD_WORDS_REQUEST });
export const addWordsSuccess = () => ({ type: ADD_WORDS_SUCCESS});
export const addWordsError = () => ({ type: ADD_WORDS_ERROR});

export const removeWhitespaces = () => ({ type: REMOVE_WHITESPACES});
export const eraseAddwordsInputs = () => ({ type: ERASE_ADD_WORDS_INPUTS});

export const wrongValidation = validation => ({ type: WRONG_VALIDATION, validation});

export const textAreaWordsChange = textAreaWords => ({ type: TEXT_AREA_WORDS_CHANGE, textAreaWords});
export const showAddWordsModal = () => ({ type: SHOW_ADD_WORDS_MODAL });
export const closeAddWordsModal = () => ({ type: CLOSE_ADD_WORDS_MODAL});

export const setWordsAreEmpty = () => ({ type: SET_WORDS_ARE_EMPTY });
export const commentChange = comment => ({ type: COMMENT_CHANGE, comment});