export const ADD_NOTIFICATION = "[NOTIFICATIONS] ADD_NOTIFICATION";
export const CLOSE_NOTIFICATION = "[NOTIFICATIONS] CLOSE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "[NOTIFICATIONS] REMOVE_NOTIFICATION";

export const addNotification = ({status, message}) => {
  message = message[0];

  return {
    type: ADD_NOTIFICATION,
    notification: {
      message,
      key: message,
      options: {
        variant: status.toLowerCase()
      }
    }
  };
};

export const closeNotification = key => ({
  type: CLOSE_NOTIFICATION,
  dismissAll: !key,
  key
})

export const removeNotification = key => ({type: REMOVE_NOTIFICATION, key});