import React from "react";
import {CHART_TABLE_TITLES} from "../constants";
import {selectAndCopyElement} from "../functions";
import TermHelpTooltipTitle from "../../../components/Tooltip/TermHelpTooltipTitle";
import UiModal from "../../../components/UiModal/UiModal";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {piTransform} from "../../../utilities/domainFunctions";
import i18n from "../../../i18n";
import {useTranslation} from "react-i18next";

const PI_HELP_TEXT = () => {
  const {t} = useTranslation();
  return (
    <TermHelpTooltipTitle term={t('power_index_term', 'Power Index (PI)')}>
      {t('what_is_power_index', 'Power Index is a value between 1 and 100 that reflects the strength of the secondary emotion in the context of the primary emotion.' +
        'Since many words and phrases may be mapped to multiple secondary emotions the totals of PI may add up to more than 100.')}
    </TermHelpTooltipTitle>
  );
}

const ADJPI_HELP_TEXT = () => {
  const {t} = useTranslation();
  return (
    <TermHelpTooltipTitle term={t('adjusted_pi', 'Adjusted PI')}>
      {t('what_is_adjusted_power_index', 'The adjusted PI value takes the value from PI and multiplies it with the strength of the percentage of the primary emotion. ' +
        'This allows one to compare values of secondary emotions across all primary emotion categories.')}
    </TermHelpTooltipTitle>
  );
}

const getColumns = mode => [
  {
    name: "secondary_emotion",
    label: i18n.t('secondary_emotion',"Secondary Emotion"),
  },
  {
    name: "word_count",
    label: i18n.t(CHART_TABLE_TITLES[mode].count[0], CHART_TABLE_TITLES[mode].count[1]),
  },
  {
    name: "power_index",
    label: i18n.t(CHART_TABLE_TITLES[mode].power_index[0], CHART_TABLE_TITLES[mode].power_index[1]),
    options: {
      hint: <PI_HELP_TEXT/>,
      sortDirection: "desc"
    }
  },
  {
    name: "pi_adjusted",
    label: i18n.t(CHART_TABLE_TITLES[mode].pi_adjusted[0], CHART_TABLE_TITLES[mode].pi_adjusted[1]),
    options: {
      hint: ADJPI_HELP_TEXT
    }
  },
  {
    name: "per_response",
    label: i18n.t(CHART_TABLE_TITLES[mode].per_response[0], CHART_TABLE_TITLES[mode].per_response[1]),
  }
];

const createTableAndCopy = (data, columns) => {
  const table = document.createElement('table');
  const headerRow = document.createElement('tr');

  columns.forEach(col => {
    const headerCell = document.createElement('th');
    headerCell.innerHTML = col.label;
    headerCell.style.fontWeight = 'bolder';
    headerRow.appendChild(headerCell);
  });

  table.appendChild(headerRow);

  data.forEach(d => {
    const dataRow = document.createElement('tr');

    d.forEach( el =>{
      const dataCell = document.createElement('td');
      dataCell.innerHTML = el;
      dataRow.appendChild(dataCell);
    });

    table.appendChild(dataRow);
  });

  document.body.appendChild(table);

  selectAndCopyElement(table);

  table.remove();
};

const SecondaryEmotionsModal = ({open, onClose, primary, mode}) => {
  const {t} = useTranslation();
  let title, table;

  if (primary) {
    const tableData = Object.keys(primary.secondaries).map(key => {
      const secondary = primary.secondaries[key][mode];

      return [
        key,
        secondary.count,
        piTransform(secondary.power_index),
        piTransform(secondary.pi_adjusted),
        piTransform(secondary.per_response),
      ];
    });

    title = (
      <span>
        {t('secondary_emotions_distribution_for', 'Secondary emotions distribution for')} {primary.primary}{' '}
        <IconButton onClick={() => createTableAndCopy(tableData, getColumns(mode))}>
          <FileCopyIcon/>
        </IconButton>
      </span>
    );

    table = (
      <MUIDataTable
        title={""}
        data={tableData}
        columns={getColumns(mode)}
        options={{
          selectableRows: "none",
          pagination: false,
          search: false,
          filter: false,
          viewColumns: false,
          download: false,
          print: false,
        }}
      />
    );
  }

  return (
    <UiModal
      open={open}
      onClose={onClose}
      title={title}
      maxWidth="md"
    >
      {table}
    </UiModal>
  );
};

export default SecondaryEmotionsModal;