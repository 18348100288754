import {
  ADD_INPUT, ADD_WORDS_ERROR, ADD_WORDS_REQUEST, ADD_WORDS_SUCCESS, CLOSE_ADD_WORDS_MODAL, ERASE_ADD_WORDS_INPUTS,
  GET_TAXONOMY_LIST_ERROR,
  GET_TAXONOMY_LIST_REQUEST,
  GET_TAXONOMY_LIST_SUCCESS, REMOVE_INPUT, REMOVE_WHITESPACES, SHOW_ADD_WORDS_MODAL, TAXONOMY_CHANGE,
  TEXT_AREA_WORDS_CHANGE, WORD_CHANGE, SET_WORDS_ARE_EMPTY,
  WRONG_VALIDATION, COMMENT_CHANGE
} from "./add-words-action-types";
import removeWhitespaces from "../../utilities/removeWhitespaces";
import {splitWords} from "./functions";

const initialState = {
  taxonomyList: [],
  selectedTaxonomy: "",
  loading: false,
  error: "",
  words: [""],
  validation: [],
  textAreaWords: "",
  textAreaWordsProcessed: [],
  modalIsShown: false,
  wordsAreEmpty: false,
  comment: ""
};

const addWordsReducer = (state = initialState, action) => {
  let words = [...state.words];
  const { textAreaWords, comment } = action;
  switch (action.type) {

    case COMMENT_CHANGE:
      return {
        ...state,
        comment
      };

    case SET_WORDS_ARE_EMPTY:
      return {
        ...state,
        wordsAreEmpty: true
      };

    case SHOW_ADD_WORDS_MODAL:
      return {
        ...state,
        modalIsShown: true
      };

    case CLOSE_ADD_WORDS_MODAL:
      return {
        ...state,
        modalIsShown: false,
        wordsAreEmpty: false
      };

    case TEXT_AREA_WORDS_CHANGE:
      const textAreaWordsProcessed = splitWords(textAreaWords).map(word => removeWhitespaces(word));
      return {
        ...state,
        textAreaWords,
        textAreaWordsProcessed,
        wordsAreEmpty: false
      };

    case WRONG_VALIDATION:
      return {
        ...state,
        validation: action.validation,
        loading: false
      };

    case ADD_WORDS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ADD_WORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        textAreaWords: "",
        textAreaWordsProcessed: []
      };

    case ADD_WORDS_ERROR:
      return {
        ...state,
        loading: false,
        validation: []
      };

    case ERASE_ADD_WORDS_INPUTS:
      return {
        ...state,
        selectedTaxonomy: state.taxonomyList[0]._id,
        words: [""]
      };

    case REMOVE_WHITESPACES:

      words = words.map(word => removeWhitespaces(word));

      return {
        ...state,
        words
      };

    case TAXONOMY_CHANGE:
      return {
        ...state,
        selectedTaxonomy: action.taxonomy
      };

    case REMOVE_INPUT:
      words.splice(action.index, 1);
      return {
        ...state,
        words
      };

    case ADD_INPUT:
      words.push("");
      return {
        ...state,
        words
      };

    case WORD_CHANGE:
      words[action.index] = action.word;
      return {
        ...state,
        words,
        validation: []
      };

    case GET_TAXONOMY_LIST_REQUEST:
      return {
        ...state,
      };

    case GET_TAXONOMY_LIST_ERROR:
      return {
        ...state,
        error: action.error
      };

    case GET_TAXONOMY_LIST_SUCCESS:
      return {
        ...state,
        taxonomyList: action.taxonomyList,
        selectedTaxonomy: state.selectedTaxonomy ? state.selectedTaxonomy : action.taxonomyList[0]._id,
        error: ""
      };

    default:
      return state;
  }
};

export default addWordsReducer;
