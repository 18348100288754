import React from 'react';
import {Grid} from "@material-ui/core";
import CheckCircle from "./CheckCircle";

const StepContainer = ({active, children}) => {
  return (
    <Grid
      item
      container
      direction="row"
      spacing={2}
    >
      <Grid item xs={1} container alignItems="center" justify="center">
        <Grid item>
          <CheckCircle active={active}/>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};

export default StepContainer;