import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom';
import {Button, Grid, Snackbar, CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {recalculateEditedRowsRequest} from "../report-router-actions";

const ReportNeedsRecalculationMessage = () => {
  const {t} = useTranslation();
  const editedRows = useSelector(state => state.reportRouter.editedRows);
  const loading = useSelector(state => state.reportRouter.recalculateEditedRowsLoading);

  const dispatch = useDispatch();
  const history = useHistory();

  const recalculate = () => {
    dispatch(recalculateEditedRowsRequest(history));
  };

  return (
    <Snackbar open={editedRows > 0} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <Alert elevation={6} variant="filled" severity="warning">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {t('this_report_contains_n_edited_responses', 'This report contains {{editedRows}} responses that were edited recently', {editedRows})}<br/>
            {t('recalculate_to_get_most_recent_report', 'Recalculate to get the most recent version of report')}
          </Grid>
          <Grid item>
            {loading ?
              <CircularProgress color="inherit" size="20px"/>
              :
              <Button color="inherit" variant="outlined" size="small" onClick={recalculate}>{t('recalculate', 'Recalculate')}</Button>
            }
          </Grid>
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default ReportNeedsRecalculationMessage;