import {select, put, takeEvery, debounce} from "redux-saga/effects";
import API from "../../API-report";
import {loadResponsesError, loadResponsesRequest, loadResponsesSuccess} from "./responses-action-creators";
import {CHANGE_PAGE_REQUEST, LOAD_RESPONSES_REQUEST} from "./responses-action-types";
import i18n from "../../i18n";

const ITEMS_PER_PAGE = 20;

const getSkip = page => page * ITEMS_PER_PAGE - ITEMS_PER_PAGE;

const getLimit = page => page * ITEMS_PER_PAGE;

export function* loadResponses({page, keepTab}) {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  try {
    const limit = getLimit(page);
    const skip = getSkip(page);

    const response = yield API.loadResponses({reportId, filters, operator, skip, limit});

    if (response.data.rows && response.data.rows.length > 0) {
      const totalPages = Math.ceil(response.data['responses_after_meta_filter'] / ITEMS_PER_PAGE);

      const tabs = response.data.rows[0]['tabs'].map(tab => tab.name);

      const hiddenVerbatims = response.data['empty_verbatims_hidden'];

      yield put(loadResponsesSuccess({responses: response.data.rows, page, totalPages, tabs, keepTab, hiddenVerbatims}));
    } else {
      yield put(loadResponsesError(i18n.t('no_data', 'No data')));
    }
  } catch(error) {
    console.log(error, 'ERROR FROM LOAD RESPONSES');
    yield put(loadResponsesError(i18n.t('something_went_wrong', "Something went wrong...")))
  }
}

export function* changePage({page}) {
  const totalPages = yield select(state => state.responses.totalPages);

  if (page > 0 && page <= totalPages) {
    yield put(loadResponsesRequest(page, true));
  }
}

export default [
  debounce(300, LOAD_RESPONSES_REQUEST, loadResponses),
  takeEvery(CHANGE_PAGE_REQUEST, changePage)
]