export const FETCH_ARTICLES_REQUEST = '[ARTICLES] FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = '[ARTICLES] FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = '[ARTICLES] FETCH_ARTICLES_FAILURE';

export const SET_CREATE_MODAL_OPEN = '[ARTICLES] SET_CREATE_MODAL_OPEN';

export const CREATE_ARTICLE_REQUEST = '[ARTICLES] CREATE_ARTICLE_REQUEST';
export const CREATE_ARTICLE_DONE = '[ARTICLES] CREATE_ARTICLE_DONE';

export const DELETE_ARTICLE_REQUEST = '[ARTICLES] DELETE_ARTICLE_REQUEST';
export const DELETE_ARTICLE_DONE = '[ARTICLES] DELETE_ARTICLE_DONE';

export const FETCH_ARTICLE_REQUEST = '[ARTICLES] FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = '[ARTICLES] FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = '[ARTICLES] FETCH_ARTICLE_FAILURE';

export const EDIT_ARTICLE_REQUEST = '[ARTICLES] EDIT_ARTICLE_REQUEST';
export const EDIT_ARTICLE_DONE = '[ARTICLES] EDIT_ARTICLE_DONE';

export const FETCH_IMAGES_REQUEST = '[ARTICLES] FETCH_IMAGES_REQUEST';
export const FETCH_IMAGES_SUCCESS = '[ARTICLES] FETCH_IMAGES_SUCCESS';
export const FETCH_IMAGES_FAILURE = '[ARTICLES] FETCH_IMAGES_FAILURE';

export const FETCH_IMAGES_TAGS_REQUEST = '[ARTICLES] FETCH_IMAGES_TAGS_REQUEST';
export const FETCH_IMAGES_TAGS_SUCCESS = '[ARTICLES] FETCH_IMAGES_TAGS_SUCCESS';
export const FETCH_IMAGES_TAGS_FAILURE = '[ARTICLES] FETCH_IMAGES_TAGS_FAILURE';

export const SET_SELECTED_TAG = '[ARTICLES] SET_SELECTED_TAG';

export const SET_IMAGE_MODAL_OPEN = '[ARTICLES] SET_IMAGE_MODAL_OPEN';
export const SET_DELETE_IMAGE_MODAL_OPEN = '[ARTICLES] SET_DELETE_IMAGE_MODAL_OPEN';
export const SET_IMAGES_SELECTOR_MODAL_OPEN = '[ARTICLES] SET_IMAGES_SELECTOR_MODAL_OPEN';

export const CREATE_IMAGE_REQUEST = '[ARTICLES] CREATE_IMAGE_REQUEST';
export const CREATE_IMAGE_DONE = '[ARTICLES] CREATE_IMAGE_DONE';

export const FETCH_ARTICLES_WITH_IMAGE_REQUEST = '[ARTICLES] FETCH_ARTICLES_WITH_IMAGE_REQUEST';
export const FETCH_ARTICLES_WITH_IMAGE_SUCCESS = '[ARTICLES] FETCH_ARTICLES_WITH_IMAGE_SUCCESS';
export const FETCH_ARTICLES_WITH_IMAGE_FAILURE = '[ARTICLES] FETCH_ARTICLES_WITH_IMAGE_FAILURE';

export const DELETE_IMAGE_REQUEST = '[ARTICLES] DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = '[ARTICLES] DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE = '[ARTICLES] DELETE_IMAGE_FAILURE';

export const fetchArticlesRequest = () => ({type: FETCH_ARTICLES_REQUEST});
export const fetchArticlesSuccess = articles => ({type: FETCH_ARTICLES_SUCCESS, articles});
export const fetchArticlesFailure = () => ({type: FETCH_ARTICLES_FAILURE});
export const setCreateModalOpen = isOpen => ({type: SET_CREATE_MODAL_OPEN, isOpen});

export const createArticleRequest = payload => ({type: CREATE_ARTICLE_REQUEST, payload});
export const createArticleDone = () => ({type: CREATE_ARTICLE_DONE});

export const deleteArticleRequest = id => ({type: DELETE_ARTICLE_REQUEST, payload: id});
export const deleteArticleDone = id => ({type: DELETE_ARTICLE_DONE, payload: id});

export const fetchArticleRequest = payload => ({type: FETCH_ARTICLE_REQUEST, payload});
export const fetchArticleSuccess = article => ({type: FETCH_ARTICLE_SUCCESS, payload: article});
export const fetchArticleFailure = () => ({type: FETCH_ARTICLE_FAILURE});

export const editArticleRequest = (articleData, redirect, history) => ({type: EDIT_ARTICLE_REQUEST, payload: {articleData, redirect, history}});
export const editArticleDone = () => ({type: EDIT_ARTICLE_DONE});

export const fetchImagesRequest = (searchString, tag) => ({type: FETCH_IMAGES_REQUEST, payload: {searchString, tag}});
export const fetchImagesSuccess = images => ({type: FETCH_IMAGES_SUCCESS, payload: images});
export const fetchImagesFailure = () => ({type: FETCH_IMAGES_FAILURE});

export const setSelectedTag = tag => ({type: SET_SELECTED_TAG, payload: tag});

export const fetchImagesTagsRequest = () => ({type: FETCH_IMAGES_TAGS_REQUEST});
export const fetchImagesTagsSuccess = images => ({type: FETCH_IMAGES_TAGS_SUCCESS, payload: images});
export const fetchImagesTagsFailure = () => ({type: FETCH_IMAGES_TAGS_FAILURE});

export const setImageModalOpen = (isOpen, pasteData, api) => ({type: SET_IMAGE_MODAL_OPEN, payload: {isOpen, pasteData, api}});

export const createImageRequest = (imageData, api) => ({type: CREATE_IMAGE_REQUEST, payload: {imageData, api}});
export const createImageDone = () => ({type: CREATE_IMAGE_DONE});

export const setDeleteImageModalOpen = (isOpen, id) => ({type: SET_DELETE_IMAGE_MODAL_OPEN, payload: {isOpen, id}});
export const setImagesSelectorModalOpen = (isOpen, api) => ({type: SET_IMAGES_SELECTOR_MODAL_OPEN, payload: {isOpen, api}});

export const fetchArticlesWithImageRequest = id => ({type: FETCH_ARTICLES_WITH_IMAGE_REQUEST, payload: id});
export const fetchArticlesWithImageSuccess = articles => ({type: FETCH_ARTICLES_WITH_IMAGE_SUCCESS, payload: articles});
export const fetchArticlesWithImageFailure = () => ({type: FETCH_ARTICLES_WITH_IMAGE_FAILURE});

export const deleteImageRequest = id => ({type: DELETE_IMAGE_REQUEST, payload: id});
export const deleteImageSuccess = id => ({type: DELETE_IMAGE_SUCCESS, payload: id});
export const deleteImageFailure = id => ({type: DELETE_IMAGE_FAILURE, payload: id});