import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";

const getFakeElement = (coords) => {
  if (!coords) return null;

  const {x, y} = coords;

  const width = 1;
  const height = 1;

  return {
    clientWidth: width,
    clientHeight: height,
    getBoundingClientRect: () => ({
      left: x,
      top: y,
      right: x + width,
      bottom: y + height,
      width: width,
      height: height,
      x: x,
      y: y,
    })
  }
};

const FloatingTooltip = ({coords, children, ...props}) => {
  return (
    <Tooltip
      PopperProps={{anchorEl: getFakeElement(coords)}}
      open={!!coords}
      {...props}
    >
      {children || <span/>}
    </Tooltip>
  );
};

export default FloatingTooltip;