import React from 'react';

import ReportInfo from "../../containers/report-info/report-info-container";

import DrawerWithContent from "../../components/Drawer/DrawerWithContent";
import Grid from "@material-ui/core/Grid";
import FiltersContainer from "../../containers/filters/filters-container";
import {useSelector} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";


const withFilter = WrappedComponent => {
  return function WithFilter(props) {
    const loaded = useSelector(state => state.filters.loaded);

    return (
      <DrawerWithContent drawerContent={<FiltersContainer/>}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <ReportInfo/>
          </Grid>
          {loaded ? (
            <WrappedComponent {...props} />
          ) : (
            <Grid item xs container justify="center" alignItems="center">
              <Grid item>
                <CircularProgress/>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DrawerWithContent>
    )
  };
};

export default withFilter;
