
export const SHOW_CONTEXT_MENU = "[CONTEXT MENU] SHOW_CONTEXT_MENU";
export const HIDE_CONTEXT_MENU = "[CONTEXT MENU] HIDE_CONTEXT_MENU";
export const SET_SELECTED_TEXT = "[CONTEXT MENU] SET_SELECTED_TEXT";


export const showContextMenu = ({ top, left }) => {
  window.onwheel = e => e.preventDefault();
  return { type: SHOW_CONTEXT_MENU, top, left };
};

export const hideContextMenu = () => {
  window.onwheel = null;
  return { type: HIDE_CONTEXT_MENU };
};

export const setSelectedText = selectedText => ({ type: SET_SELECTED_TEXT, selectedText });
