import React, {lazy, Suspense} from 'react';
import {useSelector} from "react-redux";
import {Redirect, Route, Router, Switch} from "react-router-dom";

import history from './history';

import ScrollToTop from "./hoc/scroll-to-top/scroll-to-top-component";
import Layout from "./hoc/layout/layout-component";
import PrivateRoute from "./hoc/private-route/privateRoute";
import Reports from "./containers/reports/reports";
import Login from "./containers/login/login";
import CreateReport from "./containers/create-report/create-report";
import CreateReportSuccess from "./containers/create-report-success/create-report-success";
import PurchaseStatistics from "./containers/purchase-statistics/PurchaseStatistics";
import ReportRouter from './containers/report-router/report-router';
import ForgotPassword from "./containers/password-reset/forgot-password";
import ResetPassword from "./containers/password-reset/reset-password";
import Users from "./containers/users/users";
import Taxonomy from "./containers/taxonomy/taxonomy";
import {CircularProgress} from "@material-ui/core";
import ArticleView from "./containers/articles/ArticleView";

const App = () => {
  const user = useSelector(state => state.user);

  return (
    <Router history={history}>
      <Suspense fallback={<CircularProgress/>}>
        <ScrollToTop>
          <Layout>
            <Switch>
              <PrivateRoute isAllowed={user} exact path="/" component={Reports}/>
              <Route path="/report/:id" component={ReportRouter}/>
              <PrivateRoute isAllowed={user && !user['is_guest']} path="/create-report/success"
                            component={CreateReportSuccess}/>
              <PrivateRoute isAllowed={user && !user['is_guest']} path="/create-report" component={CreateReport}/>
              <PrivateRoute isAllowed={user && !user['is_guest']} path="/purchase-statistics"
                            component={PurchaseStatistics}/>
              <PrivateRoute isAllowed={user && (user['is_superuser'] || user['is_translator'])} path="/taxonomy"
                            component={Taxonomy}/>
              <PrivateRoute isAllowed={user && user['is_superuser']} path="/users" component={Users}/>
              <PrivateRoute isAllowed={user && user['is_superuser']} path="/articles" component={lazy(() => import('./containers/articles/ArticlesRouter'))}/>
              <PrivateRoute isAllowed={user} path="/article/:name" component={ArticleView}/>

              <Route path="/login" component={Login}/>
              <Route path="/forgot-password" component={ForgotPassword}/>
              <Route path="/reset-password" component={ResetPassword}/>
              <Route render={() => <Redirect to="/"/>}/>
            </Switch>
          </Layout>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
};

export default App;
