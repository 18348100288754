import {
  CREATE_ARTICLE_DONE,
  CREATE_ARTICLE_REQUEST,
  CREATE_IMAGE_DONE,
  CREATE_IMAGE_REQUEST,
  DELETE_ARTICLE_DONE,
  DELETE_ARTICLE_REQUEST,
  DELETE_IMAGE_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  EDIT_ARTICLE_DONE,
  EDIT_ARTICLE_REQUEST,
  FETCH_ARTICLE_FAILURE,
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_WITH_IMAGE_FAILURE,
  FETCH_ARTICLES_WITH_IMAGE_REQUEST,
  FETCH_ARTICLES_WITH_IMAGE_SUCCESS,
  FETCH_IMAGES_FAILURE,
  FETCH_IMAGES_REQUEST,
  FETCH_IMAGES_SUCCESS,
  FETCH_IMAGES_TAGS_FAILURE,
  FETCH_IMAGES_TAGS_REQUEST,
  FETCH_IMAGES_TAGS_SUCCESS,
  SET_CREATE_MODAL_OPEN,
  SET_DELETE_IMAGE_MODAL_OPEN,
  SET_IMAGE_MODAL_OPEN, SET_IMAGES_SELECTOR_MODAL_OPEN, SET_SELECTED_TAG
} from "./acticles-actions";

const initialState = {
  articles: [],
  images: [],
  imagesTags: [],
  fetchLoading: false,
  createLoading: false,
  fetchSingleLoading: false,
  editLoading: false,
  fetchImagesLoading: false,
  createImageLoading: false,
  fetchImagesTagsLoading: false,
  fetchArticlesWithImageLoading: false,
  singleArticle: null,
  createModalOpen: false,
  imageModalOpen: false,
  imagePasteData: null,
  deleteImageModalOpen: false,
  imageSelectorModalOpen: false,
  imageSelectorModalApi: null,
  deleteImageId: null,
  articlesWithImage: null,
  selectedTag: null,
}

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return {...state, fetchLoading: true};
    case FETCH_ARTICLES_SUCCESS:
      return {...state, fetchLoading: false, articles: action.articles};
    case FETCH_ARTICLES_FAILURE:
      return {...state, fetchLoading: false};
    case SET_CREATE_MODAL_OPEN:
      return {...state, createModalOpen: action.isOpen};
    case CREATE_ARTICLE_REQUEST:
      return {...state, createLoading: true};
    case CREATE_ARTICLE_DONE:
      return {...state, createLoading: false};
    case DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        articles: state.articles.map(a => {
          if (a._id === action.payload) {
            return {...a, loading: true};
          }
          return a;
        })
      };
    case DELETE_ARTICLE_DONE:
      return {
        ...state,
        articles: state.articles.map(a => {
          if (a._id === action.payload) {
            return {...a, loading: false};
          }
          return a;
        })
      };
    case FETCH_ARTICLE_REQUEST:
      return {...state, fetchSingleLoading: true};
    case FETCH_ARTICLE_SUCCESS:
      return {...state, fetchSingleLoading: false, singleArticle: action.payload};
    case FETCH_ARTICLE_FAILURE:
      return {...state, fetchSingleLoading: false};
    case EDIT_ARTICLE_REQUEST:
      return {...state, editLoading: true};
    case EDIT_ARTICLE_DONE:
      return {...state, editLoading: false};
    case FETCH_IMAGES_REQUEST:
      return {...state, fetchImagesLoading: true};
    case FETCH_IMAGES_SUCCESS:
      return {...state, fetchImagesLoading: false, images: action.payload};
    case FETCH_IMAGES_FAILURE:
      return {...state, fetchImagesLoading: false};
    case FETCH_IMAGES_TAGS_REQUEST:
      return {...state, fetchImagesTagsLoading: true};
    case FETCH_IMAGES_TAGS_SUCCESS:
      return {...state, fetchImagesTagsLoading: false, imagesTags: action.payload};
    case FETCH_IMAGES_TAGS_FAILURE:
      return {...state, fetchImagesTagsLoading: false};
    case SET_IMAGE_MODAL_OPEN:
      if (action.payload.isOpen) {
        return {...state, imageModalOpen: action.payload.isOpen, imageSelectorModalApi: action.payload.api, imagePasteData: action.payload.pasteData};
      } else {
        return {...state, imageModalOpen: action.payload.isOpen, imageSelectorModalApi: null, imagePasteData: null};
      }
    case CREATE_IMAGE_REQUEST:
      return {...state, createImageLoading: true};
    case CREATE_IMAGE_DONE:
      return {...state, createImageLoading: false};
    case SET_DELETE_IMAGE_MODAL_OPEN:
      return {...state, deleteImageModalOpen: action.payload.isOpen, deleteImageId: action.payload.id};
    case FETCH_ARTICLES_WITH_IMAGE_REQUEST:
      return {...state, articlesWithImage: null, fetchArticlesWithImageLoading: true};
    case FETCH_ARTICLES_WITH_IMAGE_SUCCESS:
      return {...state, fetchArticlesWithImageLoading: false, articlesWithImage: action.payload};
    case FETCH_ARTICLES_WITH_IMAGE_FAILURE:
      return {...state, fetchArticlesWithImageLoading: false};
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        images: state.images.map(image => {
          if (image._id === action.payload) {
            return {...image, loading: true};
          }
          return image;
        })
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        images: state.images.filter(image => image._id !== action.payload)
      }
    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        images: state.images.map(image => {
          if (image._id === action.payload) {
            return {...image, loading: false};
          }
          return image;
        })
      };
    case SET_IMAGES_SELECTOR_MODAL_OPEN:
      return {...state, imageSelectorModalOpen: action.payload.isOpen, imageSelectorModalApi: action.payload.api};
    case SET_SELECTED_TAG:
      return {...state, selectedTag: action.payload};
    default:
      return state;
  }
};

export default articlesReducer;