import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  key: {
    fontWeight: '400'
  },
  keyValueRow: {
    marginBottom: theme.spacing(1)
  },
}));

const MetricKeyValuePairs = ({metricItem}) => {
  const classes = useStyles();

  return metricItem.data.map(row => (
    <div key={row.key} className={classes.keyValueRow}>
      <Typography variant="body1">
        <strong className={classes.key}>{row.key}</strong>: {row.value}
      </Typography>

      {row.description && (
        <Typography variant="body2">{row.description}</Typography>
      )}
    </div>
  ));
};

export default MetricKeyValuePairs;