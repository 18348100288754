import {VALUE_KEYS_BY_MODE} from "../../../containers/report/report-constants";
import {
  MAXIMUM_PRIMARY_RADIUS,
  MAXIMUM_SECONDARY_RADIUS,
  MINIMUM_PERCENT,
  PRIMARY_INNER_SIZE,
  SECONDARY_INNER_SIZE
} from "./constants";
import {createColor} from "../../../utilities/getColor";

const getCounts = (data, mode) => {
  let primaryTotalCount = 0;
  let secondaryTotalCount = 0;
  let primarySecondaryCounts = {};

  data.forEach(primaryItem => {
    let primarySecondaryCount = 0;

    primaryTotalCount += primaryItem.modes[mode].count;

    Object.keys(primaryItem.secondaries).forEach(secondaryName => {
      const secondaryItem = primaryItem.secondaries[secondaryName];
      let secondaryItemCount = secondaryItem[mode].count;
      secondaryTotalCount += secondaryItemCount;
      primarySecondaryCount += secondaryItemCount;
    });

    primarySecondaryCounts[primaryItem.primary] = primarySecondaryCount;
  });

  return {
    primaryTotalCount,
    secondaryTotalCount,
    primarySecondaryCounts,
  }
};

export const transformBubbleChartData = (data, mode, primaryColors) => {
  const valueKey = VALUE_KEYS_BY_MODE[mode];
  const nodes = [];
  const links = [];
  const nodesByName = {};
  const {primaryTotalCount, secondaryTotalCount, primarySecondaryCounts} = getCounts(data, mode);

  const createPrimaryNode = primaryItem => {
    const primaryName = primaryItem.primary;
    const primaryPercent = primaryItem.modes[mode][valueKey];
    const primaryItemCount = primaryItem.modes[mode].count;
    const radius = primaryItemCount / primaryTotalCount * MAXIMUM_PRIMARY_RADIUS;

    const primaryNode = {
      id: primaryName,
      name: primaryName,
      primary: true,
      secondaryCount: primarySecondaryCounts[primaryName],
      value: primaryItemCount,
      percent: primaryPercent,
      radius,
      forceRadius: radius + PRIMARY_INNER_SIZE,
      circleRadius: PRIMARY_INNER_SIZE + radius - (radius / 2),
      color: createColor(primaryColors[primaryName])
    };

    Object.keys(primaryItem.secondaries).forEach(secondaryName => {
      const secondaryItem = primaryItem.secondaries[secondaryName];
      createSecondaryNode(primaryNode, secondaryItem, secondaryName);
    });

    nodesByName[primaryName] = primaryNode;
    nodes.push(primaryNode);
  }

  const createSecondaryNode = (primaryNode, secondaryItem, secondaryName) => {
    const secondaryItemCount = secondaryItem[mode].count;
    const primaryName = primaryNode.name;

    // filter secondary nodes by minimum percent
    if (secondaryItemCount < primaryNode.secondaryCount * MINIMUM_PERCENT) {
      return;
    }

    const id = primaryName + '_' + secondaryName;
    const radius = secondaryItemCount / secondaryTotalCount * MAXIMUM_SECONDARY_RADIUS;

    const secondaryNode = {
      id,
      name: secondaryName,
      parentName: primaryName,
      parentNode: primaryNode,
      primary: false,
      value: secondaryItemCount,
      powerIndex: Math.round(secondaryItem[mode].power_index * 100),
      perResponse: secondaryItem[mode].per_response,
      radius,
      circleRadius: SECONDARY_INNER_SIZE + radius - (radius / 2),
      color: createColor(primaryColors[primaryName])
    };

    links.push({
      id: id,
      source: primaryNode,
      target: secondaryNode
    });

    nodesByName[id] = secondaryNode;
    nodes.push(secondaryNode);
  };

  data.forEach(createPrimaryNode);

  return {
    nodes,
    nodesByName,
    links,
    primaryTotalCount,
    secondaryTotalCount,
    numberOfPrimaryNodes: data.length,
  };
}