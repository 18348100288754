export const LOAD_HB_INDEX_CHART_DATA_REQUEST = "[HB INDEX CHART] LOAD_HB_INDEX_CHART_DATA_REQUEST";
export const LOAD_HB_INDEX_CHART_DATA_SUCCESS = "[HB INDEX CHART] LOAD_HB_INDEX_CHART_DATA_SUCCESS";
export const LOAD_HB_INDEX_CHART_DATA_ERROR = "[HB INDEX CHART] LOAD_HB_INDEX_CHART_DATA_ERROR";
export const HB_INDEX_SET_SELECTED_FILTER_NAME = "[HB INDEX CHART] HB_INDEX_SET_SELECTED_FILTER_NAME";
export const HB_INDEX_CHART_CLEAR_DATA = "[HB INDEX CHART] CLEAR DATA";
export const HB_INDEX_SET_SECOND_VARIABLE_NAME = "[HB INDEX CHART] HB_INDEX_SET_SECOND_VARIABLE_NAME";

export const loadHbIndexChartDataRequest = () => ({ type: LOAD_HB_INDEX_CHART_DATA_REQUEST });
export const loadHbIndexChartDataSuccess = ({ data, filterName}) => (
  { type: LOAD_HB_INDEX_CHART_DATA_SUCCESS, data, filterName }
  );
export const loadHbIndexChartDataError = error => ({ type: LOAD_HB_INDEX_CHART_DATA_ERROR, error });

export const setSelectedFilterName = filterName => ({ type: HB_INDEX_SET_SELECTED_FILTER_NAME, filterName });
export const setSecondVariableName = secondVariableName => ({ type: HB_INDEX_SET_SECOND_VARIABLE_NAME, secondVariableName });

export const clearHbIndexChartData = () => ({ type: HB_INDEX_CHART_CLEAR_DATA});
