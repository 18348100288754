import React from 'react';
import {useTranslation} from "react-i18next";
import {Button, ButtonGroup, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: '100%'
  }
});

const ButtonRadio = ({data, select, selected}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <ButtonGroup className={classes.root}>
      {data.map((d, i) => {
        const label = t('button_radio_' + d, d);
        const maxChars = 60;
        const tooLarge = label.length > maxChars;

        let valueString = label;

        if (tooLarge) {
          valueString = label.substring(0, maxChars) + '...';
        }

        return (
          <Button
            color={d === selected ? "primary" : "default"}
            variant={d === selected ? "contained" : "outlined"}
            key={i}
            onClick={() => select(d)}
            title={tooLarge ? d : undefined}
          >
            {valueString}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default ButtonRadio;
