import { call, select, put } from "redux-saga/effects";
import API from "../../API-report";
import {
  addWordsError,
  addWordsSuccess,
  closeAddWordsModal,
  getTaxonomyListError,
  getTaxonomyListSuccess,
} from "./add-words-action-creators";
import { addNotification } from "../notifications/notifications-actions";
import i18n from "../../i18n";

export function* taxonomyListRequest() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const data = yield call(API.getTaxonomyList, reportId);
    const taxonomyList = data.data;
    taxonomyList.length
      ? yield put(getTaxonomyListSuccess(data.data))
      : yield put(getTaxonomyListError(i18n.t('empty_taxonomy_list', 'Empty taxonomy list')));
  } catch (error) {
    console.log(error, "ERROR FROM TAXONOMY LIST REQUEST");
    yield put(getTaxonomyListError(i18n.t('something_went_wrong', 'Something went wrong')));
  }
}

export function* addWordsRequest() {
  const token = yield select(state => state.login.token);
  const taxonomy = yield select(state => state.addWords.selectedTaxonomy);
  const textAreaWordsProcessed = yield select(state => state.addWords.textAreaWordsProcessed);
  const comment = yield select(state => state.addWords.comment);
  const label = yield select(state => state.selectLabel.selectedLabelColor);
  const terms = textAreaWordsProcessed.map(term => {
    const newObject = {};
    newObject.term = term;
    newObject.comment = comment;
    newObject.label = label;
    return newObject;
  });

  try {
    const data = yield call(API.addWords, { taxonomy, terms, token });
    const insertedWords = data.data.inserted.map(word => word.term.term).join(", ");
    const existedWords = data.data.existed.map(word => word.term.term).join(", ");
    const messages = [
      i18n.t('inserted_in_taxonomy', 'Inserted: {{insertedWords}} in {{taxonomy}}', {insertedWords: insertedWords.length ? insertedWords : 'none', taxonomy}),
      i18n.t('existed_in_taxonomy', 'Existed: {{existedWords}} in {{taxonomy}}', {existedWords: existedWords.length ? existedWords : 'none', taxonomy}),
    ];
    yield put(addNotification({ message: messages, status: 'Success'}));
    yield put(addWordsSuccess());
    yield put(closeAddWordsModal())

  } catch (error) {
    console.log(error, "ERROR FROM ADD WORDS REQUEST");
    yield put(addWordsError());
    yield put(addNotification({ message: [i18n.t('something_went_wrong', 'Something went wrong')], status: "Error"}));
  }
}
