import React, {Fragment} from "react";
import {Box, Button, DialogActions, DialogContent, Divider, Grid, Paper, Typography} from "@material-ui/core";
import IconButtonWithCircularProgress
  from "../../../components/IconButtonWithCircularProgress/IconButtonWithCircularProgress";
import GetAppIcon from "@material-ui/icons/GetApp";
import {useTranslation} from "react-i18next";

const LinksData = ({linksData, initiateDownload, display, goBack}) => {
  const {t} = useTranslation();

  return display && (
    <Fragment>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Typography variant="h5">{t('download_links', 'Download links')}</Typography>
          </Grid>

          {linksData.map((data, i) => (
            <Grid key={i} item xs>
              <Paper component={Box} p={1}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item xs>
                    <span>{t('file', 'File')} #{i + 1}</span>
                  </Grid>

                  <Grid item>
                    <IconButtonWithCircularProgress
                      loading={data.inProgress}
                      IconButtonProps={{
                        disabled: data.inProgress,
                        onClick: () => initiateDownload(i)
                      }}
                      CircularProgressProps={{
                        variant: data.percent > 10 ? 'determinate' : undefined,
                        value: data.percent
                      }}
                    >
                      <GetAppIcon/>
                    </IconButtonWithCircularProgress>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}

        </Grid>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button type="button" onClick={goBack}>{t('go_back', 'Go back')}</Button>
      </DialogActions>
    </Fragment>
  );
};

export default LinksData;