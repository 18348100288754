import React from 'react';
import {connect} from 'react-redux';
import {loadSplitChartPrimaryEmotionsRequest} from "./split-charts-actions";

import SplitChartWrapper from "./split-chart-wrapper";
import {addNotification} from "../notifications/notifications-actions";
import PrimaryEmotionsStatisticalData from "./primary-emotions/PrimaryEmotionsStatisticalData";
import {useTranslation} from "react-i18next";

const PrimaryEmotionsSplitChart = props => {
  const {t} = useTranslation();

  const footer = (
    <PrimaryEmotionsStatisticalData
      key={props.data}
      data={props.data}
      statisticalData={props.statisticalSignificanceData}
    />
  );

  return (
    <SplitChartWrapper
      {...props}
      rowTitle={t('filter_value', 'Filter value')}
      colTitle={t('emotion', 'Emotion')}
      title={t('primary_emotions_split_chart', 'Primary Emotions Split Chart')}
      footer={footer}
    />
  );
};

const mapStateToProps = state => ({
  filters: state.filters.flatFilters,
  data: state.splitCharts.primaryEmotionsData,
  statisticalSignificanceData: state.splitCharts.primaryEmotionsStatisticalSignificanceData,
  loading: state.splitCharts.primaryEmotionsLoading,
  error: state.splitCharts.primaryEmotionsError
});

const mapDispatchToProps = dispatch => ({
  loadSplitChart: filterName => dispatch(loadSplitChartPrimaryEmotionsRequest(filterName)),
  addNotificationRequest: (status, message) => dispatch(addNotification({status, message: [message]})),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryEmotionsSplitChart);
