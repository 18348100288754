import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {unregister} from './registerServiceWorker';
import {Provider} from "react-redux";
import initApp from "./initApp";
import './i18n';

import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Notifications from "./containers/notifications/Notifications";

const store = initApp();

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(242, 101, 34)'
    }
  },
  typography: {
    fontWeightRegular: '300'
  },
  mixins: {
    toolbar: {
      minHeight: 64,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 64,
      },
      '@media (min-width:600px)': {
        minHeight: 74,
      }
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    }
  }
});

const app = (
  <Provider store={store}>
    <Suspense fallback="Language is loading">
      <MuiThemeProvider theme={MuiTheme}>
        <Notifications>
          <App />
        </Notifications>
      </MuiThemeProvider>
    </Suspense>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

unregister();
