import React, {Fragment, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import makeStyles from "@material-ui/core/styles/makeStyles";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import HelpIcon from '@material-ui/icons/Help';

import {createColor} from "../../../utilities/getColor";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  collapseRoot: {
    width: '100%'
  },
  iconGreen: {
    color: green[700]
  },
  iconRed: {
    color: red[700]
  },
  iconGrey: {
    color: grey[400]
  },
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: '15px'
  },
  smallCol: {
    width: 20
  },
  helpText: {
    fontSize: '14px',
    '& > p:first-child': {
      marginBottom: theme.spacing(2)
    }
  }
}));

const PrimaryEmotionsStatisticalData = ({data, statisticalData}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');

  const primaryColors = useSelector(state => state.report.valueSystem['primary_color']);

  const classes = useStyles();

  let error = null;

  if (firstValue === '' || secondValue === '') {
    error = t('you_need_to_select_a_pair_of_values', "You need to select a pair of values");
  } else if (firstValue === secondValue) {
    error = t('you_selected_same_value', "You selected the same value. Please select a pair of different values");
  }

  const findDataByPair = () => statisticalData.find(d => (
    (d.pair[0] === firstValue && d.pair[1] === secondValue) ||
    (d.pair[1] === firstValue && d.pair[0] === secondValue)
  ));

  const getIcon = value => {
    if (value === true) {
      return <CheckIcon fontSize="small" className={classes.iconGreen}/>
    }

    if (value === false) {
      return <CloseIcon fontSize="small" className={classes.iconRed}/>
    }

    if (value === null) {
      return (
        <Tooltip title={t('could_not_be_calculated', "Could not be calculated")}>
          <NotInterestedIcon fontSize="small" className={classes.iconGrey}/>
        </Tooltip>
      );
    }
  };

  const renderTable = () => {
    if (error) {
      return <Alert severity="warning">{error}</Alert>;
    }

    const data = findDataByPair();

    if (!data) {
      return null;
    }

    if (data.status !== "OK") {
      return <Alert severity="error">{data.status}</Alert>;
    }

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>{t('primary_emotion', 'Primary emotion')}</TableCell>
            <TableCell>90%</TableCell>
            <TableCell>95%</TableCell>
            <TableCell>99%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data.results).map(primaryEmotion => (
            <TableRow key={primaryEmotion}>
              <TableCell className={classes.smallCol}>
                <div className={classes.circle} style={{background: createColor(primaryColors[primaryEmotion])}}/>
              </TableCell>
              <TableCell>
                {primaryEmotion}
              </TableCell>
              <TableCell>{getIcon(data.results[primaryEmotion]['90'])}</TableCell>
              <TableCell>{getIcon(data.results[primaryEmotion]['95'])}</TableCell>
              <TableCell>{getIcon(data.results[primaryEmotion]['99'])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  };

  const labelHelp = (
    <div className={classes.helpText}>
      <p>{t('statistical_significance_help_1', 'Statistical significance is the likelihood that a relationship between two or more variables (in this case emotions) is caused by something other than chance.')}</p>
      <p>{t('statistical_significance_help_2', 'At Heartbeat, we utilize statistical significance tests to understand whether the differences in primary emotions among different groups are significance. Helping formulate better analyses and understandings of the emotional data.')}</p>
    </div>
  );

  const label = (
    <Fragment>
      {t('show_statistical_significance_calculator', 'Show Statistical Significance Calculator')}{' '}
      <Tooltip arrow title={labelHelp}>
        <HelpIcon fontSize="small" />
      </Tooltip>
    </Fragment>
  );

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.root}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={open}
              onChange={() => setOpen(!open)}
              color="primary"
            />
          }
          label={label}
        />
      </Grid>
      <Grid item className={classes.collapseRoot}>
        <Collapse in={open}>
          <Grid container direction="column" spacing={2} alignItems="center">
            <Grid item md={10} lg={6} container spacing={1} alignItems="center">
              <Grid item>
                {t('select_a_pair_of_values', 'Select a pair of values')}:
              </Grid>
              <Grid item xs>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  value={firstValue}
                  SelectProps={{displayEmpty: true}}
                  onChange={e => setFirstValue(e.target.value)}
                >
                  <MenuItem value=""><em>{t('select_first_value', 'Select first value')}</em></MenuItem>
                  {data.map(d => (
                    <MenuItem key={d.value} value={d.value}>{d.value}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField
                  select
                  variant="outlined"
                  fullWidth
                  value={secondValue}
                  SelectProps={{displayEmpty: true}}
                  onChange={e => setSecondValue(e.target.value)}
                >
                  <MenuItem value=""><em>{t('select_second_value', 'Select second value')}</em></MenuItem>
                  {data.map(d => (
                    <MenuItem key={d.value} value={d.value}>{d.value}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid item container xs md={10} lg={6}>
              <Grid item xs>{renderTable()}</Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default PrimaryEmotionsStatisticalData;