import React from 'react';
import {makeStyles, Paper} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  withPadding2: {
    padding: theme.spacing(2)
  },
}));

const PaperWithPadding = ({children, ...props}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.withPadding2} {...props}>
      {children}
    </Paper>
  );
};

export default PaperWithPadding;