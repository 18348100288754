import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {CircularProgress, InputAdornment, MenuItem, TextField} from "@material-ui/core";

import {changeValueSystem, loadValueSystemsRequest} from "../create-report-action-creators";

const ValueSystemInput = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {valueSystems, valueSystemsLoading, valueSystem, domain} = useSelector(state => ({
    valueSystems: state.createReport.valueSystems,
    valueSystemsLoading: state.createReport.valueSystemsLoading,
    valueSystem: state.createReport.valueSystem,
    domain: state.createReport.domain
  }), shallowEqual);

  useEffect(() => {
    if (domain) {
      dispatch(loadValueSystemsRequest());
    }
  }, [domain, dispatch]);

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      value={valueSystem}
      required
      disabled={valueSystemsLoading}
      label={t('value_system', 'Value System')}
      InputProps={{
        startAdornment: valueSystemsLoading ? <InputAdornment position="start"><CircularProgress/></InputAdornment> : null
      }}
      onChange={e => dispatch(changeValueSystem(e.target.value))}
    >
      {valueSystems.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default ValueSystemInput;
