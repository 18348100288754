import API from '../../API-report';
import {call, put, select} from "redux-saga/effects";
import {
  loadSplitChartPrimaryEmotionsError,
  loadSplitChartPrimaryEmotionsSuccess, loadSplitChartSentimentError,
  loadSplitChartSentimentSuccess
} from "./split-charts-actions";
import {
  createColor, createTintedColor,
  getSentimentColor,
  getSentimentHighlightColor
} from "../../utilities/getColor";
import {transformSplitChatData} from "./split-charts-utils";

export function* performSplitChartPrimaryEmotionsRequest({filterName}) {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  const primaryColors = yield select(state => state.report.valueSystem['primary_color']);

  try {
    const response = yield API.primaryEmotionsSplitChart({reportId, filters, operator, filterName});

    const statisticalSignificanceData = response.data['statistical_significance'];

    const data = transformSplitChatData(
      response.data.results,
      'primaries',
      'primary',
      primaryName => createColor(primaryColors[primaryName]),
      primaryName => createTintedColor(primaryColors[primaryName], 0.25)
    );

    yield put(loadSplitChartPrimaryEmotionsSuccess(data, statisticalSignificanceData));
  } catch (e) {
    yield put(loadSplitChartPrimaryEmotionsError());
  }
}

export function* performSplitChartSentimentRequest({filterName}) {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  const sentimentColors = yield select(state => state.report.valueSystem['sentiment_color'] || {});

  try {
    const response = yield call(API.sentimentSplitChart, {reportId, filters, operator, filterName});
    const statisticalSignificanceData = response.data['statistical_significance'];

    const data = transformSplitChatData(
      response.data.results,
      'sentiments',
      'sentiment',
      sentimentName => createColor(sentimentColors[sentimentName]),
      sentimentName => createTintedColor(sentimentColors[sentimentName]),
    );

    yield put(loadSplitChartSentimentSuccess(data, statisticalSignificanceData));
  } catch (e) {
    console.error('IN PERFORM_SPLIT_CHART_SENTIMENT_REQUEST', e);
    yield put(loadSplitChartSentimentError());
  }
}
