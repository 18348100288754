import React, {Fragment, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {Button, CircularProgress, Grid, Tooltip, Typography} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import Taxonomies from "./components/Taxonomies";
import Tags from "../reports/containers/Tags";
import StepContainer from "./components/StepContainer";
import {
  loadDataForStepThreeRequest,
  stepThreeActivate,
  stepThreeSaveRequest,
  tagsChange
} from "./create-report-action-creators";

const AVUHelpText = () => {
  const {t} = useTranslation();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">{t('adjusted_verbatim_units_avu', 'Adjusted Verbatim Units (AVU)')}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" style={{fontSize: '13px'}}>
          {t('adjusted_verbatim_units_explanation', 'Adjusted Verbatim Units (“AVU”): is a verbatim text occupying one cell in one row of a data file. One AVU is normally equal to one verbatim but can have adjustments allocated. Each AVU has a max character count of 1000 characters. An adjustment of 1 additional AVU is allocated for each block of 1000 characters. Customizations such as custom taxonomy, custom emotion classifications and topics can add a multiplier to the AVU. This would be negotiated at the time of customizations.')}
        </Typography>
      </Grid>
    </Grid>
  );
};

const AVUHelpTooltip = ({children}) => (
  <Tooltip title={<AVUHelpText/>} arrow>
      <span style={{textDecoration: 'underline', textDecorationStyle: 'dotted'}}>
        {children}
      </span>
  </Tooltip>
);

const StepThree = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {active, virgin, error, saveLoading, taxonomies, allTaxonomies, predictedAVUCount, tags} = useSelector(state => ({
    active: state.createReport.stepThreeActive,
    virgin: state.createReport.stepThreeVirgin,
    error: state.createReport.stepThreeError,
    saveLoading: state.createReport.stepThreeSaveLoading,
    taxonomies: state.createReport.taxonomies,
    allTaxonomies: state.createReport.allTaxonomies,
    predictedAVUCount: state.createReport.predictedAVUCount,
    tags: state.createReport.tags
  }), shallowEqual);

  useEffect(() => {
    if (active) {
      dispatch(loadDataForStepThreeRequest());
    }
  }, [dispatch, active]);

  const canSave = taxonomies.length > 0;

  const save = () => dispatch(stepThreeSaveRequest(history));
  const activate = () => dispatch(stepThreeActivate());

  let taxonomiesBlock = (
    <Grid item>
      {virgin ? (
        <h2>{t('step_three_explanation', 'Here you will set taxonomies, negators and ignore words after you will review the columns')}</h2>
      ) : (
        <h2>{t('step_three_explanation_set', 'Taxonomies, negators and ignore words are set')}</h2>
      )}
    </Grid>
  );

  let loadingSpinner = <CircularProgress color="inherit" size="25px" />;

  if (active) {
    taxonomiesBlock = (
      <Fragment>
        <Grid item>
          <Taxonomies/>
        </Grid>
        <Grid item>
          <h2>Tags</h2>
        </Grid>
        <Grid item>
          <Tags
            tags={tags}
            helperText={t('tags_help', 'Select from existing tags, or type in and press Enter to add')}
            allowNew
            onChange={newTags => dispatch(tagsChange(newTags))}
          />
        </Grid>
      </Fragment>
    );
  }

  let taxonomiesButton = null;

  if (active && canSave) {
    taxonomiesButton = (
      <Fragment>
        <Grid item xs>
          <Alert severity="info">
            <span>
              <Trans
                i18nKey="predicted_avu_count_help"
                components={{
                  tooltip: <AVUHelpTooltip/>,
                  b: <b/>
                }}
                values={{predictedAVUCount}}
              >
                Predicted <tooltip>AVU</tooltip> count: <b>{{predictedAVUCount}}</b>
              </Trans>
            </span>
          </Alert>
        </Grid>
        <Grid container item xs spacing={1}>
          <Grid item>
            <Button size="large" color="primary" variant="contained" onClick={save} disabled={saveLoading}>
              { saveLoading ? loadingSpinner : t('calculate', 'Calculate') }
            </Button>
          </Grid>
          <Grid item>
            <Button size="large" color="default" variant="contained" component={Link} to="/reports">
              {t('finish_later', 'Finish later')}
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  if (!virgin) {
    taxonomiesButton = (
      <Grid item>
        <Button size="large" variant="contained" onClick={activate} disabled={saveLoading}>
          {saveLoading ? loadingSpinner : t('set_again', 'Set again')}
        </Button>
      </Grid>
    )
  }

  if (active && !canSave) {
    let reason = '';

    if (error) {
      reason = <span>{error}</span>;
    } else if (allTaxonomies.length === 0) {
      reason = (
        <span>
          {t('no_taxonomy_available_help', 'Selected domain does not contain any taxonomy available for you. Please choose another domain or contact')} <a href="mailto:support@heartbeat.com">support@heartbeat.com</a>
        </span>
      );
    } else if (taxonomies.length === 0) {
      reason = t('no_taxonomies_selected', 'No taxonomies selected');
    }

    taxonomiesButton = (
      <Fragment>
        <Grid item>
          <Alert severity={error ? "error" : "warning"}>{reason}</Alert>
        </Grid>
        <Grid item>
          <Button
            size="large"
            color="default"
            variant="contained"
            component={Link}
            to="/"
          >
            {t('finish_later', 'Finish later')}
          </Button>
        </Grid>
      </Fragment>
    )
  }

  return (
    <StepContainer active={virgin}>
      <Grid item xs md container direction="column" spacing={2}>
        {taxonomiesBlock}
        {taxonomiesButton}
      </Grid>
    </StepContainer>
  )
}

export default StepThree;