import axios from '../../axios-api';

export const tryToDetermineChanges = async () => {
  const scripts = document.body.getElementsByTagName('script');

  for (const script of scripts) {
    const match = script.src.match(/main.(\w+).js/);
    if (match) {
      try {
        await axios.head(script.src + '?r=' + Math.random());
      } catch (e) {
        if (e.response && e.response.status === 404) {
          return true;
        }
      }

      return false;
    }
  }

  return false;
};