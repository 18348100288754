import {takeEvery, select, put, putResolve} from "redux-saga/effects";
import {
  ADD_NOTE_REQUEST, CREATE_FILTERS_FROM_NOTES_REQUEST,
  createFiltersFromNotesDone,
  DELETE_NOTE_REQUEST,
  deleteNoteDone, hideCreateFiltersFromNotesModal,
  hideNoteModal
} from "./notes-actions";
import reportApi from "../../../API-report";
import {addNotification} from "../../notifications/notifications-actions";
import {loadResponses} from "../responses-sagas";
import {loadFiltersRequest} from "../../filters/filters-sagas";
import {push} from "../../main/history/history-actions";
import i18n from "../../../i18n";

function* addNote({tabName, title, description}) {
  const reportId = yield select(state => state.report.reportId);
  const {rowNumber, dataHeader} = yield select(state => state.notes);
  const {boundaries, selectedText: highlightedText} = yield select(state => state.contextMenu);

  try {
    const response = yield reportApi.addNoteToReport(reportId, rowNumber, dataHeader, tabName, title, description, boundaries, highlightedText);

    if (response.data.status === 'error') {
      yield put(addNotification({status: 'Error', message: [response.data.message]}))
    } else {
      yield put(hideNoteModal());
      yield put(addNotification({status: 'Success', message: [response.data.message]}));
      const page = yield select(state => state.responses.page) || 1;
      yield loadResponses({page, keepTab: true});
    }
  } catch (e) {
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_create_note', 'Could not create note. Unknown error. Please try again later.')]}))
  }
}

function* deleteNote({noteId}) {
  try {
    const response = yield reportApi.deleteNoteFromReport(noteId);

    if (response.data.status === 'error') {
      yield put(deleteNoteDone());
      yield put(addNotification({status: 'Error', message: [response.data.message]}));
    } else {
      const page = yield select(state => state.responses.page) || 1;
      yield loadResponses({page, keepTab: true});
      yield put(deleteNoteDone());
      yield put(addNotification({status: 'Success', message: [i18n.t('note_deleted', 'Note deleted successfully')]}));
    }
  } catch (e) {
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_delete_note', 'Could not delete note due to server error. Please try again later.')]}));
    yield put(deleteNoteDone());
  }
}

function* createFiltersForNotes({tabName, threshold}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    const response = yield reportApi.addFilterForNoteTab(reportId, tabName, threshold);

    if (response.data.status === 'error') {
      yield put(addNotification({status: 'Error', message: [response.data.message]}))
    } else {
      yield putResolve(push({search: ''}));
      yield loadFiltersRequest();
      yield put(createFiltersFromNotesDone());
      yield put(hideCreateFiltersFromNotesModal());
      yield put(addNotification({status: 'Success', message: [i18n.t('filters_for_tab_created', 'Filters for tab "{{tabName}}" created successfully!', {tabName})]}));
    }
  } catch (e) {
    yield put(addNotification({status: 'Error', message: [i18n.t('could_not_create_filters', 'Could not create filters due to server error. Please try again later.')]}));
    yield put(createFiltersFromNotesDone());
    yield put(hideCreateFiltersFromNotesModal());
  }
}

export default [
  takeEvery(ADD_NOTE_REQUEST, addNote),
  takeEvery(DELETE_NOTE_REQUEST, deleteNote),
  takeEvery(CREATE_FILTERS_FROM_NOTES_REQUEST, createFiltersForNotes),
];