import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import UiModal from "../../../components/UiModal/UiModal";
import DialogContent from "@material-ui/core/DialogContent";
import {CircularProgress, DialogActions, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchExamplesRequest, setExamplesDialogOpen} from "../taxonomy-actions";
import {Alert} from "@material-ui/lab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExamplesTable from "./ExamplesTable";

const useStyles = makeStyles(theme => ({
  termHeader: {
    textAlign: 'center',
  },
  withoutBorderTop: {
    borderTop: 0,
  }
}));

const ExamplesDialog = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {isOpen, term, examplesLoading, examples} = useSelector(state => ({
    isOpen: state.taxonomy.examplesDialogOpen,
    term: state.taxonomy.termForExamples,
    examplesLoading: state.taxonomy.examplesLoading,
    examples: state.taxonomy.examples,
  }), shallowEqual);

  useEffect(() => {
    if (term) {
      dispatch(fetchExamplesRequest());
    }
  }, [dispatch, term]);

  const onClose = () => {
    dispatch(setExamplesDialogOpen(false));
  };

  let resultsArea = null;

  if (examples && examples.length === 0) {
    resultsArea = (
      <DialogContent dividers className={classes.withoutBorderTop}>
        <Alert severity="info">{t('no_usage_results_found', 'No usage results found')}</Alert>
      </DialogContent>
    );
  }

  if (examples && examples.length > 0) {
    resultsArea = <ExamplesTable examples={examples}/>;
  }

  if (examplesLoading) {
    resultsArea = <DialogContent dividers className={classes.withoutBorderTop}><CircularProgress/></DialogContent>;
  }

  return (
    <UiModal
      title={t('view_term_usage_examples', 'View term usage examples')}
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent dividers>
        <Typography variant="h5" className={classes.termHeader}>{term}</Typography>
      </DialogContent>
      {resultsArea}
      <DialogActions>
        <Button onClick={onClose}>{t('close', 'Close')}</Button>
      </DialogActions>
    </UiModal>
  );
};

export default ExamplesDialog;