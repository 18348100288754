import { call, select, put } from "redux-saga/effects";
import API from "../../API-report";
import {
  loadTopWordsError,
  loadTopWordsSuccess
} from "./charts-action-creators";
import {mapPercent} from "../../utilities/mapPercent";
import deepCopy from "../../utilities/deepCopy";
import {paginate} from "./functions";
import {loadTopWordsPaginated} from "../full-report-top-words/full-report-top-words-action-creators";
import i18n from "../../i18n";

export function* loadTopWordsRequest() {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  try {
    const result = yield call(API.loadTopWords, {reportId, filters, operator});

    if (result.data.results && result.data.results.length) {
      const { topWords, topWordsPaginated} = normalizeTopWords({
        topWords: result.data.results
      });
      yield put(loadTopWordsSuccess(topWords));
      yield put(loadTopWordsPaginated(topWordsPaginated));
    } else {
      yield put(loadTopWordsError("No data"));
      yield put(loadTopWordsPaginated({1: []}));
    }
  } catch(error) {
    console.log(error, 'ERROR FROM LOAD TOP WORDS REQUEST');
    yield put(loadTopWordsError(i18n.t('something_went_wrong', 'Something went wrong...')));
    yield put(loadTopWordsPaginated({1: []}));
  }
}

const normalizeTopWords = ({topWords}) => {
  let short = deepCopy(topWords.filter((word, i) => i < 10));
  const other = topWords.reduce((acc, current, currentIndex) => {
    if (currentIndex >= 10) {
      acc.count += current.count;
      return acc;
    }
    return acc;
  }, {term: 'other emotional words', count: 0});
  short.push(other);
  mapPercent(short);
  short.sort((a, b) => b.count - a.count);
  let full = deepCopy(topWords);
  mapPercent(full);
  full = paginate(full, 15);
  return {
    topWords: short,
    topWordsPaginated: full
  };
};
