import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import PieChartLegend from "../../components/Charts/pie-chart-legend/pie-chart-legend-component";
import ChartBox from "../../components/Charts/chart-box";
import {loadTopWordsRequest, switchTopWordsChartMode} from "./charts-action-creators";
import {
  OTHER_EMOTIONAL_WORDS,
  TOP_WORDS_CHART_MODE_BAR,
  TOP_WORDS_CHART_MODE_CLOUD,
  TOP_WORDS_CHART_MODE_PIE
} from "./constants";

import './top-words-chart.css';
import CloudChartComponent from "../../components/Charts/cloud-chart/cloud-chart-component";
import {createAndCopyTable} from "./functions";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import CloudIcon from '@material-ui/icons/Cloud';
import {addNotification} from "../notifications/notifications-actions";
import {getTopColor} from "../../utilities/getColor";
import Chart from "./components/Chart";
import ColorCircle from "./components/ColorCircle";
import TableCellLink from "./components/TableCellLink";

import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";
import {Menu, MenuItem} from "@material-ui/core";

const modeTitles = {
  [TOP_WORDS_CHART_MODE_PIE]: ['top_words', 'Top Words'],
  [TOP_WORDS_CHART_MODE_BAR]: ['top_words', 'Top Words'],
  [TOP_WORDS_CHART_MODE_CLOUD]: ['word_cloud', 'Word Cloud'],
};

const prepareChartData = (data, key, skipOther) => {
  const dataset = [];
  const labels = [];
  const backgroundColor = [];
  const words = [];

  data.forEach(d => {
    if (skipOther && d.term === OTHER_EMOTIONAL_WORDS) return;
    dataset.push(d[key]);
    labels.push(d.term);
    words.push(d.words);
    backgroundColor.push(d.color)
  });

  return {
    datasets: [{
      data: dataset,
      backgroundColor,
    }],
    labels,
    words,
    originalData: data
  };
};

const TopWordsChart = ({showFooter, hideLegend, height}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    termWords: null,
    menuAnchorEl: null,
  });

  const {topWordsData, fullData, loading, error, reportId, mode} = useSelector(state => ({
    topWordsData: state.charts.topWords,
    fullData: state.fullReportTopWords.topWordsPaginated,
    loading: state.charts.topWordsLoading,
    error: state.charts.topWordsError,
    reportId: state.report.reportId,
    mode: state.charts.topWordsChartMode
  }), shallowEqual);

  const onFiltersChange = useCallback(() => {
    dispatch(loadTopWordsRequest());
  }, []);

  useEffect(() => {
    onFiltersChange();
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    };
  }, [dispatch, onFiltersChange]);

  const chartData = useRef();
  const chartContainer = useRef();
  const data = useMemo(() => topWordsData.map((d, i) => ({...d, color: getTopColor(i)})), [topWordsData]);

  const createElement = (x, y) => {
    const domEl = document.createElement('div');
    domEl.style.position = 'absolute';
    domEl.style.width = '0';
    domEl.style.height = '0';
    domEl.style.left = x + 'px';
    domEl.style.top = y + 'px';
    return domEl;
  }

  const getTermSearchLink = term => {
    return `/report/${reportId}/search/${term}/0/0`
  };

  const handleChartLabelClick = (index, scale) => {
    const words = chartData.current.words[index];
    const term = chartData.current.labels[index];

    if (!words || words.length === 0) {
      history.push(getTermSearchLink(term));
    } else {
      const chartDataValues = chartData.current.datasets[0].data;
      const yCoord = scale.height / chartDataValues.length * (index + 1);
      const domEl = createElement(scale.right, yCoord);
      chartContainer.current.appendChild(domEl);
      setState({termWords: words, menuAnchorEl: domEl});
    }
  };

  const closeMenu = () => {
    setState(prev => {
      if (prev.menuAnchorEl) {
        prev.menuAnchorEl.remove();
      }

      return ({
        termWords: null,
        menuAnchorEl: null
      });
    });
  };

  const switchMode = mode => {
    dispatch(switchTopWordsChartMode(mode));
  };

  const valueName = t('term', "Term");
  const dataType = "term";

  let chart = null;

  if (mode === TOP_WORDS_CHART_MODE_PIE) {
    const renderTermCell = term => {
      return term !== OTHER_EMOTIONAL_WORDS ? (
        <TableCellLink component={Link} to={getTermSearchLink(term)}>
          {term}
        </TableCellLink>
      ) : t('other_emotional_words', 'other emotional words');
    };

    const columns = [
      {
        name: t('color', 'Color'),
        key: 'color',
        renderCell: color => <ColorCircle color={color}/>,
        renderTooltip: false
      },
      {name: valueName, key: dataType, renderCell: renderTermCell},
      {name: t('count', 'Count'), key: 'count'},
      {name: t('percent', 'Percent'), key: 'percent', transform: val => val + '%'},
    ];

    const chartData = prepareChartData(data, 'percent');

    chart = (
      <Fragment>
        <Chart
          height={height || '300px'}
          chartData={chartData}
          chartType="doughnut"
          overrideOptions={{
            plugins: {
              datalabels: {
                display: ctx => ctx.dataset.data[ctx.dataIndex] > 5,
                formatter: v => v + '%'
              }
            }
          }}
        />
        {!hideLegend &&
          <PieChartLegend
            columns={columns}
            data={data}
          />
        }
      </Fragment>
    );
  } else if (mode === TOP_WORDS_CHART_MODE_BAR) {
    chartData.current = prepareChartData(data, 'count', true);

    chart = (
      <div style={{position: 'relative'}} ref={chartContainer}>
        {state.termWords && state.menuAnchorEl && (
          <Menu open={Boolean(state.termWords)} anchorEl={state.menuAnchorEl} onClose={closeMenu}>
            {state.termWords.map(w => (
              <MenuItem
                key={w}
                onClick={() => history.push(getTermSearchLink(w))}
              >
                {w}
              </MenuItem>
            ))}
          </Menu>
        )}
        <Chart
          height={height || '300px'}
          chartData={chartData.current}
          chartType="horizontal-bar"
          scaleClickOptions={{
            id: 'y',
            scaleType: 'y',
            callback: handleChartLabelClick,
          }}
          overrideOptions={{
            plugins: {
              datalabels: {
                display: true,
                formatter: undefined,
              }
            },
            scales: {
              yAxes: [{
                id: 'y',
                gridLines: {
                  display: false
                },
                ticks: {
                  fontSize: 15,
                  fontColor: 'blue',
                  fontFamily: 'Roboto',
                  fontStyle: '300',
                  cursor: 'pointer',
                },
              }],
            },
          }}
        />
      </div>
    )
  } else if (mode === TOP_WORDS_CHART_MODE_CLOUD) {
    chart = <CloudChartComponent words={fullData} loading={loading} reportId={reportId} height={height || '700px'}/>;
  }

  const columns = [
    {name: valueName, key: dataType},
    {name: t('count', 'Count'), key: 'count'},
    {name: t('percent', 'Percent'), key: 'percent'}
  ];

  const menuItems = [
    {
      text: t('bar_chart', 'Bar chart'),
      onClick: () => switchMode(TOP_WORDS_CHART_MODE_BAR),
      icon: <BarChartIcon fontSize="small"/>,
      selected: mode === TOP_WORDS_CHART_MODE_BAR
    },
    {
      text: t('pie_chart', 'Pie chart'),
      onClick: () => switchMode(TOP_WORDS_CHART_MODE_PIE),
      icon: <PieChartIcon fontSize="small"/>,
      selected: mode === TOP_WORDS_CHART_MODE_PIE
    },
    {
      text: t('word_cloud', 'Word cloud'),
      onClick: () => switchMode(TOP_WORDS_CHART_MODE_CLOUD),
      icon: <CloudIcon fontSize="small"/>,
      selected: mode === TOP_WORDS_CHART_MODE_CLOUD
    },
    {
      divider: true
    },
    {
      text: t('copy_to_clipboard', 'Copy to clipboard'),
      onClick: () => {
        createAndCopyTable(data, columns);
        dispatch(addNotification({
          status: t('success_notification', 'Success'),
          message: [t('copied_to_clipboard_notification', 'Copied to clipboard!')]
        }));
      },
      icon: <FileCopyIcon fontSize="small"/>,
    }
  ];

  let footerLink = null;

  if (showFooter) {
    footerLink = `/report/${reportId}/full/top-words`;

    if (location.search) {
      footerLink += location.search;
    }
  }

  const [titleId, titleDefault] = modeTitles[mode];

  return (
    <ChartBox
      title={t(titleId, titleDefault)}
      footerLink={showFooter ? `/report/${reportId}/full/top-words` : null}
      actionsMenuItems={menuItems}
      loading={loading}
      error={error}
    >
      {chart}
    </ChartBox>
  );
};
export default TopWordsChart;