import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Button, CircularProgress, Grid, TextField,} from "@material-ui/core";

import UploadFile from "../../components/UploadFile/UploadFile";
import CompanyInput from "./inputs/CompanyInput";
import ValueSystemInput from "./inputs/ValueSystemInput";
import DomainInput from "./inputs/DomainInput";
import LanguageInput from "./inputs/LanguageInput";
import StepContainer from "./components/StepContainer";
import Preview from "./components/Preview";

import {
  deactivateStepOne,
  fileReady,
  reportDescriptionChange,
  reportLogoChange,
  reportNameChange,
  stepOneActivate,
  uploadRequest
} from "./create-report-action-creators";
import {addNotification} from "../notifications/notifications-actions";

const StepOne = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {reportName, active, loading, filename, file, virgin, company, logoFile, description} = useSelector(state => ({
    reportName: state.createReport.reportName,
    active: state.createReport.stepOneActive,
    loading: state.createReport.uploadLoading,
    filename: state.createReport.filename,
    file: state.createReport.file,
    virgin: state.createReport.stepOneVirgin,
    company: state.createReport.company,
    logoFile: state.createReport.logoFile,
    description: state.createReport.description,
  }), shallowEqual);

  const dataIsCorrect = reportName && file && file.name;

  const uploadButtonLabel = active ? t('upload', 'Upload') : t('upload_another_file', 'Upload another file');

  const preview = (
    <Preview
      description={description}
      company={company}
      logoFile={logoFile}
      reportName={reportName}
    />
  );

  const sendNotification = notification => dispatch(addNotification(notification));
  const activate = () => dispatch(stepOneActivate());
  const deactivate = () => dispatch(deactivateStepOne());
  const upload = uploadInfo => dispatch(uploadRequest(uploadInfo));

  return (
    <StepContainer active={active}>
      <Grid item xs md container direction="column" spacing={2}>
        {active ? (
          <Fragment>
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                label={t('report_name', 'Report Name')}
                required
                value={reportName}
                onChange={e => dispatch(reportNameChange(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <CompanyInput />
            </Grid>
            <Grid item xs>
              <DomainInput />
            </Grid>
            <Grid item xs>
              <ValueSystemInput />
            </Grid>
            <Grid item xs>
              <LanguageInput />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                label={t('description', 'Description')}
                value={description}
                onChange={e => dispatch(reportDescriptionChange(e.target.value))}
              />
            </Grid>
            <Grid item xs>
              <UploadFile
                onFileReady={logo => dispatch(reportLogoChange(logo))}
                addNotification={sendNotification}
                label={t('logo_image_file', 'Logo image file')}
                extensions={['jpg', 'jpeg', 'png', 'gif']}
                helperText={t('preferred_ratio', 'Preferably in 16:9 aspect ratio.')}
              />
            </Grid>
            <Grid item xs>
              <UploadFile
                onFileReady={file => dispatch(fileReady(file))}
                addNotification={sendNotification}
                label={t('report_csv_file', "Report CSV file")}
                required
              />
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid item>
              <h2>{t('file_is_uploaded', 'File is uploaded')}</h2>
            </Grid>
            <Grid item>
              <span>{t('report_name', 'Report name')}: </span>
              <span>{reportName}</span>
            </Grid>
            <Grid item>
              <span>{t('company', 'Company')}: </span>
              <span>{company}</span>
            </Grid>
            <Grid item>
              <span>{t('filename', 'Filename')}: </span>
              <span>{filename}</span>
            </Grid>
          </Fragment>
        )}

        <Grid item>
          <Button
            color={active ? "primary" : "default"}
            variant="contained"
            size="large"
            disabled={active ? !dataIsCorrect || loading : false}
            onClick={!active ? activate : upload}
          >
            {!loading ? uploadButtonLabel : <CircularProgress color="inherit" size="25px" />}
          </Button>

          {(!virgin && active) && (
            <Button color="default" size="large" variant="text" onClick={deactivate}>
              {t('cancel', 'Cancel')}
            </Button>
          )}
        </Grid>
      </Grid>

      {virgin && active && (
        <Grid item sm={12} md={5} xl={4}>
          {preview}
        </Grid>
      )}
    </StepContainer>
  );
}

export default StepOne;