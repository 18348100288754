import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

import {passwordResetSubmitRequest} from "./password-reset-actions";
import {Box, Button, Container, Grid, Paper, TextField, Typography} from "@material-ui/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";

const ForgotPassword = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const {loading, message, error} = useSelector(state => ({
    loading: state.passwordReset.submitLoading,
    message: state.passwordReset.submitMessage,
    error: state.passwordReset.submitError
  }), shallowEqual);

  const submitFormHandler = event => {
    event.preventDefault();
    dispatch(passwordResetSubmitRequest(email));
  };

  let content = (
    <form onSubmit={submitFormHandler}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <TextField
            label={t('email', 'Email')}
            variant="outlined"
            fullWidth
            name="email"
            type="email"
            value={email}
            error={Boolean(error)}
            required
            helperText={t('enter_your_account_email', 'Enter your account email')}
            onChange={e => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs>
          <ButtonWithProgress
            fullWidth
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
            loading={loading}
          >
            {t('request_password_reset', 'Request password reset')}
          </ButtonWithProgress>
        </Grid>
      </Grid>
    </form>
  );

  if (message) {
    content = (
      <Typography variant="body1">
        {message}
      </Typography>
    );
  }

  return (
    <Container component="main" maxWidth="sm" style={{marginTop:'20%'}}>
      <Helmet>
        <title>{t('request_password_reset', 'Request password reset')}</title>
      </Helmet>
      <Paper component={Box} p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs container justify="space-between" alignItems="center">
            <h2>{t('reset_your_password', 'Reset your password')}</h2>
            <Button color="primary" component={Link} to="/login">
              {t('return_to_login', 'Return to login')}
            </Button>
          </Grid>
          <Grid item xs>
            {content}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;