export const FETCH_VALUE_SYSTEMS_REQUEST = '[TAXONOMY] FETCH_VALUE_SYSTEMS_REQUEST';
export const FETCH_VALUE_SYSTEMS_SUCCESS = '[TAXONOMY] FETCH_VALUE_SYSTEMS_SUCCESS';
export const FETCH_VALUE_SYSTEMS_FAILURE = '[TAXONOMY] FETCH_VALUE_SYSTEMS_FAILURE';
export const SET_CURRENT_VALUE_SYSTEM = '[TAXONOMY] SET_CURRENT_VALUE_SYSTEM';

export const FETCH_LANGUAGES_REQUEST = '[TAXONOMY] FETCH_LANGUAGES_REQUEST';
export const FETCH_LANGUAGES_SUCCESS = '[TAXONOMY] FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_FAILURE = '[TAXONOMY] FETCH_LANGUAGES_FAILURE';
export const SET_CURRENT_LANGUAGE = '[TAXONOMY] SET_CURRENT_LANGUAGE';

export const FETCH_TAXONOMIES_REQUEST = '[TAXONOMY] FETCH_TAXONOMIES_REQUEST';
export const FETCH_TAXONOMIES_SUCCESS = '[TAXONOMY] FETCH_TAXONOMIES_SUCCESS';
export const FETCH_TAXONOMIES_FAILURE = '[TAXONOMY] FETCH_TAXONOMIES_FAILURE';
export const SET_SELECTED_TAXONOMIES = '[TAXONOMY] SET_SELECTED_TAXONOMIES';

export const SET_NEW_TAXONOMY_DIALOG_OPEN = '[TAXONOMY] SET_NEW_TAXONOMY_DIALOG_OPEN';
export const CREATE_TAXONOMY_REQUEST = '[TAXONOMY] CREATE_TAXONOMY_REQUEST';
export const CREATE_TAXONOMY_DONE = '[TAXONOMY] CREATE_TAXONOMY_DONE';

export const SET_DELETE_TAXONOMY_DIALOG_OPEN = '[TAXONOMY] SET_DELETE_TAXONOMY_DIALOG_OPEN';
export const DELETE_TAXONOMY_REQUEST = '[TAXONOMY] DELETE_TAXONOMY_REQUEST';
export const DELETE_TAXONOMY_DONE = '[TAXONOMY] DELETE_TAXONOMY_DONE';

export const SET_TAXONOMIES_ARE_SELECTED = '[TAXONOMY] SET_TAXONOMIES_ARE_SELECTED';

export const RESET = '[TAXONOMY] RESET';

export const SET_ADD_WORDS_DIALOG_OPEN = '[TAXONOMY] SET_ADD_WORDS_DIALOG_OPEN';
export const SET_SEARCH_WORDS_DIALOG_OPEN = '[TAXONOMY] SET_SEARCH_WORDS_DIALOG_OPEN';
export const SET_TRANSLATE_WORDS_DIALOG_OPEN = '[TAXONOMY] SET_TRANSLATE_WORDS_DIALOG_OPEN';

export const ADD_WORDS_REQUEST = '[TAXONOMY] ADD_WORDS_REQUEST';
export const ADD_WORDS_DONE = '[TAXONOMY] ADD_WORDS_DONE';

export const SEARCH_WORDS_REQUEST = '[TAXONOMY] SEARCH_WORDS_REQUEST';
export const SEARCH_WORDS_SUCCESS = '[TAXONOMY] SEARCH_WORDS_DONE';
export const SEARCH_WORDS_FAILURE = '[TAXONOMY] SEARCH_WORDS_FAILURE';

export const ADD_WORDS_TO_BASKET = '[TAXONOMY] ADD_WORDS_TO_BASKET';
export const REMOVE_WORDS_FROM_BASKET = '[TAXONOMY] REMOVE_WORDS_FROM_BASKET';
export const CLEAR_BASKET = '[TAXONOMY] CLEAR_BASKET';
export const REPLACE_BASKET = '[TAXONOMY] REPLACE_BASKET';

export const VALUE_SYSTEM_INFO_REQUEST = '[TAXONOMY] VALUE_SYSTEM_INFO_REQUEST';
export const VALUE_SYSTEM_INFO_SUCCESS = '[TAXONOMY] VALUE_SYSTEM_INFO_SUCCESS';
export const VALUE_SYSTEM_INFO_FAILURE = '[TAXONOMY] VALUE_SYSTEM_INFO_FAILURE';

export const ADD_EMOTION_COLUMN = '[TAXONOMY] ADD_EMOTION_COLUMN';

export const TOGGLE_EMOTION = '[TAXONOMY] TOGGLE_EMOTION';
export const TOGGLE_EMOTION_FOR_ALL_WORDS = '[TAXONOMY] TOGGLE_EMOTION_FOR_ALL_WORDS';
export const TOGGLE_ALL_EMOTIONS_FOR_WORD = '[TAXONOMY] TOGGLE_ALL_EMOTIONS_FOR_WORD';
export const RESET_EMOTIONS_FOR_WORD = '[TAXONOMY] RESET_EMOTIONS_FOR_WORD';
export const RESET_EMOTIONS_FOR_ALL_WORDS = '[TAXONOMY] RESET_EMOTIONS_FOR_ALL_WORDS';

export const SAVE_REQUEST = '[TAXONOMY] SAVE_REQUEST';
export const SAVE_DONE = '[TAXONOMY] SAVE_DONE';

export const FETCH_TARGET_TAXONOMIES_REQUEST = '[TAXONOMIES] FETCH_TARGET_TAXONOMIES_REQUEST';
export const FETCH_TARGET_TAXONOMIES_SUCCESS = '[TAXONOMIES] FETCH_TARGET_TAXONOMIES_SUCCESS';
export const FETCH_TARGET_TAXONOMIES_FAILURE = '[TAXONOMIES] FETCH_TARGET_TAXONOMIES_FAILURE';
export const SET_TARGET_TAXONOMY = '[TAXONOMY] SET_TARGET_TAXONOMY';
export const TRANSLATE_WORDS_REQUEST = '[TAXONOMY] TRANSLATE_WORDS_REQUEST';
export const TRANSLATE_WORDS_DONE = '[TAXONOMY] TRANSLATE_WORDS_DONE';

export const SET_EXAMPLES_DIALOG_OPEN = '[TAXONOMY] SET_EXAMPLES_DIALOG_OPEN';
export const SET_TERM_FOR_EXAMPLES = '[TAXONOMY] SET_TERM_FOR_EXAMPLES';
export const FETCH_EXAMPLES_REQUEST = '[TAXONOMY] FETCH_EXAMPLES_REQUEST';
export const FETCH_EXAMPLES_SUCCESS = '[TAXONOMY] FETCH_EXAMPLES_SUCCESS';
export const FETCH_EXAMPLES_FAILURE = '[TAXONOMY] FETCH_EXAMPLES_FAILURE';

export const FETCH_FULL_VERBATIM_REQUEST = '[TAXONOMY] FETCH_FULL_VERBATIM_REQUEST';
export const FETCH_FULL_VERBATIM_SUCCESS = '[TAXONOMY] FETCH_FULL_VERBATIM_SUCCESS';
export const FETCH_FULL_VERBATIM_FAILURE = '[TAXONOMY] FETCH_FULL_VERBATIM_FAILURE';

export const SET_DELETE_WORDS_DIALOG_OPEN = '[TAXONOMY] SET_DELETE_WORDS_DIALOG_OPEN';
export const SET_WORDS_TO_DELETE = '[TAXONOMY] SET_WORDS_TO_DELETE';
export const DELETE_WORDS_REQUEST = '[TAXONOMY] DELETE_WORDS_REQUEST';
export const DELETE_WORDS_SUCCESS = '[TAXONOMY] DELETE_WORDS_SUCCESS';
export const DELETE_WORDS_FAILURE = '[TAXONOMY] DELETE_WORDS_FAILURE';

export const fetchValueSystemsRequest = () => ({type: FETCH_VALUE_SYSTEMS_REQUEST});
export const fetchValueSystemsSuccess = valueSystems => ({type: FETCH_VALUE_SYSTEMS_SUCCESS, valueSystems});
export const fetchValueSystemsFailure = () => ({type: FETCH_VALUE_SYSTEMS_FAILURE});
export const setCurrentValueSystem = valueSystem => ({type: SET_CURRENT_VALUE_SYSTEM, valueSystem});

export const fetchLanguagesRequest = () => ({type: FETCH_LANGUAGES_REQUEST});
export const fetchLanguagesSuccess = languages => ({type: FETCH_LANGUAGES_SUCCESS, languages});
export const fetchLanguagesFailure = () => ({type: FETCH_LANGUAGES_FAILURE});
export const setCurrentLanguage = language => ({type: SET_CURRENT_LANGUAGE, language});

export const fetchTaxonomiesRequest = (language, valueSystem) => ({type: FETCH_TAXONOMIES_REQUEST, language, valueSystem});
export const fetchTaxonomiesSuccess = payload => ({type: FETCH_TAXONOMIES_SUCCESS, payload});
export const fetchTaxonomiesFailure = () => ({type: FETCH_TAXONOMIES_FAILURE});
export const setSelectedTaxonomies = taxonomies => ({type: SET_SELECTED_TAXONOMIES, taxonomies});

export const setNewTaxonomyDialogOpen = isOpen => ({type: SET_NEW_TAXONOMY_DIALOG_OPEN, isOpen});
export const createTaxonomyRequest = (taxonomyName, valueSystem, language, isTargetTaxonomy) => ({type: CREATE_TAXONOMY_REQUEST, taxonomyName, valueSystem, language, isTargetTaxonomy});
export const createTaxonomyDone = () => ({type: CREATE_TAXONOMY_DONE});

export const setDeleteTaxonomyDialogOpen = isOpen => ({type: SET_DELETE_TAXONOMY_DIALOG_OPEN, isOpen});
export const deleteTaxonomyRequest = taxonomyName => ({type: DELETE_TAXONOMY_REQUEST, taxonomyName});
export const deleteTaxonomyDone = () => ({type: DELETE_TAXONOMY_DONE});

export const setTaxonomiesAreSelected = areSelected => ({type: SET_TAXONOMIES_ARE_SELECTED, areSelected});

export const reset = () => ({type: RESET});

export const setAddWordsDialogOpen = isOpen => ({type: SET_ADD_WORDS_DIALOG_OPEN, isOpen});
export const setSearchWordsDialogOpen = isOpen => ({type: SET_SEARCH_WORDS_DIALOG_OPEN, isOpen});
export const setTranslateWordsDialogOpen = isOpen => ({type: SET_TRANSLATE_WORDS_DIALOG_OPEN, isOpen});

export const addWordsRequest = (taxonomyName, words, originalWord) => ({type: ADD_WORDS_REQUEST, taxonomyName, words, originalWord});
export const addWordsDone = () => ({type: ADD_WORDS_DONE});

export const searchWordsRequest = (taxonomyNames, searchString, isRegexp) => ({type: SEARCH_WORDS_REQUEST, taxonomyNames, searchString, isRegexp});
export const searchWordsSuccess = words => ({type: SEARCH_WORDS_SUCCESS, words});
export const searchWordsFailure = () => ({type: SEARCH_WORDS_FAILURE});

export const addWordsToBasket = words => ({type: ADD_WORDS_TO_BASKET, words});
export const removeWordsFromBasket = ids => ({type: REMOVE_WORDS_FROM_BASKET, ids});
export const clearBasket = () => ({type: CLEAR_BASKET});
export const replaceBasket = words => ({type: REPLACE_BASKET, words});

export const valueSystemInfoRequest = () => ({type: VALUE_SYSTEM_INFO_REQUEST});
export const valueSystemInfoSuccess = valueSystemInfo => ({type: VALUE_SYSTEM_INFO_SUCCESS, valueSystemInfo});
export const valueSystemInfoFailure = () => ({type: VALUE_SYSTEM_INFO_FAILURE});

export const addEmotionColumn = (code, context) => ({type: ADD_EMOTION_COLUMN, code, context});

export const toggleEmotion = (id, context, code, isActive) => ({type: TOGGLE_EMOTION, id, context, code, isActive});
export const toggleEmotionForAllWords = (context, code, isActive) => ({type: TOGGLE_EMOTION_FOR_ALL_WORDS, context, code, isActive});
export const toggleAllEmotionsForWord = (id, isActive) => ({type: TOGGLE_ALL_EMOTIONS_FOR_WORD, id, isActive});
export const resetEmotionsForWord = id => ({type: RESET_EMOTIONS_FOR_WORD, id});
export const resetEmotionsForAllWords = () => ({type: RESET_EMOTIONS_FOR_ALL_WORDS});

export const saveRequest = () => ({type: SAVE_REQUEST});
export const saveDone = () => ({type: SAVE_DONE});

export const fetchTargetTaxonomiesRequest = language => ({type: FETCH_TARGET_TAXONOMIES_REQUEST, language});
export const fetchTargetTaxonomiesSuccess = payload => ({type: FETCH_TARGET_TAXONOMIES_SUCCESS, payload});
export const fetchTargetTaxonomiesFailure = () => ({type: FETCH_TARGET_TAXONOMIES_FAILURE});
export const setTargetTaxonomy = targetTaxonomy => ({type: SET_TARGET_TAXONOMY, targetTaxonomy});
export const translateWordsRequest = (wordsIds, translations, targetTaxonomy, targetLanguage) => ({type: TRANSLATE_WORDS_REQUEST, wordsIds, translations, targetTaxonomy, targetLanguage});
export const translateWordsDone = () => ({type: TRANSLATE_WORDS_DONE});

export const setExamplesDialogOpen = isOpen => ({type: SET_EXAMPLES_DIALOG_OPEN, isOpen});
export const setTermForExamples = term => ({type: SET_TERM_FOR_EXAMPLES, term});
export const fetchExamplesRequest = () => ({type: FETCH_EXAMPLES_REQUEST});
export const fetchExamplesSuccess = examples => ({type: FETCH_EXAMPLES_SUCCESS, examples});
export const fetchExamplesFailure = () => ({type: FETCH_EXAMPLES_FAILURE});
export const fetchFullVerbatimRequest = coordinates => ({type: FETCH_FULL_VERBATIM_REQUEST, coordinates});
export const fetchFullVerbatimSuccess = verbatim => ({type: FETCH_FULL_VERBATIM_SUCCESS, verbatim});
export const fetchFullVerbatimFailure = () => ({type: FETCH_FULL_VERBATIM_FAILURE});

export const setDeleteWordsDialogOpen = isOpen => ({type: SET_DELETE_WORDS_DIALOG_OPEN, isOpen});
export const setWordsToDelete = words => ({type: SET_WORDS_TO_DELETE, words});
export const deleteWordsRequest = () => ({type: DELETE_WORDS_REQUEST});
export const deleteWordsSuccess = () => ({type: DELETE_WORDS_SUCCESS});
export const deleteWordsFailure = () => ({type: DELETE_WORDS_FAILURE});