import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import {DialogContent, Divider, Grid, Typography} from "@material-ui/core";
import UiModal from "../../components/UiModal/UiModal";

import {dataAppendHideModal, dataAppendInitiateDownload, dataAppendSetDownloadOptions} from "./data-append-actions";
import DataAppendForm from "./components/DataAppendForm";
import LinksData from "./components/LinksData";
import {useTranslation} from "react-i18next";

const DataAppendModal = props => {
  const {t} = useTranslation();

  const [displayLinks, setDisplayLinks] = useState(false);

  const submitOptionsForm = data => {
    props.setOptions(data);
    setDisplayLinks(true);
  };

  return (
    <UiModal
      title={t('get_data_append', "Get Data Append")}
      open={props.show}
      onClose={props.closeModal}
    >
      <Divider/>
      {props.report && (
        <>
          <DialogContent>
            <Grid container direction="column" spacing={1}>
              <Grid item xs><Typography variant="h5">{t('collection_info', 'Collection info')}</Typography></Grid>
              <Grid item xs><strong>{t('collection_name', 'Collection name')}:</strong> {props.report['report_name']}</Grid>
              <Grid item xs><strong>{t('created_at', 'Created at')}:</strong> {props.report['date_created']}</Grid>
              <Grid item xs><strong>{t('num_of_rows', 'Number of rows')}:</strong> {props.report['rows_count']}</Grid>
            </Grid>
          </DialogContent>
          <Divider/>
          <DataAppendForm
            display={!displayLinks}
            rows={props.report['rows_count']}
            onSubmit={submitOptionsForm}
          />
          <LinksData
            display={displayLinks}
            linksData={props.linksData}
            initiateDownload={props.initiateDownload}
            goBack={() => setDisplayLinks(false)}
          />
        </>
      )}
    </UiModal>
  );
};

const mapStateToProps = state => ({
  show: state.dataAppend.modalIsShown,
  report: state.dataAppend.report,
  downloadOptions: state.dataAppend.downloadOptions,
  linksData: state.dataAppend.linksData
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(dataAppendHideModal()),
  setOptions: downloadOptions => dispatch(dataAppendSetDownloadOptions(downloadOptions)),
  initiateDownload: number => dispatch(dataAppendInitiateDownload(number))
});

export default connect(mapStateToProps, mapDispatchToProps)(DataAppendModal);