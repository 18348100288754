import React, {createRef, Fragment, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {Grid, Button, TextField} from "@material-ui/core";

const UploadFile = props => {
  const {t} = useTranslation();
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (props.fileName) {
      setFileName(props.fileName);
    }
  }, [props.fileName]);

  const inputRef = createRef();

  const onFileChange = e => {
    const file = e.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split('.').pop();
    const possibleExtensions = props.extensions || ['csv'];

    if (!possibleExtensions.includes(fileExtension)) {
      const extensionsMsgPart = possibleExtensions.map(ext => '.' + ext).join(' or ');
      const errorMessage = t('only_ext_files_can_be_uploaded', "Only {{extensions}} files can be uploaded", {extensions: extensionsMsgPart});

      return props.addNotification({status: 'Error', message: [errorMessage]});
    }

    setFileName(file.name);
    props.onFileReady(file);
  };

  const clear = () => {
    inputRef.current.value = '';
    setFileName('');
    props.onClear && props.onClear();
  };

  return (
    <Fragment>
      <input
        style={{display: 'none'}}
        type="file"
        ref={inputRef}
        onChange={onFileChange}
      />
      <Grid container direction="row" spacing={2} alignItems="flex-start">
        <Grid item xs>
          <TextField
            variant="outlined"
            disabled
            fullWidth
            value={fileName}
            label={props.label || t('filename', 'Filename')}
            helperText={props.helperText}
            required={props.required}
            onClick={() => inputRef.current.click()}
          />
        </Grid>
        <Grid item>
          <Button
            style={{marginTop: '10px'}}
            color="default"
            variant="contained"
            onClick={() => inputRef.current.click()}
          >
            {t('browse', 'Browse')}
          </Button>
        </Grid>
        {props.clearable && (
          <Grid item>
            <Button
              style={{marginTop: '10px'}}
              color="default"
              variant="contained"
              disabled={!fileName}
              onClick={clear}
            >
              {t('clear', 'Clear')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
};

UploadFile.propTypes = {
  fileName: PropTypes.string,
  extensions: PropTypes.arrayOf(PropTypes.string),
  clearable: PropTypes.bool,
  onClear: PropTypes.func,
  addNotification: PropTypes.func,
  onFileReady: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default UploadFile;
