export const PRIMARY_EMOTIONS_FULL_REPORT_REQUEST = '[FULL REPORTS] PRIMARY_EMOTIONS_FULL_REPORT_REQUEST';
export const PRIMARY_EMOTIONS_FULL_REPORT_SUCCESS = '[FULL REPORTS] PRIMARY_EMOTIONS_FULL_REPORT_SUCCESS';
export const GET_PRIMARY_EMOTIONS_FULL_REPORT_FAILURE = '[FULL REPORTS] GET_PRIMARY_EMOTIONS_FULL_REPORT_FAILURE';

export const PRIMARY_SEARCH_CHANGE = '[FULL REPORTS] PRIMARY_SEARCH_CHANGE';

export const getPrimaryEmotionsFullReportRequest = () => ({type: PRIMARY_EMOTIONS_FULL_REPORT_REQUEST});
export const getPrimaryEmotionsFullReportSuccess = data => ({type: PRIMARY_EMOTIONS_FULL_REPORT_SUCCESS, data});
export const getPrimaryEmotionsFullReportFailure = () => ({type: GET_PRIMARY_EMOTIONS_FULL_REPORT_FAILURE});

export const primarySearchChange = value => ({type: PRIMARY_SEARCH_CHANGE, value});