import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Menu, MenuItem} from "@material-ui/core";
import {setOperator} from "../filters-actions";

const OperatorSelect = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const operator = useSelector(state => state.filters.operator);
  const open = Boolean(anchorEl);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSelect = useCallback((value) => {
    dispatch(setOperator(value));
    onClose();
  }, [dispatch, onClose]);

  return (
    <>
      <Button
        color="default"
        variant="contained"
        aria-controls={open ? 'button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={e => setAnchorEl(e.currentTarget)}
        endIcon={<ArrowDropDownIcon />}
        style={{width: '60px'}}
      >
        {t('operator_' + operator)}
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        keepMounted
      >
        <MenuItem onClick={() => onSelect('all')} value={'all'}>{t('operator_all', 'ALL')}</MenuItem>
        <MenuItem onClick={() => onSelect('or')} value={'or'}>{t('operator_or', 'OR')}</MenuItem>
        <MenuItem onClick={() => onSelect('and')} value={'and'}>{t('operator_and', 'AND')}</MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(OperatorSelect);