import {NOTE_MODAL_MODE_NOTE} from "./notes-constants";

export const SET_NOTE_INFO = '[NOTES] SET_NOTE_INFO';
export const SHOW_NOTE_MODAL = '[NOTES] SHOW_NOTE_MODAL';
export const HIDE_NOTE_MODAL = '[NOTES] HIDE_NOTE_MODAL';
export const ADD_NOTE_REQUEST = '[NOTES] ADD_NOTE_REQUEST';

export const SHOW_NOTE_CONTEXT_MENU = '[NOTES] SHOW_NOTE_CONTEXT_MENU';
export const HIDE_NOTE_CONTEXT_MENU = '[NOTES] HIDE_NOTE_CONTEXT_MENU';
export const SHOW_DELETE_NOTE_CONFIRMATION = '[NOTES] SHOW_DELETE_NOTE_CONFIRMATION';
export const HIDE_DELETE_NOTE_CONFIRMATION = '[NOTES] HIDE_DELETE_NOTE_CONFIRMATION';
export const DELETE_NOTE_REQUEST = '[NOTES] DELETE_NOTE_REQUEST';
export const DELETE_NOTE_DONE = '[NOTES] DELETE_NOTE_DONE';

export const SHOW_CREATE_FILTERS_FROM_NOTES_MODAL = '[NOTES] SHOW_CREATE_FILTERS_FROM_NOTES_MODAL';
export const HIDE_CREATE_FILTERS_FROM_NOTES_MODAL = '[NOTES] HIDE_CREATE_FILTERS_FROM_NOTES_MODAL';
export const CREATE_FILTERS_FROM_NOTES_REQUEST = '[NOTES] CREATE_FILTERS_FROM_NOTES_REQUEST';
export const CREATE_FILTERS_FROM_NOTES_DONE = '[NOTES] CREATE_FILTERS_FROM_NOTES_DONE';

export const setNoteInfo = (rowNumber, dataHeader) => ({type: SET_NOTE_INFO, rowNumber, dataHeader});
export const showNoteModal = (modalType = NOTE_MODAL_MODE_NOTE) => ({type: SHOW_NOTE_MODAL, modalType});
export const hideNoteModal = () => ({type: HIDE_NOTE_MODAL});
export const addNoteRequest = (tabName, title, description) => ({type: ADD_NOTE_REQUEST, tabName, title, description});

export const showNoteContextMenu = (mousePosition, noteId) => ({type: SHOW_NOTE_CONTEXT_MENU, mousePosition, noteId});
export const hideNoteContextMenu = () => ({type: HIDE_NOTE_CONTEXT_MENU});
export const showDeleteNoteConfirmation = () => ({type: SHOW_DELETE_NOTE_CONFIRMATION});
export const hideDeleteNoteConfirmation = () => ({type: HIDE_DELETE_NOTE_CONFIRMATION});
export const deleteNoteRequest = noteId => ({type: DELETE_NOTE_REQUEST, noteId});
export const deleteNoteDone = () => ({type: DELETE_NOTE_DONE});

export const showCreateFiltersFromNotesModal = () => ({type: SHOW_CREATE_FILTERS_FROM_NOTES_MODAL});
export const hideCreateFiltersFromNotesModal = () => ({type: HIDE_CREATE_FILTERS_FROM_NOTES_MODAL});
export const createFiltersFromNotesRequest = (tabName, threshold) => ({type: CREATE_FILTERS_FROM_NOTES_REQUEST, tabName, threshold});
export const createFiltersFromNotesDone = () => ({type: CREATE_FILTERS_FROM_NOTES_DONE});