import {LIVE_REPORT_START, LIVE_REPORT_STOP, LIVE_REPORT_SUCCESS} from "./live-report-actions";

const initialState = {
  responses: [],
  responsesTotal: 'No data',
  hxIndex: 'No data'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIVE_REPORT_START:
      return {...initialState};
    case LIVE_REPORT_STOP:
      return {...state, responses: []};
    case LIVE_REPORT_SUCCESS:
      const responsesCopy = [...state.responses];
      responsesCopy.unshift(...action.responses.reverse());
      return {responses: responsesCopy, responsesTotal: action.responsesTotal, hxIndex: action.hxIndex};
    default:
      return state;
  }
};

export default reducer;
