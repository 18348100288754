import {LOGIN_SUCCESS, LOGOUT_SUCCESS, SAVE_SEARCH_QUERY} from "./user-actions";

const initialState = null;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.user;
    case LOGOUT_SUCCESS:
      return null;
    case SAVE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    default:
      return state;
  }
};

export default userReducer;
