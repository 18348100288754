import {HIDE_CONTEXT_MENU, SET_SELECTED_TEXT, SHOW_CONTEXT_MENU} from "./context-menu-action-creators";

const initialState = {
  isShown: false,
  top: null,
  left: null,
  selectedText: "",
  selectedTextObject: null,
  boundaries: [],
};

const contextMenuReducer = ( state = initialState, action ) => {
  switch(action.type) {
    case SET_SELECTED_TEXT:
      return {
        ...state,
        selectedText: action.selectedText.text,
        boundaries: [action.selectedText.start, action.selectedText.end]
      };

    case SHOW_CONTEXT_MENU:
      return {
        ...state,
        top: action.top,
        left: action.left + 5
      };

    case HIDE_CONTEXT_MENU:
      return {
        ...state,
        top: null,
        left: null
      };

    default:
      return state;
  }
};

export default contextMenuReducer;