export const FETCH_USERS_REQUEST = '[USERS] FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = '[USERS] FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = '[USERS] FETCH_USERS_FAILURE';

export const IMPERSONATE_USER_REQUEST = '[USERS] IMPERSONATE_USER_REQUEST';
export const IMPERSONATE_USER_DONE = '[USERS] IMPERSONATE_USER_DONE';

export const fetchUsersRequest = () => ({type: FETCH_USERS_REQUEST});
export const fetchUsersSuccess = users => ({type: FETCH_USERS_SUCCESS, users});
export const fetchUsersFailure = () => ({type: FETCH_USERS_FAILURE});

export const impersonateUserRequest = (user, history) => ({type: IMPERSONATE_USER_REQUEST, user, history});
export const impersonateUserDone = () => ({type: IMPERSONATE_USER_DONE});