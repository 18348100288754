import {takeEvery, put} from 'redux-saga/effects';
import API from "../../API";
import {addNotification} from "../notifications/notifications-actions";
import {
  FETCH_USERS_REQUEST,
  fetchUsersFailure,
  fetchUsersSuccess,
  IMPERSONATE_USER_REQUEST,
  impersonateUserDone
} from "./users-actions";
import {loginSuccess} from "../login/user-actions";
import i18n from "../../i18n";

function* fetchUsers() {
  try {
    const response = yield API.showListOfUsers();

    if (response.data['ERROR']) {
      // noinspection ExceptionCaughtLocallyJS
      throw new Error(response.data['ERROR'])
    }

    yield put(fetchUsersSuccess(response.data['SUCCESS']));
  } catch (e) {
    yield put(fetchUsersFailure());
    yield put(addNotification({status: "Error",  message: [i18n.t('something_went_wrong_reload_page', "Something went wrong. Please reload the page.") + e.message]}));
  }
}

function* impersonateUser({user, history}) {
  try {
    const response = yield API.showLoginInfoAboutUser(user);

    const userData = {
      ...response.data,
      username: user
    };

    yield put(loginSuccess(userData));
    yield put(addNotification({
      status: "Success",
      message: [i18n.t('you_are_now_impersonating', `You are now impersonating user "{{user}}". You will need to logout and login again to return to previous user.`, {user})]
    }));
    history.replace('/');
  } catch (e) {
    yield put(addNotification({status: "Error",  message: [i18n.t('could_not_impersonate', "Could not impersonate. Please try again later.") + ' ' + e.message]}))
  } finally {
    yield put(impersonateUserDone());
  }
}

export default [
  takeEvery(FETCH_USERS_REQUEST, fetchUsers),
  takeEvery(IMPERSONATE_USER_REQUEST, impersonateUser),
];