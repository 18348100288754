export const SINGLE_RESPONSE_REQUEST = '[RESPONSE SINGLE] SINGLE_RESPONSE_REQUEST';
export const SINGLE_RESPONSE_SUCCESS = '[RESPONSE SINGLE] SINGLE_RESPONSE_SUCCESS';
export const SINGLE_RESPONSE_MODAL_SHOW = '[RESPONSE SINGLE] SINGLE_RESPONSE_MODAL_SHOW';
export const SINGLE_RESPONSE_MODAL_HIDE = '[RESPONSE SINGLE] SINGLE_RESPONSE_MODAL_HIDE';

export const singleResponseRequest = (responseNumber) => {
  return {type: SINGLE_RESPONSE_REQUEST, responseNumber};
};

export const singleResponseSuccess = (responses, responsesTotal) => {
  return {type: SINGLE_RESPONSE_SUCCESS, responses, responsesTotal};
};

export const hideResponseSingleModal = () => {
  return {type: SINGLE_RESPONSE_MODAL_HIDE};
};

export const showResponseSingleModal = () => {
  return {type: SINGLE_RESPONSE_MODAL_SHOW};
};