import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Pagination from "./Pagination";
import {IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import PageviewIcon from "@material-ui/icons/Pageview";
import {EXAMPLE_HIGHLIGHT, EXAMPLES_TABLE_PER_PAGE} from "../taxonomy-constants";
import FullVerbatimDialog from "./FullVerbatimDialog";

const useStyles = makeStyles(theme => ({
  term: {
    background: EXAMPLE_HIGHLIGHT,
    borderRadius: theme.shape.borderRadius,
  },
  actionsColumn: {
    width: 1,
  },
  pagination: {
    padding: theme.spacing(2),
  }
}))

const ExamplesTable = ({examples}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [coordinates, setCoordinates] = useState(null);
  const [fullVerbatimDialogOpen, setFullVerbatimDialogOpen] = useState(false);

  const total = examples.length;
  const to = page * EXAMPLES_TABLE_PER_PAGE;
  const from = to - EXAMPLES_TABLE_PER_PAGE;

  const pageExamples = examples.slice(from, to);

  const openFullVerbatimDialog = coordinates => {
    setCoordinates(coordinates);
    setFullVerbatimDialogOpen(true);
  };

  const closeFullVerbatimDialog = () => {
    setFullVerbatimDialogOpen(false);
  }

  return (
    <>
      <div className={classes.pagination}>
        <Pagination
          from={from}
          to={to}
          page={page}
          setPage={setPage}
          total={total}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('example', 'Example')}</TableCell>
            <TableCell className={classes.actionsColumn}/>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageExamples.map((example, i) => (
            <TableRow key={i}>
              <TableCell>
                {example['before_term_chunk']}
                <span className={classes.term}>{example['term_chunk']}</span>
                {example['after_term_chunk']}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => openFullVerbatimDialog(example['coordinates'])}>
                  <PageviewIcon/>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.pagination}>
        <Pagination
          from={from}
          to={to}
          page={page}
          setPage={setPage}
          total={total}
        />
      </div>
      <FullVerbatimDialog
        isOpen={fullVerbatimDialogOpen}
        onClose={closeFullVerbatimDialog}
        coordinates={coordinates}
      />
    </>
  );
};

export default ExamplesTable;