import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {
  editSingleResponseRequest,
  loadSingleResponseRequest,
  responseFieldChanged
} from "./edit-single-response-actions";
import {useDispatch, useSelector} from "react-redux";

import {Box, Button, Chip, Container, CircularProgress, Grid, MenuItem, Paper, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

import DataField from "./components/DataField";
import {filtersRequest} from "../filters/filters-actions";
import {FILTERS_TYPE_NAME_MAP} from "../filters/filters-constants";
import {useTranslation} from "react-i18next";

const EditSingleResponse = function (props) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const response = useSelector(state => state.editSingleResponse.response);
  const flatFilters = useSelector(state => state.filters.flatFilters);
  const {rowNumber} = useParams();
  const flatFiltersValues = Object.values(flatFilters);

  useEffect(() => {
    dispatch(filtersRequest());
    dispatch(loadSingleResponseRequest(rowNumber));
  }, [dispatch, rowNumber]);

  let formFields = (
    <Grid container alignItems="center" justify="center" component={Box} p={6}><CircularProgress/></Grid>
  );

  const getFilterValues = (colName, type) => {
    const parentFilter = flatFiltersValues.find(f => (f['title_in_original_language'] || f.title) === FILTERS_TYPE_NAME_MAP[type]);
    const filter = flatFiltersValues.find(f => f.parentId === parentFilter.id && f.title === colName);
    const childFilters = flatFiltersValues.filter(f => f.parentId === filter.id);

    return childFilters.map(f => f.title);
  };

  const onSubmit = e => {
    e.preventDefault();

    dispatch(editSingleResponseRequest(props.history));
  };

  if (response && flatFiltersValues.length > 0) {
    formFields = response.columns.reduce((acc, col) => {
      const commonProps = {
        variant: 'outlined',
        label: col.header,
        value: col.input_value,
        onChange: e => dispatch(responseFieldChanged(col.header, e.target.value))
      };

      let field = null;

      if (col.type === 'meta') {
        field = (
          <TextField
            {...commonProps}
            fullWidth
            select
            style={{maxWidth: '100%'}}
          >
            {getFilterValues(col.header, col.type).map(title => (
              <MenuItem value={title} key={title}>{title}</MenuItem>
            ))}
          </TextField>
        );
      } else if (col.type === 'data') {
        field = (
          <DataField {...commonProps}/>
        );
      } else if (col.type === 'meta_multival_json') {
        field = (
          <Autocomplete
            {...commonProps}
            multiple
            freeSolo
            value={JSON.parse(col['input_value'])}
            options={getFilterValues(col.header, col.type)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({index})} />
              ))
            }
            onChange={(e, value) => dispatch(responseFieldChanged(col.header, JSON.stringify(value)))}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label={col.header}
                helperText={t("helper_text_add_new_value", 'Press Enter to add new value')}
                fullWidth
              />
            )}
          />
        );
      }

      if (field) {
        acc.push((
          <Grid key={col.header} item xs>{field}</Grid>
        ));
      }

      return acc;
    }, []);

    formFields.push((
      <Grid key="____save____" item xs container direction="row" spacing={2}>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">{t('save', 'Save')}</Button>
        </Grid>
        <Grid item>
          <Button color="default" variant="contained" type="button"
                  onClick={() => props.history.goBack()}>{t('cancel', 'Cancel')}</Button>
        </Grid>
      </Grid>
    ))
  }

  return (
    <Container >
      <Paper component={Box} p={2} mt={2} style={{width: '100%'}}>
        <h2 style={{marginBottom: '10px'}}>{t('edit_single_verbatim', 'Edit single verbatim')}</h2>

        <Grid container direction="column" alignContent="center" justify="center" component="form" spacing={2}
              onSubmit={onSubmit}
        >
          {formFields}
        </Grid>
      </Paper>
    </Container>
  );
};

export default EditSingleResponse;
