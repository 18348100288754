import React, {Fragment, useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Scrollbars} from "rc-scrollbars";
import xss from 'xss';
import {
  Badge,
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Grow,
  IconButton,
  Paper,
  Typography,
  Zoom
} from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import grey from '@material-ui/core/colors/grey';
import {initChat, sendMessageRequest, startRequestingLiveChat, stopRequestingLiveChat} from "./chat-actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import IconButtonWithCircularProgress
  from "../../components/IconButtonWithCircularProgress/IconButtonWithCircularProgress";
import moment from "moment";
import {CLOSED_MODE, OPEN_MODE} from "./chat-constants";
import ChatInput from "./components/ChatInput";
import {useTranslation} from "react-i18next";

// moment.updateLocale('en', );

const arrowCommonStyles = {
  content: "''",
  position: 'absolute',
  bottom: 0,
  right: 10,
  width: 0,
  height: 0,
  border: '10px solid transparent',
  borderBottom: 0,
  marginLeft: -5,
  marginBottom: -10,
};

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  chatWindow: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    width: 400,
    boxSizing: 'content-box',
  },
  chatPaper: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  chatContent: {
    height: 400,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  message: {
    maxWidth: '100%',
    marginBottom: 20,
    padding: `0 ${theme.spacing(2)}px`,
    '& > div': {
      position: 'relative',
      borderRadius: 5,
      padding: theme.spacing(2)
    },
    '& > div::after': arrowCommonStyles,
  },
  messageInner: {
    whiteSpace: 'pre-wrap',
  },
  right: {
    justifyContent: 'flex-end',
    paddingLeft: 20,
    '& > div': {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '& > div::after': {
      right: 10,
      borderTopColor: theme.palette.primary.light,
      borderLeft: 0,
    }
  },
  left: {
    justifyContent: 'flex-start',
    paddingRight: 20,
    '& > div': {
      background: grey[300],
      color: theme.palette.getContrastText(grey[300]),
    },
    '& > div::after': {
      left: 10,
      borderTopColor: grey[300],
      borderRight: 0,
    }
  },
  time: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
    fontStyle: 'italic',
  }
}));

const Chat = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const scrollbars = useRef();
  const dispatch = useDispatch();

  const [windowOpen, setWindowOpen] = useState(false);
  const [message, setMessage] = useState('');

  const {sendLoading, messages, unseenMessages, helpArticles} = useSelector(state => state.chat, shallowEqual);
  const user = useSelector(state => state.user);

  useEffect(() => {
    dispatch(initChat());
  }, [dispatch]);

  useEffect(() => {
    scrollbars.current.scrollToBottom();
  }, [messages]);

  useEffect(() => {
    dispatch(stopRequestingLiveChat());
    dispatch(startRequestingLiveChat(windowOpen ? OPEN_MODE : CLOSED_MODE));

    return () => {
      dispatch(stopRequestingLiveChat());
    }
  }, [windowOpen]);

  const onSubmit = e => {
    e.preventDefault();
    submitMessage();
  };

  const submitMessage = () => {
    setMessage('');
    dispatch(sendMessageRequest(message));
  }

  const formatDate = date => {
    return moment.utc(date, moment.ISO_8601).local().calendar(null, {
      lastWeek : 'L LT',
      sameElse : 'L LT'
    });
  };

  const triggerSubmit = e => {
    if (e.ctrlKey && e.keyCode === 13) {
      submitMessage();
    }
  };

  const xssOptions = {
    whiteList: {
      a: ["href", "title", "target"]
    }
  };

  return (
    <Fragment>
      <Grow in={windowOpen} style={{transformOrigin: 'bottom right'}}>
        <div className={`${classes.chatWindow} mui-fixed`}>
          <Paper elevation={4} className={classes.chatPaper}>
            <DialogTitle disableTypography>
              <Typography variant="h6">{t('chat_with_support', 'Chat with support')}</Typography>
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => setWindowOpen(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className={classes.chatContent}>
              <Scrollbars ref={scrollbars}>
                {messages.length === 0 && (
                  <Grid container alignItems="center" justify="center">
                    <Chip size="small" label={t('support_chat_empty', 'Your chat with support is empty')}/>
                  </Grid>
                )}
                {messages.map(message => {
                  const className = message.user === user.username ? classes.right : classes.left;
                  const x = new xss.FilterXSS(xssOptions);
                  return (
                    <Grid container className={`${classes.message} ${className}`} key={message['date_created']}>
                      <Grid item>
                        <div className={classes.messageInner} dangerouslySetInnerHTML={{__html: x.process(message.message)}}/>
                        <div className={classes.time}>{formatDate(message['date_created'])}</div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Scrollbars>
            </DialogContent>
            <form onSubmit={onSubmit}>
              <Grid container spacing={1} component={Box} p={1} alignItems="center">
                <Grid item xs>
                  <ChatInput
                    helpArticles={helpArticles}
                    message={message}
                    setMessage={setMessage}
                    triggerSubmit={triggerSubmit}
                  />
                </Grid>
                <Grid item>
                  <IconButtonWithCircularProgress IconButtonProps={{type: "submit"}} loading={sendLoading}>
                    <SendIcon fontSize="small"/>
                  </IconButtonWithCircularProgress>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </Grow>
      <Zoom in={!windowOpen}>
        <Badge color="secondary" badgeContent={unseenMessages} className={`${classes.fab} mui-fixed`} overlap="circle">
          <Fab color="primary" aria-label="open chat" onClick={() => setWindowOpen(true)}>
            <ChatIcon />
          </Fab>
        </Badge>
      </Zoom>
    </Fragment>
  );
};

export default Chat;