import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  tooltip: {
    fontSize: '14px',
    lineHeight: '1.5'
  },
});

const BreakdownChartTooltip = ({data, columns}) => {
  const classes = useStyles();

  return (
    <div className={classes.tooltip}>
      {columns.reduce((comps, col) => {
        if (col.renderTooltip !== false) {
          let value = data[col.key];

          if (col.transform) {
            value = col.transform(value, data);
          }

          if (col.renderTooltip) {
            value = col.renderTooltip(value, data);
          }

          comps.push(
            <div key={col.key}>
              <strong>{col.name}:</strong>{' '}{value}
            </div>
          )
        }

        return comps;
      }, [])}
    </div>
  );
};

export default BreakdownChartTooltip;