import {
  LOAD_TOP_WORDS_ERROR,
  LOAD_TOP_WORDS_REQUEST,
  LOAD_TOP_WORDS_SUCCESS,
  SWITCH_TOP_WORDS_CHART_MODE
} from "./charts-action-types";
import {TOP_WORDS_CHART_MODE_BAR} from "./constants";
import {
  LOAD_PRIMARY_SECONDARY_SENTIMENT_ERROR,
  LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST,
  LOAD_PRIMARY_SECONDARY_SENTIMENT_SUCCESS
} from "./charts-action-creators";

const initialState = {
  topWordsLoading: true,
  topWords: [],
  topWordsError: "",
  topWordsChartMode: TOP_WORDS_CHART_MODE_BAR,
  primarySecondarySentimentLoading: false,
  primarySecondarySentimentError: null,
  primary: [],
  sentiment: [],
  responsesInfo: {},
  responsesInfoError: "",
};

const chartsReducer = ( state = initialState, action ) => {
  const {
    topWords,
    error,
    reportUrl
  } = action;

  switch(action.type) {

    case LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST:
      return {
        ...state,
        primarySecondarySentimentLoading: true,
      };

    case LOAD_PRIMARY_SECONDARY_SENTIMENT_SUCCESS:
      return {
        ...state,
        primary: action.primary,
        sentiment: action.sentiment,
        primarySecondarySentimentLoading: false,
        primarySecondarySentimentError: null
      };

    case LOAD_PRIMARY_SECONDARY_SENTIMENT_ERROR:
      return {
        ...state,
        primarySecondarySentimentError: action.error,
        primarySecondarySentimentLoading: false,
        primary: null,
        sentiment: null,
      };

    case LOAD_TOP_WORDS_REQUEST:
      return {
        ...state,
        topWordsLoading: true,
        topWordsError: "",
        responsesInfoError: "",
        whoMountedTopWords: reportUrl,
      };

    case LOAD_TOP_WORDS_SUCCESS:
      return {
        ...state,
        topWordsLoading: false,
        topWords,
        topWordsError: '',
        responsesInfoError: ""
      };

    case LOAD_TOP_WORDS_ERROR:
      return {
        ...state,
        topWordsLoading: false,
        topWordsError: error,
        topWords: []
      };

    case SWITCH_TOP_WORDS_CHART_MODE:
      return {
        ...state,
        topWordsChartMode: action.mode
      };

    default:
      return state;
  }
};

export default chartsReducer;
