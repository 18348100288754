import {call, put, select, take} from "redux-saga/effects";
import {dataAppendDownloadComplete, dataAppendDownloadProgress} from "./data-append-actions";
import {addNotification} from "../notifications/notifications-actions";
import saveAs from 'file-saver';
import i18n from "../../i18n";

import {
  DOWNLOAD_FILE_EVENT_COMPLETE,
  DOWNLOAD_FILE_EVENT_PROGRESS,
  downloadFileSagaHelper, getFilename
} from './data-append-helpers';

function* downloadFile(number, options) {
  const channel = yield call(downloadFileSagaHelper, options);

  while (true) {
    const event = yield take(channel);

    if (event.type === DOWNLOAD_FILE_EVENT_PROGRESS) {
      yield put(dataAppendDownloadProgress(number, event.percent));
    } else if (event.type === DOWNLOAD_FILE_EVENT_COMPLETE) {
      return event.response;
    }
  }
}

export function* dataAppendDownload({number}) {
  const report = yield select(state => state.dataAppend.report);
  const downloadOptions = yield select(state => state.dataAppend.downloadOptions);

  const options = {
    reportId: report.report_id,
    schema: downloadOptions.schema,
    skip: number * downloadOptions.limit,
    limit: downloadOptions.limit,
    partSuffix: `part_${number + 1}_of_${downloadOptions.linksNumber}`,
    sanitized: downloadOptions.sanitized,
    verbose: downloadOptions.verbose,
    includeNotes: downloadOptions.includeNotes
  };

  try {
    const response = yield downloadFile(number, options);
    yield put(dataAppendDownloadComplete(number));

    const filename = getFilename(response, options);

    saveAs(response.data, filename);
  } catch (e) {
    console.log('FAILED TO DOWNLOAD FILE', number, options);
    yield put(dataAppendDownloadComplete(number));
    yield put(addNotification({ status: "Error", message: [t('something_went_wrong_with_download', 'Something went wrong with downloading the file. Please try to lower number of rows and try again')]}))
  }
}