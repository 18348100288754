import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Box, FormControlLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";

import UploadFile from "../../../components/UploadFile/UploadFile";
import ReportEditBottom from "../components/ReportEditBottom";

import {addNotification} from "../../notifications/notifications-actions";
import {editUploadFileRequest} from "../create-report-action-creators";

const POSSIBLE_EXTENSIONS = ['csv', 'json'];

const EditUploadFile = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(state => state.createReport.editUploadLoading);

  const REPLACE = t('replace_existing_rows', 'Replace existing rows');
  const APPEND = t('append_rows_to_existing_data', 'Append rows to existing data');
  const REPLACE_RADIO_VALUES = [REPLACE, APPEND];

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [replaceExisting, setReplaceExisting] = useState(true);

  const onFileReady = file => {
    const ext = file.name.split('.').pop();
    setFile(file);
    setFileType(ext);
  };

  const submit = () => {
    dispatch(editUploadFileRequest(file, fileType, replaceExisting, history));
  };

  const selectReplaceExisting = (e, value) => {
    setReplaceExisting(value === REPLACE);
  };

  return (
    <>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('upload_new_file_to replace_append_rows', 'Upload new file to replace/append rows')}</Typography>
          </Grid>
          <Grid item xs>
            <UploadFile
              extensions={POSSIBLE_EXTENSIONS}
              onFileReady={onFileReady}
              addNotification={notification => dispatch(addNotification(notification))}
              label={t('select_a_file', 'Select a file')}
            />
          </Grid>
          <Grid item xs>
            <Typography>{t('this_file_should', 'This file should')}:</Typography>
            <RadioGroup row value={replaceExisting ? REPLACE : APPEND} onChange={selectReplaceExisting}>
              {REPLACE_RADIO_VALUES.map(v => (
                <FormControlLabel key={v} value={v} control={<Radio />} label={v} />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>

      <ReportEditBottom
        loading={loading}
        enabled={file && fileType && !loading}
        submit={submit}
        submitText={t('upload_and_recalculate', 'Upload & recalculate')}
      />
    </>
  );
}

export default EditUploadFile;