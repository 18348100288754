import React from 'react';
import {makeStyles, TableCell, Chip, Typography, Badge, Grid, Tooltip} from "@material-ui/core";
import {yellow} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  cell: {
    position: 'relative',
  },
  term: {
    fontSize: '1rem',
    fontWeight: 'bolder'
  },
  newChip: {
    fontSize: '0.6rem',
    height: theme.spacing(2),
    verticalAlign: 'text-top'
  },
  translation: {
    lineHeight: '0.9rem',
    borderBottom: `1px dotted ${theme.palette.primary.light}`,
    marginBottom: theme.spacing(0.5)
  },
  translationChip: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    left: theme.spacing(2),
    whiteSpace: 'nowrap',
    zIndex: 100,
    background: yellow[100]
  },
}));

const TermCell = ({term, taxonomy, language, _id, original_words, showTranslationInfo}) => {
  const classes = useStyles();

  const isTranslation = original_words && original_words[0];

  const termInfoClasses = [classes.term];

  if (isTranslation) {
    termInfoClasses.push(classes.translation)
  }

  const termInfo = <Typography className={termInfoClasses.join(' ')}>{term}</Typography>;

  return (
    <TableCell className={classes.cell} style={{verticalAlign: showTranslationInfo && original_words ? 'top' : undefined}}>
      <Grid container justify="space-between">
        <Grid item>
          {termInfo}
        </Grid>
        {!_id && (
          <Grid item>
            <Chip color="primary" label="New" size="small" className={classes.newChip} component="span"/>
          </Grid>
        )}
      </Grid>

      <Chip label={taxonomy} size="small" variant="outlined"/>{' '}
      <Chip label={language} size="small" variant="outlined"/>

      {showTranslationInfo && original_words && (
        <Chip
          label={<><strong>Original words: </strong> {original_words.join(', ')}</>}
          className={classes.translationChip}
          size="small"
          variant="outlined"
        />
      )}
    </TableCell>
  );
};

export default TermCell;