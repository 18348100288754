import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

import {hideResponseSingleModal, singleResponseRequest} from "./response-single-actions";
import {getSelectedText} from "../context-menu/context-menu-functions";
import {setSelectedText, showContextMenu} from "../context-menu/context-menu-action-creators";
import {setNoteInfo, showNoteContextMenu} from "../responses/notes/notes-actions";

import Paginator from "../../components/Paginator/Paginator";
import UiModal from "../../components/UiModal/UiModal";
import {CircularProgress, DialogContent, Grid} from "@material-ui/core";
import Verbatim from "../responses/verbatim";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    height: '200px'
  },
}));

const ResponseSingleModalTitle = ({responseNumber, responsesTotal, getSingleResponse}) => {
  const {t} = useTranslation();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>{t('single_verbatim_view', 'Single Verbatim View')}</Grid>
      <Grid item>
        <Paginator
          page={responseNumber}
          totalPages={responsesTotal}
          changePage={getSingleResponse}
          label={t('verbatim_n', 'Verbatim #')}
        />
      </Grid>
    </Grid>
  );
};

const ResponseSingleModal = props => {
  const styles = useStyles();

  const title = (
    <ResponseSingleModalTitle
      responseNumber={props.responseNumber}
      responsesTotal={props.responsesTotal}
      getSingleResponse={props.getSingleResponse}
    />
  );

  let responses = (
    <Grid container alignItems="center" justify="center" className={styles.loadingContainer}>
      <CircularProgress/>
    </Grid>
  );

  if (props.responses) {
    const emotionsTab = props.reportInfo ? props.reportInfo['emotions_tab_name'] : 'Emotions';

    responses = props.responses.map((response, i)=> (
      <Verbatim
        key={i}
        reportId={props.reportId}
        response={response}
        selectedTab={emotionsTab}
        setNoteInfo={props.setNoteInfo}
        setSelectedText={props.setSelectedText}
        showContextMenu={props.showContextMenu}
        showNoteContextMenu={props.showNoteContextMenu}
      />
    ));
  }

  return (
    <UiModal
      open={props.show}
      onClose={props.closeModal}
      title={title}
      maxWidth="md"
    >
      <DialogContent dividers>
        {responses}
      </DialogContent>
    </UiModal>
  );
};

const mapStateToProps = state => ({
  reportId: state.report.reportId,
  show: state.responseSingle.modalIsShown,
  responseNumber: state.responseSingle.responseNumber,
  responses: state.responseSingle.responses,
  responsesTotal: state.responseSingle.responsesTotal,
  reportInfo: state.reportInfo.report,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideResponseSingleModal()),
  showContextMenu: ({ top, left }) => dispatch(showContextMenu({ top, left })),
  setSelectedText: () => dispatch(setSelectedText(getSelectedText())),
  getSingleResponse: (responseNumber) => dispatch(singleResponseRequest(responseNumber)),
  setNoteInfo: (rowNumber, dataHeader) => dispatch(setNoteInfo(rowNumber, dataHeader)),
  showNoteContextMenu: (mousePosition, noteId) => dispatch(showNoteContextMenu(mousePosition, noteId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseSingleModal);
