export const LIVE_REPORT_START = 'LIVE_REPORT_START';

export const LIVE_REPORT_SUCCESS = 'LIVE_REPORT_SUCCESS';

export const LIVE_REPORT_STOP = 'LIVE_REPORT_STOP';

export const liveReportStart = () => ({type: LIVE_REPORT_START});

export const liveReportSuccess = (hxIndex, responsesTotal, responses) => ({type: LIVE_REPORT_SUCCESS, hxIndex, responsesTotal, responses});

export const liveReportStop = () => ({type: LIVE_REPORT_STOP});
