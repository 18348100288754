import {
    REQUEST_SUPPORT_DONE,
    REQUEST_SUPPORT_MODAL_HIDE,
    REQUEST_SUPPORT_MODAL_SHOW,
    REQUEST_SUPPORT_REQUEST
} from "./request-support-actions";

const initialState = {
    showModal: false,
    loading: false,
};

const requestSupportReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_SUPPORT_MODAL_SHOW:
            return {...state, showModal: true};
        case REQUEST_SUPPORT_MODAL_HIDE:
            return {...state, showModal: false};
        case REQUEST_SUPPORT_REQUEST:
            return {...state, loading: true};
        case REQUEST_SUPPORT_DONE:
            return {...state, loading: false};
        default:
            return state;
    }
};

export default requestSupportReducer;