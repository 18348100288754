import React from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import TaxonomyDisplay from "../../../components/TaxonomyDisplay/TaxonomyDisplay";
import {
  addTaxonomy, chooseTaxonomy, ignoreWordsChange,  removeTaxonomy,
} from "../create-report-action-creators";
import {Grid, Button, Chip} from "@material-ui/core";
import IgnoreWords from "./IgnoreWords";

const Taxonomies = (
  {
    taxonomies, allTaxonomies, removeTaxonomy, chooseTaxonomy, addTaxonomy,
    ignoreWordsChange, ignoreWords
  }
) => {
  const {t} = useTranslation();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <h2>{t('set_taxonomies', 'Set taxonomies')}</h2>
      </Grid>
      <Grid item>
        {taxonomies.length ? (
          taxonomies.map((taxonomy, i) => (
            <TaxonomyDisplay
              key={i}
              taxonomy={taxonomy}
              allTaxonomies={allTaxonomies}
              columnIndex={i}
              removeTaxonomy={() => removeTaxonomy(i)}
              chooseTaxonomy={chooseTaxonomy}
            />
          ))
        ) : (
          <Chip label={t('no_taxonomies_selected', 'No taxonomies selected')} variant="outlined" />
        )}
      </Grid>

      <Grid item>
        {allTaxonomies.length > 0 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={addTaxonomy}
          >
            {t('add_taxonomy', 'Add taxonomy')}
          </Button>
        )}
      </Grid>

      <IgnoreWords
        ignoreWords={ignoreWords}
        ignoreWordsChange={ignoreWordsChange}
      />
    </Grid>
  );
};

const mapStateToProps = state => ({
  taxonomies: state.createReport.taxonomies,
  allTaxonomies: state.createReport.allTaxonomies,
  ignoreWords: state.createReport.ignoreWords,
});

const mapDispatchToProps = dispatch => ({
  addTaxonomy: () => dispatch(addTaxonomy()),
  removeTaxonomy: i => dispatch(removeTaxonomy(i)),
  chooseTaxonomy: taxonomyInfo => dispatch(chooseTaxonomy(taxonomyInfo)),
  ignoreWordsChange: value => dispatch(ignoreWordsChange(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Taxonomies);
