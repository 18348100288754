import React, {Component} from 'react';
import {
  advancedSearchRequest,
  hideAdvancedSearchPanel,
  loadReportsRequest,
  searchClear,
  showAdvancedSearchPanel,
  showTrashBinRequest
} from "./reports-actions";
import {connect} from "react-redux";
import Tags from "./containers/Tags";
import {loadCompaniesRequest, loadDomainsRequest} from "../create-report/create-report-action-creators";
import {withRouter} from "react-router-dom";
import {saveSearchQuery} from "../login/user-actions";

import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {createQueryString, parseQueryString} from "./reports-functions";
import {withTranslation} from "react-i18next";


const SIZE_OPERATORS = [
  {title: 'Greater than', value: 'greater than'},
  {title: 'Less than', value: 'less than'},
  {title: 'Equal to', value: 'equal to'}
];

const SIZE_UNITS = [
  {title: 'AVU', value: 'AVU'},
  {title: 'Rows', value: 'row'},
  {title: 'Data columns', value: 'data columns'}
];

const defaultState = {
  hasTheWords: [],
  doesntHaveTheWords: [],
  sizeOperator: SIZE_OPERATORS[0].value,
  sizeUnit: SIZE_UNITS[0].value,
  sizeValue: '',
  daysWithin: '',
  calendar: '',
  company: '',
  domain: '',
  tags: [],
  page: 1,
  offset: 0,
};

class ReportsSearch extends Component {
  toggleAdvanced = e => {
    e.preventDefault();

    this.props.panelShow ?
      this.props.hideAdvancedSearchPanel() :
      this.props.showAdvancedSearchPanel()
  };

  syncQueryToState = next => {
    const parsedQuery = parseQueryString(this.props.location.search);

    this.props.saveSearchQuery(this.props.location.search);

    const newState = {...defaultState};

    Object.keys(newState).forEach(key => {
      if (key in parsedQuery) {
        const value = parsedQuery[key];
        if (['hasTheWords', 'doesntHaveTheWords', 'tags'].includes(key)) {
          return newState[key] = value.split('|');
        }

        if (key === 'page') {
          return newState.offset = this.props.limit * (value - 1);
        }

        return newState[key] = value;
      }
    });

    this.setState(newState, next);
  };

  state = {...defaultState};

  inputChangeHandler = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  };

  tagsChange = tags => {
    this.setState({tags});
  };

  hasTheWordsChange = hasTheWords => {
    this.setState({hasTheWords});
  };

  onCompanyChange = company => {
    company = company ?? '';
    this.setState({company})
  };

  doesntHaveTheWordsChange = doesntHaveTheWords => {
    this.setState({doesntHaveTheWords});
  };

  hasTheWordsChangeAndSearch = hasTheWords => {
    this.setState({hasTheWords}, this.advancedSearch);
  };

  onCalendarChange = (date) => {
    const dateString = date ? date.format('YYYY-MM-DD') : '';

    this.setState({calendar: dateString});
  };

  handleAdvancedSearch = event => {
    event.preventDefault();
    this.advancedSearch();
  };

  advancedSearch = () => {
    const queryObj = {};

    Object.keys(this.state).forEach(key => {
      let value = this.state[key];

      if (value.length > 0 && value !== defaultState[key]) {
        if (Array.isArray(this.state[key])) {
          value = this.state[key].join('|');
        }

        queryObj[key] = value;
      }
    });

    const searchString = '?' + createQueryString(queryObj);
    this.props.history.push({search: searchString});
  };

  componentDidMount() {
    this.props.loadCompaniesRequest();
    this.props.loadDomainsRequest();

    this.loadReports();
  }

  loadReports = () => {
    this.syncQueryToState(() => {
      if (this.props.location.search === '?archived') {
        return this.props.showTrashBinRequest();
      }

      if (!this.props.location.search) {
        this.props.searchClear();
      }

      return this.props.advancedSearchRequest({...this.state});
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadReports();
    }
  }

  clear = e => {
    e.stopPropagation();
    this.props.hideAdvancedSearchPanel();
    this.props.searchClear();
    this.props.history.replace({search: ''});
  };

  getSelectedSearchFields = () => {
    return Object.keys(this.state).reduce((acc, key) => {
      if (['hasTheWords', 'page', 'offset'].includes(key)) return acc;

      if (Array.isArray(this.state[key])) {
        if (this.state[key].length > 0) {
          acc.push({name: key, value: this.state[key].join(', ')});
        }
      } else if (this.state[key] !== defaultState[key]) {
        acc.push({name: key, value: this.state[key]});
      }

      return acc;
    }, []);
  };


  render() {
    const t = this.props.t;

    const classNames = ['report-search'];

    if (this.props.panelShow) {
      classNames.push('report-search-simple-active');
    }

    const fieldsSelected = this.getSelectedSearchFields();

    const inputAdornments = (
      <InputAdornment position="end">
        {this.props.loading && <IconButton type="button"><CircularProgress size="20px"/></IconButton> }
        {!this.props.loading && fieldsSelected.length > 0 && (
          <IconButton size="small" type="button" onClick={this.clear}>
            <ClearIcon fontSize="small"/>
          </IconButton>
        ) }
        {!this.props.panelShow && (
          <IconButton size="small" type="submit">
            <SearchIcon fontSize="small"/>
          </IconButton>
        )}

        <IconButton type="button" size="small" onClick={this.toggleAdvanced}>
          <Badge color="primary" variant="dot" invisible={fieldsSelected.length === 0 || this.props.panelShow}>
            <ExpandMoreIcon fontSize="small"/>
          </Badge>
        </IconButton>
      </InputAdornment>
    );

    return (
      <Grid container className={classNames.join(' ')} direction="column">
        <Backdrop open={this.props.panelShow} onClick={this.toggleAdvanced} style={{zIndex: 1}}/>

        <Grid item component="form" onSubmit={this.handleAdvancedSearch}>
          {this.props.panelShow ? (
            <TextField
              variant="outlined"
              disabled
              placeholder={t('advanced_search', 'Advanced search')}
              fullWidth
              size="small"
              InputProps={{
                endAdornment: inputAdornments
              }}
            />
          ) : (
            <Autocomplete
              value={this.state.hasTheWords}
              onChange={this.hasTheWordsChangeAndSearch}
              label={t('search', 'Search')}
              disableClearable
              addOnBlur
              size="small"
              TextFieldInputProps={{
                endAdornment: inputAdornments
              }}
            />
          )}
        </Grid>

        <Paper className="report-search-advanced" component={Box} p={2} elevation={2} style={{display: this.props.panelShow ? 'block' : 'none'}}>
          <Grid container direction="column" spacing={2} component="form" onSubmit={this.handleAdvancedSearch}>

            <Grid item>
              <Autocomplete
                label={t('has_the_words', 'Has the words')}
                value={this.state.hasTheWords}
                onChange={this.hasTheWordsChange}
                helperText={t('has_the_words_help', 'Press Enter to separate words. Words can have spaces inside them.')}
                addOnBlur
              />
            </Grid>

            <Grid item>
              <Autocomplete
                label={t('doesnt_have_the_words', "Doesn't have the words")}
                value={this.state.doesntHaveTheWords}
                onChange={this.doesntHaveTheWordsChange}
                helperText={t('has_the_words_help', 'Press Enter to separate words. Words can have spaces inside them.')}
                addOnBlur
              />
            </Grid>

            <Grid item container direction="row" spacing={2}>
              <Grid item xs>
                <TextField
                  name="sizeOperator"
                  value={this.state.sizeOperator} onChange={this.inputChangeHandler}
                  variant="outlined"
                  fullWidth
                  select
                >
                  {SIZE_OPERATORS.map(operator =>(
                    <MenuItem key={operator.value} value={operator.value}>
                      {t('search_size_op_' + operator.value, operator.title)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField
                  label={t('size', "Size")}
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={this.state.sizeValue}
                  name="sizeValue"
                  onChange={this.inputChangeHandler}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  name="sizeUnit"
                  value={this.state.sizeUnit} onChange={this.inputChangeHandler}
                  variant="outlined"
                  fullWidth
                  select
                >
                  {SIZE_UNITS.map(unit =>(
                    <MenuItem key={unit.value} value={unit.value}>
                      {t('search_size_unit_' + unit.value, unit.title)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item container direction="row" spacing={2}>
              <Grid item xs>
                <TextField
                  label={t('days_within', "Days within")}
                  name="daysWithin"
                  value={this.state.daysWithin} onChange={this.inputChangeHandler}
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="">{t('days_within_none', 'None')}</MenuItem>
                  <MenuItem value="1">{t('days_within_1', '1 day')}</MenuItem>
                  <MenuItem value="2">{t('days_within_2', '2 days')}</MenuItem>
                  <MenuItem value="7">{t('days_within_7', '1 week')}</MenuItem>
                  <MenuItem value="14">{t('days_within_14', '2 weeks')}</MenuItem>
                  <MenuItem value="30">{t('days_within_30', '1 month (30 days)')}</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    inputVariant="outlined"
                    label={t('select_a_date', "Select a date")}
                    variant="inline"
                    format="YYYY-MM-DD"
                    autoOk
                    value={this.state.calendar ? moment(this.state.calendar) : null}
                    onChange={this.onCalendarChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => this.onCalendarChange(null)}>
                          <ClearIcon />
                        </IconButton>
                      )
                    }}
                    InputAdornmentProps={{
                      position: "start"
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid item container direction="row" spacing={2}>
              <Grid item xs>
                <MUIAutocomplete
                  options={this.props.companies}
                  value={this.state.company || null}
                  onChange={(e, value) => this.onCompanyChange(value)}
                  renderInput={params => {
                   return (
                      <TextField
                        {...params}
                        fullWidth
                        label={t('company', "Company")}
                        variant="outlined"
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  label={t('domain', "Domain")}
                  name="domain"
                  value={this.state.domain} onChange={this.inputChangeHandler}
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="">{t('none_selected', 'None selected')}</MenuItem>
                  {this.props.domains.map(item =>(
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item>
              <Tags
                tags={this.state.tags}
                onChange={this.tagsChange}
              />
            </Grid>

            <Grid item container justify="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.props.loading}
              >
                {this.props.loading ? <CircularProgress size="25px" color="inherit"/> : t('search', 'Search')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

    );
  }
}

const mapStateToProps = state => ({
  limit: state.reports.limit,
  text: state.reports.searchText,
  loading: state.reports.searchLoading,
  loaded: state.reports.searchLoaded,
  panelShow: state.reports.advancedSearchPanelShow,
  companies: state.createReport.companies,
  domains: state.createReport.domains,
});

const mapDispatchToProps = dispatch => ({
  loadReports: () => dispatch(loadReportsRequest()),
  searchClear: () => dispatch(searchClear()),
  showAdvancedSearchPanel: () => dispatch(showAdvancedSearchPanel()),
  hideAdvancedSearchPanel: () => dispatch(hideAdvancedSearchPanel()),
  loadCompaniesRequest: () => dispatch(loadCompaniesRequest()),
  loadDomainsRequest: () => dispatch(loadDomainsRequest()),
  advancedSearchRequest: requestData => dispatch(advancedSearchRequest(requestData)),
  saveSearchQuery: searchQuery => dispatch(saveSearchQuery(searchQuery)),
  showTrashBinRequest: () => dispatch(showTrashBinRequest()),
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportsSearch)));
