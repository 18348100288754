import React from 'react';
import {connect} from "react-redux";

import AddNewColumnsUploadFile from "./AddNewColumnsUploadFile";
import AddNewColumnsConfigure from "./AddNewColumnsConfigure";

export const STEPS = [
  {id: 'UploadFile', component: AddNewColumnsUploadFile},
  {id: 'configure-columns', component: AddNewColumnsConfigure}
];

const AddNewColumns = ({stepId, ...props}) => {
    const step = STEPS.find(s => s.id === stepId);
    const StepComponent = step.component;

    return <StepComponent {...props} />;
};

const mapStateToProps = state => ({
  stepId: state.createReport.editAddColumnsStepId
});

export default connect(mapStateToProps)(AddNewColumns);