import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {CircularProgress, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {changeLanguage, loadLanguagesRequest} from "../create-report-action-creators";
import {useTranslation} from "react-i18next";

const LanguageInput = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {languages, loading, language, company} = useSelector(state => ({
    languages: state.createReport.languages,
    loading: state.createReport.languagesLoading,
    language: state.createReport.language,
    company: state.createReport.company,
  }), shallowEqual);

  useEffect(() => {
    if (company) {
      dispatch(loadLanguagesRequest(company));
    }
  }, [dispatch, company]);

  const doChangeLanguage = e => {
    dispatch(changeLanguage(e.target.value));
  };

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      required
      value={language}
      disabled={loading}
      label= {t('language', "Language")}
      InputProps={{
        startAdornment: loading ? <InputAdornment position="start"><CircularProgress/></InputAdornment> : null
      }}
      onChange={doChangeLanguage}
    >
      {languages.length > 0 ? languages.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      )) : <MenuItem disabled>Please select a company first</MenuItem>}
    </TextField>
  );
};

export default LanguageInput;
