import React from 'react';
import TopWordsChart from "./top-words-chart";
import PrimaryEmotionsChart from "./containers/PrimaryEmotionsBreakdownChart";
import SentimentBreakdownChart from "./containers/SentimentBreakdownChart";
import Grid from "@material-ui/core/Grid";
import SecondaryBreakdownChart from "./containers/SecondaryBreakdownChart";

class ChartsContainer extends React.Component {
  render() {
    return (
      <Grid container direction="column" spacing={1}>
        <Grid item xs>
          <PrimaryEmotionsChart showFooter/>
        </Grid>
        <Grid item xs>
          <SentimentBreakdownChart/>
        </Grid>
        <Grid item xs>
          <TopWordsChart showFooter/>
        </Grid>
        <Grid item xs>
          <SecondaryBreakdownChart />
        </Grid>
      </Grid>
    );
  }
}

export default ChartsContainer;
