export const INPUT_CHANGE = "[LOGIN] INPUT CHANGE";
export const INPUT_ERROR = "[LOGIN] INPUT ERROR";
export const LOGIN_REQUEST = "[LOGIN] LOGIN REQUEST";
export const LOGIN_SUCCESS = "[LOGIN] LOGIN SUCCESS";
export const LOGIN_END = "[LOGIN] LOGIN_END";

export const LOGOUT_REQUEST = "[LOGOUT] LOGOUT REQUEST";
export const LOGOUT_SUCCESS = "[LOGOUT] LOGOUT SUCCESS";
export const FORCE_LOGOUT = "[LOGOUT] FORCE LOGOUT";

export const SAVE_LOCATION_BEFORE_LOGIN = "[USER] SAVE_LOCATION_BEFORE_LOGIN";
export const SAVE_SEARCH_QUERY = "[USER] SAVE_SEARCH_QUERY";

export const inputChange = ({field, value}) => ({type: INPUT_CHANGE, field, value});
export const inputError = ({field, error}) => ({type: INPUT_ERROR, field, error});
export const loginRequest = history => ({type: LOGIN_REQUEST, history});
export const loginSuccess = user => ({type: LOGIN_SUCCESS, user});
export const loginEnd = () => ({type: LOGIN_END});

export const logoutRequest = history => ({type: LOGOUT_REQUEST, history});
export const logoutSuccess = () => ({type: LOGOUT_SUCCESS});
export const forceLogout = history => ({type: FORCE_LOGOUT, history});

export const saveLocationBeforeLogin = location => ({type: SAVE_LOCATION_BEFORE_LOGIN, location});
export const saveSearchQuery = searchQuery => ({type: SAVE_SEARCH_QUERY, searchQuery});