import React from 'react';
import {useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Link, useHistory, useLocation} from "react-router-dom";
import {createQueryString, parseQueryString} from "../reports-functions";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {useTranslation} from "react-i18next";

const ReportsPagination = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const total = useSelector(state => state.reports.total);
  const limit = useSelector(state => state.reports.limit);
  const loading = useSelector(state => state.reports.searchLoading);
  const totalPages = Math.ceil(total / limit);
  const params = parseQueryString(location.search);
  const page = params.page ? Number(params.page) : 1;

  delete params.page;

  const paramsWithoutPage = '/?' + createQueryString(params);
  let prevLink = paramsWithoutPage;

  if (page > 2) {
    prevLink += '&page=' + (page - 1);
  }

  const nextLink = paramsWithoutPage + '&page=' + (page + 1);

  const doChangePage = newPage => {
    if (newPage === 1) {
      history.push(paramsWithoutPage);
    } else {
      history.push(paramsWithoutPage + '&page=' + newPage);
    }
  };

  return totalPages > 0 && (
    <Grid container spacing={1} alignItems="center" justify="flex-end" component="form">
      <Grid item>
        <IconButton
          disabled={page === 1 || loading}
          type="button"
          label={t('previous', 'Previous')}
          component={Link}
          to={prevLink}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Grid>
        <TextField
          select
          label={t('page', 'Page')}
          variant="outlined"
          fullWidth
          disabled={loading}
          value={page}
          onChange={e => doChangePage(Number(e.target.value))}
        >
          {[...Array(totalPages).keys()].map((_, i) => (
            ++i && <MenuItem key={i} value={i}>{i}</MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        / {totalPages > 0 && totalPages}
      </Grid>
      <Grid item>
        <IconButton
          disabled={page === totalPages || loading}
          type="button"
          label={t('next', 'Next')}
          component={Link}
          to={nextLink}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ReportsPagination;