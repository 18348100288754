import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import {changeCompany, loadCompaniesRequest} from "../create-report-action-creators";
import {CircularProgress, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const CompanyInput = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {companies, companiesLoading, company} = useSelector(state => ({
    companies: state.createReport.companies,
    companiesLoading: state.createReport.companiesLoading,
    company: state.createReport.company
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadCompaniesRequest())
  }, [dispatch]);

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      required
      value={company}
      disabled={companiesLoading}
      label={t('company', "Company")}
      InputProps={{
        startAdornment: companiesLoading ? <InputAdornment position="start"><CircularProgress/></InputAdornment> : null
      }}
      onChange={e => dispatch(changeCompany(e.target.value))}
    >
      {companies.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CompanyInput;