import {put, select, takeEvery} from "redux-saga/effects";

import reportApi from '../../API-report';
import {
  PRIMARY_EMOTIONS_FULL_REPORT_REQUEST, getPrimaryEmotionsFullReportFailure,
  getPrimaryEmotionsFullReportSuccess
} from "./full-report-charts-actions";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";

function* getPrimaryEmotionsFullReport() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const filters = yield select(state => state.filters.selectedFilters);
    const operator = yield select(state => state.filters.operator);

    const response = yield reportApi.getEmoWordsListsByPrimaryEmotions(reportId, filters, operator);

    yield put(getPrimaryEmotionsFullReportSuccess(response.data['emo_words_lists_grouped_by_primaries']));

  } catch (e) {
    console.error('ERROR IN GET_PRIMARY_EMOTIONS_FULL_REPORT', e);

    let message = i18n.t("error_load_primary_emo_repo",'Full report for primary emotions chart could not be loaded. Try to reload the page.');

    if (e.response) {
      message += ' Message: ' + e.response.data;
    }

    yield put(addNotification({status: 'Error', message: [message]}));

    yield put(getPrimaryEmotionsFullReportFailure());
  }
}

export default [
  takeEvery(PRIMARY_EMOTIONS_FULL_REPORT_REQUEST, getPrimaryEmotionsFullReport),
];