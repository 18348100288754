export const PASSWORD_RESET_SUBMIT_REQUEST = '[PASSWORD_RESET] PASSWORD_RESET_SUBMIT_REQUEST';
export const PASSWORD_RESET_SUBMIT_SUCCESS = '[PASSWORD_RESET] PASSWORD_RESET_SUBMIT_SUCCESS';
export const PASSWORD_RESET_SUBMIT_ERROR = '[PASSWORD_RESET] PASSWORD_RESET_SUBMIT_ERROR';

export const PASSWORD_RESET_CHECK_REQUEST = '[PASSWORD_RESET] PASSWORD_RESET_CHECK_REQUEST';
export const PASSWORD_RESET_CHECK_SUCCESS = '[PASSWORD_RESET] PASSWORD_RESET_CHECK_SUCCESS';
export const PASSWORD_RESET_CHECK_ERROR = '[PASSWORD_RESET] PASSWORD_RESET_CHECK_ERROR';

export const PASSWORD_RESET_REQUEST = '[PASSWORD_RESET] PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = '[PASSWORD_RESET] PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = '[PASSWORD_RESET] PASSWORD_RESET_ERROR';

export const passwordResetSubmitRequest = email => ({type: PASSWORD_RESET_SUBMIT_REQUEST, email});
export const passwordResetSubmitSuccess = message => ({type: PASSWORD_RESET_SUBMIT_SUCCESS, message});
export const passwordResetSubmitError = error => ({type: PASSWORD_RESET_SUBMIT_ERROR, error});

export const passwordResetCheckRequest = token => ({type: PASSWORD_RESET_CHECK_REQUEST, token});
export const passwordResetCheckSuccess = () => ({type: PASSWORD_RESET_CHECK_SUCCESS});
export const passwordResetCheckError = error => ({type: PASSWORD_RESET_CHECK_ERROR, error});

export const passwordResetRequest = (token, newPassword, history) => ({type: PASSWORD_RESET_REQUEST, token, newPassword, history});
export const passwordResetSuccess = () => ({type: PASSWORD_RESET_SUCCESS});
export const passwordResetError = error => ({type: PASSWORD_RESET_ERROR, error});
