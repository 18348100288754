import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import {makeStyles, TextField, IconButton} from "@material-ui/core";
import {setDeleteTaxonomyDialogOpen, setNewTaxonomyDialogOpen} from "../taxonomy-actions";
import NewTaxonomyDialog from "./NewTaxonomyDialog";
import DeleteTaxonomyDialog from "./DeleteTaxonomyDialog";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {useDispatch} from "react-redux";

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
  itemTitle: {
    flex: '1 1 100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
}))

const TargetTaxonomySelector = ({targetLanguage, targetTaxonomy, setTargetTaxonomy, targetTaxonomies, targetTaxonomiesInfo, targetTaxonomiesLoading}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newTaxonomy, setNewTaxonomy] = useState('');
  const [taxonomyToDelete, setTaxonomyToDelete] = useState('');

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '' && !targetTaxonomies.includes(params.inputValue)) {
      filtered.push({
        inputValue: params.inputValue,
        title: t('create_some_taxonomy', 'Create "{{name}}" taxonomy', {name: params.inputValue}),
      });
    }

    return filtered;
  };

  const onTaxonomyChange = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setNewTaxonomy(newValue.inputValue);
      dispatch(setNewTaxonomyDialogOpen(true));
      return;
    }

    setTargetTaxonomy(newValue);
  };

  const showDeleteDialog = (event, taxonomyName) => {
    event.stopPropagation();
    setTaxonomyToDelete(taxonomyName);
    dispatch(setDeleteTaxonomyDialogOpen(true));
  };

  const renderOption = option => {
    if (option.inputValue) {
      return <><AddIcon fontSize="small"/> {option.title}</>;
    }

    return (
      <div className={classes.itemContainer}>
        <div className={classes.itemTitle}>
          {option} ({targetTaxonomiesInfo[option]['rows_count']})
        </div>
        <div>
          <IconButton size="small" onClick={e => showDeleteDialog(e, option)}>
            <DeleteForeverIcon fontSize="small"/>
          </IconButton>
        </div>
      </div>
    );
  };

  const getOptionLabel = (option) => {
    if (option.inputValue) {
      return option.title;
    }

    return option;
  };

  return (
    <>
      <Autocomplete
        value={targetTaxonomy || null}
        options={targetTaxonomies}
        disabled={targetTaxonomiesLoading}
        onChange={onTaxonomyChange}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={params => (
          <TextField
            required
            {...params}
            variant="outlined"
            label={t('select_target_taxonomy', 'Select target taxonomy')}
            fullWidth
          />
        )}
      />
      <NewTaxonomyDialog
        language={targetLanguage}
        value={newTaxonomy}
        setValue={setNewTaxonomy}
        isTargetTaxonomy
      />
      <DeleteTaxonomyDialog
        taxonomyName={taxonomyToDelete}
      />
    </>
  );
};

export default TargetTaxonomySelector;