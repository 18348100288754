import React from 'react';
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: `${theme.spacing(2)}px auto`
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(2)
  },
  square: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

const ChartIndex = ({indexData}) => {
  if (!indexData) return null;
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>

      {indexData.map(d => (
        <Grid item key={d.name} className={classes.item}>
          <div className={classes.square} style={{backgroundColor: d.color}}/>
          <div className={classes.text}>{d.name}</div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChartIndex;