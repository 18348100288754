import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import UiModal from "../../../components/UiModal/UiModal";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {addWordsRequest, setAddWordsDialogOpen} from "../taxonomy-actions";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

const AddWordsDialog = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {language, valueSystem, selectedTaxonomies, isOpen, loading} = useSelector(state => ({
    language: state.taxonomy.language,
    valueSystem: state.taxonomy.valueSystem,
    selectedTaxonomies: state.taxonomy.selectedTaxonomies,
    isOpen: state.taxonomy.addWordsDialogOpen,
    loading: state.taxonomy.addWordsLoading,
  }), shallowEqual);

  const [taxonomyName, setTaxonomyName] = useState('');
  const [words, setWords] = useState([]);

  useEffect(() => {
    if (selectedTaxonomies.length === 1) {
      setTaxonomyName(selectedTaxonomies[0]);
    }
  }, [selectedTaxonomies]);

  const onClose = () => {
    dispatch(setAddWordsDialogOpen(false));
  };

  const onSubmit = event => {
    event.preventDefault();
    dispatch(addWordsRequest(taxonomyName, words));
  };

  const canAdd = taxonomyName && words.length > 0;

  return (
    <UiModal
      title={t('add_words', 'Add words')}
      open={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                defaultValue={valueSystem}
                label={t('value_system', 'Value System')}
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                defaultValue={language}
                label={t('language', 'Language')}
              />
            </Grid>
            <Grid item xs>
              <TextField
                required
                variant="outlined"
                fullWidth
                select
                value={taxonomyName}
                onChange={e => setTaxonomyName(e.target.value)}
                label={t('taxonomy', 'Taxonomy')}
              >
                {selectedTaxonomies.map(t => (
                  <MenuItem key={t} value={t}>{t}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <Autocomplete
                required
                multiple
                freeSolo
                value={words}
                options={[]}
                onChange={(_, newValue) => setWords(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" size="small" label={option} {...getTagProps({index})} />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    label={t('new_words', 'New words')}
                    helperText={t('new_words_help', 'Press Enter to add new word to the list')}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      required: words.length === 0
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonWithProgress
            type="submit"
            color="primary"
            loading={loading}
            disabled={loading || !canAdd}
          >
            {t('add_words', 'Add words')}
          </ButtonWithProgress>
          <Button onClick={onClose}>{t('cancel', 'Cancel')}</Button>
        </DialogActions>
      </form>
    </UiModal>
  );
};

export default AddWordsDialog;