import React from 'react';
import {Grid, IconButton, MenuItem, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

const TaxonomyDisplay = (
  {
    taxonomy,
    allTaxonomies,
    columnIndex,
    removeTaxonomy,
    chooseTaxonomy
  }
) => {
  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item>
        <TextField
          select
          variant="outlined"
          value={taxonomy.value}
          onChange={e => chooseTaxonomy({columnType: e.target.value, columnIndex})}
          style={{width: '300px'}}
        >
          {allTaxonomies.map(t => (
            <MenuItem key={t['taxonomy_name']} value={t['taxonomy_name']}>{t['taxonomy_name']} ({t['rows_count']})</MenuItem>
          ))}
        </TextField>
      </Grid>

      {columnIndex !== 0 && (
        <Grid item>
          <IconButton aria-label="delete" onClick={removeTaxonomy}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
};

export default TaxonomyDisplay;
