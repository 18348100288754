import React from "react";
import Grid from "@material-ui/core/Grid";

const ColumnsContainer = ({children}) => (
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-start"
    wrap="nowrap"
    spacing={2}
    style={{overflowX: 'auto'}}
  >
    {children}
  </Grid>
);

export default ColumnsContainer;