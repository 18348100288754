import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {MenuItem, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {selectLabelColor} from "./select-label-action-creators";

const colors = [
  {value: "", color: "#d4d4d4", label: ['no_color_selected', 'No color selected']},
  {value: "yellow", color: "#fcff46", label: ['yellow', 'Yellow']},
  {value: "red", color: "#ff3b31", label: ['red', 'Red']},
  {value: "green", color: "#00a900", label: ['green', 'Green']},
];

const useStyles = makeStyles({
  circle: {
    borderRadius: '50%',
    width: '18px',
    height: '18px',
    display: 'inline-block',
    marginRight: '10px',
    verticalAlign: 'middle',
  }
});

const SelectLabel = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  const selectedLabelColor = useSelector(state => state.selectLabel.selectedLabelColor);
  const dispatch = useDispatch();

  return (
    <TextField
      fullWidth
      select
      onChange={e => dispatch(selectLabelColor(e.target.value))}
      label={t('select_label_color', 'Select label color')}
      variant="outlined"
      SelectProps={{displayEmpty: true}}
      InputLabelProps={{
        shrink: true,
      }}
      value={selectedLabelColor}
    >
      {colors.map(color => (
        <MenuItem
          key={color.value}
          value={color.value}
        >
          <div className={classes.circle} style={{background: color.color}}/>
          {t(color.label[0], color.label[1])}
        </MenuItem>
      ))}
    </TextField>
  )
};

export default SelectLabel;