import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import UiModal from "../../../components/UiModal/UiModal";
import {addTopicsRequest, fetchTopicNamespacesRequest, setTopicsModalOpen} from "../report-actions";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography
} from "@material-ui/core";
import LabelIcon from '@material-ui/icons/Label';
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";

const TopicsModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {namespacesLoading, namespaces, isOpen, addLoading} = useSelector(state => ({
    namespacesLoading: state.report.topicsNamespacesLoading,
    namespaces: state.report.topicsNamespaces,
    isOpen: state.report.topicsModalOpen,
    addLoading: state.report.topicsAddLoading,
  }), shallowEqual);

  const [selectedNamespace, setSelectedNamespace] = useState('');
  const currentNamespace = useMemo(() => {
    return namespaces.find(n => n.namespace === selectedNamespace);
  }, [namespaces, selectedNamespace]);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchTopicNamespacesRequest());
    }
  }, [dispatch, isOpen]);

  const close = () => dispatch(setTopicsModalOpen(false));
  const apply = () => dispatch(addTopicsRequest(selectedNamespace));

  return (
    <UiModal
      title={t('add_topics', 'Add Topics')}
      open={isOpen}
      onClose={close}
    >
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {namespacesLoading ? <CircularProgress/> : (
              <TextField
                select
                fullWidth
                variant="outlined"
                label={t('select_namespace', "Select namespace")}
                value={selectedNamespace}
                onChange={e => setSelectedNamespace(e.target.value)}
              >
                {namespaces.map(namespace => (
                  <MenuItem key={namespace['namespace']} value={namespace['namespace']}>{namespace['namespace']}</MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          {currentNamespace ? (
            <>
              <Grid item>
                <Typography variant="h6">{t('select_namespace_topics', 'Selected namespace topics')}:</Typography>
                <List dense>
                  {currentNamespace.topics.map(topic => (
                    <ListItem key={topic}>
                      <ListItemIcon>
                        <LabelIcon/>
                      </ListItemIcon>
                      <ListItemText>{topic}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Alert severity="info">
                {t('select_a_namespace_to_view_topics', 'Select a namespace to view topics in it')}
              </Alert>
            </Grid>
          )}

          {selectedNamespace && (
            <Grid item>
              <Alert severity="warning">
                {t('warning_report_will_be_locked', 'Warning! Report will be locked until the procedure is completed.')}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('cancel', 'Cancel')}</Button>
        <ButtonWithProgress
          onClick={apply}
          color="primary"
          disabled={!selectedNamespace || addLoading}
          loading={addLoading}
        >
          {t('apply_topics', 'Apply topics')}
        </ButtonWithProgress>
      </DialogActions>
    </UiModal>
  );
};

export default TopicsModal;