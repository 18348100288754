import React, {Component, Fragment, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Box, Typography, Grid} from "@material-ui/core";

import UploadFile from "../../../components/UploadFile/UploadFile";
import ReportEditBottom from "../components/ReportEditBottom";

import {addNotification} from "../../notifications/notifications-actions";
import {editAddColumnsUploadFileRequest} from "../create-report-action-creators";
import {useTranslation} from "react-i18next";

const POSSIBLE_EXTENSIONS = ['csv', 'json'];

const AddNewColumnsUploadFile = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.createReport.editAddColumnsUploadFileLoading);

  const [file, setFile] = useState(null);

  return (
    <>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('upload_new_file_to_add_columns', 'Upload new file to add columns')}</Typography>
          </Grid>
          <Grid item xs>
            <UploadFile
              extensions={POSSIBLE_EXTENSIONS}
              onFileReady={file => setFile(file)}
              addNotification={notification => dispatch(addNotification(notification))}
              label={t('select_a_file', 'Select a file')}
              helperText={t('add_new_columns_upload_help', 'File should contain same amount of rows. Any new column will be added, any column with the same name will be ignored.')}
            />
          </Grid>
        </Grid>
      </Box>
      <ReportEditBottom
        submitText={t('upload_and_next', 'Upload & Next')}
        warningText={t('add_new_columns_upload_warning', "After you upload a new file, you'll need to configure columns.\nAfter that, report will be recalculated and you'll need to wait until it's over.")}
        enabled={file && !loading}
        loading={loading}
        submit={() => dispatch(editAddColumnsUploadFileRequest(file))}
      />
    </>
  )
}

export default AddNewColumnsUploadFile;