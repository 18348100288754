import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import HxIndexArcChart from "./hx-index-arc-chart";
import Stats from "./stats";

import {loadHxIndexRequest, subscribeToFilters, unsubscribeFromFilters} from "../filters-actions";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0`,
    paddingBottom: 0,
    height: 220,
    position: "relative"
  },
  loading: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.2)'
  },
  strong: {
    fontWeight: 400
  }
}));

const HxIndex = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {loaded, hxData, hxDataLoading, hxDataError, reportId} = useSelector(state => ({
    loaded: state.filters.loaded,
    hxData: state.filters.hxData,
    hxDataError: state.filters.hxDataError,
    reportId: state.report.reportId,
  }), shallowEqual);

  const onFiltersChange = useCallback(() => {
    dispatch(loadHxIndexRequest());
  }, []);

  useEffect(() => {
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    };
  }, [dispatch]);

  useEffect(() => {
    if (loaded) {
      onFiltersChange();
    }
  }, [loaded]);

  let hxIndexComponent = null;

  if (hxDataError) {
    hxIndexComponent = <Grid item>{t("HX_data_not_available", "Not available:")} {hxDataError}</Grid>;
  } else if (hxData) {
    const hxIndex = parseFloat(hxData['results']);

    if (isNaN(hxIndex)) {
      hxIndexComponent = <Grid item>{t("HX_data_review_filters", "Not enough data. Please review filters selection")}</Grid>;
    } else {
      hxIndexComponent = (
        <Grid item xs>
          <HxIndexArcChart hxIndex={hxIndex}/>
          <Stats hxData={hxData} reportId={reportId}/>
        </Grid>
      );
    }
  }

  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.container}>
      {hxDataLoading && (
        <Grid container className={classes.loading} alignItems="center" justify="center">
          <Grid item><CircularProgress/></Grid>
        </Grid>
      )}
      {hxIndexComponent}
    </Grid>
  )
};

export default HxIndex;
