
export const LOAD_RESPONSES_REQUEST = "[RESPONSES] LOAD RESPONSES REQUEST";
export const LOAD_RESPONSES_SUCCESS = "[RESPONSES] LOAD RESPONSES SUCCESS";
export const LOAD_RESPONSES_ERROR = "[RESPONSES] LOAD RESPONSES ERROR";

export const CHANGE_PAGE_REQUEST = "[RESPONSES] CHANGE_PAGE_REQUEST";
export const CHANGE_PAGE_SUCCESS = "[RESPONSES] CHANGE PAGE SUCCESS";

export const CHANGE_TAB = '[RESPONSES] CHANGE_TAB';

export const RESET = '[RESPONSES] RESET';