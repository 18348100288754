import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {Box, Divider, Grid} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";

const ReportEditBottom = ({enabled, loading, submit, submitText, warningText, disableWarning, warningSeverity}) => {
  const {t} = useTranslation();
  warningText = warningText || t('report_recalculation_warning', 'This operation will lead to report recalculation. You will need to wait until it\'s over.');

  warningSeverity = warningSeverity || t('warning', 'warning');

  return (
    <Fragment>
      <Divider/>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          {enabled && !disableWarning && (
            <Grid item xs>
              <Alert severity={warningSeverity}>{warningText}</Alert>
            </Grid>
          )}

          <Grid item xs>
            <ButtonWithProgress
              color="primary"
              variant="contained"
              onClick={submit}
              loading={loading}
              disabled={!enabled || loading}
            >
              {submitText}
            </ButtonWithProgress>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ReportEditBottom;
