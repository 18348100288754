import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import UiModal from "../../../components/UiModal/UiModal";
import {
  Grid,
  DialogActions,
  DialogContent,
  TextField,
  MenuItem,
  Button,
  CircularProgress
} from "@material-ui/core";
import {createFiltersFromNotesRequest, hideCreateFiltersFromNotesModal} from "./notes-actions";
import {connect} from "react-redux";
import Alert from "@material-ui/lab/Alert";

const CreateFiltersFromNotesModal = ({modalShown, hideModal, tabs, loading, createFiltersRequest, reportInfo}) => {
  const {t} = useTranslation();
  const [tabName, setTabName] = useState('');
  const [threshold, setThreshold] = useState(1);
  const protectedTabs = reportInfo['protected_note_tabs'] || ['Emotions'];

  const onSubmit = e => {
    e.preventDefault();
    createFiltersRequest(tabName, threshold);
  };

  tabs = tabs.filter(t => !protectedTabs.includes(t));

  let form = (
    <Alert severity="warning">
      <Trans i18nKey="no_notes_added_to_report">
        No notes were added to this report. <br/> Please add notes to be able to build filters from them!
      </Trans>
    </Alert>
  );

  const setThresholdNumber = e => {
    let value = e.target.value;

    if (!value) {
      setThreshold('');
    } else {
      setThreshold(Number(value));
    }
  };

  if (tabs.length > 0) {
    form = (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            select
            required
            fullWidth
            variant="outlined"
            label={t('tab_name', "Tab name")}
            value={tabName}
            onChange={e => setTabName(e.target.value)}
          >
            {tabs.map(t => (
              <MenuItem key={t} value={t}>{t}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label={t('threshold', "Threshold")}
            helperText={t('threshold_help', 'All notes with note title that appears in report less than threshold times will be combined under "_others" filter')}
            InputProps={{
              required: true
            }}
            required
            type="number"
            value={threshold}
            onChange={setThresholdNumber}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <UiModal open={modalShown} onClose={hideModal} title={t('create_filters_for_notes', "Create filters for notes")}>
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          {form}
        </DialogContent>
        <DialogActions>
          <Button type="button" color="default" onClick={hideModal}>{t('cancel', 'Cancel')}</Button>
          <Button type="submit" color="primary" disabled={!tabs.length || !tabName || loading}>
            {loading ? <CircularProgress color="inherit" size="25px"/> : t('create_filters', "Create filters")}
          </Button>
        </DialogActions>
      </form>
    </UiModal>
  );
};

const mapStateToProps = state => ({
  modalShown: state.notes.createFiltersModalShown,
  tabs: state.responses.tabs || [],
  loading: state.notes.createFiltersLoading,
  reportInfo: state.reportInfo.report,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideCreateFiltersFromNotesModal()),
  createFiltersRequest: (tabName, threshold) => dispatch(createFiltersFromNotesRequest(tabName, threshold))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFiltersFromNotesModal);