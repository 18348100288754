import {select, takeEvery} from 'redux-saga/effects';
import {
  ADD_WORDS_TO_BASKET, CLEAR_BASKET, DELETE_WORDS_SUCCESS, REMOVE_WORDS_FROM_BASKET, REPLACE_BASKET,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_VALUE_SYSTEM,
  SET_SELECTED_TAXONOMIES,
  SET_TAXONOMIES_ARE_SELECTED
} from "./taxonomy-actions";
import {savePersistentData} from "../../utilities/localStorageMiddleware";
import {
  TAXONOMY_ARE_SELECTED,
  TAXONOMY_LANGUAGE,
  TAXONOMY_SELECTED_TAXONOMIES,
  TAXONOMY_VALUE_SYSTEM, TAXONOMY_WORDS_IN_BASKET
} from "./taxonomy-constants";

function* persistValueSystem({valueSystem}) {
  savePersistentData(TAXONOMY_VALUE_SYSTEM, valueSystem);
}

function* persistLanguage({language}) {
  savePersistentData(TAXONOMY_LANGUAGE, language);
}

function* persistSelectedTaxonomies({taxonomies}) {
  savePersistentData(TAXONOMY_SELECTED_TAXONOMIES, taxonomies);
}

function* persistTaxonomiesAreSelected({areSelected}) {
  savePersistentData(TAXONOMY_ARE_SELECTED, areSelected);
}

function* persistWordsInBasket() {
  const wordsInBasket = yield select(state => state.taxonomy.wordsInBasket);
  savePersistentData(TAXONOMY_WORDS_IN_BASKET, wordsInBasket);
}


export default [
  takeEvery(SET_CURRENT_VALUE_SYSTEM, persistValueSystem),
  takeEvery(SET_CURRENT_LANGUAGE, persistLanguage),
  takeEvery(SET_SELECTED_TAXONOMIES, persistSelectedTaxonomies),
  takeEvery(SET_TAXONOMIES_ARE_SELECTED, persistTaxonomiesAreSelected),
  takeEvery(ADD_WORDS_TO_BASKET, persistWordsInBasket),
  takeEvery(REMOVE_WORDS_FROM_BASKET, persistWordsInBasket),
  takeEvery(CLEAR_BASKET, persistWordsInBasket),
  takeEvery(REPLACE_BASKET, persistWordsInBasket),
  takeEvery(DELETE_WORDS_SUCCESS, persistWordsInBasket),
];