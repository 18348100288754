import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import GeoPattern from "geopattern";

import {Link} from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Grid,
  IconButton,
  Link as MuiLink,
  Typography
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import EditIcon from "@material-ui/icons/Edit";
import SyncIcon from "@material-ui/icons/Sync";
import GetAppIcon from "@material-ui/icons/GetApp";
import ForwardIcon from "@material-ui/icons/Forward";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteReportRequest,
  fetchCsvFromReport,
  lockUnlockReportRequest,
  moveToTrashBinRequest,
  recalcRequest,
  restoreFromTrashBinRequest,
  selectReportForSnapshots,
  showReportInfoModal
} from "../reports-actions";
import {dataAppendShowModal} from "../../data-append/data-append-actions";

const MAX_DESCRIPTION_LENGTH = 200;
const MAX_DESCRIPTION_LINES = 4;

const useStyles = makeStyles(theme => ({
  cardContent: {
    maxHeight: '2.5rem',
    overflow: "hidden"
  },
  media: {
    position: 'relative',
    height: 0,
    paddingTop: '56.25%',
  },
  mediaChip: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

const getLink = ({report, status}) => (
  `/create-report?reportId=${report.report_id}&reportName=${encodeURIComponent(report.report_name)}&filename=No+name&status=${status ? status : report.status}`
);

const CardDescription = ({description, openReportInfoModal}) => {
  const {t} = useTranslation();
  const truncatedDescription = description.substring(0, MAX_DESCRIPTION_LENGTH);
  const splitDescription = truncatedDescription.split('\n');

  const more = <MuiLink onClick={openReportInfoModal} style={{cursor: 'pointer'}}>{t('more', 'More')}</MuiLink>

  if (description.length > MAX_DESCRIPTION_LENGTH) {
    return <React.Fragment>{truncatedDescription}... {more}</React.Fragment>;
  }

  if (splitDescription.length > MAX_DESCRIPTION_LINES) {
    const resultDescriptionText = splitDescription.slice(0, MAX_DESCRIPTION_LINES - 1).join('\n') + '...';
    return <React.Fragment>{resultDescriptionText}... {more}</React.Fragment>;
  }

  return description;
};

const ReportCard = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {targetIndex, report, progress, duplicate, parentId, goAction} = props;

  const reportId = report['report_id'];

  const askToDeleteReport = () => {
    if (window.confirm(t('do_you_really_want_to_delete_report', 'Do you really want to DELETE this report?'))) {
      dispatch(deleteReportRequest(reportId, parentId))
    }
  };

  const askToRecalculateReport = () => {
    if (window.confirm(t('do_you_really_want_to_recalculate_report', 'Do you really want to RECALCULATE this report?'))) {
      dispatch(recalcRequest({reportId, targetIndex}));
    }
  };

  const askToMoveToTrashBin = () => {
    if (window.confirm(t('do_you_really_want_to_archive_report', 'Do you really want to ARCHIVE this report?'))) {
      dispatch(moveToTrashBinRequest(reportId));
    }
  };

  const askToRestoreFromTrashBin = () => {
    if (window.confirm(t('do_you_really_want_to_restore_from_archive', 'Do you really want to RESTORE FROM ARCHIVE this report?'))) {
      dispatch(restoreFromTrashBinRequest(reportId));
    }
  };

  const selectSnapshotsReport = () => dispatch(selectReportForSnapshots(reportId));

  let footerActions;
  let menuActions = [];

  const reportIsFinished = report.status === 'finished' || report.status === 'finished_with_errors';

  const reportIsNotCalculatedYet = report.status === 'not_started' || report.status === 'configured';

  const deleteAction = {
    text: t('delete', 'Delete'),
    icon: <DeleteIcon />,
    onClick: askToDeleteReport,
    restrict: user['is_guest'] || report['report_locked']
  };

  const moveToTrashAction = {
    text: t('archive_report', 'Archive report'),
    icon: <ArchiveIcon/>,
    onClick: askToMoveToTrashBin,
    restrict: user['is_guest'] || report['report_locked'],
  };

  const restoreFromTrashAction = {
    text: t('restore_from_archive', 'Restore from archive'),
    icon: <UnarchiveIcon/>,
    onClick: askToRestoreFromTrashBin,
    restrict: user['is_guest'] || report['report_locked']
  };

  const lockUnlockAction = {
    text: report['report_locked'] ? t('unlock', 'Unlock') : t('lock', 'Lock'),
    icon: report['report_locked'] ? <LockOpenIcon/> : <LockIcon/>,
    onClick: () => dispatch(lockUnlockReportRequest(reportId)),
    restrict: user['is_guest']
  };

  const editAction = {
    text: t('edit', 'Edit'),
    icon: <EditIcon/>,
    component: Link,
    props: {
      to: `/report/${report.report_id}/edit`
    },
    restrict: user['is_guest'] || report['report_locked']
  };

  const recalculateAction = {
    text: t('recalculate', 'Recalculate'),
    icon: <SyncIcon/>,
    onClick: askToRecalculateReport,
    restrict: user['is_guest'] || report['report_locked']
  };

  const getDataAppendAction = {
    text: t('get_data_append', 'Get data append'),
    icon: <GetAppIcon/>,
    onClick: () => dispatch(dataAppendShowModal(report)),
    restrict: !user['is_superuser'] && report['restrict_users_to_free_features'],
  };

  const csvDownloadAction = {
    text: t('csv_download', 'CSV download'),
    icon: <GetAppIcon/>,
    onClick: () => dispatch(fetchCsvFromReport(reportId, report['report_name']))
  };

  const goToReportAction = {
    text: t('go_to_report', 'Go to report'),
    icon: <ForwardIcon/>,
    component: Link,
    props: {
      to: `/report/${report.report_id}`
    },
    onClick: goAction,
  };

  const finishConfigurationAction = {
    text: t('finish_configuration', 'Finish configuration'),
    icon: <SettingsIcon/>,
    component: Link,
    props: {
      to: getLink({report})
    }
  };

  const showInfoButton = (
    <IconButton
      variant="contained"
      onClick={() => dispatch(showReportInfoModal(report))}
    >
      <InfoOutlinedIcon />
    </IconButton>
  );

  const showSnapshotsAction = {
    text: t('manage_snapshots', 'Manage snapshots'),
    icon: <CameraAltIcon/>,
    onClick: selectSnapshotsReport
  };

  if (reportIsFinished) {
    menuActions = [
      editAction,
      recalculateAction,
      getDataAppendAction,
      csvDownloadAction,
      lockUnlockAction,
      goToReportAction,
      showSnapshotsAction,
    ];

    if (report.archived) {
      menuActions = [
        deleteAction,
        restoreFromTrashAction,
      ];
    } else {
      menuActions.push(moveToTrashAction);
    }

    if (duplicate) {
      menuActions = [
        getDataAppendAction,
        csvDownloadAction,
        goToReportAction,
        deleteAction,
      ];
    }

    footerActions = (
      <Fragment>
        <Grid item>
          {!report.archived && showInfoButton}
        </Grid>
        <Grid item>
          <Button
            onClick={goAction}
            component={Link}
            to={`/report/${report.report_id}`}
            color="primary"
          >
            {t('go', 'Go')} <ChevronRightIcon/>
          </Button>
        </Grid>
      </Fragment>
    );
  } else if (reportIsNotCalculatedYet) {
    menuActions = [
      finishConfigurationAction,
      deleteAction
    ];

    footerActions = (
      <Fragment>
        <Grid item>
          {showInfoButton}
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to={getLink({report})}
            color="primary"
          >
            {t('finish', 'Finish')} <ChevronRightIcon/>
          </Button>
        </Grid>
      </Fragment>
    );
  } else {
    const actualProgress = progress || 0;

    if (report.status === 'added_columns') {
      menuActions.push(editAction);
    }

    menuActions.push(deleteAction);

    footerActions = (
      <Fragment>
        <Grid item>
          {showInfoButton}
        </Grid>
        <Grid item xs style={{padding: '0 5px'}}>
          <div className="reports-progressbar">
            <div className="reports-progressbar-percent" style={{width: actualProgress + '%'}}/>
          </div>
        </Grid>
      </Fragment>
    );
  }

  let reportMenuIcon = report['report_locked'] ? <LockIcon/> : <MoreVertIcon/>;

  const dropdownMenu = (
    <DropdownMenu
      icon={reportMenuIcon}
      items={menuActions}
      loading={report['loading']}
      disabled={report['loading']}
    />
  );

  const avatar = report['logo'] ? (
    <Avatar aria-label="company" src={report['logo']} />
  ) : (
    <Avatar aria-label="company">{report.company[0].toUpperCase()}</Avatar>
  );

  let image = report['report_logo'];

  if (!image) {
    const pattern = GeoPattern.generate(report['report_id']);
    image = pattern.toDataUri();
  }

  const classes = useStyles();

  return (
    <Card style={{position: "relative", paddingBottom: '45px'}}>
      <CardHeader
        classes={{content: classes.cardContent}}
        avatar={avatar}
        title={report['report_name']}
        action={dropdownMenu}
      />

      <CardMedia
        className={classes.media}
        image={image}
      >
        {report.snapshots && report.snapshots.length > 0 && (
          <Chip
            clickable
            icon={<CameraAltIcon fontSize="small"/>}
            onClick={selectSnapshotsReport}
            className={classes.mediaChip}
            label={report.snapshots.length}
            color="primary"
          />
        )}
      </CardMedia>

      <CardContent>
        <Grid container direction="column" spacing={2}>
          {report.description && (
            <Grid item>
              <Typography variant="body2" color="textSecondary" component="pre">
                <CardDescription
                  description={report.description}
                  openReportInfoModal={() => dispatch(showReportInfoModal(report))}
                />
              </Typography>
            </Grid>
          )}

          {(report.tags && report.tags.length > 0) && (
            <Grid item>
              <Typography variant="body2" component="p">
                {report.tags.map(t => (
                  <Chip
                    clickable
                    key={t}
                    label={t}
                    component={Link}
                    style={{marginRight: '8px',marginTop:'5px',marginBottom:'5px'}}
                    to={'/?tags=' + t}
                  />
                ))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <Grid container direction="row" justify="space-between" alignItems="center" style={{position: 'absolute', bottom: 0}} component={Box} p={1}>
        {footerActions}
      </Grid>
    </Card>
  );
};

export default React.memo(ReportCard);