import React from 'react';
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";


const Autocomplete = ({value, onChange, label, helperText, addOnBlur, size, options, disableClearable, TextFieldInputProps, TextFieldProps, AutocompleteProps}) => {
  const doAddOnBlur = e => {
    const inputValue = e.target.value;

    if (inputValue === '') {
      return;
    }

    const words = new Set(value);
    words.add(inputValue);
    onChange(Array.from(words));
  };

  return (
    <MUIAutocomplete
      multiple
      value={value}
      size={size}
      options={options || []}
      onChange={(event, value) => onChange(value)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" size={size} label={option} {...getTagProps({ index })} />
        ))
      }
      disableClearable={disableClearable}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          helperText={helperText}
          fullWidth
          onBlur={addOnBlur ? doAddOnBlur : undefined}
          InputProps={{
            ...params.InputProps,
            ...TextFieldInputProps
          }}

          {...TextFieldProps}
        />
      )}
      {...AutocompleteProps}
    />
  );
};

export default Autocomplete;