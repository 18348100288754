import axios from "./axios-api";

const API = {
  login: ({ username, password }) => {
    const credentials = new FormData();
    credentials.append("username", username);
    credentials.append("password", password);
    return axios.post("/token-auth/", credentials);
  },

  loadReports: () => {
    return axios.post("/get_all_reports_info/", {})
  },

  uploadCSV: ({file, reportName, company}) => {
    const payload = new FormData();
    payload.append("file", file, file.name);
    payload.append("report_name", reportName);
    payload.append('sanitize', 1);
    payload.append('company', company);
    return axios.post("/upload_csv_to_hb2/", payload);
  },

  uploadCSVForCelery: ({file, reportName, company, valueSystem, domain, language, logoFile, description, reportId}) => {
    const payload = new FormData();

    payload.append("file", file, file.name);
    payload.append("report_name", reportName);
    payload.append('company', company);
    payload.append('value_system', valueSystem);
    payload.append('domain', domain);
    payload.append('language', language);
    payload.append('single_core_mode', '');

    reportId && payload.append('report_id', reportId);
    logoFile && payload.append('logo_file', logoFile);
    description && payload.append('description', description);

    return axios.post("/celery_upload_csv_to_hb2/", payload);
  },

  getValueSystemList: domain => {
    const body = {
      "selected_domain": domain
    };

    return axios.post('/get_value_systems_list/', body);
  },

  getDomains: () => {
    return axios.post('/show_domains_for_cur_user/', {});
  },

  configureReport: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };
    return axios.post("/configure_report/", body);
  },

  acceptColumns: ({ reportId, config }) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      config
    };

    return axios.post("/write_configuration/", body);
  },

  writeConfigToReport: ({reportId, config}) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      config
    };

    return axios.post('/write_config_to_report/', body);
  },

  showTaxonomy: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      },
      include_rows_count: true,
    };
    return axios.post("/show_taxonomy/", body)
  },

  setTaxonomy: ({ reportId, taxonomies }) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      taxonomy: taxonomies
    };
    return axios.post("/set_taxonomy/", body)
  },

  showNegators: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };
    return axios.post("/show_negator_set/", body)
  },

  setNegators: ({ reportId, negators }) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      negator_set: negators
    };
    return axios.post("/set_negator_set/", body)
  },

  showIgnoreWords: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };
    return axios.post("/show_ignore_words/", body)
  },

  setIgnoreWords: ({ reportId, ignoreWords }) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      ignore_words: ignoreWords
    };
    return axios.post("/set_ignore_words/", body)
  },

  calculate: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      },
      desired_pool_n: 8
    };
    return axios.post("/run_final_calculation/", body)
  },

  createPrecookedTaxonomy: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };

    return axios.post('/create_precooked_taxonomy/', body);
  },

  progressBarCalculation: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };

    return axios.post('/progress_bar_calculation/', body);
  },

  recalc: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };
    return axios.post("/recalc_pos_tagged/", body)
  },

  showCompaniesOfUser: () => {
    return axios.post("/show_companies_of_user/", {});
  },

  deleteReport: reportId => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/delete_report_hb2/', body);
  },

  moveReportToTrashBin: reportId => {
    const body = {report_id: {$oid: reportId}};

    return axios.post('/move_report_to_trashbin/', body);
  },

  showTrashBinForCurUser: () => {
    return axios.post('/show_trashbin_for_cur_user/');
  },

  restoreReportFromTrashBin: reportId => {
    const body = {report_id: {$oid: reportId}};

    return axios.post('/restore_report_from_trashbin/', body);
  },

  renameReport: ({reportId, reportName}) => {
    const body = {
      report_id: {$oid: reportId},
      new_report_name: reportName
    };

    return axios.post('/rename_report/', body);
  },

  editReportCompany: (reportId, newCompany) => {
    const body = {
      report_id: {$oid: reportId},
      new_company: newCompany
    };

    return axios.post('/move_report_between_companies/', body);
  },

  addOrReplaceRowsToReport: ({reportId, file, fileType, replaceExisting}) => {
    const payload = new FormData();
    payload.append("file", file, file.name);
    payload.append("report_id", JSON.stringify({$oid: reportId}));
    payload.append("file_type", fileType);
    payload.append('replace_existing', replaceExisting ? "True" : "") ;

    return axios.post("/add_or_replace_rows_to_report_hb2/", payload);
  },

  recalcConfigured: ({reportId, forceDataRecalculation}) => {
    const body = {
      report_id: {$oid: reportId}
    };

    if (forceDataRecalculation) {
      body.recalc_only_meta = 'force_recalc_data';
    }

    return axios.post('/recalc_configured/', body);
  },

  recalcPosTagged: reportId => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/recalc_pos_tagged/', body);
  },

  valueSystemToolkit: reportId => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/value_system_toolkit/', body);
  },

  getCSVFromReport: (reportId) => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/get_csv_from_report/', body, {
      headers: {
        'content-disposition': 'json', // need to trigger preflight request on localhost
      },
      responseType: 'blob'
    });
  },

  getTaxonomyByValueSystem: valueSystem => {
    const body = {value_system: valueSystem};

    return axios.post('/get_taxonomy_list/', body);
  },

  getValueSystem: valueSystem => {
    const body = {value_system_name: valueSystem};

    return axios.post('/get_value_system/', body);
  },

  loadWord: ({word, taxonomy}) => {
    const body = {
      term: word,
      taxonomy,
    };

    return axios.post('/get_taxonomy_row/', body);
  },

  saveWord: word => {
    const body = [word];

    return axios.post('/update_rows/', body);
  },

  deleteWord: word => {
    const body = [word];

    return axios.post('/delete_taxonomy_rows/', body);
  },

  getTags: () => {
    return axios.post('/tag_show/');
  },

  writeTags: (reportId, tags) => {
    const body = {
      report_id: {$oid: reportId},
      'tags_to_add': tags
    };

    return axios.post('/tag_write/', body);
  },

  reportAdvancedSearch: requestData => {
    const body = {
      name: '',
      has_the_words: requestData.hasTheWords.join(','),
      doesnt_have: requestData.doesntHaveTheWords.join(','),
      size_operator: requestData.sizeOperator,
      size_unit: requestData.sizeUnit,
      size_value: requestData.sizeValue,
      days_within: requestData.daysWithin,
      calendar: requestData.calendar,
      company: requestData.company,
      domain: requestData.domain,
      tags: requestData.tags.join(','),
      pagination: true,
      limit: requestData.limit,
      offset: requestData.offset,
    };

    return axios.post('/report_advanced_search/', body);
  },

  passwordResetSubmit: email => {
    const body = {email};

    return axios.post('/reset_password_submit/', body);
  },

  passwordResetCheck: token => {
    const body = {reset_token: token};

    return axios.post('/reset_password_check/', body);
  },

  passwordReset: (token, newPassword) => {
    const body = {
      reset_token: token,
      new_password: newPassword
    };

    return axios.post('/reset_password/', body);
  },

  showListOfUsers: () => {
    return axios.post('/show_list_of_users/');
  },

  showLoginInfoAboutUser: user => {
    return axios.post('/show_login_info_about_user/', {requested_user: user});
  },

  getRepliesForLiveChat: (numberOfMessages, date) => {
    const body = {};

    if (numberOfMessages) {
      body['show_last_n_messages'] = numberOfMessages;
    }

    if (date) {
      body['date'] = date;
    }

    return axios.post('/get_reply_live_chat/', body);
  },

  sendMessageToLiveChat: message => {
    const body = {message};

    return axios.post('/send_message_live_chat/', body);
  },

  getHelpArticles: () => {
    return axios.post('/get_help_articles/');
  },

  getWhitelabelInfo: domain => {
    const body = {domain};

    return axios.post('/get_whitelabel_info/', body);
  },

  prepopulateIgnoreWords: reportId => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/prepopulate_ignore_words/', body);
  },

  getPurchaseStatsCompanies: () => {
    return axios.get('/get_purchase_statistics/');
  },

  getPurchaseStats: company => {
    const body = {company};
    return axios.post('/get_purchase_statistics/', body);
  },

  getCompanyStatistics: company => {
    return axios.get('/get_company_statistics/?company=' + company);
  },

  getUserStatistics: username => {
    return axios.get('/get_user_statistics/?username=' + username)
  },

  getValueSystemListRev2: () => {
    return axios.get('/get_value_systems_list_rev_2/');
  },

  getLanguageList: () => {
    return axios.get('/get_language_list/');
  },

  getTaxonomyListRev2: (valueSystem, language) => {
    const body = {
      value_system: valueSystem,
      language,
      include_rows_count: true,
    };

    return axios.post('/get_taxonomy_list_rev_2/', body);
  },

  createTaxonomy: (taxonomyName, valueSystem, language) => {
    const body = {
      taxonomy_name: taxonomyName,
      value_system: valueSystem,
      language
    };

    return axios.post('/create_taxonomy/', body);
  },

  deleteTaxonomyRev2: taxonomyName => {
    const body = {taxonomy_name: taxonomyName};

    return axios.post('/delete_taxonomy_rev_2/', body);
  },

  createTaxonomyRows: (taxonomyName, words, originalWord) => {
    const body = {
      taxonomy_name: taxonomyName,
      list_of_terms_to_add: words
    };

    if (originalWord) {
      body.original_word = originalWord
    }

    return axios.post('/create_taxonomy_rows/', body);
  },

  getTaxonomyRows: (taxonomyNames, searchString, isRegexp) => {
    const body = {
      'list_of_taxonomy_names': taxonomyNames,
      'search_string': searchString,
      'regex': isRegexp,
    };

    return axios.post('/get_taxonomy_rows/', body);
  },

  updateTaxonomyRows: words => {
    const body = {
      'list_of_taxonomy_rows': words,
    };

    return axios.post('/update_taxonomy_rows/', body);
  },

  getTaxonomyTermSentencesRev2: term => {
    const body = {
      term_itself: term
    };

    return axios.post('/get_taxonomy_term_sentences_rev2/', body);
  },

  getSanitizeValue: coordinates => {
    const body = {coordinates};

    return axios.post('/get_sanitize_value/', body);
  },

  deleteTaxonomyRowsRev2: rows => {
    const body = {
      'list_of_taxonomy_rows': rows
    };

    return axios.post('/delete_taxonomy_rows_rev_2/', body);
  },

  showLanguageChoices: company => {
    return axios.get('/show_language_choices/?company=' + company);
  },

  getHbArticles: () => {
    return axios.get('/hb_articles/');
  },

  getSingleHbArticleById: id => {
    return axios.get('/hb_articles/?hb_article_id=' + id);
  },

  getSingleHbArticleByName: name => {
    return axios.get('/hb_articles/?hb_article_name=' + name);
  },

  createHbArticle: (name, title) => {
    const body = {
      'hb_article_name': name,
      'hb_article_title': title
    };

    return axios.post('/hb_articles/', body);
  },

  deleteHbArticle: id => {
    const body = {'_id': id};
    return axios.delete('/hb_articles/', {data: body});
  },

  editHbArticle: data => {
    const body = {
      "_id": data._id,
      "name": data.name,
      "title": data.title,
      "body": data.body,
      "tags": data.tags,
    };

    return axios.put('/hb_articles/', body);
  },

  getHbArticleImages: (searchString, tag) => {
    const params = new URLSearchParams();

    if (searchString) {
      params.append('search_string', searchString);
    }

    if (tag) {
      params.append('tag', tag);
    }

    let url = '/hb_articles/images/';

    return axios.get('/hb_articles/images/?' + params.toString());
  },

  getHbArticleImagesTags: () => {
    return axios.get('/hb_articles/images/get_hb_images_tags/');
  },

  createHbArticleImage: imageData => {
    const body = new FormData();

    body.append('hb_image_name', imageData.name);

    const tagsLength = imageData.tags ? imageData.tags.length : 0;
    for (let i = 0; i < tagsLength; i++) {
      body.append(`tags`, imageData.tags[i]);
    }

    body.append('file', imageData.file);

    return axios.post('/hb_articles/images/', body);
  },

  getHbArticlesWithImage: id => {
    return axios.get('/hb_articles/images/get_articles_with_image/?hb_image_id=' + id);
  },

  deleteHbArticleImage: id => {
    const body = {'_id': id};

    return axios.delete('/hb_articles/images/', {data: body});
  },
};

export default API;
