import {put, select, debounce} from 'redux-saga/effects';
import {
  LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST,
  loadPrimarySecondarySentimentError,
  loadPrimarySecondarySentimentSuccess
} from "./charts-action-creators";
import API from "../../API-report";
import i18n from "../../i18n";

export function* loadPrimarySecondarySentiment() {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  try {
    const response = yield API.primarySecondarySentimentDistribution(reportId, filters, operator);

    const primaryData = response.data.results['primary_distribution'];
    const secondaryData = response.data.results['secondary_distribution'];

    const primary = Object.keys(primaryData).map(primaryKey => {
      return {
        primary: primaryKey,
        modes: primaryData[primaryKey],
        secondaries: secondaryData[primaryKey]
      }
    });

    const sentimentData = response.data.results['sentiment_distribution'];

    const sentiment = Object.keys(sentimentData).map(sentimentKey => ({
      sentiment: sentimentKey,
      modes: sentimentData[sentimentKey]
    })).reverse();

    yield put(loadPrimarySecondarySentimentSuccess(primary, sentiment));

  } catch(error) {
    console.log(error, 'ERROR FROM LOAD PRIMARY EMOTIONS BREAKDOWN REQUEST');
    yield put(loadPrimarySecondarySentimentError(i18n.t('something_went_wrong', 'Something went wrong')));
  }
}

export default [
  debounce(100, LOAD_PRIMARY_SECONDARY_SENTIMENT_REQUEST, loadPrimarySecondarySentiment),
];