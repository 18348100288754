import React, {Component} from 'react';
import ReportInfoComponent from "./report-info-component";
import {connect} from "react-redux";

class ReportInfo extends Component {
  render() {
    return (
      <ReportInfoComponent
        report={this.props.report}
        loading={this.props.loading}
      />
    );
  }
}

const mapStateToProps = state => ({
  report: state.reportInfo.report,
  loading: state.reportInfo.loading
});

export default connect(mapStateToProps)(ReportInfo);
