import i18n from "../../i18n";

export const TOP_WORDS_CHART_MODE_PIE = 'PIE';
export const TOP_WORDS_CHART_MODE_BAR = 'BAR';
export const TOP_WORDS_CHART_MODE_CLOUD = 'CLOUD';

export const BREAKDOWN_CHART_MODE_NORMAL = 'normal';
export const BREAKDOWN_CHART_MODE_BINARY = 'binary';

export const BREAKDOWN_CHART_TYPE_DOUGHNUT = 'doughnut';
export const BREAKDOWN_CHART_TYPE_BAR = 'bar';

export const OTHER_EMOTIONAL_WORDS = 'other emotional words';

export const CHART_TABLE_TITLES = {
  'normal': {
    'count': ['title_count', 'Count'],
    'distribution': ['title_distribution', 'Distribution'],
    'per_response': ['title_per_response', 'Per response'],
    'power_index': ['power_index', 'Power Index'],
    'pi_adjusted': ['power_index_adjusted', 'PI Adjusted']
  },
  'binary': {
    'count': ['title_count_binary', 'Count (binary]'],
    'distribution': ['title_distribution_binary', 'Distribution (binary]'],
    'per_response': ['title_per_response_binary', 'Per response (binary]'],
    'power_index': ['power_index_binary', 'Power Index (binary]'],
    'pi_adjusted': ['power_index_adjusted_binary', 'PI Adjusted (binary]']
  }
};

export const DISTRIBUTION_HELP_TEXT = ['distribution_help_text', 'Percentage distribution of primary emotion. Calculated by dividing the count of the primary emotion to the total sum of all counts.'];
export const PER_RESPONSE_HELP_TEXT = ['per_response_help_text', 'Percentage of responses that include primary emotion. Calculated by dividing the count of the primary emotion by the total number of responses.'];

export const DISTRIBUTION_HELP_TEXT_SENTIMENT = ['distribution_help_text_sentiment', 'Percentage distribution of sentiment. Calculated by dividing the count of the sentiment to the total sum of all counts.'];
export const PER_RESPONSE_HELP_TEXT_SENTIMENT = ['per_response_help_text_sentiment', 'Percentage of responses that include sentiment. Calculated by dividing the count of the sentiment by the total number of responses.'];