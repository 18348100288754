import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import PaperWithPadding from "../../../components/PaperWithPadding/PaperWithPadding";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  removeWordsFromBasket,
  resetEmotionsForAllWords,
  resetEmotionsForWord,
  saveRequest,
  setDeleteWordsDialogOpen,
  setExamplesDialogOpen,
  setTermForExamples,
  setWordsToDelete,
  toggleAllEmotionsForWord,
  toggleEmotion,
  valueSystemInfoRequest
} from "../taxonomy-actions";
import {Button, Checkbox, CircularProgress, FormControlLabel, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CommentIcon from '@material-ui/icons/Comment';
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddEmotion from "./AddEmotion";
import TermCell from "./TermCell";
import EmotionColumnCell from "./EmotionColumnCell";
import {darken, fade, lighten} from '@material-ui/core/styles/colorManipulator';

import RemoveIcon from '@material-ui/icons/Remove';
import {Alert} from "@material-ui/lab";
import {CONTEXTS, HEADER_ROW_HEIGHT, TABLE_ROW_HEIGHT} from "../taxonomy-constants";

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const Inactive = () => {
  const {t} = useTranslation();

  return (
    <PaperWithPadding>
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <Typography variant="body2">{t('select_words_to_start_editing', 'Please select words to start editing')}</Typography>
        </Grid>
      </Grid>
    </PaperWithPadding>
  );
};

const useStyles = makeStyles(theme => {
  const tableBorder = `1px solid
    ${
    theme.palette.type === 'light'
      ? lighten(fade(theme.palette.divider, 1), 0.88)
      : darken(fade(theme.palette.divider, 1), 0.68)
  }`;

  return ({
    row: {
      height: TABLE_ROW_HEIGHT,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    actions: {
      '& td': {
        whiteSpace: 'nowrap'
      }
    },
    headerRow: {
      borderTop: tableBorder,
      '& th': {
        height: HEADER_ROW_HEIGHT,
      },
    },
    tableActions: {
      marginBottom: theme.spacing(1)
    },
    emotionsBackgroundTable: {
      width: '100%',
      position: 'absolute',
    },
    emotionsRow: {
      height: TABLE_ROW_HEIGHT
    },
    emotionCell: {
      position: 'relative',
      overflowX: 'visible',
    },
    emotionCellChanged: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  });
});

const WordTable = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const valueSystemInfo = useSelector(state => state.taxonomy.valueSystemInfo);
  const valueSystemInfoLoading = useSelector(state => state.taxonomy.valueSystemInfoLoading)
  const wordsInBasket = useSelector(state => state.taxonomy.wordsInBasket);
  const emotionColumns = useSelector(state => state.taxonomy.emotionColumns);
  const saveLoading = useSelector(state => state.taxonomy.saveLoading);

  const [showTranslation, setShowTranslation] = useState(false);

  useEffect(() => {
    dispatch(valueSystemInfoRequest());
  }, [dispatch]);

  if (valueSystemInfoLoading) return <CircularProgress/>;

  if (!valueSystemInfo) return <div>{t('error_loading_value_system_info', 'Error loading value system info. Please reload the page.')}</div>;

  if (wordsInBasket.length === 0) return <Inactive/>;

  const wordsColumn = [];
  const backgroundColumn = [];
  const wordsEmotionsColumns = [];
  const actionsColumn = [];

  wordsInBasket.forEach((w, i) => {
    const id = w._id ? w._id.$oid : w.tempId;

    wordsColumn.push(
      <TableRow key={id} className={classes.row}>
        <TermCell {...w} showTranslationInfo={showTranslation}/>
      </TableRow>
    );

    backgroundColumn.push(
      <TableRow key={id} className={classes.row}>
        <TableCell/>
      </TableRow>
    );

    if (emotionColumns.length > 0) {
      const emotionColCells = [];

      emotionColumns.forEach((col, i) => {
        const contextInfo = CONTEXTS[col.context];
        const isChecked = w.contexts[col.context].includes(col.code);
        const isOriginallyChecked = w.originalContexts[col.context].includes(col.code);

        const cellStyle = {
          borderBottomColor: contextInfo.borderColor,
          backgroundColor: CONTEXTS[col.context].bgColors[i % 2]
        };

        emotionColCells.push(
          <TableCell
            key={col.code + col.context}
            align="center"
            className={classes.emotionCell}
            style={cellStyle}
          >
            {isChecked !== isOriginallyChecked && (
              <PriorityHighIcon fontSize="small" className={classes.emotionCellChanged} color="primary"/>
            )}
            <Checkbox
              onClick={e => dispatch(toggleEmotion(id, col.context, col.code, e.target.checked))}
              checked={isChecked}
            />
          </TableCell>
        )
      });

      wordsEmotionsColumns.push(<TableRow key={id} className={classes.row}>{emotionColCells}</TableRow>);
    }

    const wordActions = [
      {
        text: t('check_all_emotions_for_term', 'Check all emotions for term'),
        onClick: () => dispatch(toggleAllEmotionsForWord(id, true)),
        icon: <CheckBoxIcon/>,
      },
      {
        text: t('uncheck_all_emotions_for_term', 'Uncheck all emotions for term'),
        onClick: () => dispatch(toggleAllEmotionsForWord(id, false)),
        icon: <CheckBoxOutlineBlankIcon/>,
      },
      {
        text: t('reset_emotions_for_term', 'Reset emotions for term'),
        onClick: () => dispatch(resetEmotionsForWord(id)),
        icon: <RotateLeftIcon/>,
      },
      {
        text: t('show_usage_examples', 'Show usage examples'),
        onClick: () => {
          dispatch(setTermForExamples(w.term));
          dispatch(setExamplesDialogOpen(true));
        },
        icon: <CommentIcon/>,
      },
      {
        text: t('remove_from_list', 'Remove from list'),
        onClick: () => dispatch(removeWordsFromBasket([id])),
        icon: <RemoveIcon/>,
      },
      {
        divider: true,
      },
      {
        text: t('delete_forever', 'Delete forever'),
        onClick: () => {
          dispatch(setWordsToDelete([w]));
          dispatch(setDeleteWordsDialogOpen(true));
        },
        props: {disabled: !w._id},
        icon: <DeleteForeverIcon/>,
      },
    ];

    actionsColumn.push(
      <TableRow key={id} className={`${classes.row} ${classes.actions}`}>
        <TableCell>
          <DropdownMenu icon={<MoreVertIcon/>} items={wordActions}/>
        </TableCell>
      </TableRow>
    );
  })

  return (
    <PaperWithPadding>
      <Grid container spacing={2} className={classes.tableActions} justify="space-between">
        <Grid item container spacing={2} style={{width: 'auto'}} alignItems="center">
          <Grid item>
            <AddEmotion valueSystemInfo={valueSystemInfo}/>
          </Grid>
          <Grid item>
            <Button
              startIcon={<RotateLeftIcon/>}
              onClick={() => dispatch(resetEmotionsForAllWords())}
            >
              {t('reset_emotions', 'Reset emotions')}
            </Button>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Checkbox checked={showTranslation} onChange={e => setShowTranslation(e.target.checked)} />}
              label={t('show_original_words', "Show original words")}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ButtonWithProgress
            variant="contained"
            color="primary"
            loading={saveLoading}
            disabled={saveLoading}
            onClick={() => dispatch(saveRequest())}
          >
            {t('save', 'Save')}
          </ButtonWithProgress>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Table>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell valign="middle">
                  {t('term', 'Term')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wordsColumn}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs style={{position: 'relative', overflowX: 'auto'}}>
          <Table className={classes.emotionsBackgroundTable}>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {backgroundColumn}
            </TableBody>
          </Table>

          <Table style={{width: emotionColumns.length > 0 ? 'auto' : '100%'}}>
            <TableHead>
              <TableRow className={classes.headerRow}>
                {emotionColumns.length > 0 ? emotionColumns.map(col => (
                  <EmotionColumnCell
                    key={col.code + col.context}
                    valueSystemInfo={valueSystemInfo}
                    col={col}
                    words={wordsInBasket}
                  />
                )) : (
                  <TableCell>
                    <Alert severity="info">{t('no_emotions_added_to_selected', 'No emotions are added to selected words. Please add some.')}</Alert>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {wordsEmotionsColumns}
            </TableBody>
          </Table>
        </Grid>
        <Grid item>
          <Table>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>{t('actions', 'Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {actionsColumn}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </PaperWithPadding>
  );
}

const WordsBasket = () => {
  const taxonomiesAreSelected = useSelector(state => state.taxonomy.taxonomiesAreSelected);

  if (!taxonomiesAreSelected) return <Inactive/>;

  return <WordTable/>;
};

export default WordsBasket;