import React from 'react';
import {Checkbox, Chip, Grid, makeStyles, TableCell, Typography} from "@material-ui/core";
import {CONTEXTS, HEADER_ROW_HEIGHT} from "../taxonomy-constants";
import {toggleEmotionForAllWords} from "../taxonomy-actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: 150,
    minWidth: 180,
    maxWidth: 150,
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
  },
  gridContainer: {
    height: '100%',
  },
  primaryChip: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(0.5),
  },
  description: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  sentiment: {
    fontSize: '0.9rem',
  },
  code: {
    fontSize: '0.9rem',
    fontFamily: 'monospace',
  },
  primaryLine: {
    height: theme.spacing(0.5),
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  overflowContainer: {
    width: '100%',
    height: HEADER_ROW_HEIGHT - 1,
    overflow: 'hidden',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const EmotionColumnCell = ({valueSystemInfo, col, words}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const emotion = valueSystemInfo.byCoding[col.code];
  const primaryColor = valueSystemInfo.primaryColors[emotion.primary];

  const primaryStyle = {backgroundColor: primaryColor};
  const contextInfo = CONTEXTS[col.context];

  const isEmotionChecked = words.every(w => w.contexts[col.context].includes(col.code));
  const isEmotionIndeterminate = !isEmotionChecked && words.some(w => w.contexts[col.context].includes(col.code))

  return (
    <TableCell key={col.code} align="center" className={classes.root}>
      <div className={classes.overflowContainer}>
        <Grid container direction="column" justify="space-between" style={{minHeight: '100%'}}>
          <Grid item>
            <Chip size="small" label={emotion.primary} className={classes.primaryChip} style={primaryStyle}/>
            <Chip size="small" label={contextInfo.label[0]} style={{backgroundColor: contextInfo.bgColors[0]}}/>
          </Grid>
          <Grid item>
            <Typography className={classes.description}>{emotion.description}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.sentiment}>{emotion.sentiment}</Typography>
            <Typography className={classes.code}>{emotion.code}</Typography>
            <Checkbox
              checked={isEmotionChecked}
              indeterminate={isEmotionIndeterminate}
              onChange={e => dispatch(toggleEmotionForAllWords(col.context, col.code, e.target.checked))}
            />
          </Grid>
        </Grid>
        <div className={classes.primaryLine} style={primaryStyle}/>
      </div>
    </TableCell>
  );
};

export default EmotionColumnCell;