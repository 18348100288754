import React from 'react';
import {CircularProgress, IconButton} from "@material-ui/core";

const IconButtonWithCircularProgress = React.forwardRef(({loading, children, IconButtonProps, CircularProgressProps, menu, className, ...other}, ref) => (
  <div style={{position: 'relative', display: 'inline-block'}} className={className} ref={ref} {...other}>
    <IconButton
      {...IconButtonProps}
    >
      {children}
    </IconButton>
    {menu}
    {loading && (
      <CircularProgress
        size={48}
        style={{
          position: "absolute",
          top: '50%',
          left: '50%',
          marginTop: -24,
          marginLeft: -24,
        }}
        {...CircularProgressProps}
      />
    )}
  </div>
));

export default IconButtonWithCircularProgress;