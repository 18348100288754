import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import UiModal from "../../../components/UiModal/UiModal";
import TargetTaxonomySelector from "./TargetTaxonomySelector";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import {
  fetchTargetTaxonomiesRequest,
  setTargetTaxonomy,
  setTranslateWordsDialogOpen,
  translateWordsRequest
} from "../taxonomy-actions";

const TranslateWordsDialog = ({selectedWordsIds}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {isOpen, language, languages, targetTaxonomiesLoading, targetTaxonomies, targetTaxonomiesInfo, searchWords, targetTaxonomy, loading} = useSelector(state => ({
    isOpen: state.taxonomy.translateWordsDialogOpen,
    language: state.taxonomy.language,
    languages: state.taxonomy.languages,
    targetTaxonomiesLoading: state.taxonomy.targetTaxonomiesLoading,
    targetTaxonomies: state.taxonomy.targetTaxonomies,
    targetTaxonomiesInfo: state.taxonomy.targetTaxonomiesInfo,
    searchWords: state.taxonomy.searchWords,
    targetTaxonomy: state.taxonomy.targetTaxonomy,
    loading: state.taxonomy.translateWordsLoading
  }), shallowEqual);

  const [targetLanguages, setTargetLanguages] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState('');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const targetLanguages = languages.filter(l => l !== language);
    setTargetLanguages(targetLanguages);

    if (targetLanguages.length > 0) {
      setTargetLanguage(targetLanguages[0]);
    }
  }, [languages, language]);

  useEffect(() => {
    if (targetLanguage) {
      dispatch(setTargetTaxonomy(''));
      dispatch(fetchTargetTaxonomiesRequest(targetLanguage));
    }
  }, [targetLanguage]);

  useEffect(() => {
    if (searchWords) {
      setTranslations(searchWords.reduce((acc, w) => {
        if (selectedWordsIds.includes(w._id.$oid)) {
          acc[w._id.$oid] = {originalTerm: w.term, translation: []};
        }

        return acc;
      }, {}));
    }
  }, [selectedWordsIds, searchWords]);

  const onClose = () => {
    dispatch(setTranslateWordsDialogOpen(false));
  };

  const doTranslate = () => {
    dispatch(translateWordsRequest(selectedWordsIds, translations, targetTaxonomy, targetLanguage));
  };

  const onTranslationChange = (id, value) => {
    setTranslations(prev => {
      if (prev[id].translation.length < value.length) {
        const newWord = value[value.length - 1];

        if (newWord.indexOf('%') !== -1) {
          const split = newWord.split(/\s*%\s*/gi).filter(v => Boolean(v));
          const unique = Array.from(new Set(split));
          value = value.slice(0, -1).concat(unique);
        }
      }

      return {
        ...prev,
        [id]: {
          ...prev[id],
          translation: value
        }
      };
    });
  };

  const canTranslate = targetLanguage && targetTaxonomy && Object.keys(translations).every(id => translations[id].translation.length > 0);

  return (
    <UiModal
      maxWidth="md"
      title={t('translate_terms', 'Translate terms')}
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent dividers>
        <Grid container direction="row" spacing={2} wrap="nowrap">
          <Grid item xs>
            <TextField
              variant="outlined"
              select
              fullWidth
              value={targetLanguage}
              label={t('select_target_language', 'Select target language')}
              onChange={e => setTargetLanguage(e.target.value)}
            >
              {targetLanguages.map(l => (
                <MenuItem key={l} value={l}>{l}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs>
            <TargetTaxonomySelector
              targetLanguage={targetLanguage}
              targetTaxonomy={targetTaxonomy}
              setTargetTaxonomy={targetTaxonomy => dispatch(setTargetTaxonomy(targetTaxonomy))}
              targetTaxonomies={targetTaxonomies}
              targetTaxonomiesInfo={targetTaxonomiesInfo}
              targetTaxonomiesLoading={targetTaxonomiesLoading}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: '30%'}}>{t('original_term', 'Original term')}</TableCell>
            <TableCell>{t('translation_to_target_language', 'Translation to "{{targetLanguage}}', {targetLanguage})}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(translations).map(id => (
            <TableRow key={id}>
              <TableCell>{translations[id].originalTerm}</TableCell>
              <TableCell>
                <Autocomplete
                  fullWidth
                  freeSolo
                  multiple
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" size="small" label={option} {...getTagProps({index})} />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                    />
                  )}
                  options={[]}
                  value={translations[id].translation}
                  onChange={(_, v) => onTranslationChange(id, v)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DialogActions>
        <Button onClick={onClose}>{t('close', 'Close')}</Button>
        <ButtonWithProgress
          color="primary"
          disabled={!canTranslate || loading}
          loading={loading}
          onClick={doTranslate}
        >
          {t('save_and_edit', 'Save and edit')}
        </ButtonWithProgress>
      </DialogActions>
    </UiModal>
  );
};

export default TranslateWordsDialog;