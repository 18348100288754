import React from 'react';
import {connect} from "react-redux";
import FullReportTopWordsComponent from "./full-report-top-words-component";
import {loadTopWordsRequest} from "../charts/charts-action-creators";
import {changePage, changeTargetPage, downloadTopWords, searchChange} from "./full-report-top-words-action-creators";
import {useTranslation} from "react-i18next";

const FullReportTopWordsContainer = ({topWordsLoading, ...props}) => {
  const {t} = useTranslation();

  const columns = [
    {
      name: 'term',
      displayName: t('term', 'Term'),
      width: '40%'
    },
    {
      name: 'count',
      displayName: t('count', 'Count'),
      width: '30%'
    },
    {
      name: 'percent',
      displayName: t('percent', 'Percent'),
      width: '30%'
    }
  ];

  return <FullReportTopWordsComponent
    columns={columns}
    loading={topWordsLoading}
    {...props}
  />;
};

const mapStateToProps = state => ({
  topWords: state.charts.topWords,
  topWordsPaginated: state.fullReportTopWords.topWordsPaginated,
  page: state.fullReportTopWords.page,
  topWordsLoading: state.charts.topWordsLoading,
  searchValue: state.fullReportTopWords.searchValue,
  reportId: state.report.reportId,
  targetPage: state.fullReportTopWords.targetPage,
  downloading: state.fullReportTopWords.downloading
});

const mapDispatchToProps = dispatch => ({
  loadTopWords: () => dispatch(loadTopWordsRequest()),
  changePage: page => dispatch(changePage({targetPage: page})),
  searchChange: searchValue => dispatch(searchChange(searchValue)),
  changeTargetPage: targetPage => dispatch(changeTargetPage(targetPage)),
  download: () => dispatch(downloadTopWords())
});

export default connect(mapStateToProps, mapDispatchToProps)(FullReportTopWordsContainer);