import {LOGIN_SUCCESS, LOGOUT_SUCCESS, SAVE_SEARCH_QUERY} from "../containers/login/user-actions";

const APP_STATE = 'hbState';
const PERSISTENT_DATA = '__persistentData';
const saveActions = [LOGIN_SUCCESS, LOGOUT_SUCCESS, SAVE_SEARCH_QUERY];

export const localStorageMiddleware = ({ getState }) => {
  return next => action => {
    const nextAction = next(action);

    if (saveActions.includes(nextAction.type)) {
      localStorage.setItem(APP_STATE, JSON.stringify({
        user: getState().user
      }));
    }

    if (nextAction.type === LOGOUT_SUCCESS) {
      clearPersistentData();
    }

    return nextAction;
  };
};

export const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(APP_STATE);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const savePersistentData = (key, value) => {
  let data;

  try {
    data = JSON.parse(localStorage.getItem(PERSISTENT_DATA));
  } catch (e) {
    data = {};
  }

  localStorage.setItem(PERSISTENT_DATA, JSON.stringify({
    ...data,
    [key]: value
  }));
};

export const selectPersistentData = key => {
  try {
    const data = JSON.parse(localStorage.getItem(PERSISTENT_DATA));
    return data[key];
  } catch (e) {
    return null;
  }
};

export const deletePersistentData = key => {
  try {
    const data = JSON.parse(localStorage.getItem(PERSISTENT_DATA));
    delete data[key];
    localStorage.setItem(PERSISTENT_DATA, JSON.stringify(data));
  } catch (e) {
    // do nothing, probably json parse error
  }
}

export const clearPersistentData = () => {
  localStorage.removeItem(PERSISTENT_DATA);
};