import API from "../../API-report";
import { call, put, select } from 'redux-saga/effects';
import {loadHbIndexChartDataError, loadHbIndexChartDataSuccess} from "./hb-index-chart-action-creators";
import {DATA_COLUMNS_SPLIT} from "../split-charts/split-charts-utils";
import i18n from "../../i18n";

export function* loadHbIndexChartData() {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);
  let filterName = yield select(state => state.hbIndexChart.selectedFilterName);
  const secondVariableName = yield select(state => state.hbIndexChart.secondVariableName);

  if (!filterName) filterName = DATA_COLUMNS_SPLIT;

  try {
    let results;

    if (secondVariableName) {
      const availableFilters = yield select(state => state.filters.filters);
      const filtersCategory = availableFilters.find(f => f.item.title === 'Meta Filters');
      let filter = filtersCategory.children.find(f => f.item.title === secondVariableName);

      if (secondVariableName === DATA_COLUMNS_SPLIT) {
        filter = availableFilters.find(f => f.item.title === 'Data Filters');
      }

      const redactedFilters = filters.filter(f => {
        return f.parentId !== filter.item.id;
      });

      let selectedChildren = filter.children.filter(f => f.item.selected);

      if (selectedChildren.length === 0) {
        selectedChildren = filter.children;
      }

      results = yield Promise.all(selectedChildren.map(async f => {
        const response = await API.loadHbIndexData({reportId, filters: [...redactedFilters, f.item], operator, filterName});
        return {
          filterName: f.item.title,
          data: response.data.results ? normalize(response.data.results) : [],
        };
      }));

      results = results.reduce((acc, r) => {
        if (r.data.length > 0) {
          acc.push(r);
        }

        return acc;
      }, []);

      if (results.length === 0) {
        return yield put(loadHbIndexChartDataError(i18n.t('no_data', "No data")));
      }
    } else {
      const response = yield API.loadHbIndexData({reportId, filters, operator, filterName});
      if (!response.data.results) {
        return yield put(loadHbIndexChartDataError(i18n.t('no_data', "No data")))
      }

      results = [{
        filterName,
        data: normalize(response.data.results)
      }];
    }

    yield put(loadHbIndexChartDataSuccess({data: results, filterName}));
  } catch(error) {
    console.log(error, 'ERROR FROM HB INDEX CHART LOAD DATA');
    yield put(loadHbIndexChartDataError(i18n.t('something_went_wrong', "Something went wrong...")));
  }
}

const normalize = data => {
  data.forEach(column => {
    if (!column.score_column_name) {
      column.score_column_name = "unspecified";
    }
  });

  return data;
};
