import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {saveLocationBeforeLogin} from "../../containers/login/user-actions";

const PrivateRoute = ({ isAllowed, ...rest }) => {
  if (isAllowed) {
    return <Route {...rest} />;
  }

  rest.saveLocationBeforeLogin(rest.location.pathname);

  return <Redirect to="/login"/>;
};

const mapDispatchToProps = dispatch => ({
  saveLocationBeforeLogin: location => dispatch(saveLocationBeforeLogin(location))
});

export default connect(null, mapDispatchToProps)(PrivateRoute);
