import {
  HIDE_SEARCH_MODAL,
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SET_SEARCH_CRITERIA,
  SHOW_SEARCH_MODAL
} from "./ngram-search-actions";

const initialState = {
  searchCriteria: {
    searchWord: '',
    head: 0,
    tail: 0
  },
  searchResults: null,
  modalIsShown: false,
  modalSelectedReportId: null
};

const ngramSearchReducer = (state=initialState, action) => {
  switch(action.type) {
    case SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: {
          ...state.searchCriteria,
          ...action.searchCriteria
        }
      };
    case SEARCH_REQUEST:
      return {
        ...state,
        searchResults: null
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults
      };
    case SHOW_SEARCH_MODAL:
      return {
        ...state,
        modalSelectedReportId: action.reportId,
        modalIsShown: true
      };
    case HIDE_SEARCH_MODAL:
      return {
        ...state,
        modalIsShown: false
      };
    default:
      return state;
  }
};

export default ngramSearchReducer;