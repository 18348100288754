import {FILTERS_TYPE_NAME_MAP} from "../filters/filters-constants";
import i18n from "../../i18n";

export const DATA_COLUMNS_SPLIT = '__data_columns';

export const transformSplitChatData = (data, columnsKey, singleColKey, getColor, getHighlight) => {
  return data.map(row => {
    return {
      value: row.split_filter_value,
      totalCount: row[columnsKey].reduce((acc, col) => {
        return acc + col.count;
      }, 0),
      cols: row[columnsKey].map(col => ({
        value: col[singleColKey],
        count: col.count,
        color: getColor(col[singleColKey]),
        highlight: getHighlight(col[singleColKey]),
      }))
    };
  });
};

export const createFilterNames = flatFilters => {
  flatFilters = Object.values(flatFilters);
  const metaFiltersParent = flatFilters.find(f => (f['title_in_original_language'] || f.title) === FILTERS_TYPE_NAME_MAP['meta']);
  let metaFilterNames = [];

  if (metaFiltersParent) {
    metaFilterNames = flatFilters.filter(f => f.parentId === metaFiltersParent.id)
      .map(f => ({title: f.title, value: f.title}));
  }

  metaFilterNames.unshift({
    title: i18n.t('data_columns', 'Data columns'),
    value: DATA_COLUMNS_SPLIT
  });

  return metaFilterNames;
};