export const parseQueryString = query => {
  const queryParts = Array.from((new URLSearchParams(query)).entries());

  return queryParts.reduce((acc, pair) => {
    acc[pair[0]] = pair[1];

    return acc;
  }, {});
};

export const createQueryString = params => {
  const urlSearchParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
    urlSearchParams.append(key, params[key]);
  });

  return urlSearchParams.toString();
};