import React from 'react';
import {useTranslation} from "react-i18next";
import PaperWithPadding from "../../../components/PaperWithPadding/PaperWithPadding";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddWordsDialog from "./AddWordsDialog";
import {clearBasket, setAddWordsDialogOpen, setSearchWordsDialogOpen} from "../taxonomy-actions";
import SearchWordsDialog from "./SearchWordsDialog";

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const ActionsBar = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const taxonomiesAreSelected = useSelector(state => state.taxonomy.taxonomiesAreSelected);

  if (!taxonomiesAreSelected) return (
    <PaperWithPadding>
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <Typography variant="body2">{t('select_taxonomies_to_start_searching', 'Please select taxonomies to start searching for words')}</Typography>
        </Grid>
      </Grid>
    </PaperWithPadding>
  );

  return (
    <>
      <PaperWithPadding>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              startIcon={<SearchIcon/>}
              onClick={() => dispatch(setSearchWordsDialogOpen(true))}
            >
              {t('search_for_words', 'Search for words')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<AddIcon/>}
              onClick={() => dispatch(setAddWordsDialogOpen(true))}
            >
              {t('add_new_words', 'Add new words')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<ClearIcon/>}
              onClick={() => dispatch(clearBasket())}
            >
              {t('clear_words_table', 'Clear words table')}
            </Button>
          </Grid>
        </Grid>
      </PaperWithPadding>
      <AddWordsDialog/>
      <SearchWordsDialog/>
    </>
  );
};

export default ActionsBar;