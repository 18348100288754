import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  addWordsToBasket,
  searchWordsRequest,
  searchWordsSuccess,
  setSearchWordsDialogOpen,
  setTranslateWordsDialogOpen
} from "../taxonomy-actions";
import {
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import UiModal from "../../../components/UiModal/UiModal";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";
import SearchWordsTable from "./SearchWordsTable";
import TranslateWordsDialog from "./TranslateWordsDialog";
import {normalizeWords} from "../taxonomy-functions";

const SearchWordsDialog = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {selectedTaxonomies, isOpen, loading, searchWords} = useSelector(state => ({
    selectedTaxonomies: state.taxonomy.selectedTaxonomies,
    isOpen: state.taxonomy.searchWordsDialogOpen,
    loading: state.taxonomy.searchWordsLoading,
    searchWords: state.taxonomy.searchWords,
  }), shallowEqual);

  const [taxonomyNames, setTaxonomyNames] = useState(selectedTaxonomies);
  const [isRegexp, setIsRegexp] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedWordsIds, setSelectedWordsIds] = useState([]);
  const [showTranslation, setShowTranslation] = useState(false);

  useEffect(() => {
    if (searchWords && searchWords.length > 0) {
      setSelectedWordsIds(prev => prev.filter(id => searchWords.find(w => w._id.$oid === id)));
    }
  }, [searchWords]);

  const onClose = () => {
    dispatch(setSearchWordsDialogOpen(false));
  };

  const onSearchSubmit = event => {
    event.preventDefault();
    setSelectedWordsIds([]);

    dispatch(searchWordsRequest(taxonomyNames, searchString, isRegexp));
  };

  const onTaxonomyNameChange = e => {
    const {checked, value} = e.target;

    setTaxonomyNames(prev => checked ? [...prev, value] : prev.filter(v => v !== value))
  };

  const selectWordsForEditing = () => {
    const selectedWords = searchWords.filter(w => selectedWordsIds.includes(w._id.$oid));
    dispatch(addWordsToBasket(normalizeWords(selectedWords)));
    setSelectedWordsIds([]);
    onClose();
  };

  const reset = () => {
    setTaxonomyNames(selectedTaxonomies);
    setIsRegexp(false);
    setSearchString('');
    setSelectedWordsIds([]);
    dispatch(searchWordsSuccess(null));
  };

  const canAdd = selectedWordsIds.length > 0;
  const canSearch = taxonomyNames.length > 0;

  let resultsArea = null;

  if (searchWords && searchWords.length > 0) {
    resultsArea = (
      <SearchWordsTable
        searchWords={searchWords}
        selectedWordsIds={selectedWordsIds}
        setSelectedWordsIds={setSelectedWordsIds}
        showTranslation={showTranslation}
      />
    );
  }

  if (searchWords && searchWords.length === 0) {
    resultsArea = (
      <Alert severity="warning">{t('no_results_found', 'No results found')}</Alert>
    );
  }

  if (loading) {
    resultsArea = <Grid container alignItems="center" justify="center"><CircularProgress/></Grid>;
  }

  return (
    <>
      <UiModal
        maxWidth="md"
        title={t('search_for_words', 'Search for words')}
        open={isOpen}
        onClose={onClose}
      >
        <form onSubmit={onSearchSubmit}>
          <DialogContent dividers>
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid item xs>
                <FormControl component="fieldset" required>
                  <FormLabel component="legend">{t('taxonomies_to_search_in', 'Taxonomies to search in')}</FormLabel>
                  <FormGroup row>
                    {selectedTaxonomies.map(taxonomyName => (
                      <FormControlLabel
                        key={taxonomyName}
                        control={(
                          <Checkbox
                            checked={taxonomyNames.includes(taxonomyName)}
                            onChange={onTaxonomyNameChange}
                            value={taxonomyName}/>
                        )}
                        label={taxonomyName}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('search_options', 'Search options')}</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={isRegexp} onChange={() => setIsRegexp(prev => !prev)}/>}
                      label={t('regular_expression', 'Regular expression')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs>
                <TextField
                  autoFocus
                  variant="outlined"
                  fullWidth
                  label={t('search_string', 'Search string')}
                  value={searchString}
                  onChange={e => setSearchString(e.target.value)}
                />
              </Grid>
              <Grid item xs>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('results_display_options', 'Results display options')}</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={showTranslation} onChange={e => setShowTranslation(e.target.checked)} />}
                      label={t('show_original_words', 'Show original words')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs>
                {resultsArea}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonWithProgress
              type="submit"
              color="primary"
              loading={loading}
              disabled={loading || !canSearch}
            >
              {t('search', 'Search')}
            </ButtonWithProgress>
            <ButtonWithProgress
              type="button"
              color="primary"
              loading={loading}
              disabled={loading || !canAdd}
              onClick={selectWordsForEditing}
            >
              {t('edit_selected', 'Edit selected')}
            </ButtonWithProgress>
            <ButtonWithProgress
              type="button"
              color="primary"
              loading={loading}
              disabled={loading || !canAdd}
              onClick={() => {
                dispatch(setTranslateWordsDialogOpen(true))
              }}
            >
              {t('translate_selected', 'Translate selected')}
            </ButtonWithProgress>
            <Button
              type="button"
              color="default"
              onClick={reset}
            >
              {t('reset', 'Reset')}
            </Button>
            <Button onClick={onClose}>{t('close', 'Close')}</Button>
          </DialogActions>
        </form>
      </UiModal>
      <TranslateWordsDialog selectedWordsIds={selectedWordsIds} />
    </>
  );
};

export default SearchWordsDialog;