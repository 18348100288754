import React, {Fragment, useState} from 'react';
import {Bar, Bubble, Doughnut, HorizontalBar} from "react-chartjs-2";
import {makeStyles} from "@material-ui/core/styles";
import ChartJS from 'chart.js';
import {formatPercent} from "../functions";
import BreakdownChartTooltipTitle from "./BreakdownChartTooltip";

import FloatingTooltip from "../../../components/Tooltip/FloatingTooltip";

import DataLabels from 'chartjs-plugin-datalabels';
/* stupid way to prevent unused import error */
DataLabels.toString();

const useStyles = makeStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    '& canvas': {
      position: 'absolute'
    }
  },
});

const chartComponents = {
  'bar': Bar,
  'doughnut': Doughnut,
  'horizontal-bar': HorizontalBar,
  'bubble': Bubble,
};

const Chart = React.forwardRef(({
                                  height,
                                  chartData,
                                  tooltipColumns,
                                  chartType,
                                  overrideOptions,
                                  specificOptions,
                                  scaleClickOptions
                                }, ref) => {
  const [tooltipCoords, setTooltipCoords] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);

  let tooltip = null;

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        color: 'white',
        display: function (context) {
          const total = context.dataset.data.reduce((sum, item) => sum + item, 0);
          const value = context.dataset.data[context.dataIndex];
          return value / total > 0.05
        },
        font: {
          size: 14
        },
        formatter: formatPercent,
      }
    },
    maintainAspectRatio: false,
  };

  const optionsByChartType = {
    'pie': {
      plugins: {
        datalabels: {
          anchor: 'center'
        }
      }
    },
    'bubble': {
      plugins: {
        datalabels: {
          display: function (context) {
            const value = context.dataset.data[context.dataIndex];
            return value.displayValue > 0;
          },
          formatter: v => v.displayValue
        }
      },
    },
    'bar': {
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'start'
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            max: specificOptions && (specificOptions.yAxisMax || undefined),
            callback: function (value, index, values) {
              return Math.round(value * 100) + '%'
            }
          }
        }],
        xAxes: [{
          gridLines: {
            display: false
          }
        }]
      },
    },
    'horizontal-bar': {
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'start'
        }
      },
      scales: {
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontSize: 15,
            fontColor: 'blue',
            fontFamily: 'Roboto',
            fontStyle: '300'
          }
        }],
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
          }
        }]
      }
    }
  };

  ChartJS.helpers.merge(options, [optionsByChartType[chartType], overrideOptions]);

  if (tooltipColumns) {
    options.onHover = function (evt, el) {
      if (el.length) {
        setTooltipCoords({x: evt.clientX, y: evt.clientY});
        const index = el[0]._index;
        setTooltipData(chartData.originalData[index]);
      } else {
        setTooltipCoords(null);
      }
    };

    const tooltipTitle = tooltipData ? (
      <BreakdownChartTooltipTitle data={tooltipData} columns={tooltipColumns}/>
    ) : '';

    tooltip = (
      <FloatingTooltip
        coords={tooltipCoords}
        arrow title={tooltipTitle}
        placement="right"
      >
        <span/>
      </FloatingTooltip>
    );
  }

  const ChartComponent = chartComponents[chartType];

  const classes = useStyles();

  let plugins = undefined;

  if (scaleClickOptions) {
    plugins = [
      {
        id: 'scale-click',
        afterEvent(chart, event) {
          const scale = chart.scales[scaleClickOptions.id];

          if (event.type === 'click' && scale) {
            const coord = scaleClickOptions.scaleType === 'x' ? event.x : event.y;
            const index = scale.getValueForPixel(coord);

            if (scaleClickOptions.scaleType === 'y' && event.x > scale.width) {
              return;
            }

            scaleClickOptions.callback(index, scale);
          }

          if (event.type === 'mousemove' && scale && scaleClickOptions.scaleType === 'y') {
            if (event.x < scale.right) {
              chart.canvas.style.cursor = 'pointer';
            } else {
              chart.canvas.style.cursor = 'default';
            }
          }

          if (event.type === 'mouseout') {
            chart.canvas.style.cursor = 'default';
          }
        }
      }
    ]
  }

  return (
    <Fragment>
      <div className={classes.root} style={{height}}>
        <ChartComponent
          data={chartData}
          options={options}
          height={null}
          width={null}
          plugins={plugins}
          ref={ref}
        />
      </div>
      {tooltip}
    </Fragment>
  );
});

export default Chart;