import React from 'react';
import {HorizontalBar} from "react-chartjs-2";
import {makeStyles} from "@material-ui/core/styles";
import DataLabels from 'chartjs-plugin-datalabels';
/* stupid way to prevent unused import error */
DataLabels.toString();

const primaryColor = 'rgb(242, 101, 34)';

const useStyles = makeStyles(theme => ({
  chartBox: {
    position: 'relative',
    overflow: 'hidden',
    '& canvas': {
      position: 'absolute'
    }
  },
}));

const MetricBarChart = ({metricItem}) => {
  const classes = useStyles();

  const preparedData = Object.keys(metricItem.data).reduce((acc, key) => {
    const value = metricItem.data[key];

    acc.total += value;
    acc.labels.push(key);
    acc.data.push(value);

    return acc;
  }, {labels: [], data: [], total: 0});

  const chartData = {
    labels: preparedData.labels,
    datasets: [{
      data: preparedData.data,
      backgroundColor: primaryColor,
    }]
  };

  const chartOptions = {
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        color: context => {
          const value = context.dataset.data[context.dataIndex];

          return value / preparedData.total > 0.05 ? 'white' : primaryColor;
        },
        font: {
          size: 14
        },
        anchor: 'end',
        // align: 'end',
        align: context => {
          const value = context.dataset.data[context.dataIndex];

          return value / preparedData.total > 0.05 ? 'start' : 'end';
        }
      }
    },
    maintainAspectRatio: false,
  };

  return (
    <div className={classes.chartBox} style={{height: chartData.labels.length * 30 + 30}}>
      <HorizontalBar
        data={chartData}
        options={chartOptions}
        height={null}
        width={null}
      />
    </div>
  );
};

export default MetricBarChart;