import {put, select, takeEvery} from "redux-saga/effects";
import {REPORT_METRICS_LOAD_REQUEST, reportMetricsLoadSuccess} from "./report-metrics-actions";
import API from "../../API-report";

function* loadReportMetrics() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const filters = yield select(state => state.filters.selectedFilters);
    const operator = yield select(state => state.filters.operator);

    const response = yield API.getMetricsForReport(reportId, filters, operator);

    yield put(reportMetricsLoadSuccess(response.data.results));
  } catch (e) {
    console.error('ERROR WITH LOADING REPORT METRICS', e);
  }
}

export default [
  takeEvery(REPORT_METRICS_LOAD_REQUEST, loadReportMetrics)
];