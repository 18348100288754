import React from 'react';
import Sticky from "react-stickynode";
import Scrollbars from "rc-scrollbars";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles(theme => ({
  secondColumnSticky: {
    [theme.breakpoints.up("lg")]: {
      height: `calc(100vh - ${theme.mixins.toolbar.minHeight + theme.spacing(2)}px)`,
      paddingBottom: theme.spacing(1),
    }
  },
  rootSticky: {
    overflow: 'hidden',
    height: '100%',
    padding: theme.spacing(1)
  },
  rootNonSticky: {
    padding: theme.spacing(2)
  },
  inner: {
    padding: theme.spacing(1)
  },
}))

const CONTAINER_ID = 'report-container';

const TwoColumnsStickyLayout = ({firstColumn, secondColumn}) => {
  const classes = useStyles();
  const theme = useTheme();

  const top = theme.mixins.toolbar.minHeight + theme.spacing(2);

  return (
    <Grid item container spacing={1} id={CONTAINER_ID}>
      <Grid item xs={12} lg={6}>
        {firstColumn}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Hidden mdDown>
          <Sticky top={top} bottomBoundary={'#' + CONTAINER_ID}>
            <div className={classes.secondColumnSticky}>
              <Paper className={classes.rootSticky}>
                <Scrollbars>
                  <div className={classes.inner}>
                    {secondColumn}
                  </div>
                </Scrollbars>
              </Paper>
            </div>
          </Sticky>
        </Hidden>
        <Hidden lgUp>
          <Paper className={classes.rootNonSticky}>
            {secondColumn}
          </Paper>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default TwoColumnsStickyLayout;