import React from 'react';

import Grid from "@material-ui/core/Grid";

import withFilter from "../../hoc/with-filter/with-filter-hoc";
import FullReportTopWords from '../full-report-top-words/full-report-top-words-container';
import TopWordsChart from "../charts/top-words-chart";
import PrimaryEmotionsBreakdownChart from "../charts/containers/PrimaryEmotionsBreakdownChart";
import PrimaryEmotionsFullReport from "./containers/PrimaryEmotionsFullReport";
import {useTranslation} from "react-i18next";


const Chart = ({reportName}) => {
  const {t} = useTranslation();

  switch (reportName) {
    case 'top-words':
      return <TopWordsChart/>;
    case 'primary-emotions':
      return <PrimaryEmotionsBreakdownChart/>;
    default:
      return <div>{t('no_chart_available_yet', 'No chart available yet')}</div>;
  }
};

const Report = ({reportName}) => {
  const {t} = useTranslation();

  switch (reportName) {
    case 'top-words':
      return <FullReportTopWords />;
    case 'primary-emotions':
      return <PrimaryEmotionsFullReport />;
    default:
      return <div>{t('no_chart_available_yet', 'No chart available yet')}</div>;
  }
};

const FullReportComponent = ({match}) => {
  const reportName = match.params.reportName;

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} xl={6}>
        <Chart reportName={reportName} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <Report reportName={reportName} />
      </Grid>
    </Grid>
  );
};

export default withFilter(FullReportComponent);
