import {
  ADD_TOPICS_DONE,
  ADD_TOPICS_REQUEST,
  CHANGE_PUBLICITY_OF_REPORT,
  CHANGE_PUBLICITY_OF_REPORT_DONE,
  CHANGE_REPORT_ID, FETCH_TOPIC_NAMESPACES_FAILURE,
  FETCH_TOPIC_NAMESPACES_REQUEST,
  FETCH_TOPIC_NAMESPACES_SUCCESS,
  SET_REPORT_MODE,
  SET_TOPICS_MODAL_OPEN,
  SET_VALUE_SYSTEM,
  TOGGLE_SHARE_MODAL
} from "./report-actions";
import {REPORT_MODE_NORMAL} from "./report-constants";

const initialState = {
  reportId: "",
  valueSystem: null,
  shareModalVisible: false,
  changePublicityLoading: false,
  mode: REPORT_MODE_NORMAL,
  topicsModalOpen: false,
  topicsNamespacesLoading: false,
  topicsNamespaces: [],
  topicsAddLoading: false,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_REPORT_ID:
      return {...state, reportId: action.reportId};

    case SET_VALUE_SYSTEM:
      return {...state, valueSystem: action.valueSystem};

    case TOGGLE_SHARE_MODAL:
      return {...state, shareModalVisible: !state.shareModalVisible};

    case CHANGE_PUBLICITY_OF_REPORT:
      return {...state, changePublicityLoading: true};

    case CHANGE_PUBLICITY_OF_REPORT_DONE:
      return {...state, changePublicityLoading: false};

    case SET_REPORT_MODE:
      return {...state, mode: action.mode};

    case SET_TOPICS_MODAL_OPEN:
      return {...state, topicsModalOpen: action.payload};

    case FETCH_TOPIC_NAMESPACES_REQUEST:
      return {...state, topicsNamespacesLoading: true, topicsNamespaces: []};

    case FETCH_TOPIC_NAMESPACES_SUCCESS:
      return {...state, topicsNamespacesLoading: false, topicsNamespaces: action.payload};

    case FETCH_TOPIC_NAMESPACES_FAILURE:
      return {...state, topicsNamespacesLoading: false};

    case ADD_TOPICS_REQUEST:
      return {...state, topicsAddLoading: true};

    case ADD_TOPICS_DONE:
      return {...state, topicsAddLoading: false};

    default:
      return state;
  }
};

export default reportReducer;
