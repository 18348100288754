import React from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ShareIcon from "@material-ui/icons/Share";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import BuildIcon from "@material-ui/icons/Build";
import SearchIcon from "@material-ui/icons/Search";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import AssessmentIcon from '@material-ui/icons/Assessment';
import LockIcon from "@material-ui/icons/Lock";

import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";

import {showSearchModal} from "../../ngram-search/ngram-search-actions";
import {showResponseSingleModal, singleResponseRequest} from "../../response-single/response-single-actions";
import {showCreateFiltersFromNotesModal} from "../../responses/notes/notes-actions";
import {setReportMode, setTopicsModalOpen, toggleShareModal} from "../../report/report-actions";
import {REPORT_MODE_BINARY, REPORT_MODE_NORMAL} from "../../report/report-constants";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import IconButtonWithCircularProgress
  from "../../../components/IconButtonWithCircularProgress/IconButtonWithCircularProgress";
import {Grid, Tooltip} from "@material-ui/core";

const ReportToolsMenu = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const {reportId, mode, isDuplicate, topicsInProgress, topicsProgress, user} = useSelector(state => ({
    reportId: state.report.reportId,
    mode: state.report.mode,
    isDuplicate: state.report.duplicate,
    topicsInProgress: state.reportRouter.topicsInProgress,
    topicsProgress: state.reportRouter.topicsProgress,
    user: state.user,
  }), shallowEqual);

  const isNormalMode = mode === REPORT_MODE_NORMAL;

  const showAndRequestSingleResponse = () => {
    dispatch(showResponseSingleModal());
    dispatch(singleResponseRequest(1));
  }

  const items = [
    {
      onClick: () => {dispatch(showSearchModal(reportId))},
      icon: <SearchIcon/>,
      text: t('search_by_word', 'Search by word'),
    },
    {
      onClick: showAndRequestSingleResponse,
      icon: <ChatBubbleIcon/>,
      text: t('single_verbatim_view', 'Single verbatim view'),
    },
    {
      component: Link,
      props: {
        to: `/report/${reportId}/edit`
      },
      icon: <EditIcon/>,
      text: t('edit', 'Edit'),
      restrict: !user || user['is_guest'] || isDuplicate
    },
    {
      component: Link,
      props: {
        to: `/report/${reportId}/live${location.search}`
      },
      icon: <OndemandVideoIcon/>,
      text: t('live_view', 'Live view'),
      restrict: !user || user['is_guest'] || isDuplicate
    },
    {
      onClick: () => dispatch(showCreateFiltersFromNotesModal()),
      icon: <PostAddIcon/>,
      text: t('create_filters_for_notes', 'Create filters for notes'),
      restrict: !user || user['is_guest'] || isDuplicate
    },
    {
      icon: <ShareIcon/>,
      text: t('share', 'Share'),
      onClick: () => dispatch(toggleShareModal()),
      restrict: !user || !(user['allow_publish_reports'] || user['is_superuser'] || user['is_manager'])
    },
    {
      component: Link,
      props: {
        to: `/report/${reportId}/metrics${location.search}`
      },
      icon: <AssessmentIcon/>,
      text: t('report_metrics', 'Report metrics'),
    },
    {
      text: t('add_topics', 'Add topics'),
      icon: <PlaylistAddIcon/>,
      onClick: () => dispatch(setTopicsModalOpen(true)),
      restrict: !user
    },
    {
      divider: true,
    },
    {
      text: t('normal_mode', "Normal mode"),
      onClick: () => dispatch(setReportMode(REPORT_MODE_NORMAL)),
      icon: isNormalMode ? <RadioButtonCheckedIcon fontSize="small"/> : <RadioButtonUncheckedIcon fontSize="small"/>,
      selected: isNormalMode
    },
    {
      text: t('binary_mode', "Binary mode"),
      onClick: () => dispatch(setReportMode(REPORT_MODE_BINARY)),
      icon: !isNormalMode ? <RadioButtonCheckedIcon fontSize="small"/> : <RadioButtonUncheckedIcon fontSize="small"/>,
      selected: !isNormalMode
    },
  ];

  let locked = null;

  if (topicsInProgress) {
    let mode = 'indeterminate';

    if (topicsProgress > 0 && topicsProgress < 100) {
      mode = 'determinate';
    }

    locked = (
      <Grid item>
        <Tooltip arrow title={(
          <>
            <p>{t('report_is_locked_while_adding_topics', 'Report is locked while adding topics. You can still view it.')}</p>
            <p>{t('current_progress', 'Current progress')}: {Math.round(topicsProgress)}%</p>
          </>
        )}>
          <IconButtonWithCircularProgress
            loading
            CircularProgressProps={{variant: mode, value: topicsProgress}}
          >
            <LockIcon/>
          </IconButtonWithCircularProgress>
        </Tooltip>
      </Grid>
    )
  }

  return (
    <>
      {locked}
      <Grid item>
        <DropdownMenu
          items={items}
          icon={<BuildIcon/>}
        />
      </Grid>
    </>
  )
};

export default ReportToolsMenu;