import React, {createContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Scrollbars} from "rc-scrollbars";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import FilterItem from "./components/FilterItem";
import {buildFiltersUrlPart} from "./filters-utils";
import {applyFilters, filtersRequest} from "./filters-actions";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import {ButtonGroup, IconButton, Menu, MenuItem, TextField} from "@material-ui/core";
import OperatorSelect from "./components/OperatorSelect";

export const EmoContext = createContext(false);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  container: {
    padding: theme.spacing(1)
  },
  buttons: {
    padding: theme.spacing(2),
  },
  loadingArea: {
    height: '200px',
  },
}));

const Filters = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {filters, flatFilters, loading, loaded, checksum, selectedFilters, operator} = useSelector(state => state.filters);
  const [showEmoNumbers, setShowEmoNumbers] = useState(false);

  const changeFiltersUrl = () => {
    const filtersUrlPart = buildFiltersUrlPart(filters, flatFilters, checksum, operator);
    if (filtersUrlPart) {
      history.push('?filters=' + filtersUrlPart);
    } else {
      reset();
    }
  };

  const reset = () => {
    history.push({pathname: window.location.pathname});
  };

  useEffect(() => {
    dispatch(filtersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (loaded) {
      dispatch(applyFilters());
    }
  }, [dispatch, location.search]);

  let filtersComponent = (
    <Grid container alignItems="center" justify="center" className={classes.loadingArea}>
      <Grid item>
        <CircularProgress/>
      </Grid>
    </Grid>
  );

  const menuActions = [
    {
      text: t('show_emo_count', "Show emo count"),
      onClick: () => setShowEmoNumbers(prev => !prev),
      icon: showEmoNumbers ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>,
    },
  ];

  const showMenu = useMemo(() => {
    for (let key in flatFilters) {
      if (flatFilters[key]['emo_count'] !== undefined) {
        return true;
      }
    }

    return false;
  }, [flatFilters]);

  if (!loading && loaded) {
    filtersComponent = (
      <Grid container direction="column" className={classes.root}>
        <Grid item container wrap="nowrap" spacing={2} className={classes.buttons} alignItems="center">
          <Grid item>
            <OperatorSelect initialOperator={operator} />
          </Grid>
          <Grid item xs>
            <Button fullWidth variant="contained" color="primary" onClick={changeFiltersUrl}>{t('apply', 'Apply')}</Button>
          </Grid>
          <Grid item>
            <IconButton size="small" title={t('reset', 'Reset')} disabled={selectedFilters.length === 0} onClick={reset}>
              <RotateLeftIcon fontSize="small"/>
            </IconButton>
          </Grid>
          {showMenu && (
            <Grid item>
              <DropdownMenu
                icon={<MoreVertIcon/>}
                items={menuActions}
                buttonSize='small'
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs>
          <Scrollbars>
            <EmoContext.Provider value={showEmoNumbers}>
              <div className={classes.container}>
                {filters.map(filter => (
                  <FilterItem key={filter.item.id} filter={filter} flatFilters={flatFilters} />
                ))}
              </div>
            </EmoContext.Provider>
          </Scrollbars>
        </Grid>
      </Grid>
    );
  }

  return filtersComponent;
};

export default Filters;