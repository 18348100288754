import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import MUIDataTable from "mui-datatables"
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {Paper, CircularProgress, createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {fetchUsersRequest, impersonateUserRequest} from "./users-actions";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import Grid from "@material-ui/core/Grid";

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(2)': {
          width: 70,
          textAlign: 'right'
        }
      }
    },
    MUIDataTableBodyCell: {
      stackedCommon: {
        height: 'auto !important'
      }
    }
  }
});

const Users = ({history}) => {
  const {t} = useTranslation();
  const {usersLoading, impersonateLoading, users} = useSelector(state => ({
    usersLoading: state.users.usersLoading,
    impersonateLoading: state.users.impersonateLoading,
    users: state.users.users
  }), shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersRequest());
  }, []);

  let content = null;

  if (users) {
    const columns = [{
      name: 'username',
      label: t('username', 'Username'),
      options: {
        filter: false,
        sort: true,
        sortDirection: 'asc'
      }
    }, {
      name: 'actions',
      label: t('actions', 'Actions'),
      options: {
        filter: false,
        sort: false,
        customBodyRender: value => (
          <ButtonWithProgress
            size="small"
            loading={impersonateLoading === value}
            disabled={impersonateLoading}
            onClick={() => dispatch(impersonateUserRequest(value, history))}
          >
            <RecordVoiceOverIcon fontSize="small"/>&nbsp;{t('impersonate', 'Impersonate')}
          </ButtonWithProgress>
        ),
      },
    }];

    const data = users.map(u => [u, u]);

    const theme = getMuiTheme();

    content = (
      <MuiThemeProvider theme={theme}>
        <MUIDataTable
          title={t('users_list', 'Users list')}
          data={data}
          columns={columns}
          options={{
            pagination: false,
            selectableRows: 'none',
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            textLabels: {
              toolbar: {
                search: t('search', 'Search')
              }
            }
          }}
        />
      </MuiThemeProvider>
    )
  }

  if (usersLoading) {
    content = <Paper><CircularProgress/></Paper>;
  }

  return (
    <Grid container>
      <Grid item xs>
        {content}
      </Grid>
    </Grid>
  );
};

export default Users;