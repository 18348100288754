import {
  PRIMARY_EMOTIONS_FULL_REPORT_SUCCESS,
  PRIMARY_EMOTIONS_FULL_REPORT_REQUEST, GET_PRIMARY_EMOTIONS_FULL_REPORT_FAILURE, PRIMARY_SEARCH_CHANGE
} from "./full-report-charts-actions";
import {searchChangeInPrimary} from "../../utilities/searchChangeInTerms";
import deepCopy from "../../utilities/deepCopy";

const initialState = {
  primaryEmotionsFullReportData: null,
  primaryEmotionsFullReportProcessed: null,
  primaryEmotionsFullReportLoading: false,
  primaryEmotionsFullReportSearchValue: '',
};

const fullReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRIMARY_EMOTIONS_FULL_REPORT_REQUEST:
      return {
        ...state,
        primaryEmotionsFullReportLoading: true,
        primaryEmotionsFullReportSearchValue: ''
      };

    case PRIMARY_EMOTIONS_FULL_REPORT_SUCCESS:
      return {
        ...state,
        primaryEmotionsFullReportData: action.data,
        primaryEmotionsFullReportProcessed: action.data,
        primaryEmotionsFullReportLoading: false
      };

    case GET_PRIMARY_EMOTIONS_FULL_REPORT_FAILURE:
      return {...state, primaryEmotionsFullReportLoading: false};

    case PRIMARY_SEARCH_CHANGE:
      const primaryData = state.primaryEmotionsFullReportData;
      const primarySearchResult = searchChangeInPrimary({
        data: deepCopy(primaryData),
        searchValue: action.value
      });

      return {
        ...state,
        primaryEmotionsFullReportProcessed: primarySearchResult,
        primaryEmotionsFullReportSearchValue: action.value
      };

    default:
      return state;
  }
};

export default fullReportReducer;