import React from 'react';
import TopWordsTable from "./TopWordsTable";

const FullReportTopWordsComponent = (
  {
    topWordsPaginated,
    columns,
    page,
    loading,
    changePage,
    searchChange,
    searchValue,
    reportId,
    ...props
  }
) => {
  return topWordsPaginated ? (
    <TopWordsTable
      columns={columns}
      data={topWordsPaginated}
      page={page}
      loading={loading}
      changePage={changePage}
      searchChange={searchChange}
      searchValue={searchValue}
      reportId={reportId}
      {...props}
    />
  ) : null;
};

export default FullReportTopWordsComponent;