import {put, call, select} from 'redux-saga/effects';
import {inputError, loginEnd, loginSuccess, logoutSuccess} from "./user-actions";
import API from "../../API";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";

const WRONG_CREDENTIALS = "Unable to log in with provided credentials.";

export function* login({ history }) {

  const username = yield select(state => state.login.username);
  const password = yield select(state => state.login.password);

  try {
    validate(username.value)
  } catch(error) {
    yield put(inputError({field: 'username', error: i18n.t('it_should_not_be_empty', 'It should not be empty')}));
    yield put(loginEnd());
  }
  try {
    validate(password.value)
  } catch(error) {
    yield put(inputError({field: 'password', error: i18n.t('it_should_not_be_empty', 'It should not be empty')}));
    yield put(loginEnd());
    return;
  }

  try {
    const result = yield call(API.login, {username: username.value, password: password.value});
    yield put(loginEnd());

    if (result.data && result.data.token) {
      result.data.username = username.value;
      yield put(loginSuccess(result.data));

      const lastLocation = yield select(state => state.login.lastLocation);

      const redirectTo = lastLocation || '/';
      history.replace(redirectTo);
    }
  } catch(error) {
    console.log(error, 'ERROR FROM LOGIN REQUEST');
    yield put(loginEnd());

    let errorMessage;
    if (error.response.data.non_field_errors && error.response.data.non_field_errors[0] === WRONG_CREDENTIALS) {
      errorMessage = i18n.t('wrong_username_or_password', "Wrong username or password")
    } else {
      errorMessage = i18n.t('something_went_wrong', "Something went wrong...");
    }

    yield put(addNotification({status: "Error", message: [errorMessage]}))
  }
}

export function* logout({history}) {
  // TODO: add server logout
  history.push('/login');
  yield put(logoutSuccess());
  yield put(addNotification({status: "Success", message: [i18n.t('logged_out', "Logged out")]}));
}

export function* forceLogout({history}) {
  history.replace('/login');
  yield put(logoutSuccess());
  yield put(addNotification({status: 'Error', message: [i18n.t('session_expired_log_in_again', "Your session has expired. Please log in again")]}));
}

const validate = value => {
  if (!value) throw new Error();
};
