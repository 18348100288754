export const LOAD_REPORTS_REQUEST = "[REPORTS] LOAD_REPORTS_REQUEST";
export const LOAD_REPORTS_SUCCESS = "[REPORTS] LOAD_REPORTS_SUCCESS";
export const LOAD_REPORTS_ERROR = "[REPORTS] LOAD_REPORTS_ERROR";

export const RECALC_REQUEST = "[REPORTS] RECALC_REQUEST";
export const RECALC_SUCCESS = "[REPORTS] RECALC_SUCCESS";
export const RECALC_ERROR = "[REPORTS] RECALC_ERROR";

export const DELETE_REPORT_REQUEST = "[REPORTS] DELETE_REPORT_REQUEST";
export const DELETE_REPORT_SUCCESS = "[REPORTS] DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "[REPORTS] DELETE_REPORT_ERROR";

export const MOVE_TO_TRASH_BIN_REQUEST = "[REPORTS] MOVE_TO_TRASH_BIN_REQUEST";
export const MOVE_TO_TRASH_BIN_SUCCESS = "[REPORTS] MOVE_TO_TRASH_BIN_SUCCESS";
export const MOVE_TO_TRASH_BIN_ERROR = "[REPORTS] MOVE_TO_TRASH_BIN_ERROR";

export const SHOW_TRASH_BIN_REQUEST = "[REPORTS] SHOW_TRASH_BIN_REQUEST";

export const RESTORE_FROM_TRASH_BIN_REQUEST = "[REPORTS] RESTORE_FROM_TRASH_BIN_REQUEST";
export const RESTORE_FROM_TRASH_BIN_SUCCESS = "[REPORTS] RESTORE_FROM_TRASH_BIN_SUCCESS";
export const RESTORE_FROM_TRASH_BIN_ERROR = "[REPORTS] RESTORE_FROM_TRASH_BIN_ERROR";

export const UPDATE_SINGLE_REPORT = "[REPORTS] UPDATE SINGLE";

export const FETCH_CSV_FROM_REPORT = "[REPORT] FETCH_CSV_FROM_REPORT";
export const FETCH_CSV_FROM_REPORT_FINISH = "[REPORT] FETCH_CSV_FROM_REPORT_FINISH";

export const LOCK_UNLOCK_REPORT_REQUEST = "[REPORT] LOCK_UNLOCK_REPORT_REQUEST";
export const LOCK_UNLOCK_REPORT_SUCCESS = "[REPORT] LOCK_UNLOCK_REPORT_SUCCESS";

export const SHOW_ADVANCED_SEARCH_PANEL = "[REPORTS] SHOW_ADVANCED_SEARCH_PANEL";
export const HIDE_ADVANCED_SEARCH_PANEL = "[REPORTS] HIDE_ADVANCED_SEARCH_PANEL";

export const SEARCH_CLEAR = "[REPORTS] SEARCH_CLEAR";

export const GET_TAGS_REQUEST = '[REPORTS] GET_TAGS_REQUEST';
export const GET_TAGS_SUCCESS = '[REPORTS] GET_TAGS_SUCCESS';

export const ADVANCED_SEARCH_REQUEST = "[REPORTS] ADVANCED_SEARCH_REQUEST";
export const ADVANCED_SEARCH_DONE = "[REPORTS] ADVANCED_SEARCH_DONE";

export const SELECT_REPORT_FOR_SNAPSHOTS = '[REPORTS] SELECT_REPORT_FOR_SNAPSHOTS';
export const OPEN_CREATE_SNAPSHOT_MODAL = '[REPORTS] OPEN_CREATE_SNAPSHOT_MODAL';
export const CLOSE_CREATE_SNAPSHOT_MODAL = '[REPORTS] CLOSE_CREATE_SNAPSHOT_MODAL';
export const CREATE_SNAPSHOT_REQUEST = '[REPORTS] CREATE_SNAPSHOT_REQUEST';
export const CREATE_SNAPSHOT_DONE = '[REPORTS] CREATE_SNAPSHOT_DONE';

export const SHOW_REPORT_INFO_MODAL = '[REPORTS] SHOW_REPORT_INFO_MODAL';
export const HIDE_REPORT_INFO_MODAL = '[REPORTS] HIDE_REPORT_INFO_MODAL';

export const loadReportsRequest = () => ({ type: LOAD_REPORTS_REQUEST });
export const loadReportsSuccess = (reports, total, tags) => ({ type: LOAD_REPORTS_SUCCESS, reports, total, tags});
export const loadReportsError = () => ({ type: LOAD_REPORTS_ERROR });

export const recalcRequest = args => ({ type: RECALC_REQUEST, ...args });
export const recalcSuccess = targetIndex => ({ type: RECALC_SUCCESS, targetIndex });
export const recalcError = targetIndex => ({ type: RECALC_ERROR, targetIndex });

export const deleteReportRequest = (reportId, parentId) => ({type: DELETE_REPORT_REQUEST, reportId, parentId});
export const deleteReportSuccess = (reportId, parentId) => ({type: DELETE_REPORT_SUCCESS, reportId, parentId});
export const deleteReportError = (reportId, parentId) => ({type: DELETE_REPORT_ERROR, reportId, parentId});

export const moveToTrashBinRequest = reportId => ({type: MOVE_TO_TRASH_BIN_REQUEST, reportId});
export const moveToTrashBinSuccess = reportId => ({type: MOVE_TO_TRASH_BIN_SUCCESS, reportId});
export const moveToTrashBinError = reportId => ({type: MOVE_TO_TRASH_BIN_ERROR, reportId});

export const showTrashBinRequest = () => ({type: SHOW_TRASH_BIN_REQUEST});

export const restoreFromTrashBinRequest = reportId => ({type: RESTORE_FROM_TRASH_BIN_REQUEST, reportId});
export const restoreFromTrashBinSuccess = reportId => ({type: RESTORE_FROM_TRASH_BIN_SUCCESS, reportId});
export const restoreFromTrashBinError = reportId => ({type: RESTORE_FROM_TRASH_BIN_ERROR, reportId});

export const updateSingleReport = (reportId, reportData) => ({type: UPDATE_SINGLE_REPORT, reportId, reportData});

export const fetchCsvFromReport = (reportId, reportName) => ({type: FETCH_CSV_FROM_REPORT, reportId, reportName});
export const fetchCsvFromReportFinish = reportId => ({type: FETCH_CSV_FROM_REPORT_FINISH, reportId});

export const lockUnlockReportRequest = reportId => ({type: LOCK_UNLOCK_REPORT_REQUEST, reportId});
export const lockUnlockReportFinished = reportId => ({type: LOCK_UNLOCK_REPORT_SUCCESS, reportId});

export const showAdvancedSearchPanel = () => ({type: SHOW_ADVANCED_SEARCH_PANEL});
export const hideAdvancedSearchPanel = () => ({type: HIDE_ADVANCED_SEARCH_PANEL});
export const searchClear = () => ({type: SEARCH_CLEAR});

export const getTagsRequest = () => ({type: GET_TAGS_REQUEST});
export const getTagsSuccess = tags => ({type: GET_TAGS_SUCCESS, tags});

export const advancedSearchRequest = requestData => ({type: ADVANCED_SEARCH_REQUEST, requestData});
export const advancedSearchDone = () => ({type: ADVANCED_SEARCH_DONE});

export const selectReportForSnapshots = report => ({type: SELECT_REPORT_FOR_SNAPSHOTS, report});
export const openCreateSnapshotModal = () => ({type: OPEN_CREATE_SNAPSHOT_MODAL});
export const closeCreateSnapshotModal = () => ({type: CLOSE_CREATE_SNAPSHOT_MODAL});
export const createSnapshotRequest = (reportId, name) => ({type: CREATE_SNAPSHOT_REQUEST, reportId, name});
export const createSnapshotDone = () => ({type: CREATE_SNAPSHOT_DONE});

export const showReportInfoModal = report => ({type: SHOW_REPORT_INFO_MODAL, report});
export const hideReportInfoModal = () => ({type: HIDE_REPORT_INFO_MODAL});