import React, { Component } from "react";
import AddWordsComponent from "./add-words-component";
import { connect } from "react-redux";
import {
  addInput,
  addWordsRequest, closeAddWordsModal, commentChange,
  getTaxonomyListRequest,
  removeInput,
  removeWhitespaces, setWordsAreEmpty,
  taxonomyChange, textAreaWordsChange,
  wordChange
} from "./add-words-action-creators";
import {resetSelectLabelState} from "../select-label/select-label-action-creators";

class AddWordsContainer extends Component {

  state = {
    commentValue: ""
  };

  toggleLabelSelection = () =>
    this.setState(prevState => ({
      labelSelectionIsShown: !prevState.labelSelectionIsShown
    }));

  selectLabelColor = color =>
    this.setState({ selectedLabelColor: color, labelSelectionIsShown: false });

  addWords = () => {
    if (!this.props.textAreaWords) {
      this.props.setWordsAreEmpty();
    } else {
      this.props.addWordsRequest();
      this.props.resetSelectLabelState();
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.modalIsShown !== this.props.modalIsShown && this.props.modalIsShown) {
      this.props.getTaxonomyList();
    }
  }

  closeModal = () => {
    this.props.closeModal();
    this.props.resetSelectLabelState();
  };

  render() {
    return (
      <AddWordsComponent
        {...this.props}
        addWords={this.addWords}
        selectedLabelColor={this.state.selectedLabelColor}
        commentValue={this.state.commentValue}
        labelSelectionIsShown={this.state.labelSelectionIsShown}
        toggleLabelSelection={this.toggleLabelSelection}
        selectLabelColor={this.selectLabelColor}
        onCloseModal={this.closeModal}
      />
    );
  }
}

const mapStateToProps = state => ({
  taxonomyList: state.addWords.taxonomyList,
  selectedTaxonomy: state.addWords.selectedTaxonomy,
  loading: state.addWords.loading,
  error: state.addWords.error,
  words: state.addWords.words,
  modalIsShown: state.addWords.modalIsShown,
  validation: state.addWords.validation,
  textAreaWords: state.addWords.textAreaWords,
  textAreaWordsProcessed: state.addWords.textAreaWordsProcessed,
  wordsAreEmpty: state.addWords.wordsAreEmpty,
  comment: state.addWords.comment
});

const mapDispatchToProps = dispatch => ({
  textAreaWordsChange: words => dispatch(textAreaWordsChange(words)),
  getTaxonomyList: () => dispatch(getTaxonomyListRequest()),
  wordChange: (word, index) => dispatch(wordChange(word, index)),
  taxonomyChange: taxonomy => dispatch(taxonomyChange(taxonomy)),
  addInput: () => dispatch(addInput()),
  removeInput: index => dispatch(removeInput(index)),
  addWordsRequest: () => dispatch(addWordsRequest()),
  removeWhiteSpaces: () => dispatch(removeWhitespaces()),
  closeModal: () => dispatch(closeAddWordsModal()),
  resetSelectLabelState: () => dispatch(resetSelectLabelState()),
  setWordsAreEmpty: () => dispatch(setWordsAreEmpty()),
  commentChange: comment => dispatch(commentChange(comment))
});

const AddWords = connect(mapStateToProps, mapDispatchToProps)(AddWordsContainer);
export default AddWords;
