import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import MetricBarChart from "./MetricBarChart";
import MetricListOfResponses from "./MetricListOfResponses";
import MetricKeyValuePairs from "./MetricKeyValuePairs";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  metricItem: {
    padding: theme.spacing(2)
  },
  metricItemHeading: {
    marginBottom: theme.spacing(2)
  },
}));

const ReportMetricItem = ({metricItem}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  let metricItemContent;

  switch (metricItem.type) {
    case 'key_value_pairs':
      metricItemContent = <MetricKeyValuePairs metricItem={metricItem} />;
      break;
    case 'bar_chart':
      metricItemContent = <MetricBarChart metricItem={metricItem} />;
      break;
    case 'list_of_responses':
      metricItemContent = <MetricListOfResponses metricItem={metricItem} />;
      break;
    default:
      metricItemContent = <div>{t('unknown_metric_type', 'Unknown metric type')}</div>;
  }

  return (
    <Grid item>
      <Paper className={classes.metricItem}>
        <div className={classes.metricItemHeading}>
          <Typography variant="h5">{metricItem.title}</Typography>
          {metricItem.description && <Typography>{metricItem.description}</Typography>}
        </div>

        {metricItemContent}
      </Paper>
    </Grid>
  )
};

export default ReportMetricItem;