import React, {useContext, useState} from 'react';
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import FilterCheckbox from "./FilterCheckbox";
import FilterItem from "./FilterItem";
import {changeFilter} from "../filters-actions";
import {EmoContext} from "../filters";

const useStyles = makeStyles(theme => ({
  menuList: {
    width: '100%',
  },
  menuItemTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  menuItem: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  count: {
    marginRight: theme.spacing(1)
  },
  arrowClose: {
    transform: 'rotate(0deg)',
    transition: '100ms linear',
  },
  arrowOpen: {
    transform: 'rotate(180deg)',
    transition: '100ms linear',
  },
}));

const SingleValueFilterItem = ({filter, flatFilters}) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const flatFilter = flatFilters[filter.item.id];
  const showEmo = useContext(EmoContext);

  const toggleSelected = () => dispatch(changeFilter(flatFilter, {
    selected: !flatFilter.selected,
    partial: false
  }));

  const toggleOpen = e => {
    e.stopPropagation();
    setIsOpen(isOpen => !isOpen);
  };

  if (filter.children.length === 0) {
    return (
      <MenuItem className={classes.menuItem} onClick={toggleSelected}>
        <div className={classes.title}>
          <FilterCheckbox selected={flatFilter.selected} partial={flatFilter.partial}/>
          <span title={flatFilter.title}>{flatFilter.title}</span>
        </div>
        <div className={classes.count}>
          ({showEmo && flatFilter['emo_count'] + ' / '}{flatFilter.count})
        </div>
      </MenuItem>
    );
  }

  return (
    <MenuList className={classes.menuList} disablePadding>
      <MenuItem className={classes.menuItemTitle} component={Paper} square onClick={toggleSelected}>
        <div className={classes.title}>
          <FilterCheckbox selected={flatFilter.selected} partial={flatFilter.partial}/>
          <span title={flatFilter.title}>{filter.item.title}</span>
        </div>
        <div className={classes.count}>
          ({showEmo && filter.item['emo_count'] + ' / '}{filter.item.count})
        </div>
        <IconButton size="small" onClick={toggleOpen}>
          <ExpandMoreIcon className={isOpen ? classes.arrowOpen : classes.arrowClose}/>
        </IconButton>
      </MenuItem>

      <Collapse in={isOpen} mountOnEnter>
        {filter.children.map(childFilter => (
          <FilterItem key={childFilter.item.id} filter={childFilter} flatFilters={flatFilters} />
        ))}
      </Collapse>
    </MenuList>
  );
};

export default SingleValueFilterItem;