import React, {useMemo} from "react";
import ParentGroupFilterItem from "./ParentGroupFilterItem";
import SingleValueFilterItem from "./SingleValueFilterItem";
import RangeFilterItem from "./RangeFilterItem";
import NotExistingFilterItem from "./NotExistingFilterItem";

const FilterItem = ({filter, flatFilters}) => {
  const flatFilter = flatFilters[filter.item.id];

  return useMemo(() => {
    switch (flatFilter.type) {
      case 'parent_group':
        return <ParentGroupFilterItem filter={filter} flatFilters={flatFilters} />;
      case 'single_value':
        return <SingleValueFilterItem filter={filter} flatFilters={flatFilters} />;
      case 'range':
        return <RangeFilterItem filter={filter} flatFilters={flatFilters} />;
      default:
        return <NotExistingFilterItem filter={filter} flatFilters={flatFilters} />;
    }
  }, [flatFilter]);
};

export default FilterItem;