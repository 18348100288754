import {put, putResolve, select, takeEvery} from "redux-saga/effects";
import API from "../../API-report";
import {
  APPLY_FILTERS,
  cacheUrlMappedFilters,
  FILTERS_REQUEST,
  filtersFailure,
  filtersSuccess,
  LOAD_HX_INDEX_REQUEST,
  loadHxIndexFailure, loadHxIndexSuccess, showExpiredUrlModal,
  updateSelectedFilters
} from "./filters-actions";
import {getFiltersUrlPart, parseFiltersUrlPart, toggleSelectedDown, toggleSelectedUp} from "./filters-utils";
import {CHECKSUM_SEPARATOR, DEFAULT_OPERATOR, EMPTY_FILTERS_URL_PART} from "./filters-constants";
import {replace} from "../main/history/history-actions";
import i18n from "../../i18n";

const normalizeFilters = (list, selectedFromUrl) => {
  const tree = {};
  const flatFilters = {};
  const topLevel = [];

  // Building tree, adding top level
  list.forEach(item => {
    const node = {item, children: []};
    item.childrenCount = 0;

    if (item.parentId) {
      const parent = tree[item.parentId];

      if (parent) {
        parent.children.push(node);
        parent.item.childrenCount++;
      } else {
        tree[item.parentId] = {children: [node]};
      }

      tree[item.id] = node;
    } else {
      if (tree[item.id]) {
        item.childrenCount = tree[item.id].children.length;
        tree[item.id].item = item;
      } else {
        tree[item.id] = node;
      }

      topLevel.push(node);
    }

    flatFilters[item.id] = item;
  });

  const selectedFilters = getSelectedFilters(selectedFromUrl, flatFilters);

  return {topLevel, flat: flatFilters, selectedFilters};
};

const getSelectedFilters = (selectedFromUrl, flatFilters) => {
  const selectedFilters = [];

  Object.keys(selectedFromUrl).forEach(id => {
    const filter = flatFilters[id];
    selectedFilters.push(filter);

    if (filter.type === 'single_value') {
      filter.selected = true;
      toggleSelectedUp(flatFilters, id, filter.parentId);
      toggleSelectedDown(flatFilters, id);
    } else if (filter.type === 'range') {
      filter.value = selectedFromUrl[id].value;
      toggleSelectedUp(flatFilters, id, filter.parentId);
    }
  });

  return selectedFilters;
};

const resetFilters = flatFilters => {
  Object.keys(flatFilters).forEach(id => {
    const flatFilter = flatFilters[id];

    if (flatFilter.type === 'parent_group') {
      flatFilters[id] = {
        ...flatFilters[id],
        selected: false,
        partial: false,
      };

      toggleSelectedDown(flatFilters, id);
    }
  });
};

export function* loadFiltersRequest() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const response = yield API.loadFilters(reportId);

    const filters = response.data.filters || [];
    const checksum = response.data.checksum || '';

    const [selected, operator] = yield getSelectedFromUrl(checksum);
    const {topLevel, flat, selectedFilters} = normalizeFilters(filters, selected);

    yield putResolve(filtersSuccess(topLevel, flat, selectedFilters, checksum, operator));

    const filtersUrlPart = getFiltersUrlPart();
    yield putResolve(cacheUrlMappedFilters(filtersUrlPart || EMPTY_FILTERS_URL_PART));
  } catch (error) {
    console.error('IN LOAD FILTERS', error);
    yield put(filtersFailure(i18n.t("something_went_wrong","Something went wrong...")));
  }
}

export function* getSelectedFromUrl(checksum) {
  const filtersUrlPart = getFiltersUrlPart();

  if (!filtersUrlPart) return [{}, DEFAULT_OPERATOR];

  const [urlChecksum, urlPart, operator] = filtersUrlPart.split(CHECKSUM_SEPARATOR);

  if (checksum !== urlChecksum) {
    yield put(showExpiredUrlModal());
    yield putResolve(replace({pathname: window.location.pathname}));
    return {};
  }

  if (!urlPart) return [{}, DEFAULT_OPERATOR];

  const selectedOperator = operator || DEFAULT_OPERATOR;

  return [parseFiltersUrlPart(urlPart), selectedOperator];
}

export function* applyFilters() {
  const filtersUrlPart = getFiltersUrlPart() || EMPTY_FILTERS_URL_PART;
  const cache = yield select(state => state.filters.urlMappedCache[filtersUrlPart]);

  if (cache) {
    const {filters, flatFilters, selectedFilters, checksum, operator} = cache;
    yield putResolve(filtersSuccess(filters, flatFilters, selectedFilters, checksum, operator));
  } else {
    const flatFilters = yield select(state => state.filters.flatFilters);
    let urlPart = EMPTY_FILTERS_URL_PART;
    let selectedFilters = [];

    if (filtersUrlPart === EMPTY_FILTERS_URL_PART) {
      resetFilters(flatFilters);
    } else {
      const parts = filtersUrlPart.split(CHECKSUM_SEPARATOR);
      urlPart = parts[1];

      const selectedFromUrl = parseFiltersUrlPart(urlPart);
      selectedFilters = getSelectedFilters(selectedFromUrl, flatFilters);
    }

    yield putResolve(updateSelectedFilters(flatFilters, selectedFilters));
    yield putResolve(cacheUrlMappedFilters(urlPart));
  }

  yield loadData();
}

export function* loadData() {
  const subscriptions = yield select(state => state.filters.subscriptions);

  subscriptions.forEach(subscription => {
    subscription();
  });
}

export function* loadHxIndex() {
  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  try {
    const response = yield API.loadHxIndex(reportId, filters, operator);
    yield put(loadHxIndexSuccess(response.data));
  } catch(error) {
    console.error('IN LOAD HX INDEX');
    yield put(loadHxIndexFailure(i18n.t("something_went_wrong","Something went wrong...")))
  }
}

export default [
  takeEvery(FILTERS_REQUEST, loadFiltersRequest),
  takeEvery(APPLY_FILTERS, applyFilters),
  takeEvery(LOAD_HX_INDEX_REQUEST, loadHxIndex),
];