export const BEGINNING_OF_TIME = '1970-01-01 00:00:00';
export const NUMBER_OF_MESSAGES = 10;

export const OPEN_MODE = 'open';
export const CLOSED_MODE = 'closed';

export const LAST_SEEN_DATE_KEY = 'lastSeenLiveChatMessageDate';

export const TIME_INTERVALS = {
  [OPEN_MODE]: 1000,
  [CLOSED_MODE]: 5000,
};