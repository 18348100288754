import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {IconButton, TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

const ChatInput = ({message, helpArticles, setMessage, triggerSubmit}) => {
  const {t} = useTranslation();

  const setHelpMessage = () => {
    setMessage('!help ');
  };

  const getHelpTopicsButton = (
    <Tooltip title={t('available_help_topics', 'Get available help topics')} arrow>
      <IconButton size="small" onClick={setHelpMessage}>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Autocomplete
      size="small"
      freeSolo
      options={message.startsWith('!help') ? helpArticles : []}
      onInputChange={(e, v) => setMessage(v)}
      inputValue={message}
      renderInput={params => (
        <TextField
          {...params}
          label={t('send_msg_to_support', 'Your message (Ctrl+Enter to send)')}
          multiline
          variant="outlined"
          onKeyDown={triggerSubmit}
          onChange={e => setMessage(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {getHelpTopicsButton}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default ChatInput;