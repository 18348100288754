import { call, select, put } from 'redux-saga/effects';

import API from "../../API-report";
import {loadReportInfoError, loadReportInfoSuccess} from "./report-info-action-creators";
import i18n from "../../i18n";

export function* loadReportInfoRequest() {
  const reportId = yield select(state => state.report.reportId);
  try {
    const result = yield call(API.loadReportInfo, { reportId });

    if (result.data) {
      /**
       * @property {String} report_name
       * @property {String} value_system
       * @property {String} company
       */
      const reportInfo = result.data;

      yield put(loadReportInfoSuccess(reportInfo));
    } else {
      yield put(loadReportInfoError(i18n.t('no_data', 'No data')));
    }
  } catch(error) {
    yield put(loadReportInfoError(i18n.t('something_went_wrong', 'Something went wrong...')))
  }
}
