import React from 'react';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";

const PieChartFooter = ({link}) => {
  const {t} = useTranslation();

  return (
    <Grid container justify="flex-end" component={Box} mt={2}>
      <Button component={Link} to={link} color="primary">
        {t('full_report', 'Full report')}
      </Button>
    </Grid>
  );
};

export default PieChartFooter;