import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, DialogActions, DialogContent, makeStyles, Typography} from "@material-ui/core";
import UiModal from "../../../components/UiModal/UiModal";
import {fetchFullVerbatimRequest} from "../taxonomy-actions";
import {EXAMPLE_HIGHLIGHT} from "../taxonomy-constants";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  sentence: {
    background: EXAMPLE_HIGHLIGHT,
    borderRadius: theme.shape.borderRadius,
  },
}))

const FullVerbatimDialog = ({isOpen, onClose, coordinates}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector(state => state.taxonomy.fullVerbatimLoading);
  const fullVerbatim = useSelector(state => state.taxonomy.fullVerbatim);

  useEffect(() => {
    if (coordinates) {
      dispatch(fetchFullVerbatimRequest(coordinates));
    }
  }, [dispatch, coordinates]);

  let resultsArea = null;

  if (fullVerbatim) {
    resultsArea = (
      <Typography variant="body1">
        {fullVerbatim['before_sentence_chunk']}
        <span className={classes.sentence}>{fullVerbatim['sentence_chunk']}</span>
        {fullVerbatim['after_sentence_chunk']}
      </Typography>
    );
  }

  if (loading) {
    resultsArea = <CircularProgress/>;
  }

  return (
    <UiModal
      title={t('view_full_verbatim_of_example', 'View full verbatim of the example')}
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
    >
      <DialogContent dividers>
        {resultsArea}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close', 'Close')}</Button>
      </DialogActions>
    </UiModal>
  );
};

export default FullVerbatimDialog;