import React, {useCallback, useEffect, useState} from 'react';
import BreakdownChart from "./BreakdownChart";
import {createColor} from "../../../utilities/getColor";
import {
  BREAKDOWN_CHART_MODE_BINARY,
  BREAKDOWN_CHART_MODE_NORMAL,
  CHART_TABLE_TITLES,
  DISTRIBUTION_HELP_TEXT_SENTIMENT,
  PER_RESPONSE_HELP_TEXT_SENTIMENT
} from "../constants";
import ColorCircle from "../components/ColorCircle";
import {formatPercent} from "../functions";
import withBreakdownData from "../hoc/withBreakdownData";
import {useTranslation} from "react-i18next";


const chartTitle = {
  normal: ['sentiment_breakdown', 'Sentiment Breakdown'],
  binary: ['sentiment_breakdown_bin', 'Sentiment Breakdown (binary)'],
};

const dataType = 'sentiment';

const SentimentBreakdownChart = props => {
  const {t} = useTranslation();
  const [mode, setMode] = useState(BREAKDOWN_CHART_MODE_NORMAL);
  const height = props.height || '300px';

  const valueName = t('sentiment_name', 'Sentiment');

  const onFiltersChange = useCallback(() => props.loadPrimarySecondarySentimentRequest(), []);

  useEffect(() => {
    props.subscribeToFilters(onFiltersChange);

    return () => {
      props.unsubscribeFromFilters(onFiltersChange);
    }
  }, []);

  const colors = props?.valueSystem?.['sentiment_color'] ?? {};

  const transformDataByMode = (data, mode) => {
    return data.map(d => ({
      sentiment: d.sentiment,
      color: createColor(colors[d.sentiment]),
      ...d.modes[mode],
    }));
  };

  const columns = [
    {name: t('color', 'Color'), key: 'color', renderCell: color => <ColorCircle color={color}/>, renderTooltip: false},
    {name: valueName, key: dataType},
    {name: t(CHART_TABLE_TITLES[mode].count[0], CHART_TABLE_TITLES[mode].count[1]), key: 'count'},
  ];

  if (mode === BREAKDOWN_CHART_MODE_NORMAL) {
    columns.push({
      name: t(CHART_TABLE_TITLES[mode].distribution[0], CHART_TABLE_TITLES[mode].distribution[1]),
      key: 'distribution',
      transform: formatPercent,
      helpText: t(DISTRIBUTION_HELP_TEXT_SENTIMENT[0], DISTRIBUTION_HELP_TEXT_SENTIMENT[1])
    });
  } else if (mode === BREAKDOWN_CHART_MODE_BINARY) {
    columns.push({
      name: t(CHART_TABLE_TITLES[mode].per_response[0], CHART_TABLE_TITLES[mode].per_response[1]),
      key: 'per_response',
      transform: formatPercent,
      helpText: t(PER_RESPONSE_HELP_TEXT_SENTIMENT[0], PER_RESPONSE_HELP_TEXT_SENTIMENT[1])
    });
  }

  const indexData = props.data.map(d => ({
    color: createColor(colors[d.sentiment]),
    name: d.sentiment
  }));

  const [titleId, titleDefault] = chartTitle[mode]

  return (
    <BreakdownChart
      chartTitle={t(titleId, titleDefault)}
      transformDataByMode={transformDataByMode}
      valueName={valueName}
      dataType={dataType}
      columns={columns}
      mode={mode}
      setMode={setMode}
      height={height}
      indexData={indexData}
      {...props}
    />
  )
};

export default withBreakdownData('sentiment')(SentimentBreakdownChart);
