import React from "react";
import "./add-words.css";
import SelectLabel from "../select-label/select-label";
import {Link} from "react-router-dom";
import {Grid, TextField, Typography, Button, DialogContent, DialogActions} from "@material-ui/core";
import UiModal from "../../components/UiModal/UiModal";
import Alert from "@material-ui/lab/Alert";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";
import {useTranslation} from "react-i18next";

const AddWordsComponent = ({
                             taxonomyList,
                             taxonomyChange,
                             selectedTaxonomy,
                             loading,
                             closeModal,
                             words,
                             wordChange,
                             addInput,
                             removeInput,
                             modalIsShown,
                             addWords,
                             onCloseModal,
                             validation,
                             textAreaWordsChange,
                             textAreaWords,
                             textAreaWordsProcessed,
                             wordsAreEmpty,
                             commentChange,
                             comment
                           }) => {
  const {t} = useTranslation();

  return (
    <UiModal
      open={modalIsShown}
      onClose={onCloseModal}
      title={t('add_words', "Add Words")}
    >
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <span>{t('select_taxonomy', 'Select taxonomy')}</span>
          </Grid>
          <Grid item xs>
            {taxonomyList.length ? (
              <TextField
                onChange={e => taxonomyChange(e.target.value)}
                value={selectedTaxonomy}
                fullWidth
                select
                variant="outlined"
              >
                {taxonomyList.map((t, i) => (
                  <option key={i} value={t._id}>
                    {t._id}
                  </option>
                ))}
              </TextField>) : (
              <span>{t('taxonomy_list_is_empty', 'Taxonomy list is empty...')}</span>
            )}
          </Grid>

          <Grid item xs>
            <Link
              to="/add-taxonomy"
              style={{
                textDecoration: 'none'
              }}
              onClick={closeModal}
            >
              {t('add_taxonomy', 'Add taxonomy')}
            </Link>
          </Grid>

          <Grid item xs>
            <span>{t('new_words', 'New words')}:</span>
          </Grid>

          <Grid item xs>
            <TextField
              value={textAreaWords}
              onChange={e => textAreaWordsChange(e.target.value)}
              multiline
              variant="outlined"
            />
          </Grid>
          <Grid item xs style={{display: wordsAreEmpty ? 'block' : 'none'}}>
            <Alert severity="warning">
              {t('it_should_not_be_empty', 'It should not be empty')}
            </Alert>
          </Grid>

          <Grid item xs style={{display: textAreaWordsProcessed.length ? 'block' : 'none'}}>
            <Typography variant="subtitle2">{t('these_words_you_want_to_add', 'These words you want to add')}:</Typography>
            {
              textAreaWordsProcessed.map((w, i) => (
                  <div key={i}>
                    <span>{i + 1} </span>
                    <span>{w}</span>
                  </div>
                )
              )
            }
          </Grid>
          <Grid item xs>
            <SelectLabel/>
          </Grid>
          <Grid item xs>
            <span style={{marginBottom: '10px'}}>{t('write_comment_for_words', 'Write comment for the words')}:</span>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={comment}
              onChange={e => commentChange(e.target.value)}
              size="medium"
            />
          </Grid>
          <DialogActions>
            <Button color="inherit" onClick={onCloseModal}>{t('cancel', 'Cancel')}</Button>
            <ButtonWithProgress color="primary" loading={loading} onClick={addWords}>
              {t('save', 'Save')}
            </ButtonWithProgress>
          </DialogActions>
        </Grid>
      </DialogContent>
    </UiModal>
  );
};

export default AddWordsComponent;
