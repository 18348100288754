import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

import {Box, Grid, ListItemIcon, MenuItem, MenuList, Paper, Slider, Typography, CircularProgress} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LabelIcon from '@material-ui/icons/Label';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FilterListIcon from "@material-ui/icons/FilterList";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import DrawerWithContent from "../../components/Drawer/DrawerWithContent";
import FiltersContainer from "../filters/filters-container";
import BubbleChartSvg from "../../components/Charts/bubble-chart/bubble-chart-svg";

import {debounce} from "../../utilities/functions";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";
import {toggleMobileDrawer} from "../main/main-actions";
import {loadPrimarySecondarySentimentRequest} from "../charts/charts-action-creators";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    margin: -theme.spacing(1)
  },
  darkContainer: {
    background: '#24323F',
    '& $link': {
      stroke: 'rgba(255, 255, 255, 0.3)',
    }
  },
  lightContainer: {
    background: 'lightgray',
    '& $link': {
      stroke: 'rgba(36, 50, 63, 0.5)'
    }
  },
  tools: {
    position: 'fixed',
    top: theme.mixins.toolbar.minHeight + theme.spacing(2),
    left: 0,
    width: 220,
    zIndex: 9,
  },
  link: {
    strokeWidth: 1
  },
  loadingContainer: {
    width: '100%',
    height: '100%'
  }
}));

const BubbleChartContainer = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    primary: data,
    primarySecondarySentimentLoading: loading
  } = useSelector(state => state.charts, shallowEqual);

  const reportId = useSelector(state => state.report.reportId);

  const [state, setState] = useState({
    mode: 'dark',
    limit: 0,
    primaryLimit: 0,
    showLabels: false,
  });

  const onFiltersChange = useCallback(() => {
    dispatch(loadPrimarySecondarySentimentRequest());
  }, []);

  useEffect(() => {
    onFiltersChange();
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    };
  }, [dispatch, onFiltersChange]);

  const toggleLightMode = () => {
    setState(prevState => {
      const newMode = prevState.mode === 'light' ? 'dark' : 'light';

      return {
        ...prevState,
        mode: newMode
      };
    });
  };

  const modifyState = useCallback((key, value) => {
    setState(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    })
  }, []);

  const debounceStateModification = useCallback(debounce(modifyState, 100), []);
  const containerColorClass = state.mode === 'dark' ? classes.darkContainer : classes.lightContainer;

  return (
    <DrawerWithContent fullscreen drawerContent={<FiltersContainer/>}>
      <div className={`${classes.container} ${containerColorClass}`}>
        <Grid container alignItems="center" className={classes.tools}>
          <Paper>
            <MenuList>
              <MenuItem component={Link} to={'/report/' + reportId}>
                <ListItemIcon>
                  <ArrowBackIcon/>
                </ListItemIcon>
                <Typography>{t('back_to_report','Back to Report')}</Typography>
              </MenuItem>

              <MenuItem onClick={() => dispatch(toggleMobileDrawer())}>
                <ListItemIcon>
                  <FilterListIcon/>
                </ListItemIcon>
                <Typography>{t('show_filters', 'Show Filters')}</Typography>
              </MenuItem>

              <MenuItem onClick={() => modifyState('showLabels', true)}>
                <ListItemIcon>
                  <LabelIcon/>
                </ListItemIcon>
                <Typography>{t('show_all_labels', 'Show All labels')}</Typography>
              </MenuItem>

              <MenuItem onClick={() => modifyState('showLabels', false)}>
                <ListItemIcon>
                  <LabelOffIcon/>
                </ListItemIcon>
                <Typography>{t('hide_all_labels', 'Hide All labels')}</Typography>
              </MenuItem>

              <MenuItem onClick={toggleLightMode}>
                <ListItemIcon>
                  {state.mode === 'light' ? <CheckBoxIcon color="primary"/> : <CheckBoxOutlineBlankIcon/>}
                </ListItemIcon>
                <Typography>{t('light_mode', 'Light mode')}</Typography>
              </MenuItem>
            </MenuList>
            <Box p={2}>
              {t('min_power_index', 'Minimal Power Index (PI)')}
              <Slider
                defaultValue={0}
                aria-labelledby="minimal-pi-slider"
                valueLabelDisplay="auto"
                onChange={(_, value) => debounceStateModification('limit', value)}
                track="inverted"
                marks
                min={0}
                max={100}
              />

              {t('min_primary_percent', 'Minimal Primary Percent')}
              <Slider
                defaultValue={0}
                aria-labelledby="minimal-primary-slider"
                valueLabelDisplay="auto"
                onChange={(_, value) => debounceStateModification('primaryLimit', value)}
                track="inverted"
                marks
                min={0}
                max={100}
              />
            </Box>
          </Paper>
        </Grid>

        {loading ? (
          <Grid container alignItems="center" justify="center" className={classes.loadingContainer}>
            <CircularProgress/>
          </Grid>
        ) : (
          <BubbleChartSvg
            data={data}
            limit={state.limit}
            primaryLimit={state.primaryLimit}
            showLabels={state.showLabels}
            themeClasses={classes}
          />
        )}
      </div>
    </DrawerWithContent>
  );
};

export default BubbleChartContainer;
