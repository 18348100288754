import React, {useCallback, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import withFilter from "../../hoc/with-filter/with-filter-hoc";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {reportMetricsLoadRequest} from "./report-metrics-actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import ReportMetricItem from "./components/ReportMetricItem";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";

const useStyles = makeStyles(theme => ({
  loading: {
    padding: theme.spacing(3)
  },
}));

const ReportMetrics = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onFiltersChange = useCallback(() => {
    dispatch(reportMetricsLoadRequest());
  }, []);

  const {loading, reportMetrics} = useSelector(state => state.reportMetrics, shallowEqual);

  useEffect(() => {
    onFiltersChange();
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    }
  }, []);

  let metrics = (
    <Grid container alignItems="center" justify="center" className={classes.loading}>
      <Grid item>
        <CircularProgress/>
      </Grid>
    </Grid>
  );

  if (!loading) {
    metrics = (
      <Grid container direction="column" spacing={1}>
        {reportMetrics.map(metricItem => (
          <ReportMetricItem key={metricItem.title} metricItem={metricItem}/>
        ))}
      </Grid>
    )
  }

  return (
    <Grid item container justify="flex-start" alignItems="flex-end">
      {metrics}
    </Grid>
  );
};

export default withFilter(ReportMetrics);