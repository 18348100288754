import {
  LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_ERROR,
  LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST,
  LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_SUCCESS, LOAD_SPLIT_CHART_SENTIMENT_ERROR,
  LOAD_SPLIT_CHART_SENTIMENT_REQUEST, LOAD_SPLIT_CHART_SENTIMENT_SUCCESS
} from "./split-charts-actions";

const initialState = {
  primaryEmotionsData: [],
  primaryEmotionsStatisticalSignificanceData: [],
  primaryEmotionsLoading: false,
  primaryEmotionsError: false,
  sentimentData: [],
  sentimentsStatisticalSignificanceData: [],
  sentimentLoading: false,
  sentimentError: false
};


const splitChartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_REQUEST:
      return {
        ...state,
        primaryEmotionsLoading: true
      };
    case LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_SUCCESS:
      return {
        ...state,
        primaryEmotionsLoading: false,
        primaryEmotionsError: false,
        primaryEmotionsData: action.data,
        primaryEmotionsStatisticalSignificanceData: action.statisticalSignificanceData,
      };
    case LOAD_SPLIT_CHART_PRIMARY_EMOTIONS_ERROR:
      return {
        ...state,
        primaryEmotionsLoading: false,
        primaryEmotionsError: true,
        primaryEmotionsData: []
      };
    case LOAD_SPLIT_CHART_SENTIMENT_REQUEST:
      return {
        ...state,
        sentimentLoading: true
      };
    case LOAD_SPLIT_CHART_SENTIMENT_SUCCESS:
      return {
        ...state,
        sentimentLoading: false,
        sentimentError: false,
        sentimentData: action.data,
        sentimentsStatisticalSignificanceData: action.statisticalSignificanceData
      };
    case LOAD_SPLIT_CHART_SENTIMENT_ERROR:
      return {
        ...state,
        sentimentLoading: false,
        sentimentError: true,
        sentimentData: []
      };
    default:
      return state;
  }
};

export default splitChartsReducer;
