import {
  INIT_CHAT,
  NEW_MESSAGES,
  SEND_MESSAGE_DONE,
  SEND_MESSAGE_REQUEST,
  SET_HELP_ARTICLES,
  SET_UNSEEN_MESSAGES
} from "./chat-actions";

const initialState = {
  sendLoading: false,
  messages: [],
  unseenMessages: 0,
  helpArticles: [],
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CHAT:
      return {...initialState};
    case SEND_MESSAGE_REQUEST:
      return {...state, sendLoading: true};
    case SEND_MESSAGE_DONE:
      return {...state, sendLoading: false};
    case NEW_MESSAGES:
      return {...state, messages: state.messages.concat(action.messages)};
    case SET_UNSEEN_MESSAGES:
      return {...state, unseenMessages: action.number};
    case SET_HELP_ARTICLES:
      return {...state, helpArticles: action.helpArticles};
    default:
      return state;
  }
};

export default chatReducer;