export const SET_SEARCH_CRITERIA = '[NGRAM SEARCH] SET_SEARCH_CRITERIA';
export const SEARCH_REQUEST = '[NGRAM SEARCH] SEARCH_REQUEST';
export const SEARCH_SUCCESS = '[NGRAM SEARCH] SEARCH_SUCCESS';
export const SHOW_SEARCH_MODAL = '[NGRAM SEARCH] SHOW_SEARCH_MODAL';
export const HIDE_SEARCH_MODAL = '[NGRAM SEARCH] HIDE_SEARCH_MODAL';

export const setSearchCriteria = (searchCriteria) => {
  return {type: SET_SEARCH_CRITERIA, searchCriteria};
};

export const searchRequest = (searchCriteria) => {
  return {type: SEARCH_REQUEST, ...searchCriteria};
};

export const searchSuccess = (searchResults) => {
  return {type: SEARCH_SUCCESS, searchResults: searchResults};
};

export const showSearchModal = (reportId) => {
  return {type: SHOW_SEARCH_MODAL, reportId};
};

export const hideSearchModal = () => {
  return {type: HIDE_SEARCH_MODAL};
};