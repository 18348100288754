import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import UiModal from "../../components/UiModal/UiModal";
import NgramSearchForm from './ngram-search-form';
import {hideSearchModal} from "./ngram-search-actions";

const NgramSearchModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {show, reportId} = useSelector(state => ({
    show: state.ngramSearch.modalIsShown,
    reportId: state.ngramSearch.modalSelectedReportId
  }), shallowEqual);

  return (
    <UiModal
      open={show}
      title={t('search_by_word', 'Search by word')}
      onClose={() => dispatch(hideSearchModal())}
      maxWidth="sm"
    >
      <NgramSearchForm reportId={reportId} />
    </UiModal>
  )
}

export default NgramSearchModal;