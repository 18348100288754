import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Tooltip} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  helpTextIcon: {
    verticalAlign: 'middle',
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  helpTextTooltip: {
    fontSize: '0.9rem',
    lineHeight: '1rem',
  },
}))

const PieChartLegend = ({data, columns}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  if (!data.length) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columns.map(col => (
            <TableCell key={col.name}>
              {col.name}
              {col.helpText && (
                <Tooltip
                  title={(
                    <div className={classes.helpTextTooltip}>{col.helpText}</div>
                  )}
                >
                  <HelpOutlineIcon className={classes.helpTextIcon} color="secondary"/>
                </Tooltip>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(((row, i) => (
          <TableRow key={i}>
            {columns.map(col => {
              let value = row[col.key];

              if (col.transform) {
                value = col.transform(value, row);
              }

              if (col.renderCell) {
                value = col.renderCell(value, row);
              }

              return (
                <TableCell key={col.name}>{value}</TableCell>
              )
            })}
          </TableRow>
        )))}
      </TableBody>

      <TableFooter>
        <TableRow>
          <TableCell>{t('total', 'Total')}:</TableCell>
          <TableCell/>
          <TableCell>{data.reduce((acc, row) => acc + row.count, 0)}</TableCell>
          <TableCell/>
        </TableRow>
      </TableFooter>

    </Table>
  );
};

export default PieChartLegend;
