import {connect} from "react-redux";
import {loadPrimarySecondarySentimentRequest} from "../charts-action-creators";

import {addNotification} from "../../notifications/notifications-actions";
import {subscribeToFilters, unsubscribeFromFilters} from "../../filters/filters-actions";

const mapStateToProps = dataType => state => ({
  data: state.charts[dataType],
  error: state.charts.primarySecondarySentimentError,
  loading: state.charts.primarySecondarySentimentLoading,
  valueSystem: state.report.valueSystem,
  reportId: state.report.reportId,
});

const mapDispatchToProps = dispatch => ({
  loadPrimarySecondarySentimentRequest: () => dispatch(loadPrimarySecondarySentimentRequest()),
  subscribeToFilters: subscription => dispatch(subscribeToFilters(subscription)),
  unsubscribeFromFilters: subscription => dispatch(unsubscribeFromFilters(subscription)),
  addNotificationRequest: (status, message) => dispatch(addNotification({status, message: [message]})),
});

const withBreakdownData = dataType => Component => {
  return connect(mapStateToProps(dataType), mapDispatchToProps)(Component);
};

export default withBreakdownData;