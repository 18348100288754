import React from 'react';
import {useTranslation} from "react-i18next";
import UiModal from "../../../components/UiModal/UiModal";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {hideReportInfoModal} from "../reports-actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import i18n from "../../../i18n";

const useStyles = makeStyles(theme => ({
  Description: {
    whiteSpace: 'pre-wrap'
  }
}))

const humanizeCalculationTime = time => {
  const parsed = time.match(/(\d+):(\d+):(\d+).(\d+)/);

  if (!parsed) return time;

  let result = [];

  let hours = parseInt(parsed[1], 10);
  let minutes = parseInt(parsed[2], 10);
  let seconds = parseInt(parsed[3], 10);

  if (hours) result.push(hours + i18n.t('hours_abbr', 'h'));
  if (minutes) result.push(minutes + i18n.t('minutes_abbr', 'm'));
  result.push(seconds + i18n.t('seconds_abbr', 's'));

  return result.join(' ');
};

const ReportInfoModal = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const report = useSelector(state => state.reports.reportInfoModalReport);

  let content = null;

  const close = () => dispatch(hideReportInfoModal());

  if (report) {
    content = (
      <React.Fragment>
        <DialogContent dividers>
          <Grid container direction="column" spacing={1}>
            {Object.keys(report['sorted_info']).map(key => {
              let value = report['sorted_info'][key];

              if (key === 'Calculation Time') {
                value = humanizeCalculationTime(value);
              } else if (Array.isArray(value)) {
                value = value.join(', ');
              } else {
                try {
                  value = value.toString();
                } catch (e) {
                  // do nothing, it's probably null or undefined
                }
              }

              return (
                <Grid item key={key} className={classes[key]}>
                  <strong>{key}: </strong>
                  <span>{value}</span>
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t('close', 'Close')}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  return (
    <UiModal
      title={report ? report['report_name'] : t('report_info', 'Report info')}
      open={!!report}
      onClose={close}
      maxWidth="sm"
    >
      {content}
    </UiModal>
  );
};

export default ReportInfoModal;