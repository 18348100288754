import React from 'react';
import {Link} from "react-router-dom";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import Paginator from "../../components/Paginator/Paginator";
import IconButtonWithCircularProgress from "../../components/IconButtonWithCircularProgress/IconButtonWithCircularProgress";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  }
}));

const useCellStyles = makeStyles({
  link: {
    textDecoration: 'none'
  }
})

const CellContents = ({reportId, d, col}) => {
  const classes = useCellStyles();

  switch (col.name) {
    case 'percent':
      return `${d[col.name]}%`;
    case 'term':
      return <Link to={`/report/${reportId}/search/${d[col.name]}/0/0`} className={classes.link}>{d[col.name]}</Link>
    default:
      return d[col.name]
  }
}

const TopWordsTable = (
  {
    columns,
    data,
    page,
    changePage,
    searchChange,
    searchValue,
    reportId,
    download,
    downloading,
  }
) => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={2} component={Box} p={2} mb={2}>
      <Typography variant="h5" className={classes.heading}>{t("top_words_full_repo", "Top Words Full Report")}</Typography>
      <Box mb={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item style={{flexGrow: 1}}>
            <TextField
              fullWidth
              label={t("search_words", "Search words")}
              value={searchValue}
              onChange={e => searchChange(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Paginator
              changePage={changePage}
              page={page}
              totalPages={Object.keys(data).length}
            />
          </Grid>
          <Grid item>
            <IconButtonWithCircularProgress
              IconButtonProps={{
                type: 'button',
                onClick: download,
                disabled: downloading,
              }}
              loading={downloading}
            >
              <GetAppIcon/>
            </IconButtonWithCircularProgress>
          </Grid>
        </Grid>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <TableCell key={i} style={{width: col.width}}>
                {col.displayName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data[page].map((d, i) => (
            <TableRow key={i}>
              {columns.map((col, i) => (
                <TableCell key={i} >
                  <CellContents reportId={reportId} d={d} col={col}/>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
};

export default TopWordsTable;
