import {RESET_SELECT_LABEL_STATE, SELECT_LABEL_COLOR} from "./select-label-action-creators";

const initialState = {
  selectedLabelColor: ""
};

const selectLabelReducer = ( state = initialState, action ) => {
  const { color } = action;

  switch (action.type) {
    case SELECT_LABEL_COLOR:
      return {
        ...state,
        selectedLabelColor: color,
        labelSelectionIsShown: false
      };

    case RESET_SELECT_LABEL_STATE:
      return {
        ...state,
        labelSelectionIsShown: false,
        selectedLabelColor: ""
      };

    default:
      return state;
  }
};

export default selectLabelReducer;