import React, {createRef, useEffect, useState} from 'react';
import saveAs from "file-saver";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ImageIcon from "@material-ui/icons/Image";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BarChartIcon from '@material-ui/icons/BarChart';

import {BREAKDOWN_CHART_TYPE_BAR, BREAKDOWN_CHART_TYPE_DOUGHNUT, CHART_TABLE_TITLES} from "../constants";
import {createAndCopyTable} from "../functions";

import ChartBox from "../../../components/Charts/chart-box";
import PieChartLegend from "../../../components/Charts/pie-chart-legend/pie-chart-legend-component";
import Chart from "../components/Chart";
import {REPORT_MODE_NORMAL} from "../../report/report-constants";
import ChartIndex from "../../../components/Charts/pie-chart-index/chart-index";
import {useTranslation} from "react-i18next";


const prepareChartData = (data, dataType, dataKey) => {
  const dataset = [];
  const labels = [];
  const backgroundColor = [];

  data.forEach(d => {
    dataset.push(d[dataKey]);
    labels.push(d[dataType]);
    backgroundColor.push(d.color)
  });

  return {
    datasets: [{
      data: dataset,
      backgroundColor,
    }],
    labels,
    originalData: data
  };
};

const BreakdownChart = (
  {
    chartTitle,
    dataType,
    columns,
    transformDataByMode,
    data,
    error,
    loading,
    height,
    addNotificationRequest,
    footerLink,
    hideLegend,
    mode,
    indexData,
  }) => {

  const {t} = useTranslation();
  const isNormalMode = mode === REPORT_MODE_NORMAL;

  const [chartType, setChartType] = useState(BREAKDOWN_CHART_TYPE_DOUGHNUT);
  const [isNormalized, setIsNormalized] = useState(false);

  useEffect(() => {
    setChartType(isNormalMode ? BREAKDOWN_CHART_TYPE_DOUGHNUT : BREAKDOWN_CHART_TYPE_BAR);
    setIsNormalized(false);
  }, [isNormalMode]);

  useEffect(() => {
    if (chartType === BREAKDOWN_CHART_TYPE_DOUGHNUT) {
      setIsNormalized(false);
    }
  }, [chartType]);

  const chartRef = createRef();

  try {
    data = transformDataByMode(data, mode);
  } catch (e) {
    data = [];
  }

  /* Menu */
  const menuItems = [
    {
      text: t('download_as_png', 'Download as PNG'),
      onClick: () => {
        chartRef.current.chartInstance.canvas.toBlob(function (blob) {
          saveAs(blob, chartTitle + '.png');
        });
      },
      icon: <ImageIcon fontSize="small"/>,
    },
    {
      onClick: () => {
        createAndCopyTable(data, columns);
        addNotificationRequest('Success', t('copied_to_clipboard_notification', 'Copied to clipboard!'))
      },
      icon: <FileCopyIcon fontSize="small"/>,
      text: t('copy_to_clipboard', 'Copy to clipboard')
    },
    {
      divider: true
    }
  ];

  const doughnutChartItem = {
    text: t('doughnut_chart', 'Doughnut Chart'),
    onClick: () => setChartType(BREAKDOWN_CHART_TYPE_DOUGHNUT),
    icon: <DonutLargeIcon fontSize="small"/>,
    selected: chartType === BREAKDOWN_CHART_TYPE_DOUGHNUT,
  };

  const barChartItem = {
    text: t('bar_chart', 'Bar Chart'),
    onClick: () => setChartType(BREAKDOWN_CHART_TYPE_BAR),
    icon: <BarChartIcon fontSize="small"/>,
    selected: chartType === BREAKDOWN_CHART_TYPE_BAR,
  };

  const isNormalizedItem = {
    text: t('normalized', "Normalized"),
    onClick: () => setIsNormalized(prev => !prev),
    icon: isNormalized ? <CheckBoxIcon/> : <CheckBoxOutlineBlank fontSize="small"/>,
    props: {
      disabled: chartType !== BREAKDOWN_CHART_TYPE_BAR
    },
  };

  if (isNormalMode) {
    menuItems.push(doughnutChartItem, barChartItem, isNormalizedItem);
  } else {
    menuItems.push(barChartItem, isNormalizedItem);
  }

  menuItems.push({divider: true});

  const dataKey = isNormalMode ? 'distribution' : 'per_response'

  const chartData = prepareChartData(data, dataType, dataKey);

  const specificOptions = {};

  if (isNormalized) {
    specificOptions.yAxisMax = 1;
  }


  let chart = (
    <Chart
      height={height}
      chartData={chartData}
      tooltipColumns={columns}
      chartType={chartType}
      ref={chartRef}
      specificOptions={specificOptions}
    />
  );

  const [subTitleId, subTitleDefault] = CHART_TABLE_TITLES[mode][dataKey];

  return (
    <ChartBox
      title={chartTitle}
      subTitle={t(subTitleId, subTitleDefault)}
      footerLink={footerLink}
      loading={loading}
      error={error}
      actionsMenuItems={menuItems}
    >
      {chart}

      <ChartIndex indexData={indexData} />

      {!hideLegend && (
        <PieChartLegend
          data={data}
          columns={columns}
        />
      )}
    </ChartBox>
  );
};


export default BreakdownChart;