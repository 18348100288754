import {
  CHANGE_PAGE_REQUEST,
  CHANGE_PAGE_SUCCESS, CHANGE_TAB, LOAD_RESPONSES_ERROR, LOAD_RESPONSES_REQUEST, LOAD_RESPONSES_SUCCESS, RESET,
} from "./responses-action-types";

const initialState = {
  responses: [],
  page: 1,
  totalPages: null,
  loading: false,
  error: '',
  tabs: [],
  selectedTab: null,
  hiddenVerbatims: ''
};

const responsesReducer = (state = initialState, action) => {
  switch(action.type) {
    case CHANGE_PAGE_REQUEST:
      return {
        ...state,
        page: action.page
      };

    case CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        page: action.page
      };

    case LOAD_RESPONSES_REQUEST:
      return {
        ...state,
        loading: true,
        page: action.page,
        selectedTab: null,
        error: ""
      };

    case LOAD_RESPONSES_SUCCESS:
      const stateCopy = {
        ...state,
        loading: false,
        totalPages: action.totalPages,
        page: action.page,
        error: "",
        responses: action.responses,
        tabs: action.tabs,
        hiddenVerbatims: action.hiddenVerbatims
      };

      if (!stateCopy.selectedTab || !action.keepTab) {
        stateCopy.selectedTab = action.tabs[0];
      }

      return stateCopy;

    case LOAD_RESPONSES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case CHANGE_TAB:
      return {
        ...state,
        selectedTab: action.tabName
      };

    case RESET:
      return {
        ...initialState,
      }

    default:
      return state;
  }
};

export default responsesReducer;