
import {LOAD_REPORT_INFO_ERROR, LOAD_REPORT_INFO_REQUEST, LOAD_REPORT_INFO_SUCCESS} from "./report-info-action-types";

const initialState = {
  report: null,
  loading: false,
  error: ""
};


const reportInfoReducer = ( state = initialState, action ) => {
  const { reportInfo, error } = action;

  switch(action.type) {

    case LOAD_REPORT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        report: null,
        error: "",
      };

    case LOAD_REPORT_INFO_SUCCESS:
      return {
        ...state,
        report: reportInfo,
        loading: false,
        error: ""
      };

    case LOAD_REPORT_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error
      };


    default:
      return state;
  }
};

export default reportInfoReducer;
