import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, CircularProgress, Grid, TextField, Typography, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import ReportEditBottom from "../components/ReportEditBottom";

import {
  changeColumnName,
  getColumnsNamesMapConfigRequest,
  updateColumnsNamesMapRequest
} from "../create-report-action-creators";
import {useTranslation} from "react-i18next";

const EditColumnsNames = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const columnsMapLoading = useSelector(state => state.createReport.columnsNamesMapConfigLoading);
  const columnsMap = useSelector(state => state.createReport.columnsNamesMapConfig);
  const updateColumnsMapLoading = useSelector(state => state.createReport.updateColumnsNamesMapLoading);

  useEffect(() => {
    dispatch(getColumnsNamesMapConfigRequest());
  }, []);

  let hasDuplicates = false;

  const columns = columnsMap.map((col, i) => {
    let duplicate = false;

    const colMapped = col['mapped_to_name'];

    if (col['column_type'] !== 'ignore') {
      columnsMap.forEach((anotherCol, j) => {
        const anotherColMapped = anotherCol['mapped_to_name'];
        const anotherColName = anotherCol['column_name'];

        const isDuplicateOfAnotherColumn = (
          i !== j &&
          colMapped &&
          (colMapped === anotherColMapped || colMapped === anotherColName)
        );

        const isDuplicateOfSelfColumn = (
          i === j &&
          colMapped === anotherColName
        );

        if (isDuplicateOfAnotherColumn || isDuplicateOfSelfColumn) {
          duplicate = true;
          hasDuplicates = true;
        }
      });
    }

    return {...col, duplicate};
  });

  return (
    <Fragment>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('edit_col_names', 'Edit column names')}</Typography>
          </Grid>

          {columnsMapLoading && (
            <Grid item xs>
              <CircularProgress/>
            </Grid>
          )}

          {columnsMap.length > 0 && (
            <Grid item xs>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('col_type','Column type')}</TableCell>
                    <TableCell>{t('original_col_name', 'Original column name')}</TableCell>
                    <TableCell>{t('new_col_name', 'New column name')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {columns.map((column, i) => (
                    <TableRow key={column['column_name']}>
                      <TableCell component="th" scope="row">{column['column_type']}</TableCell>
                      <TableCell component="th" scope="row">{column['column_name']}</TableCell>
                      <TableCell>
                        <TextField
                          error={column['duplicate']}
                          fullWidth
                          size="small"
                          variant="outlined"
                          disabled={column['column_type'] === 'ignore'}
                          value={column['mapped_to_name'] || ''}
                          onChange={e => dispatch(changeColumnName(i, e.target.value))}
                          helperText={column['duplicate'] && 'Duplicate name'}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}

          {hasDuplicates && (
            <Grid item xs>
              <Alert severity="error">{t('duplicate_col_names_error', 'Cannot save columns. Please check column names for duplicates.')}</Alert>
            </Grid>
          )}
        </Grid>
      </Box>
      <ReportEditBottom
        disableWarning
        loading={updateColumnsMapLoading}
        enabled={!hasDuplicates}
        submit={() => dispatch(updateColumnsNamesMapRequest())}
        submitText= {t('save_col_names_map', "Save columns name map")}
      />
    </Fragment>
  );
};

export default EditColumnsNames;