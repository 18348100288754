import {call, put, select, takeEvery} from 'redux-saga/effects';

import API from '../../API';
import reportApi from '../../API-report';
import {
  ADD_TOPICS_REQUEST,
  addTopicsDone,
  CHANGE_PUBLICITY_OF_REPORT,
  CHANGE_REPORT_ID,
  changePublicityOfReportDone,
  FETCH_TOPIC_NAMESPACES_REQUEST,
  fetchTopicNamespacesFailure,
  fetchTopicNamespacesSuccess,
  setTopicsModalOpen,
  setValueSystem
} from "./report-actions";
import {loadReportInfoRequest} from "../report-info/load-report-info-request-saga";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";


export function* fetchValueSystemForReport({reportId}) {
  try {
    if (reportId === null) return yield put(setValueSystem(null));

    const response = yield call(API.valueSystemToolkit, reportId);

    yield put(setValueSystem(response.data));
  } catch (e) {
    console.error('ERROR IN FETCH_VALUE_SYSTEM', e);
  }
}

function* changePublicityOfReport() {
  try {
    const reportId = yield select(state => state.report.reportId);

    yield reportApi.changePublicityOfReport(reportId);

    yield loadReportInfoRequest();

  } catch (e) {
    console.error('ERROR IN CHANGE_PUBLICITY_OF_REPORT', e);

    yield put(addNotification({
      status: "Error",
      message: [i18n.t('could_not_change_publicity_of_report', "Could not change publicity of report. Please try again later")]
    }))
  } finally {
    yield put(changePublicityOfReportDone());
  }
}

function* getTopicsNamespaces() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const response = yield reportApi.getTopicsNamespaces(reportId);
    yield put(fetchTopicNamespacesSuccess(response.data.results));
  } catch (e) {
    console.error('ERROR IN *getTopicsNamespaces()', e);

    yield put(fetchTopicNamespacesFailure());
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('could_not_fetch_topic_namespaces', 'Could not fetch topic namespaces. Please try again later')]
    }));
  }
}

function* addTopics({payload: namespace}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    yield reportApi.addTopics(reportId, namespace);
    yield *loadReportInfoRequest();
    yield put(setTopicsModalOpen(false));
  } catch (e) {
    console.error('ERROR IN *addTopics()', e);
    yield put(addNotification({
      status: "Error",
      message: [i18n.t('could_not_add_topics_to_report', 'Could not add topics to the report. Please try again later.')]
    }));
  } finally {
    yield put(addTopicsDone());
  }
}

export default [
  takeEvery(CHANGE_REPORT_ID, fetchValueSystemForReport),
  takeEvery(CHANGE_PUBLICITY_OF_REPORT, changePublicityOfReport),
  takeEvery(FETCH_TOPIC_NAMESPACES_REQUEST, getTopicsNamespaces),
  takeEvery(ADD_TOPICS_REQUEST, addTopics),
];