import React from 'react';
import {connect, useSelector} from "react-redux";
import {showAddWordsModal, textAreaWordsChange} from "../add-words/add-words-action-creators";
import {hideContextMenu} from "./context-menu-action-creators";
import {setSearchCriteria, showSearchModal} from "../ngram-search/ngram-search-actions";
import {hideResponseSingleModal} from "../response-single/response-single-actions";
import HideFrom from "../../hoc/authorization/HideFrom";
import {Menu, MenuItem} from "@material-ui/core";
import {showNoteModal} from "../responses/notes/notes-actions";
import {NOTE_MODAL_MODE_IGNORE_BOUNDARIES} from "../responses/notes/notes-constants";
import {useTranslation} from "react-i18next";

const ContextMenuComponent = (
  {
    top,
    left,
    addToTaxonomy,
    showSearchModal,
    selectedText,
    reportId,
    hideContextMenu,
    showNoteModal,
  }
) => {
  const anchorPosition = top !== null && left !== null ? {top, left} : undefined;
  const report = useSelector(state => state.reportInfo.report);
  const isDuplicate = report && report.duplicate;

  const {t} = useTranslation();

  return (
    <Menu
      open={top !== null}
      onClose={hideContextMenu}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      <HideFrom guest>
        <MenuItem onClick={() => addToTaxonomy(selectedText)}>{t('add_to_taxonomy', 'Add to Taxonomy')}</MenuItem>
      </HideFrom>
      <MenuItem onClick={() => showSearchModal(selectedText, reportId)}>{t('search_this_text_modal', 'Search this text')}</MenuItem>

      {!isDuplicate && (
        <HideFrom guest>
          <MenuItem onClick={() => showNoteModal()}>{t('add_note', 'Add Note')}</MenuItem>
          <MenuItem onClick={() => showNoteModal(NOTE_MODAL_MODE_IGNORE_BOUNDARIES)}>{t('add_ignore_boundary','Add Ignore Boundary')}</MenuItem>
        </HideFrom>
      )}
    </Menu>
  )
};


const mapStateToProps = state => ({
  top: state.contextMenu.top,
  left: state.contextMenu.left,
  isShown: state.contextMenu.isShown,
  selectedText: state.contextMenu.selectedText,
  reportId: state.report.reportId
});

const mapDispatchToProps = dispatch => ({
  hideContextMenu: () => dispatch(hideContextMenu()),
  addToTaxonomy: selectedText => {
    dispatch(hideResponseSingleModal());
    dispatch(textAreaWordsChange(selectedText));
    dispatch(showAddWordsModal());
    dispatch(hideContextMenu());
  },
  showSearchModal: (selectedText, reportId) => {
    dispatch(hideResponseSingleModal());
    dispatch(setSearchCriteria({searchWord: selectedText}));
    dispatch(hideContextMenu());
    dispatch(showSearchModal(reportId));
  },
  showNoteModal: modalType => {
    dispatch(showNoteModal(modalType));
    dispatch(hideContextMenu());
  }
});

const ContextMenu = connect(mapStateToProps, mapDispatchToProps)(ContextMenuComponent);

export default ContextMenu;
