import React, {Component} from 'react';
import {connect} from "react-redux";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import SplitChart from "../../components/Charts/split-chart/split-chart";
import ButtonRadio from "../../components/ButtonRadio/ButtonRadio";
import {createFilterNames} from "./split-charts-utils";
import {createAndCopyTable} from "../charts/functions";
import ChartBox from "../../components/Charts/chart-box";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";
import {withTranslation} from "react-i18next";

export const SPLIT_CHART_MODE_RELATIVE = 'Relative';
export const SPLIT_CHART_MODE_ABSOLUTE = 'Absolute';

const MODES = [SPLIT_CHART_MODE_RELATIVE, SPLIT_CHART_MODE_ABSOLUTE];
const DEFAULT_MODE = SPLIT_CHART_MODE_RELATIVE;

class SplitChartWrapper extends Component {
  state = {
    mode: DEFAULT_MODE,
    showLabels: true,
    filterName: null
  };

  changeChartMode = newMode => {
    this.setState({mode: newMode});
  };

  setSelectedFilterName = event => {
    this.setState({filterName: event.target.value});
    this.loadChartData(event.target.value);
  };

  toggleShowLabels = () => {
    this.setState({showLabels: !this.state.showLabels});
  };

  loadChartData = filterName => {
    this.props.loadSplitChart(filterName);
  };

  loadSelectedFilterChart = () => {
    if (this.state.filterName) return;

    const filterNames = createFilterNames(this.props.filters);
    const filterName = filterNames[0].value;

    this.setState({filterName});
    this.loadChartData(filterName);
  };

  componentDidMount() {
    this.loadSelectedFilterChart();
    this.props.subscribeToFilters(this.onFiltersChange);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromFilters(this.onFiltersChange);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters && this.props.filters.length > 0) {
      this.loadSelectedFilterChart();
    }
  }

  onFiltersChange = () => {
    this.loadChartData(this.state.filterName);
  };

  copyTable = mode => {
    const t = this.props.t;

    const columns = [
      {name: t('value', 'Value'), key: 'value'},
    ];

    const tableData = [];

    if (this.props.data.length > 0) {
      this.props.data[0].cols.forEach(col => {
        columns.push({name: col.value, key: col.value});
      });

      this.props.data.forEach(dataRow => {
        const rowObj = {value: dataRow.value};

        dataRow.cols.forEach(col => {
          if (mode === 'percents') {
            rowObj[col.value] = (col.count / dataRow.totalCount * 100) + '%';
          } else {
            rowObj[col.value] = col.count;
          }

        });

        tableData.push(rowObj);
      });
    }

    createAndCopyTable(tableData, columns);
    this.props.addNotificationRequest('Success', t('copied_to_clipboard_notification', 'Copied to clipboard!'));
  };

  render() {
    if (this.props.filters.length === 0) return null;

    const t = this.props.t;
    const filterNames = createFilterNames(this.props.filters);

    let chart = (
      <SplitChart
        data={this.props.data}
        mode={this.state.mode}
        showLabels={this.state.showLabels}
        rowTitle={this.props.rowTitle}
        colTitle={this.props.colTitle}
      />
    );

    const menuItems = [
      {
        text: t('copy_to_clipboard_percents', "Copy to clipboard (percents)"),
        onClick: () => this.copyTable('percents'),
        icon: <FileCopyIcon fontSize="small"/>,
      },
      {
        text: t('copy_to_clipboard_values', "Copy to clipboard (values)"),
        onClick: () => this.copyTable('values'),
        icon: <FileCopyIcon fontSize="small"/>,
      },
      {
        text: t('show_percentage_labels', 'Show percentage labels'),
        icon: this.state.showLabels ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon fontSize="small"/>,
        onClick: this.toggleShowLabels
      }
    ];

    return (
      <ChartBox
        title={this.props.title}
        loading={this.props.loading}
        actionsMenuItems={menuItems}
      >
        <Grid container spacing={1}>
          <Grid item xs>
          <TextField
            size="small"
            variant="outlined"
            label={t("select_filters_split_chart_label", "Comparison Group")}
            fullWidth
            select
            onChange={this.setSelectedFilterName}
            value={this.state.filterName || ''}
          >
            {filterNames.map((filterName, i) => (
              <MenuItem key={i} value={filterName.value}>{filterName.title}</MenuItem>
            ))}
          </TextField>
          </Grid>
          <Grid item>
            <ButtonRadio
              data={MODES}
              selected={this.state.mode}
              select={this.changeChartMode}
            />
          </Grid>
        </Grid>
        {chart}
        {this.props.footer}
      </ChartBox>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  subscribeToFilters: subscription => dispatch(subscribeToFilters(subscription)),
  unsubscribeFromFilters: subscription => dispatch(unsubscribeFromFilters(subscription))
});

export default withTranslation()(connect(null, mapDispatchToProps)(SplitChartWrapper));
