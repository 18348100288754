
export const LOAD_TOP_WORDS_REQUEST = "[CHARTS] LOAD TOP WORDS REQUEST";
export const LOAD_TOP_WORDS_SUCCESS = "[CHARTS] LOAD TOP WORDS SUCCESS";
export const LOAD_TOP_WORDS_ERROR = "[CHARTS] LOAD TOP WORDS ERROR";
export const SWITCH_TOP_WORDS_CHART_MODE = "[CHARTS] SWITCH TOP WORDS CHART MODE";

export const LOAD_SENTIMENT_BREAKDOWN_REQUEST = "[CHARTS] LOAD SENTIMENT BREAKDOWN REQUEST";
export const LOAD_SENTIMENT_BREAKDOWN_SUCCESS = "[CHARTS] LOAD SENTIMENT BREAKDOWN SUCCESS";
export const LOAD_SENTIMENT_BREAKDOWN_ERROR = "[CHARTS] LOAD SENTIMENT BREAKDOWN ERROR";

export const LOAD_PRIMARY_EMOTIONS_BREAKDOWN_REQUEST = "[CHARTS] LOAD_PRIMARY_EMOTIONS_BREAKDOWN_REQUEST";
export const LOAD_PRIMARY_EMOTIONS_BREAKDOWN_SUCCESS = "[CHARTS] LOAD_PRIMARY_EMOTIONS_BREAKDOWN_SUCCESS";
export const LOAD_PRIMARY_EMOTIONS_BREAKDOWN_ERROR = "[CHARTS] LOAD_PRIMARY_EMOTIONS_BREAKDOWN_ERROR";

export const LOAD_RESPONSES_INFO_SUCCESS = "[CHARTS] LOAD_RESPONSES_INFO_SUCCESS";
export const LOAD_RESPONSES_INFO_ERROR = "[CHARTS] LOAD_RESPONSES_INFO_ERROR";

export const LOAD_HB_INDEX_SUCCESS = "[CHARTS] LOAD_HB_INDEX_SUCCESS";
export const LOAD_HB_INDEX_ERROR = "[CHARTS] LOAD_HB_INDEX_ERROR";
