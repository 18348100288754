import React, { Component } from 'react';
import {connect} from "react-redux";
import ResponsesComponent from "./responses-component";
import {changeTab, loadResponsesRequest} from "./responses-action-creators";
import {setSelectedText, showContextMenu} from "../context-menu/context-menu-action-creators";
import {getSelectedText} from "../context-menu/context-menu-functions";
import {setNoteInfo, showNoteContextMenu} from "./notes/notes-actions";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";

class ResponsesContainer extends Component {
  componentDidMount() {
    this.loadData();
    this.props.subscribeToFilters(this.onFiltersChange);
  }

  componentWillUnmount() {
    this.props.unsubscribeFromFilters(this.onFiltersChange);
  }

  loadData = () => {
    this.props.loadResponses(this.props.page);
  };

  onFiltersChange = () => {
    this.props.loadResponses(1);
  };

  render() {
    return <ResponsesComponent {...this.props} />;
  }
}

const mapStateToProps = state => ({
  responses: state.responses.responses,
  loading: state.responses.loading,
  page: state.responses.page,
  totalPages: state.responses.totalPages,
  error: state.responses.error,
  whoMounted: state.responses.whoMounted,
  reportId: state.report.reportId,
  tabs: state.responses.tabs,
  selectedTab: state.responses.selectedTab,
  noteModalShown: state.notes.modalShown,
  hiddenVerbatims: state.responses.hiddenVerbatims
});

const mapDispatchToProps = dispatch => ({
  loadResponses: page => dispatch(loadResponsesRequest(page, true)),
  showContextMenu: ({ top, left }) => dispatch(showContextMenu({ top, left })),
  setSelectedText: () => dispatch(setSelectedText(getSelectedText())),
  subscribeToFilters: subscription => dispatch(subscribeToFilters(subscription)),
  unsubscribeFromFilters: subscription => dispatch(unsubscribeFromFilters(subscription)),
  changeTab: tabName => dispatch(changeTab(tabName)),
  setNoteInfo: (rowNumber, dataHeader) => dispatch(setNoteInfo(rowNumber, dataHeader)),
  showNoteContextMenu: (mousePosition, noteId) => dispatch(showNoteContextMenu(mousePosition, noteId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesContainer);
