import React from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {makeStyles, Hidden} from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {toggleMobileDrawer} from "../../containers/main/main-actions";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  drawerFullscreen: {
    width: 0
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    position: 'relative'
  },
  toggleButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    flex: 1
  },
  content: {
    flexGrow: 1
  },
}));

function DrawerWithContent({drawerContent, children, fullscreen, defaultClosed}) {
  const classes = useStyles();

  const {mobileDrawerVisible, desktopDrawerVisible} = useSelector(state => ({
    mobileDrawerVisible: state.main.mobileDrawerVisible,
    desktopDrawerVisible: state.main.desktopDrawerVisible,
  }), shallowEqual);

  const dispatch = useDispatch();
  const toggle = () => dispatch(toggleMobileDrawer());

  const drawerInner = (
    <>
      <div className={classes.toolbar}>
        {mobileDrawerVisible && (
          <IconButton
            className={classes.toggleButton}
            onClick={toggle}
          >
            <CloseIcon/>
          </IconButton>
        )}
      </div>
      <div className={classes.drawerContainer}>
        {drawerContent}
      </div>
    </>
  );

  const mobileDrawer = (
    <MuiDrawer
      variant="temporary"
      anchor="left"
      open={mobileDrawerVisible}
      onClose={toggle}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {drawerInner}
    </MuiDrawer>
  );

  const desktopDrawerClasses = [classes.drawer];

  if ((defaultClosed && !desktopDrawerVisible) || (!defaultClosed && desktopDrawerVisible)) {
    desktopDrawerClasses.push(classes.drawerFullscreen);
  }

  const drawer = fullscreen ? mobileDrawer : (
    <nav className={desktopDrawerClasses.join(' ')}>
      <Hidden mdUp implementation="js">
        {mobileDrawer}
      </Hidden>
      <Hidden smDown implementation="js">
        <MuiDrawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="persistent"
          open={defaultClosed ? desktopDrawerVisible : !desktopDrawerVisible}
        >
          {drawerInner}
        </MuiDrawer>
      </Hidden>
    </nav>
  );

  const content = (
    <div className={classes.content}>
      {children}
    </div>
  );

  return (
    <div className={classes.root}>
      {drawer}
      {content}
    </div>
  )
}

export default DrawerWithContent;