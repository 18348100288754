import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import BreakdownChart from "./BreakdownChart";
import {createColor} from "../../../utilities/getColor";
import TableCellLink from "../components/TableCellLink";
import ColorCircle from "../components/ColorCircle";
import {
  BREAKDOWN_CHART_MODE_BINARY,
  BREAKDOWN_CHART_MODE_NORMAL,
  CHART_TABLE_TITLES,
  DISTRIBUTION_HELP_TEXT, PER_RESPONSE_HELP_TEXT
} from "../constants";
import {formatPercent} from "../functions";
import SecondaryEmotionsModal from "../components/SecondaryEmotionsModal";
import withBreakdownData from "../hoc/withBreakdownData";
import {REPORT_MODE_BINARY, REPORT_MODE_NORMAL} from "../../report/report-constants";
import i18n from "../../../i18n";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const chartTitle = {
  [REPORT_MODE_NORMAL]: ['primary_emotions_breakdown', 'Primary Emotions Breakdown'],
  [REPORT_MODE_BINARY]: ['primary_emotions_breakdown_binary', 'Primary Emotions Breakdown (binary)'],
};

const valueName = 'Primary';
const dataType = 'primary';

const PrimaryEmotionsBreakdownChart = props => {
  const {t} = useTranslation();
  const location = useLocation();
  const mode = useSelector(state => state.report.mode);
  const [primaryEmotionModalOpen, setPrimaryEmotionModalOpen] = useState(false);
  const [primaryEmotion, setPrimaryEmotion] = useState(null);
  const height = props.height || '300px';

  /* Effects */
  const onFiltersChange = useCallback(() => props.loadPrimarySecondarySentimentRequest(), []);

  useEffect(() => {
    onFiltersChange();
    props.subscribeToFilters(onFiltersChange);

    return () => {
      props.unsubscribeFromFilters(onFiltersChange);
    }
  }, []);

  const colors = props.valueSystem ? props.valueSystem['primary_color'] : {};

  const transformDataByMode = (data, mode) => {
    const transformed = data.map(d => ({
      primary: d.primary,
      secondaries: d.secondaries,
      color: createColor(colors[d.primary]),
      ...d.modes[mode],
    }));

    transformed.sort((a, b) => a.count > b.count ? -1 : 1);

    return transformed;
  };

  const openPrimaryEmotionModal = primaryName => {
    setPrimaryEmotionModalOpen(true);
    setPrimaryEmotion(props.data.find(d => d.primary === primaryName))
  };

  const closePrimaryEmotionModal = () => {
    setPrimaryEmotionModalOpen(false);
  };

  const renderPrimaryCell = primaryName => (
    <TableCellLink onClick={() => openPrimaryEmotionModal(primaryName)}>
      {primaryName}
    </TableCellLink>
  );

  const columns = [
    {name: t('color', 'Color'), key: 'color', renderCell: color => <ColorCircle color={color}/>, renderTooltip: false},
    {name: t('table_cell_' + valueName), key: dataType, renderCell: renderPrimaryCell},
    {name: t(CHART_TABLE_TITLES[mode].count[0], CHART_TABLE_TITLES[mode].count[1]), key: 'count'},
  ];

  if (mode === BREAKDOWN_CHART_MODE_NORMAL) {
    columns.push({
      name: t(CHART_TABLE_TITLES[mode].distribution[0], CHART_TABLE_TITLES[mode].distribution[1]),
      key: 'distribution',
      transform: formatPercent,
      helpText: t(DISTRIBUTION_HELP_TEXT[0], DISTRIBUTION_HELP_TEXT[1])
    });
  } else if (mode === BREAKDOWN_CHART_MODE_BINARY) {
    columns.push({
      name: t(CHART_TABLE_TITLES[mode].per_response[0], CHART_TABLE_TITLES[mode].per_response[0]),
      key: 'per_response',
      transform: formatPercent,
      helpText: t(PER_RESPONSE_HELP_TEXT[0], PER_RESPONSE_HELP_TEXT[1])
    });
  }

  let footerLink = null;

  if (props.showFooter) {
    footerLink = `/report/${props.reportId}/full/primary-emotions`;

    if (location.search) footerLink += location.search;
  }

  // const footerLink = props.showFooter ? `/report/${props.reportId}/full/primary-emotions` : null;

  const indexData = props.data.map(d => ({
    color: createColor(colors[d.primary]),
    name: d.primary
  }));

  const [titleId, titleDefault] = chartTitle[mode]

  return (
    <Fragment>
      <BreakdownChart
        chartTitle={t(titleId, titleDefault)}
        transformDataByMode={transformDataByMode}
        valueName={valueName}
        dataType={dataType}
        columns={columns}
        mode={mode}
        footerLink={footerLink}
        height={height}
        indexData={indexData}
        {...props}
      />
      <SecondaryEmotionsModal
        open={primaryEmotionModalOpen}
        primary={primaryEmotion}
        onClose={closePrimaryEmotionModal}
        mode={mode}
      />
    </Fragment>
  )
};



export default withBreakdownData('primary')(PrimaryEmotionsBreakdownChart);
