import {
  CLEAR_DRAWER_STATE, WHITELABEL_FAILURE, WHITELABEL_REQUEST, WHITELABEL_SUCCESS,
  NEW_CONTENT_AVAILABLE_STORE,
  TOGGLE_DESKTOP_DRAWER,
  TOGGLE_MOBILE_DRAWER,
} from "./main-actions";

const initialState = {
  newContentAvailable: false,
  mobileDrawerVisible: false,
  desktopDrawerVisible: false,
  logoUrl: null,
  title: '',
  ico: null,
  logoLoading: false,
  showFooterLogo: true,
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEW_CONTENT_AVAILABLE_STORE:
      return {...state, newContentAvailable: true};
    case TOGGLE_MOBILE_DRAWER:
      return {...state, mobileDrawerVisible: !state.mobileDrawerVisible};
    case TOGGLE_DESKTOP_DRAWER:
      return {...state, desktopDrawerVisible: !state.desktopDrawerVisible};
    case CLEAR_DRAWER_STATE:
      return {...state, mobileDrawerVisible: false, desktopDrawerVisible: false};
    case WHITELABEL_REQUEST:
      return {...state, logoLoading: true};
    case WHITELABEL_SUCCESS:
      return {...state, logoLoading: false, logoUrl: action.logoUrl, title: action.title, ico: action.ico, showFooterLogo: action.showFooterLogo};
    case WHITELABEL_FAILURE:
      return {...state, logoLoading: false};
    default:
      return state;
  }
};

export default mainReducer;