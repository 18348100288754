
const removeWhitespaces = item => {
  const preResult = [];
  const result = [];
  const raw = item.split("");
  raw.forEach((letter, i) => {
    const currentLetter = raw[i];
    const nextLetter = raw[++i];
    if (nextLetter) {
      if (currentLetter === " " && nextLetter === " " ) return;
    }
    if (currentLetter === " ") return preResult.push(currentLetter);
    preResult.push(currentLetter);
  });
  preResult.forEach((letter, i) => {
    const currentLetter = preResult[i];
    if ((currentLetter === " " && i === 0) || (currentLetter === " " && i === preResult.length - 1)) {
      return;
    }
    result.push(currentLetter);
  });
  return result.join("");
};


export default removeWhitespaces;