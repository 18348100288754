import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  drag,
  event as d3event,
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
  forceX,
  forceY,
  select
} from "d3";
import {shallowEqual, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {transformBubbleChartData} from "./data-provider";
import {CHARGE_VALUE, PRIMARY_FONT_SIZE, SECONDARY_INNER_SIZE} from "./constants";
import BubbleChartTooltip from "./bubble-chart-tooltip";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  chart: {
    width: '100%',
    height: '100%',
    overflow: 'visible',
  },
  circle: {
    opacity: '0.8',
    fill: 'white',
  },
  tooltipContainer: {
    position: 'absolute',
    width: 1,
    height: 1,
  },
  primaryLabel: {
    fontSize: PRIMARY_FONT_SIZE,
  }
}));

const createInitialTooltipsState = (transformedData, frozen = false) => {
  return transformedData.nodes.reduce((acc, node) => {
    if (!node.primary) {
      acc[node.id] = {
        display: false,
        frozen
      }
    }

    return acc;
  }, {});
};

const BubbleChartSvg = ({data, primaryLimit, limit, showLabels, themeClasses}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const chartRef = useRef();
  const rootRef = useRef();

  const {mode, valueSystem} = useSelector(state => state.report, shallowEqual);
  const primaryColors = valueSystem['primary_color'];

  const transformedData = useMemo(() => {
    return transformBubbleChartData(data, mode, primaryColors);
  }, [data, mode, primaryColors]);

  const [tooltipsState, setTooltipsState] = useState(createInitialTooltipsState(transformedData));
  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    setTooltipsState(createInitialTooltipsState(transformedData));
  }, [transformedData]);

  useEffect(() => {
    if (showLabels) {
      setTooltipsState(createInitialTooltipsState(transformedData, true));
    } else {
      setTooltipsState(createInitialTooltipsState(transformedData));
    }
  }, [transformedData, showLabels]);

  useEffect(() => {
    /** @type {HTMLElement} */
    const chartElement = chartRef.current;
    const svg = select(chartElement);
    const dimensions = chartElement.getBoundingClientRect();
    const {width, height} = dimensions;

    const force = forceSimulation()
      .nodes(transformedData.nodes)
      .force('link', forceLink(transformedData.links).distance(d => d.source.forceRadius))
      .force('charge', forceManyBody().strength(CHARGE_VALUE / transformedData.numberOfPrimaryNodes))
      .force("center", forceCenter(width / 2, height / 2))
      .force("xAxis", forceX(width / 2))
      .force("yAxis", forceY(height / 2))
      .on("tick", tick);

    const dragStarted = () => {
      if (!d3event.active) force.alphaTarget(1).restart();
      d3event.subject.fx = d3event.subject.x;
      d3event.subject.fy = d3event.subject.y;
      setIsDragged(true);
    };

    const dragged = () => {
      d3event.subject.fx = d3event.x;
      d3event.subject.fy = d3event.y;
    };

    const dragEnded = () => {
      if (!d3event.active) force.alphaTarget(0);
      d3event.subject.fx = null;
      d3event.subject.fy = null;
      setIsDragged(false);
    };

    const mapById = function(d) {
      return d ? d.id : this.getAttribute('data-id');
    }

    const group = svg.selectAll('g')
      .data(transformedData.nodes, mapById)
      .call(drag().on('start', dragStarted).on('drag', dragged).on('end', dragEnded));

    const link = svg.selectAll('line')
      .data(transformedData.links, mapById);

    const tooltips = select(rootRef.current)
      .selectAll('.' + classes.tooltipContainer)
      .data(transformedData.nodes, mapById);

    function tick() {
      link.attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      group.attr("transform", d => `translate(${d.x}, ${d.y})`)
        .attr("cx", function (node) {
          const radius = node.radius;
          return node.x = Math.max(radius, Math.min(width - radius, node.x));
        })
        .attr("cy", function (node) {
          const radius = node.radius;
          return node.y = Math.max(radius, Math.min(height - radius, node.y));
        });

      tooltips.style('top', d => d.y - (d.radius + SECONDARY_INNER_SIZE * 2) + 'px').style('left', d => d.x + 'px');
    }

    return () => {
      force.stop();
      group.on('mousedown.drag', null); // remove drag handlers before redraw
    };
  }, [transformedData]);

  const isPrimaryDisplayed = node => {
    return node.percent >= (primaryLimit / 100);
  }

  const circleIsDisplayedByLimit = node => {
    if (node.primary) {
      return isPrimaryDisplayed(node);
    }

    return node.powerIndex >= limit && isPrimaryDisplayed(node.parentNode);
  }

  const linkIsDisplayedByLimit = link => {
    if (!isPrimaryDisplayed(link.source)) {
      return false;
    } else if (link.target.powerIndex < limit) {
      return false;
    }

    return true;
  }

  const circles = [];
  const tooltips = [];

  const toggleTooltipState = (node, key) => {
    if (node.primary || isDragged) return;

    setTooltipsState(prevState => {
      return {
        ...prevState,
        [node.id]: {
          ...prevState[node.id],
          [key]: !prevState[node.id][key]
        }
      }
    });
  };

  transformedData.nodes.forEach(node => {
    const isDisplayedByLimit = circleIsDisplayedByLimit(node);

    circles.push((
      <g
        key={node.id}
        data-id={node.id}
        onMouseEnter={() => toggleTooltipState(node, 'display')}
        onMouseLeave={() => toggleTooltipState(node, 'display')}
        onDoubleClick={() => toggleTooltipState(node, 'frozen')}
        style={{display: isDisplayedByLimit ? undefined : 'none'}}
      >
        <circle
          className={classes.circle}
          style={{stroke: node.color}}
          r={node.circleRadius}
          strokeWidth={node.radius}
        />
        {node.primary && (
          <>
            <text textAnchor="middle" className={classes.primaryLabel} dy={-3}>
              {node.name}
            </text>
            <text textAnchor="middle" className={classes.primaryLabel} dy={12}>
              {(node.percent * 100).toFixed(1) + '%'}
            </text>
          </>
        )}
      </g>
    ));

    if (!node.primary) {
      const isDisplayedByState = tooltipsState[node.id] && (tooltipsState[node.id].display || tooltipsState[node.id].frozen);
      const isDisplayed = isDisplayedByState && isDisplayedByLimit;

      tooltips.push((
        <div
          key={node.id}
          className={classes.tooltipContainer}
          data-id={node.id}
          style={{display: isDisplayed ? 'block' : 'none'}}
        >
          <BubbleChartTooltip>
            {node.name}<br/>
            {t('power_index_abbr', 'PI')}: {node.powerIndex}
          </BubbleChartTooltip>
        </div>
      ));
    }
  });

  return (
    <div className={classes.root} ref={rootRef}>
      <svg className={classes.chart} ref={chartRef}>
        {transformedData.links.map(link => (
          <line
            key={link.id}
            data-id={link.id}
            className={themeClasses.link}
            style={{
              display: linkIsDisplayedByLimit(link) ? undefined : 'none'
            }}
          />
        ))}

        {circles}
      </svg>

      {tooltips}
    </div>
  );
};

export default BubbleChartSvg;