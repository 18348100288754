
export const FILE_READY = "[CREATE REPORT] FILE READY";

export const UPLOAD_REQUEST = "[CREATE REPORT] UPLOAD_REQUEST";
export const UPLOAD_SUCCESS = "[CREATE REPORT] UPLOAD_SUCCESS";
export const UPLOAD_ERROR = "[CREATE REPORT] UPLOAD_ERROR";

export const REPORT_NAME_CHANGE = "[CREATE REPORT] REPORT_NAME_CHANGE";
export const REPORT_LOGO_CHANGE = "[CREATE REPORT] REPORT_LOGO_CHANGE";
export const REPORT_DESCRIPTION_CHANGE = "[CREATE REPORT] REPORT_DESCRIPTION_CHANGE";

export const STEP_ONE_ACTIVATE = "[CREATE REPORT] STEP_ONE_ACTIVATE";
export const STEP_TWO_ACTIVATE = "[CREATE REPORT] STEP TWO ACTIVATE";
export const STEP_THREE_ACTIVATE = "[CREATE REPORT] STEP THREE ACTIVATE";

export const DEACTIVATE_STEP_ONE = "[CREATE REPORT] DEACTIVATE_STEP_ONE";
export const DEACTIVATE_STEP_TWO = "[CREATE REPORT] DEACTIVATE_STEP_TWO";

export const LOAD_COLUMNS_REQUEST = "[CREATE REPORT] LOAD_COLUMNS_REQUEST";
export const LOAD_COLUMNS_SUCCESS = "[CREATE REPORT] LOAD_COLUMNS_SUCCESS";
export const LOAD_COLUMNS_ERROR = "[CREATE REPORT] LOAD_COLUMNS_ERROR";

export const ACCEPT_COLUMNS_REQUEST = "[CREATE REPORT] ACCEPT_COLUMNS_REQUEST";
export const ACCEPT_COLUMNS_SUCCESS = "[CREATE REPORT] ACCEPT_COLUMNS_SUCCESS";
export const ACCEPT_COLUMNS_ERROR = "[CREATE REPORT] ACCEPT_COLUMNS_ERROR";

export const CHANGE_COLUMN_TYPE = "[CREATE REPORT] CHANGE_COLUMN_TYPE";

export const TOGGLE_DROPDOWN = "[CREATE REPORT] TOGGLE_DROPDOWN";

export const ACTIVATE_EDIT_COLUMN_TYPE = "[CREATE REPORT] ACTIVATE EDIT COLUMN TYPE";
export const DEACTIVATE_EDIT_COLUMN_TYPE = "[CREATE REPORT] DEACTIVATE_EDIT_COLUMN_TYPE";

export const LOAD_DATA_FOR_STEP_THREE_REQUEST = "[CREATE REPORT] LOAD_DATA_FOR_STEP_THREE_REQUEST";
export const LOAD_DATA_FOR_STEP_THREE_SUCCESS = "[CREATE REPORT] LOAD_DATA_FOR_STEP_THREE_SUCCESS";
export const LOAD_DATA_FOR_STEP_THREE_ERROR = "[CREATE REPORT] LOAD_DATA_FOR_STEP_THREE_ERROR";

export const ADD_TAXONOMY = "[CREATE REPORT] ADD TAXONOMY";
export const REMOVE_TAXONOMY = "[CREATE REPORT] REMOVE_TAXONOMY";
export const CHOOSE_TAXONOMY = "[CREATE REPORT] CHOOSE_TAXONOMY";

export const STEP_THREE_SAVE_REQUEST = "[CREATE REPORT] STEP_THREE_SAVE_REQUEST";
export const STEP_THREE_SAVE_SUCCESS = "[CREATE REPORT] STEP_THREE_SAVE_SUCCESS";
export const STEP_THREE_SAVE_ERROR = "[CREATE REPORT] STEP_THREE_SAVE_ERROR";

export const IGNORE_WORDS_CHANGE = "[CREATE REPORT] IGNORE_WORDS_CHANGE";

export const PRELOAD = "[CREATE REPORT] PRELOAD";
export const RESET_STATE = "[CREATE REPORT] RESET STATE";

export const LOAD_COMPANIES_REQUEST = "[CREATE REPORT] LOAD_COMPANIES_REQUEST";
export const LOAD_COMPANIES_SUCCESS = "[CREATE REPORT] LOAD_COMPANIES_SUCCESS";
export const LOAD_COMPANIES_ERROR = "[CREATE REPORT] LOAD_COMPANIES_ERROR";

export const CHANGE_COMPANY = "[CREATE REPORT] CHANGE_COMPANY";

export const LOAD_VALUE_SYSTEMS_REQUEST = "[CREATE REPORT] LOAD_VALUE_SYSTEMS_REQUEST";
export const LOAD_VALUE_SYSTEMS_SUCCESS = "[CREATE REPORT] LOAD_VALUE_SYSTEMS_SUCCESS";
export const LOAD_VALUE_SYSTEMS_ERROR = "[CREATE REPORT] LOAD_VALUE_SYSTEMS_ERROR";

export const CHANGE_VALUE_SYSTEM = "[CREATE REPORT] CHANGE_VALUE_SYSTEM";

export const LOAD_DOMAINS_REQUEST = "[CREATE REPORT] LOAD_DOMAINS_REQUEST";
export const LOAD_DOMAINS_SUCCESS = "[CREATE REPORT] LOAD_DOMAINS_SUCCESS";
export const LOAD_DOMAINS_FAILURE = "[CREATE REPORT] LOAD_DOMAINS_FAILURE";

export const CHANGE_DOMAIN = "[CREATE REPORT] CHANGE_DOMAIN";

export const LOAD_LANGUAGES_REQUEST = "[CREATE REPORT] LOAD_LANGUAGES_REQUEST";
export const LOAD_LANGUAGES_SUCCESS = "[CREATE REPORT] LOAD_LANGUAGES_SUCCESS";
export const LOAD_LANGUAGES_FAILURE = "[CREATE REPORT] LOAD_LANGUAGES_FAILURE";

export const CHANGE_LANGUAGE = "[CREATE REPORT] CHANGE_LANGUAGE";

export const LOAD_PROGRESS_BAR_REQUEST = "[CREATE REPORT] LOAD_PROGRESS_BAR_REQUEST";
export const PROGRESS_BAR_CANCEL = "[CREATE_REPORT] PROGRESS_BAR_CANCEL";
export const PROGRESS_BAR_PROGRESS = "[CREATE REPORT] PROGRESS_BAR_PROGRESS";
export const PROGRESS_BAR_COMPLETE = "[CREATE REPORT] PROGRESS_BAR_COMPLETE";
export const PROGRESS_BAR_CANCEL_ALL = "[CREATE REPORT] CANCEL ALL PROGRESS BARS";

export const TOGGLE_RENAME_EDITING = "[EDIT REPORT] TOGGLE_RENAME_EDITING";
export const RENAME_REPORT_REQUEST = "[EDIT REPORT] RENAME_REPORT_REQUEST";
export const RENAME_REPORT_SUCCESS = "[EDIT REPORT] RENAME_REPORT_SUCCESS";
export const RENAME_REPORT_ERROR = "[EDIT REPORT] RENAME_REPORT_ERROR";

export const EDIT_TOGGLE_COMPANY = "[EDIT REPORT] EDIT_TOGGLE_COMPANY";
export const EDIT_COMPANY_REQUEST = "[EDIT REPORT] EDIT_COMPANY_REQUEST";
export const EDIT_COMPANY_SUCCESS = "[EDIT REPORT] EDIT_COMPANY_SUCCESS";

export const EDIT_REPORT_INFORMATION_REQUEST = '[EDIT REPORT] EDIT_REPORT_INFORMATION_REQUEST';
export const EDIT_REPORT_INFORMATION_DONE = '[EDIT REPORT] EDIT_REPORT_INFORMATION_DONE';

export const EDIT_UPLOAD_FILE_REQUEST = "[EDIT REPORT] EDIT_UPLOAD_FILE_REQUEST";
export const EDIT_UPLOAD_FILE_SUCCESS = "[EDIT REPORT] EDIT_UPLOAD_FILE_SUCCESS";
export const EDIT_UPLOAD_FILE_ERROR = "[EDIT REPORT] EDIT_UPLOAD_FILE_ERROR";

export const EDIT_UPDATE_COLUMNS_REQUEST = "[EDIT REPORT] EDIT_UPDATE_COLUMNS_REQUEST";
export const EDIT_UPDATE_COLUMNS_SUCCESS = "[EDIT REPORT] EDIT_UPDATE_COLUMNS_SUCCESS";
export const EDIT_UPDATE_COLUMNS_ERROR = "[EDIT REPORT] EDIT_UPDATE_COLUMNS_ERROR";

export const EDIT_UPDATE_TAXONOMIES_REQUEST = "[EDIT REPORT] EDIT_UPDATE_TAXONOMIES_REQUEST";
export const EDIT_UPDATE_TAXONOMIES_SUCCESS = "[EDIT REPORT] EDIT_UPDATE_TAXONOMIES_SUCCESS";
export const EDIT_UPDATE_TAXONOMIES_ERROR = "[EDIT REPORT] EDIT_UPDATE_TAXONOMIES_ERROR";

export const EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST = "[EDIT REPORT] ADD_COLUMNS_UPLOAD_FILE_REQUEST";
export const EDIT_ADD_COLUMNS_UPLOAD_FILE_SUCCESS = "[EDIT REPORT] ADD_COLUMNS_UPLOAD_FILE_SUCCESS";
export const EDIT_ADD_COLUMNS_UPLOAD_FILE_FAILURE = "[EDIT REPORT] ADD_COLUMNS_UPLOAD_FILE_FAILURE";

export const EDIT_ADD_COLUMNS_CONFIGURE_REQUEST = "[EDIT REPORT] ADD_COLUMNS_CONFIGURE_REQUEST";
export const EDIT_ADD_COLUMNS_CONFIGURE_SUCCESS = "[EDIT REPORT] ADD_COLUMNS_CONFIGURE_SUCCESS";
export const EDIT_ADD_COLUMNS_CONFIGURE_FAILURE = "[EDIT REPORT] ADD_COLUMNS_CONFIGURE_FAILURE";

export const WRITE_TAGS_REQUEST = '[CREATE REPORT] WRITE_TAGS';
export const TAGS_CHANGE = '[CREATE REPORT] TAGS_CHANGE';

export const GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST = '[EDIT REPORT] GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST';
export const GET_COLUMNS_NAMES_MAP_CONFIG_SUCCESS = '[EDIT REPORT] GET_COLUMNS_NAMES_MAP_CONFIG_SUCCESS';
export const GET_COLUMNS_NAMES_MAP_CONFIG_FAILURE = '[EDIT REPORT] GET_COLUMNS_NAMES_MAP_CONFIG_FAILURE';

export const CHANGE_COLUMN_NAME = '[EDIT REPORT] CHANGE_COLUMN_NAME';

export const UPDATE_COLUMNS_NAMES_MAP_REQUEST = '[EDIT REPORT] UPDATE_COLUMNS_NAMES_MAP_REQUEST';
export const UPDATE_COLUMNS_NAMES_MAP_DONE = '[EDIT REPORT] UPDATE_COLUMNS_NAMES_MAP_DONE';

export const fileReady = file => ({ type: FILE_READY, file });
export const uploadRequest = ({ file, reportName }) => ({ type: UPLOAD_REQUEST, file, reportName });
export const uploadSuccess = ({ reportId, filename }) => ({ type: UPLOAD_SUCCESS, reportId, filename });
export const uploadError = () => ({ type: UPLOAD_ERROR });

export const reportNameChange = reportName => ({ type: REPORT_NAME_CHANGE, reportName });
export const reportLogoChange = logo => ({type: REPORT_LOGO_CHANGE, logo});
export const reportDescriptionChange = description => ({type: REPORT_DESCRIPTION_CHANGE, description});

export const stepOneActivate = () => ({ type: STEP_ONE_ACTIVATE });
export const stepTwoActivate = () => ({ type: STEP_TWO_ACTIVATE });
export const stepThreeActivate = () => ({ type: STEP_THREE_ACTIVATE });

export const deactivateStepOne = () => ({ type: DEACTIVATE_STEP_ONE });
export const deactivateStepTwo = () => ({ type: DEACTIVATE_STEP_TWO });

export const loadColumnsRequest = mode => ({ type: LOAD_COLUMNS_REQUEST, mode });
export const loadColumnsSuccess = columns => ({ type: LOAD_COLUMNS_SUCCESS, columns });
export const loadColumnsError = () => ({ type: LOAD_COLUMNS_ERROR });

export const acceptColumnsRequest = () => ({ type: ACCEPT_COLUMNS_REQUEST });
export const acceptColumnsSuccess = () => ({ type: ACCEPT_COLUMNS_SUCCESS });
export const acceptColumnsError = () => ({ type: ACCEPT_COLUMNS_ERROR });

export const changeColumnType = ({ columnType, columnIndex }) => ({ type: CHANGE_COLUMN_TYPE, columnType, columnIndex });

export const toggleDropdown = columnIndex => ({ type: TOGGLE_DROPDOWN, columnIndex });

export const activateEditColumnType = columnIndex => ({ type: ACTIVATE_EDIT_COLUMN_TYPE, columnIndex });
export const deactivateEditColumnType = columnIndex => ({ type: DEACTIVATE_EDIT_COLUMN_TYPE, columnIndex });

export const loadDataForStepThreeRequest = (mode) => ({type: LOAD_DATA_FOR_STEP_THREE_REQUEST, mode});
export const loadDataForStepThreeSuccess = ({taxonomies, allTaxonomies, ignoreWords, predictedAVUCount}) => ({
  type: LOAD_DATA_FOR_STEP_THREE_SUCCESS,
  taxonomies,
  allTaxonomies,
  ignoreWords,
  predictedAVUCount
});
export const loadDataForStepThreeError = error => ({ type: LOAD_DATA_FOR_STEP_THREE_ERROR, error });

export const addTaxonomy = () => ({ type: ADD_TAXONOMY });
export const removeTaxonomy = i => ({ type: REMOVE_TAXONOMY, i });
export const chooseTaxonomy = ({ columnType, columnIndex }) => ({ type: CHOOSE_TAXONOMY, columnType, columnIndex });

export const stepThreeSaveRequest = history => ({ type: STEP_THREE_SAVE_REQUEST, history });
export const stepThreeSaveSuccess = () => ({ type: STEP_THREE_SAVE_SUCCESS });
export const stepThreeSaveError = () => ({ type: STEP_THREE_SAVE_ERROR });

export const ignoreWordsChange = value => ({ type: IGNORE_WORDS_CHANGE, value });

export const preLoadCreateReportState = parameters => ({ type: PRELOAD, parameters});
export const resetState = () => ({ type: RESET_STATE });

export const loadCompaniesRequest = () => ({type: LOAD_COMPANIES_REQUEST});
export const loadCompaniesSuccess = companies => ({type: LOAD_COMPANIES_SUCCESS, companies});
export const loadCompaniesError = () => ({type: LOAD_COMPANIES_ERROR});

export const changeCompany = company => ({type: CHANGE_COMPANY, company});

export const loadValueSystemsRequest = () => ({type: LOAD_VALUE_SYSTEMS_REQUEST});
export const loadValueSystemsSuccess = valueSystems => ({type: LOAD_VALUE_SYSTEMS_SUCCESS, valueSystems});
export const loadValueSystemsError = () => ({type: LOAD_VALUE_SYSTEMS_ERROR});

export const changeValueSystem = valueSystem => ({type: CHANGE_VALUE_SYSTEM, valueSystem});

export const loadDomainsRequest = () => ({type: LOAD_DOMAINS_REQUEST});
export const loadDomainsSuccess = domains => ({type: LOAD_DOMAINS_SUCCESS, domains});
export const loadDomainsError = () => ({type: LOAD_DOMAINS_FAILURE});

export const changeDomain = domain => ({type: CHANGE_DOMAIN, domain});

export const loadLanguagesRequest = company => ({type: LOAD_LANGUAGES_REQUEST, company});
export const loadLanguagesSuccess = languages => ({type: LOAD_LANGUAGES_SUCCESS, languages});
export const loadLanguagesError = () => ({type: LOAD_LANGUAGES_FAILURE});

export const changeLanguage = language => ({type: CHANGE_LANGUAGE, language});

export const loadProgressBarRequest = reportId => ({type: LOAD_PROGRESS_BAR_REQUEST, reportId});
export const progressBarProgress = (reportId, progress) => ({type: PROGRESS_BAR_PROGRESS, reportId, progress});
export const progressBarComplete = reportId => ({type: PROGRESS_BAR_COMPLETE, reportId});
export const progressBarCancel = reportId => ({type: PROGRESS_BAR_CANCEL, reportId});
export const progressBarCancelAll = () => ({type: PROGRESS_BAR_CANCEL_ALL});

export const editReportInformationRequest = reportInfo => ({type: EDIT_REPORT_INFORMATION_REQUEST, reportInfo});
export const editReportInformationDone = () => ({type: EDIT_REPORT_INFORMATION_DONE});

export const toggleRenameEditing = () => ({type: TOGGLE_RENAME_EDITING});
export const renameReportRequest = reportName => ({type: RENAME_REPORT_REQUEST, reportName});
export const renameReportSuccess = () => ({type: RENAME_REPORT_SUCCESS});
export const renameReportError = () => ({type: RENAME_REPORT_ERROR});

export const editToggleCompany = () => ({type: EDIT_TOGGLE_COMPANY});
export const editCompanyRequest = company => ({type: EDIT_COMPANY_REQUEST, company});
export const editCompanySuccess = () => ({type: EDIT_COMPANY_SUCCESS});

export const editUploadFileRequest = (file, fileType, replaceExisting, history) => ({
  type: EDIT_UPLOAD_FILE_REQUEST,
  file, fileType, replaceExisting, history
});
export const editUploadFileSuccess = () => ({type: EDIT_UPLOAD_FILE_SUCCESS});
export const editUploadFileError = () => ({type: EDIT_UPLOAD_FILE_ERROR});

export const editUpdateColumnsRequest = (forceDataRecalculation, history) => ({type: EDIT_UPDATE_COLUMNS_REQUEST, forceDataRecalculation, history});
export const editUpdateColumnsSuccess = () => ({type: EDIT_UPDATE_COLUMNS_SUCCESS});
export const editUpdateColumnsError = () => ({type: EDIT_UPDATE_COLUMNS_ERROR});

export const editUpdateTaxonomiesRequest = history => ({type: EDIT_UPDATE_TAXONOMIES_REQUEST, history});
export const editUpdateTaxonomiesSuccess = () => ({type: EDIT_UPDATE_TAXONOMIES_SUCCESS});
export const editUpdateTaxonomiesError = () => ({type: EDIT_UPDATE_TAXONOMIES_ERROR});

export const editAddColumnsUploadFileRequest = file => ({type: EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST, file});
export const editAddColumnsUploadFileSuccess = () => ({type: EDIT_ADD_COLUMNS_UPLOAD_FILE_SUCCESS});
export const editAddColumnsUploadFileFailure = () => ({type: EDIT_ADD_COLUMNS_UPLOAD_FILE_FAILURE});

export const editAddColumnsConfigureRequest = history => ({type: EDIT_ADD_COLUMNS_CONFIGURE_REQUEST, history});
export const editAddColumnsConfigureSuccess = () => ({type: EDIT_ADD_COLUMNS_CONFIGURE_SUCCESS});
export const editAddColumnsConfigureFailure = () => ({type: EDIT_ADD_COLUMNS_CONFIGURE_FAILURE});

export const tagsChange = tags => ({type: TAGS_CHANGE, tags});
export const writeTagsRequest = () => ({type: WRITE_TAGS_REQUEST});

export const getColumnsNamesMapConfigRequest = () => ({type: GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST});
export const getColumnsNamesMapConfigSuccess = columnsMap => ({type: GET_COLUMNS_NAMES_MAP_CONFIG_SUCCESS, columnsMap});
export const getColumnsNamesMapConfigFailure = () => ({type: GET_COLUMNS_NAMES_MAP_CONFIG_FAILURE});

export const changeColumnName = (i, name) => ({type: CHANGE_COLUMN_NAME, i, name});

export const updateColumnsNamesMapRequest = () => ({type: UPDATE_COLUMNS_NAMES_MAP_REQUEST});
export const updateColumnsNamesMapDone = () => ({type: UPDATE_COLUMNS_NAMES_MAP_DONE});