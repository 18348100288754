import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import GeoPattern from "geopattern";

import DropdownMenu from "../../../components/DropdownMenu/DropdownMenu";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useTranslation} from "react-i18next";

const Preview = ({company, reportName, logoFile, description, reportId}) => {
  const {t} = useTranslation();
  let image;

  if (logoFile instanceof File) {
    image = URL.createObjectURL(logoFile);
  } else if (logoFile) {
    image = logoFile;
  } else if (reportId) {
    const pattern = GeoPattern.generate(reportId);
    image = pattern.toDataUri();
  }

  const mediaStyle = {height: 0, paddingTop: '56.25%'};

  const media = image ? (
    <CardMedia style={mediaStyle} image={image}/>
  ) : (
    <CardMedia style={{...mediaStyle, position: 'relative', background: '#ccc'}}>
      <div style={{position: 'absolute', color: 'white', width: '100%', top: '40%', padding: '10px', left: '0', textAlign: 'center'}}>
        {t('no_img_selected', 'No image selected.')} <br/> {t('random_pattern_generation_note', 'A random pattern will be generated for you.')}
      </div>
    </CardMedia>
  );

  return (
    <Card>
      <CardHeader
        avatar={<Avatar aria-label="company">{company && company[0].toUpperCase()}</Avatar>}
        title={reportName || t('report_card_preview', 'Report card preview. Start filling up fields to see changes')}
        action={<DropdownMenu items={[]} disabled/>}
      />
      {media}
      <CardContent>
        {description && (
          <Typography variant="body2" color="textSecondary" component="pre">
            {description}
          </Typography>
        )}
      </CardContent>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <IconButton
            variant="contained"
            disabled
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            disabled
            color="primary"
          >
            {t('go', 'Go')} <ChevronRightIcon/>
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};


export default Preview;