import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import UiModal from "../../../components/UiModal/UiModal";
import {hideExpiredUrlModal} from "../filters-actions";
import {useTranslation} from "react-i18next";

const ExpiredUrlModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.filters.expiredUrlModalOpen);
  const close = () => {
    dispatch(hideExpiredUrlModal());
  };

  return (
    <UiModal
      title="This url has expired!"
      open={isOpen}
      onClose={close}
    >
      <DialogContent dividers>
        <Alert severity="warning">
          <strong>{t("url_has_expired_modal", "Current URL for filters has expired!")}</strong> <br/>
          {t("url_has_expire_helper_text", "One or more changes were applied to existing filters and we are not able to restore filters state from previous time. You are redirected to page without any filters applied.")}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t('ok_got_it', 'Ok, got it')}</Button>
      </DialogActions>
    </UiModal>
  );
};

export default ExpiredUrlModal;