import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Box, Button, Divider, Grid, TextField, Typography} from "@material-ui/core";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";

import UploadFile from "../../../components/UploadFile/UploadFile";
import Tags from "../../reports/containers/Tags";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";

import {addNotification} from "../../notifications/notifications-actions";
import {
  editReportInformationRequest,
  loadCompaniesRequest, loadDomainsRequest,
  tagsChange,
  writeTagsRequest
} from "../create-report-action-creators";
import Preview from "../components/Preview";
import MenuItem from "@material-ui/core/MenuItem";


const EditBasicInfo = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [reportInfo, setReportInfo] = useState({
    name: '',
    company: '',
    domain: '',
    description: '',
    logoFile: null,
    clearLogo: false,
    existingLogo: null,
  });

  const info = useSelector(state => ({
    report: state.reportInfo.report,
    companies: state.createReport.companies,
    domains: state.createReport.domains,
    tags: state.createReport.tags,
    loading: state.createReport.editReportInformationLoading,
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadCompaniesRequest());
    dispatch(loadDomainsRequest());
  }, []);

  useEffect(() => {
    setReportInfo({
      ...reportInfo,
      name: info.report['report_name'],
      company: info.report['company'],
      domain: info.report['domain'],
      description: info.report['description'],
      existingLogo: info.report['report_logo']
    })
  }, info.report);

  const onChange = e => {
    setReportInfo({
      ...reportInfo,
      [e.target.name]: e.target.value
    });
  };

  const onCompanyChange = (e, value) => {
    setReportInfo({...reportInfo, company: value});
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(editReportInformationRequest(reportInfo));
  };

  const fileSelected = file => {
    setReportInfo({
      ...reportInfo,
      logoFile: file,
      clearLogo: false,
    });
  };

  const fileCleared = () => {
    setReportInfo({
      ...reportInfo,
      logoFile: null,
      existingLogo: null,
      clearLogo: true,
    })
  };

  const preview = (
    <Preview
      description={reportInfo.description}
      company={reportInfo.company}
      logoFile={reportInfo.logoFile || reportInfo.existingLogo}
      reportName={reportInfo.name}
      reportId={info.report['report_id'].$oid}
    />
  );

  const getFileName = url => url.split('/').pop();

  return (
    <Fragment>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t('basic_info', 'Basic info')}</Typography>
          </Grid>
          <Grid item xs>
            <Grid container direction="column" justify="flex-start" spacing={2} component="form" onSubmit={onSubmit}>
              <Grid item xs>
                <TextField
                  fullWidth
                  variant="outlined"
                  label= {t('name', 'Name')}
                  required
                  name="name"
                  value={reportInfo.name}
                  onChange={onChange}
                />
              </Grid>

              <Grid item xs>
                <MUIAutocomplete
                  options={info.companies}
                  value={reportInfo.company}
                  name="company"
                  onChange={onCompanyChange}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label= {t('company', 'Company')}
                        variant="outlined"
                      />
                    )
                  }}
                />
              </Grid>

              <Grid item xs>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label= {t('domain', "Domain")}
                  name="domain"
                  value={reportInfo.domain}
                  onChange={onChange}
                >
                  {info.domains.map(d => (
                    <MenuItem key={d} value={d}>{d}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs>
                <TextField
                  fullWidth
                  multiline
                  type="textarea"
                  variant="outlined"
                  label= {t('report_description', "Report description")}
                  name="description"
                  value={reportInfo.description || ''}
                  onChange={onChange}
                />
              </Grid>

              <Grid item xs>
                <UploadFile
                  onFileReady={fileSelected}
                  addNotification={value => dispatch(addNotification(value))}
                  label={t('logo_image_file', 'Logo image file')}
                  extensions={['jpg', 'jpeg', 'png', 'gif']}
                  helperText= {t('preferred_ratio', "Preferably in 16:9 aspect ratio.")}
                  clearable
                  onClear={fileCleared}
                  fileName={(!reportInfo.logoFile && reportInfo.existingLogo) && getFileName(reportInfo.existingLogo)}
                />
              </Grid>

              <Grid item xs>
              <ButtonWithProgress
                color="primary"
                variant="contained"
                type="submit"
                loading={info.loading}
                disabled={info.loading}
              >
                {t('save_basic_info', 'Save basic info')}
              </ButtonWithProgress>
            </Grid>
            </Grid>
          </Grid>
          <Grid item style={{width: '400px'}}>
            {preview}
          </Grid>
        </Grid>
      </Box>

      <Divider/>

      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('tags', 'Tags')}</Typography>
          </Grid>

          <Grid item xs>
            <Tags
              allowNew
              tags={info.tags}
              onChange={value => dispatch(tagsChange(value))}
            />
          </Grid>

          <Grid item xs>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(writeTagsRequest())}
            >
              {t('save_tags', 'Save tags')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default EditBasicInfo;