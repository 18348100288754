import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import {changeDomain, loadDomainsRequest} from "../create-report-action-creators";
import {CircularProgress, InputAdornment, MenuItem, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const DomainInput = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {domains, domainsLoading, domain} = useSelector(state => ({
    domains: state.createReport.domains,
    domainsLoading: state.createReport.domainsLoading,
    domain: state.createReport.domain
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadDomainsRequest())
  }, [dispatch]);

  return (
    <TextField
      select
      fullWidth
      variant="outlined"
      required
      value={domain}
      disabled={domainsLoading}
      label={t('domain', 'Domain')}
      InputProps={{
        startAdornment: domainsLoading ? <InputAdornment position="start"><CircularProgress/></InputAdornment> : null
      }}
      onChange={e => dispatch(changeDomain(e.target.value))}
    >
      {domains.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default DomainInput;