export const searchChangeInPrimary = ({data, searchValue}) => {
  Object.keys(data).forEach(primaryName => {
    data[primaryName] = Object.keys(data[primaryName]).reduce((acc, key) => {
      if (key.startsWith(searchValue)) {
        acc[key] = data[primaryName][key];
      }

      return acc;
    }, {});
  });

  return data;
};
