import {
  ADD_EMOTION_COLUMN,
  ADD_WORDS_DONE,
  ADD_WORDS_REQUEST,
  ADD_WORDS_TO_BASKET,
  CLEAR_BASKET,
  CREATE_TAXONOMY_DONE,
  CREATE_TAXONOMY_REQUEST,
  DELETE_TAXONOMY_DONE,
  DELETE_TAXONOMY_REQUEST,
  DELETE_WORDS_FAILURE,
  DELETE_WORDS_REQUEST,
  DELETE_WORDS_SUCCESS,
  FETCH_EXAMPLES_FAILURE,
  FETCH_EXAMPLES_REQUEST,
  FETCH_EXAMPLES_SUCCESS,
  FETCH_FULL_VERBATIM_FAILURE,
  FETCH_FULL_VERBATIM_REQUEST,
  FETCH_FULL_VERBATIM_SUCCESS,
  FETCH_LANGUAGES_FAILURE,
  FETCH_LANGUAGES_REQUEST,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_TARGET_TAXONOMIES_FAILURE,
  FETCH_TARGET_TAXONOMIES_REQUEST,
  FETCH_TARGET_TAXONOMIES_SUCCESS,
  FETCH_TAXONOMIES_FAILURE,
  FETCH_TAXONOMIES_REQUEST,
  FETCH_TAXONOMIES_SUCCESS,
  FETCH_VALUE_SYSTEMS_FAILURE,
  FETCH_VALUE_SYSTEMS_REQUEST,
  FETCH_VALUE_SYSTEMS_SUCCESS,
  REMOVE_WORDS_FROM_BASKET,
  REPLACE_BASKET,
  RESET, RESET_EMOTIONS_FOR_ALL_WORDS, RESET_EMOTIONS_FOR_WORD,
  SAVE_DONE,
  SAVE_REQUEST,
  SEARCH_WORDS_FAILURE,
  SEARCH_WORDS_REQUEST,
  SEARCH_WORDS_SUCCESS,
  SET_ADD_WORDS_DIALOG_OPEN,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_VALUE_SYSTEM,
  SET_DELETE_TAXONOMY_DIALOG_OPEN,
  SET_DELETE_WORDS_DIALOG_OPEN,
  SET_EXAMPLES_DIALOG_OPEN,
  SET_NEW_TAXONOMY_DIALOG_OPEN,
  SET_SEARCH_WORDS_DIALOG_OPEN,
  SET_SELECTED_TAXONOMIES,
  SET_TARGET_TAXONOMY,
  SET_TAXONOMIES_ARE_SELECTED,
  SET_TERM_FOR_EXAMPLES,
  SET_TRANSLATE_WORDS_DIALOG_OPEN,
  SET_WORDS_TO_DELETE,
  TOGGLE_ALL_EMOTIONS_FOR_WORD,
  TOGGLE_EMOTION,
  TOGGLE_EMOTION_FOR_ALL_WORDS,
  TRANSLATE_WORDS_DONE,
  TRANSLATE_WORDS_REQUEST,
  VALUE_SYSTEM_INFO_FAILURE,
  VALUE_SYSTEM_INFO_REQUEST,
  VALUE_SYSTEM_INFO_SUCCESS
} from "./taxonomy-actions";
import {CONTEXTS} from "./taxonomy-constants";

const initialState = {
  valueSystems: [],
  valueSystemsLoading: false,
  valueSystem: '',
  languages: [],
  languagesLoading: false,
  language: '',
  taxonomies: [],
  taxonomiesInfo: null,
  taxonomiesLoading: false,
  selectedTaxonomies: [],
  newTaxonomyDialogOpen: false,
  newTaxonomyLoading: false,
  deleteTaxonomyDialogOpen: false,
  deleteTaxonomyLoading: false,
  taxonomiesAreSelected: false,
  addWordsDialogOpen: false,
  searchWordsDialogOpen: false,
  translateWordsDialogOpen: false,
  addWordsLoading: false,
  searchWordsLoading: false,
  searchWords: null,
  wordsInBasket: [],
  valueSystemInfoLoading: false,
  valueSystemInfo: null,
  emotionColumns: [],
  saveLoading: false,
  targetTaxonomies: [],
  targetTaxonomiesInfo: null,
  targetTaxonomiesLoading: false,
  targetTaxonomy: '',
  translateWordsLoading: false,
  termForExamples: '',
  examplesDialogOpen: false,
  examplesLoading: false,
  examples: null,
  fullVerbatimLoading: false,
  fullVerbatim: null,
  deleteWordsDialogOpen: false,
  wordsToDelete: [],
  deleteWordsLoading: false,
};

const taxonomyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VALUE_SYSTEMS_REQUEST:
      return {...state, valueSystemsLoading: true};
    case FETCH_VALUE_SYSTEMS_SUCCESS:
      return {...state, valueSystemsLoading: false, valueSystems: action.valueSystems};
    case FETCH_VALUE_SYSTEMS_FAILURE:
      return {...state, valueSystemsLoading: false};
    case SET_CURRENT_VALUE_SYSTEM:
      return {...state, valueSystem: action.valueSystem};
    case FETCH_LANGUAGES_REQUEST:
      return {...state, languagesLoading: true};
    case FETCH_LANGUAGES_SUCCESS:
      return {...state, languagesLoading: false, languages: action.languages};
    case FETCH_LANGUAGES_FAILURE:
      return {...state, languagesLoading: false};
    case SET_CURRENT_LANGUAGE:
      return {...state, language: action.language};
    case FETCH_TAXONOMIES_REQUEST:
      return {...state, taxonomiesLoading: true};
    case FETCH_TAXONOMIES_SUCCESS:
      return {...state, taxonomiesLoading: false, taxonomies: action.payload.taxonomies, taxonomiesInfo: action.payload.taxonomiesInfo};
    case FETCH_TAXONOMIES_FAILURE:
      return {...state, taxonomiesLoading: false};
    case SET_SELECTED_TAXONOMIES:
      return {...state, selectedTaxonomies: action.taxonomies};
    case SET_NEW_TAXONOMY_DIALOG_OPEN:
      return {...state, newTaxonomyDialogOpen: action.isOpen};
    case CREATE_TAXONOMY_REQUEST:
      return {...state, newTaxonomyLoading: true};
    case CREATE_TAXONOMY_DONE:
      return {...state, newTaxonomyLoading: false};
    case SET_DELETE_TAXONOMY_DIALOG_OPEN:
      return {...state, deleteTaxonomyDialogOpen: action.isOpen};
    case DELETE_TAXONOMY_REQUEST:
      return {...state, deleteTaxonomyLoading: true};
    case DELETE_TAXONOMY_DONE:
      return {...state, deleteTaxonomyLoading: false};
    case SET_TAXONOMIES_ARE_SELECTED:
      return {...state, taxonomiesAreSelected: action.areSelected};
    case RESET:
      return {...state, taxonomiesAreSelected: false, searchWords: null};
    case SET_ADD_WORDS_DIALOG_OPEN:
      return {...state, addWordsDialogOpen: action.isOpen};
    case SET_SEARCH_WORDS_DIALOG_OPEN:
      return {...state, searchWordsDialogOpen: action.isOpen};
    case SET_TRANSLATE_WORDS_DIALOG_OPEN:
      return {...state, translateWordsDialogOpen: action.isOpen};
    case ADD_WORDS_REQUEST:
      return {...state, addWordsLoading: true};
    case ADD_WORDS_DONE:
      return {...state, addWordsLoading: false};
    case SEARCH_WORDS_REQUEST:
      return {...state, searchWords: null, searchWordsLoading: true};
    case SEARCH_WORDS_SUCCESS:
      return {...state, searchWords: action.words, searchWordsLoading: false};
    case SEARCH_WORDS_FAILURE:
      return {...state, searchWordsLoading: false};
    case ADD_WORDS_TO_BASKET:
      return addWordsToBasket(state, action);
    case REMOVE_WORDS_FROM_BASKET:
      return {
        ...state,
        wordsInBasket: state.wordsInBasket.filter(word => {
          if (word._id) return !action.ids.includes(word._id.$oid);
          return !action.ids.includes(word.tempId);
        })
      };
    case CLEAR_BASKET:
      return {...state, wordsInBasket: [], emotionColumns: []};
    case REPLACE_BASKET:
      return {...state, wordsInBasket: action.words, emotionColumns: createEmotionColumns(state, action.words)};
    case VALUE_SYSTEM_INFO_REQUEST:
      return {...state, valueSystemInfoLoading: true};
    case VALUE_SYSTEM_INFO_SUCCESS:
      return {...state, valueSystemInfoLoading: false, valueSystemInfo: action.valueSystemInfo};
    case VALUE_SYSTEM_INFO_FAILURE:
      return {...state, valueSystemInfoLoading: false};
    case ADD_EMOTION_COLUMN:
      if (state.emotionColumns.find(c => c.code === action.code && c.context === action.context)) return state;

      return {
        ...state,
        emotionColumns: sortEmotionColumns(
          state.valueSystemInfo,
          [...state.emotionColumns, createColumn(action.code, action.context)]
        )
      };
    case TOGGLE_EMOTION:
      return toggleEmotion(state, action);
    case TOGGLE_EMOTION_FOR_ALL_WORDS:
      return toggleEmotionForAllWords(state, action);
    case TOGGLE_ALL_EMOTIONS_FOR_WORD:
      return toggleAllEmotionsForWord(state, action);
    case RESET_EMOTIONS_FOR_WORD:
      return resetEmotionsForWord(state, action);
    case RESET_EMOTIONS_FOR_ALL_WORDS:
      return resetEmotionsForAllWords(state);
    case SAVE_REQUEST:
      return {...state, saveLoading: true};
    case SAVE_DONE:
      return {...state, saveLoading: false};
    case FETCH_TARGET_TAXONOMIES_REQUEST:
      return {...state, targetTaxonomiesLoading: true};
    case FETCH_TARGET_TAXONOMIES_SUCCESS:
      return {...state, targetTaxonomiesLoading: false, targetTaxonomies: action.payload.taxonomies, targetTaxonomiesInfo: action.payload.taxonomiesInfo};
    case FETCH_TARGET_TAXONOMIES_FAILURE:
      return {...state, targetTaxonomiesLoading: false};
    case SET_TARGET_TAXONOMY:
      return {...state, targetTaxonomy: action.targetTaxonomy};
    case TRANSLATE_WORDS_REQUEST:
      return {...state, translateWordsLoading: true};
    case TRANSLATE_WORDS_DONE:
      return {...state, translateWordsLoading: false};
    case SET_EXAMPLES_DIALOG_OPEN:
      return {...state, examplesDialogOpen: action.isOpen};
    case SET_TERM_FOR_EXAMPLES:
      return {...state, termForExamples: action.term};
    case FETCH_EXAMPLES_REQUEST:
      return {...state, examplesLoading: true};
    case FETCH_EXAMPLES_SUCCESS:
      return {...state, examplesLoading: false, examples: action.examples};
    case FETCH_EXAMPLES_FAILURE:
      return {...state, examplesLoading: false};
    case FETCH_FULL_VERBATIM_REQUEST:
      return {...state, fullVerbatimLoading: true};
    case FETCH_FULL_VERBATIM_SUCCESS:
      return {...state, fullVerbatimLoading: false, fullVerbatim: action.verbatim};
    case FETCH_FULL_VERBATIM_FAILURE:
      return {...state, fullVerbatimLoading: false};
    case SET_DELETE_WORDS_DIALOG_OPEN:
      return {...state, deleteWordsDialogOpen: action.isOpen};
    case SET_WORDS_TO_DELETE:
      return {...state, wordsToDelete: action.words};
    case DELETE_WORDS_REQUEST:
      return {...state, deleteWordsLoading: true};
    case DELETE_WORDS_SUCCESS:
      return deleteWords(state);
    case DELETE_WORDS_FAILURE:
      return {...state, deleteWordsLoading: false};
    default:
      return state;
  }
};

const addWordsToBasket = (state, action) => {
  const wordsInBasket = [...state.wordsInBasket];

  action.words.forEach(word => {
    const isAlreadyInBasket = word._id ?
      Boolean(state.wordsInBasket.find(w => w._id ? w._id.$oid === word._id.$oid : false)) :
      Boolean(state.wordsInBasket.find(w => w.tempId ? w.tempId === word.tempId : false));

    if (!isAlreadyInBasket) {
      wordsInBasket.push(word);
    }
  });

  return {
  ...state,
    wordsInBasket,
    emotionColumns: createEmotionColumns(state, wordsInBasket),
  };
};

const toggleEmotion = (state, action) => {
  const wordsInBasket = [...state.wordsInBasket];

  const wordIndex = wordsInBasket.findIndex(w => w?._id?.$oid === action.id || w.tempId === action.id);

  const originalWord = wordsInBasket[wordIndex];
  const originalContext = originalWord.contexts[action.context];

  const newContext = action.isActive ?
    [...originalContext, action.code] :
    originalContext.filter(c => c !== action.code);

  wordsInBasket[wordIndex] = {
    ...originalWord,
    contexts: {
      ...originalWord.contexts,
      [action.context]: newContext
    }
  };

  return {...state, wordsInBasket};
};

const toggleEmotionForAllWords = (state, action) => {
  const wordsInBasket = state.wordsInBasket.map(word => {
    const originalContext = word.contexts[action.context];
    const hasEmotion = originalContext.includes(action.code);
    let newContext = originalContext;

    if (action.isActive && !hasEmotion) {
      newContext = [...originalContext, action.code];
    } else if (!action.isActive && hasEmotion) {
      newContext = originalContext.filter(c => c !== action.code);
    }

    return {
      ...word,
      contexts: {
        ...word.contexts,
        [action.context]: newContext
      }
    }
  });

  return {...state, wordsInBasket};
};

const toggleAllEmotionsForWord = (state, action) => {
  const wordsInBasket = [...state.wordsInBasket];
  const wordIndex = wordsInBasket.findIndex(w => w?._id?.$oid === action.id || w.tempId === action.id);

  const word = {...wordsInBasket[wordIndex]};

  if (action.isActive) {
    word.contexts = state.emotionColumns.reduce((acc, col) => {
      if (col.context in acc) {
        acc[col.context].push(col.code);
      } else {
        acc[col.context] = [col.code];
      }

      return acc;
    }, {})
  } else {
    word.contexts = Object.keys(CONTEXTS).reduce((acc, context) => {
      acc[context] = [];
      return acc;
    }, {})
  }

  wordsInBasket[wordIndex] = word;

  return {...state, wordsInBasket};
};

const resetEmotionsForWord = (state, action) => {
  const wordsInBasket = [...state.wordsInBasket];
  const wordIndex = wordsInBasket.findIndex(w => w?._id?.$oid === action.id || w.tempId === action.id);
  const originalWord = wordsInBasket[wordIndex];

  const originalContextsCopy = copyContexts(originalWord.originalContexts);

  wordsInBasket[wordIndex] = {
    ...originalWord,
    contexts: originalContextsCopy
  };

  return {...state, wordsInBasket};
};

const resetEmotionsForAllWords = state => {
  return {
    ...state,
    wordsInBasket: state.wordsInBasket.map(w => ({
      ...w,
      contexts: copyContexts(w.originalContexts)
    }))
  };
};

const copyContexts = contexts => {
  return Object.keys(contexts).reduce((acc, contextName) => {
    acc[contextName] = [...contexts[contextName]];
    return acc;
  }, {});
};

const createColumn = (code, context) => ({code, context});

const createEmotionColumns = (state, words) => {
  const valueSystemInfo = state.valueSystemInfo;
  const existingEmotionColumns = state.emotionColumns;

  const emotionColumns = [...existingEmotionColumns];

  words.forEach(word => {
    Object.keys(word.contexts).forEach(context => {
      word.contexts[context].forEach(code => {
        const columnAlreadyExists = emotionColumns.find(c => c.code === code && c.context === context);

        if (!columnAlreadyExists) {
          emotionColumns.push(createColumn(code, context));
        }
      })
    })
  });

  return sortEmotionColumns(valueSystemInfo, emotionColumns);
};

const sortEmotionColumns = (valueSystemInfo, columns) => {
  return [...columns].sort((a, b) => {
    if (a.context > b.context) {
      return 1;
    } else if (a.context < b.context) {
      return -1;
    }

    const aPrimaryOrder = valueSystemInfo.byCoding[a.code]['primary_order'];
    const bPrimaryOrder = valueSystemInfo.byCoding[b.code]['primary_order'];

    if (aPrimaryOrder > bPrimaryOrder) {
      return 1;
    } else if (aPrimaryOrder < bPrimaryOrder) {
      return -1;
    } else {
      const aSecondaryOrder = valueSystemInfo.byCoding[a.code]['secondary_order'];
      const bSecondaryOrder = valueSystemInfo.byCoding[b.code]['secondary_order'];

      if (aSecondaryOrder > bSecondaryOrder) {
        return 1;
      } else {
        return -1;
      }
    }
  });
}

const deleteWords = state => {
  const wordsInBasket = state.wordsInBasket.filter(w => !state.wordsToDelete.find(wtd => wtd._id.$oid === w._id.$oid));

  return {
    ...state,
    wordsInBasket,
    searchWords: state.searchWords ? state.searchWords.filter(w => !state.wordsToDelete.find(wtd => wtd._id.$oid === w._id.$oid)) : state.searchWords,
    wordsToDelete: [],
    deleteWordsLoading: false,
    emotionColumns: wordsInBasket.length === 0 ? [] : state.emotionColumns
  };
}

export default taxonomyReducer;