import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import {Link} from "react-router-dom";

import HideFrom from "../../hoc/authorization/HideFrom";
import VerbatimSentences from "./verbatim-sentences";

import './verbatim.css';

const sanitizeIndex = index => index === null ? 'N/A' : Number(index).toFixed(3);


const Verbatim = ({response, reportId, selectedTab, showContextMenu, setSelectedText, setNoteInfo, showNoteContextMenu}) => {
  const {t} = useTranslation();
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(prev => !prev)
  };
  const hxTitle = useSelector(state => state.main.title);
  const report = useSelector(state => state.reportInfo.report);
  const isDuplicate = report && report.duplicate;

  const rowNumber = response['row_number'];
  const infoRow = (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item><span className="key">{t('row', 'Row')}:</span> {rowNumber}</Grid>
      <Grid item><span className="key">{t('header', 'Header')}:</span> {response['header']}</Grid>
      <Grid item><span className="key">{hxTitle} {t('index', 'Index')}:</span> {sanitizeIndex(response['hb_index'])}</Grid>

      {!isDuplicate && (
        <HideFrom guest>
          <Grid item>
            <Button
              color="primary"
              component={Link}
              to={"/report/" + reportId + "/edit-single-report/" + rowNumber}>
              {t('edit', 'Edit')}
            </Button>
          </Grid>
        </HideFrom>
      )}
    </Grid>
  );

  return (
    <Paper elevation={1} component={Box} p={2} className="response_component">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs>
          {infoRow}
        </Grid>

        <VerbatimSentences
          response={response}
          selectedTab={selectedTab}
          showContextMenu={showContextMenu}
          setSelectedText={setSelectedText}
          setNoteInfo={setNoteInfo}
          showNoteContextMenu={showNoteContextMenu}
        />

        <Grid item xs container justify="flex-end" className="metacolumns">
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange} color="primary"/>}
            label={t('show_more', 'Show more')}
          />
        </Grid>

        <Collapse in={checked}>
          <List dense>
            {response['meta_columns'].map((meta, i) => {
              return (
                <ListItem key={meta.header + i}>
                  <span>
                    <b style={{marginRight: '5px'}}>{meta.header}:</b>{' '}
                    {Array.isArray(meta.value) ? meta.value.join(', ') : meta.value}
                  </span>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </Grid>
    </Paper>
  );
};

export default Verbatim;