import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import {loadFromLocalStorage, localStorageMiddleware} from "./utilities/localStorageMiddleware";
import axios from "./axios-api";
import history from './history';
import {forceLogout} from "./containers/login/user-actions";
import {
  CREATE_IMAGE_REQUEST,
  SET_IMAGE_MODAL_OPEN,
  SET_IMAGES_SELECTOR_MODAL_OPEN
} from "./containers/articles/acticles-actions";
import tryToDetectCorrectLanguage from "./utilities/tryToDetectCorrectLanguage";
import i18n from "./i18n";

const NODE_ENV = process.env.NODE_ENV;

const actionSanitizer = (action) => {
  const badActions = [
    CREATE_IMAGE_REQUEST,
    SET_IMAGE_MODAL_OPEN,
    SET_IMAGES_SELECTOR_MODAL_OPEN,
  ]
  if (badActions.includes(action.type) && action.payload.api) {
    return {...action, payload: {...action.payload, api: '<<MD_API>>'}};
  }

  return action;
};

const stateSanitizer = (state) => state.articles?.imageSelectorModalApi ? { ...state, articles: {...state.articles, imageSelectorModalApi: '<<MD_API>>'} } : state

const getEnhancers = ({ isProduction, middleware }) => {
  if (isProduction) {
    return applyMiddleware(...middleware);
  }

  let composeEnhancers = compose;

  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({actionSanitizer, stateSanitizer});
  }

  return composeEnhancers(applyMiddleware(...middleware));
};

const configureInterceptors = (store, axiosInstance) => {
  axiosInstance.interceptors.request.use(config => {
    config.headers['Accept-Language'] = tryToDetectCorrectLanguage(i18n.language);

    try {
      const token = store.getState().user.token;
      config.headers['Authorization'] = `Token ${token}`;
    } catch (e) {
      // do nothing, user is not logged in
    }

    return config;
  });

  axiosInstance.interceptors.response.use(res => res,
    error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        store.dispatch(forceLogout(history));
      } else {
        return Promise.reject(error);
      }
    });
};

const initApp = () => {
  const sagaMiddleware = createSagaMiddleware();
  const persistedState = loadFromLocalStorage();

  const store = createStore(rootReducer, persistedState, getEnhancers({
    isProduction: NODE_ENV === 'production',
    middleware: [sagaMiddleware, localStorageMiddleware]
  }));

  sagaMiddleware.run(rootSaga, {history});

  configureInterceptors(store, axios);

  return store;
};

export default initApp;
