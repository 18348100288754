import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    color: props => props.color,
    '&:checked': {
      color: props => props.color,
    },
    checked: {},
  },
});

const WordCloudLegendItem = ({color, label, onChange, checked}) => {
  const classes = useStyles({color});

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          checked={checked}
          onChange={onChange}
          className={classes.root}
        />
      }
      label={label}
    />
  );
};

export default WordCloudLegendItem;