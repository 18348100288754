import {
  CACHE_URL_MAPPED_FILTERS,
  CHANGE_FILTER,
  CLEAN_FILTERS_STATE,
  FILTERS_REQUEST,
  FILTERS_SUCCESS,
  HIDE_EXPIRED_URL_MODAL,
  LOAD_HX_INDEX_FAILURE,
  LOAD_HX_INDEX_REQUEST,
  LOAD_HX_INDEX_SUCCESS, SET_OPERATOR,
  SHOW_EXPIRED_URL_MODAL,
  SUBSCRIBE_TO_FILTERS,
  UNSUBSCRIBE_FROM_FILTERS,
  UPDATE_SELECTED_FILTERS
} from "./filters-actions";
import {toggleSelectedDown, toggleSelectedUp} from "./filters-utils";
import {DEFAULT_OPERATOR} from "./filters-constants";

const initialState = {
  loading: false,
  loaded: false,
  filters: [],
  flatFilters: {},
  selectedFilters: [],
  operator: DEFAULT_OPERATOR,
  subscriptions: [],
  urlMappedCache: {},
  hxData: null,
  hxDataLoading: true,
  hxDataError: null,
  checksum: '',
  expiredUrlModalOpen: false,
};

const changeFilter = (state, action) => {
  const filter = action.filter;
  const id = filter.id;
  const changes = action.changes;

  let newFlatFilters = {
    ...state.flatFilters,
    [id]: {
      ...state.flatFilters[id],
      ...changes
    }
  };

  toggleSelectedUp(newFlatFilters, id, filter.parentId);
  toggleSelectedDown(newFlatFilters, id);

  return {
    ...state,
    flatFilters: newFlatFilters
  };
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTERS_REQUEST:
      return {...state, loading: true, loaded: false};
    case FILTERS_SUCCESS:
      return {
        ...state,
        checksum: action.checksum,
        filters: action.filters,
        flatFilters: action.flatFilters,
        selectedFilters: action.selectedFilters,
        operator: action.operator,
        loading: false,
        loaded: true
      };
    case CHANGE_FILTER:
      return changeFilter(state, action);
    case UPDATE_SELECTED_FILTERS:
      return {
        ...state,
        flatFilters: action.flatFilters,
        selectedFilters: action.selectedFilters,
      };
    case SUBSCRIBE_TO_FILTERS:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.subscription]
      };
    case UNSUBSCRIBE_FROM_FILTERS:
      return {
        ...state,
        subscriptions: state.subscriptions.filter(s => s !== action.subscription)
      };
    case CACHE_URL_MAPPED_FILTERS:
      return {
        ...state,
        urlMappedCache: {
          ...state.urlMappedCache,
          [action.urlPart]: {
            filters: [...state.filters],
            flatFilters: {...state.flatFilters},
            selectedFilters: [...state.selectedFilters],
            checksum: state.checksum,
            operator: state.operator,
          }
        }
      };
    case LOAD_HX_INDEX_REQUEST:
      return {...state, hxDataLoading: true};
    case LOAD_HX_INDEX_SUCCESS:
      return {...state, hxDataLoading: false, hxData: action.hxData};
    case LOAD_HX_INDEX_FAILURE:
      return {...state, hxDataLoading: false, hxDataError: action.error};
    case SHOW_EXPIRED_URL_MODAL:
      return {...state, expiredUrlModalOpen: true};
    case HIDE_EXPIRED_URL_MODAL:
      return {...state, expiredUrlModalOpen: false};
    case CLEAN_FILTERS_STATE:
      return {...initialState};
    case SET_OPERATOR:
      return {...state, operator: action.operator};
    default:
      return state;
  }
};

export default filtersReducer;