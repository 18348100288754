import React from 'react';
import {HorizontalBar} from "react-chartjs-2";
import {useTranslation} from "react-i18next";

const GREEN_INACTIVE = 'rgb(44, 160, 44)';
const RED_INACTIVE = 'rgb(214, 39, 40)';
const GRAY_INACTIVE = 'rgb(180, 180, 180)';

const getHxColor = value => {
  let color = GRAY_INACTIVE;

  if (value < -0.15) {
    color = RED_INACTIVE;
  } else if (value > 0.15) {
    color = GREEN_INACTIVE;
  }

  return color;
}

const HxIndexChart2 = React.forwardRef(({data, noScale}, ref) => {
  const {t} = useTranslation();
  const initialData = {
    labels: [],
    datasets: [{
      barThickness: 35,
      data: [],
      backgroundColor: [],
      counts: [],
    }],
  };

  const chartData = data.reduce((acc, d) => {
    acc.labels.push(d['score_column_name']);
    const dataset = acc.datasets[0];
    const value = d['hb_index'];

    dataset.data.push(value);
    dataset.counts.push(d['count']);
    dataset.backgroundColor.push(getHxColor(value));

    return acc;
  }, initialData);

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];

          return t('hx_index', 'HX Index') + ': ' + value.toFixed(3);
        },
        afterLabel: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const count = dataset.counts[tooltipItem.index];

          return t('count', 'Count') + ': ' + count;
        },
      },
      yAlign: 'center',
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return value > 0 ? 'start' : 'end'
        },
        formatter: v => v.toFixed(3),
        labels: {
          value: {
            align: context => {
              const value = context.dataset.data[context.dataIndex];
              return value > 0 ? '-155' : '-25'
            },
            color: context => {
              const value = context.dataset.data[context.dataIndex];
              return getHxColor(value);
            },
            offset: 6,
          },
          count: {
            align: context => {
              const value = context.dataset.data[context.dataIndex];
              return value > 0 ? '155' : '25'
            },
            formatter: (_, context) => {
              return context.dataset.counts[context.dataIndex];
            },
            offset: 6,
          }
        }
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          min: -1,
          max: 1,
          fontColor: Boolean(noScale) ? 'rgba(0, 0, 0, 0)' : undefined
        }
      }],
    },
  };

  const chartHeight = initialData.datasets.length * initialData.datasets[0].data.length * 40 + 60;

  return (
    <div style={{height: chartHeight, overflow: 'hidden', marginBottom: noScale ? '-25px' : '0'}}>
      <HorizontalBar
        ref={ref}
        data={chartData}
        height={null}
        width={null}
        options={options}
      />
    </div>
  );
});

export default HxIndexChart2;