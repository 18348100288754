import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getTagsRequest} from "../reports-actions";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Chip, TextField} from "@material-ui/core";
import './Tags.css';

const Tags = ({id, tags, onChange, allowNew, helperText}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const suggestions = useSelector(state => state.reports.tags);

  useEffect(() => {
    dispatch(getTagsRequest());
  }, [dispatch]);

  return (
    <Autocomplete
      id={id}
      multiple
      filterSelectedOptions
      value={tags}
      onChange={(event, value) => onChange(value)}
      options={suggestions}
      freeSolo={allowNew}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={t('tags', 'Tags')}
          helperText={helperText}
          fullWidth
        />
      )}
    />
  );
};

export default Tags;