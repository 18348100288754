import React from 'react';
import HxIndexChart2 from "./hx-index-chart2";
import {Grid} from "@material-ui/core";

const maxLength = 250;

const HxIndexChartCombiner = ({chartRefs, data, secondVariableMode}) => {
  if (!secondVariableMode) {
    return <HxIndexChart2 data={data[0].data} ref={el => chartRefs.current[0] = el}/>
  }

  const maxLengthlabel = Math.max(...data.map(d => d.filterName.length));
  let length = maxLengthlabel * 7;
  if (length > maxLength) {
    length = maxLength;
  }

  return data.map((d, i) => (
    <Grid item container alignItems="center" key={d.filterName}>
      <Grid item style={{width: length}}>
        {d.filterName}
      </Grid>
      <Grid item xs style={{overflow: 'hidden'}}>
        <HxIndexChart2
          data={d.data}
          noScale={i < data.length - 1}
          ref={el => chartRefs.current[i] = el}
        />
      </Grid>
    </Grid>
  ));
};

export default HxIndexChartCombiner;