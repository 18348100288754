import React from 'react';
import {makeStyles, Grid, IconButton, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  pageInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none'
    },
    '& input': {
      width: '60px',
      textAlign: 'right',
    }
  }
}));

const Paginator  = ({
  changePage,
  page,
  totalPages,
  callback,
  label,
}) => {
  const {t} = useTranslation();
  const styles = useStyles();

  const doChangePage = page => {
    if (page > 0 && page <= totalPages) {
      changePage(page);

      if (callback) {
        callback();
      }
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    doChangePage(page);
  };

  return (
    <Grid container spacing={1} alignItems="center" justify="flex-end" component="form" onSubmit={onSubmit}>
      <Grid item>
        <IconButton
          disabled={page === 1}
          type="button"
          label={t('previous', 'Previous')}
          onClick={() => doChangePage(page - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Grid>
        <TextField
          label={label || t('page', 'Page')}
          variant="outlined"
          fullWidth
          type="number"
          min="1"
          max={totalPages}
          value={page}
          className={styles.pageInput}
          onChange={e => doChangePage(Number(e.target.value))}
          onFocus={(e => e.target.select())}
        />
      </Grid>
      <Grid item>
        / {totalPages > 0 && totalPages}
      </Grid>
      <Grid item>
        <IconButton
          disabled={page === totalPages}
          type="button"
          label={t('next', 'Next')}
          onClick={() => doChangePage(page + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Paginator;