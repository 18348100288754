import React from 'react';
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from 'react-router-dom';
import {Link, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  stats: {
    textAlign: 'center',
    padding: `0 0 ${theme.spacing(1)}px 0`
  },
  strong: {
    fontWeight: 400
  }
}));

const Stats = ({hxData, reportId}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const responsesAfterFilter = hxData['responses_after_meta_filter'];
  const responsesTotal = hxData['responses_total'];
  const totalWords = hxData['total_words'];
  const emoWordCount = hxData['emo_word_count'];
  const empty = hxData['empty_lines_count'];
  const emoResponses = hxData['emotional_responses'];

  let responsesNumber = emoResponses + ' / ' + responsesTotal;

  if (responsesAfterFilter !== responsesTotal) {
    responsesNumber = emoResponses + ' / ' + responsesAfterFilter + ' / ' + responsesTotal;
  }

  let emotionality = 'No data';

  if (totalWords && emoWordCount) {
    emotionality = (emoWordCount / totalWords * 100).toFixed(1) + "%";
  }

  return (
    <p className={classes.stats}>
      <strong className={classes.strong}>{t("stats_emo_responses", "Emo Responses")}:</strong> {responsesNumber}<br/>
      <strong className={classes.strong}>{t("stats_empty", "Empty")}:</strong> {empty}{' | '}
      <strong className={classes.strong}>{t("stats_emotionality", "Emo")}:</strong> {emotionality}{' | '}
      <Link component={RouterLink} to={'/report/' + reportId + '/metrics'}>{t('more', 'More...')}</Link>
    </p>
  );
};

export default Stats;