import {put, takeEvery, select} from 'redux-saga/effects';
import reportApi from "../../API-report";
import {
  EDIT_SINGLE_RESPONSE_REQUEST,
  LOAD_SINGLE_RESPONSE_REQUEST,
  loadSingleResponseSuccess
} from "./edit-single-response-actions";
import {addNotification} from "../notifications/notifications-actions";
import {checkIfReportGotEditedRows} from "../report-router/report-router-sagas";
import {createErrorMessage} from "../../utilities/createErrorMessage";
import i18n from "../../i18n";

export function* loadSingleResponse({rowNumber}) {
  try {
    const reportId = yield select(state => state.report.reportId);

    const response = yield reportApi.loadSingleResponseForEdit(reportId, rowNumber);

    yield put(loadSingleResponseSuccess(response.data));
  } catch (e) {
    yield put(addNotification({status: "Error", message: [i18n.t("error_load_verbatim", "Could not load this verbatim")]}));
    console.error('ERROR FROM LOAD SINGLE RESPONSE FOR EDIT', e);
  }
}

export function* editSingleResponse({history}) {
  try {
    const editedReportRow = yield select(state => state.editSingleResponse.response);
    const reportId = yield select(state => state.report.reportId);
    const rowNumber = editedReportRow.row_number;

    yield reportApi.editSingleResponse(reportId, rowNumber, editedReportRow);

    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/report/' + reportId);
    }

    yield checkIfReportGotEditedRows(reportId);

    yield put(addNotification({status: "Success", message: [i18n.t("save_success", "Save successful")]}));
  } catch (e) {
    console.error('ERROR FROM EDIT SINGLE RESPONSE', e);
    yield put(addNotification({status: "Error", message: [createErrorMessage(e, i18n.t("error_save_verbatim", "Could not save this verbatim"))]}));
  }
}

export default [
  takeEvery(LOAD_SINGLE_RESPONSE_REQUEST, loadSingleResponse),
  takeEvery(EDIT_SINGLE_RESPONSE_REQUEST, editSingleResponse),
];
