import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Helmet from "react-helmet";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {companyStatsRequest, userStatsRequest} from "./purchase-statistics-actions";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import UiModal from "../../components/UiModal/UiModal";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import UserReportsModal from "./UserReportsModal";

const useStyles = makeStyles(theme => ({
  paperWithPadding: {
    padding: theme.spacing(2),
  },
  mainContainer: {
    maxWidth: 900,
    margin: '0 auto',
  },
  statsTableRow: {
    '& > td': {
      width: '50%'
    }
  },
  statsLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(5)
  },
  showCompanyStats: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}))

const PurchaseStatistics = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [company, setCompany] = useState('');
  const [details, setDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showCompanyStats, setShowCompanyStats] = useState(false);

  const {
    companyStats,
    companyStatsLoading,
    userStatsLoading,
    userStats
  } = useSelector(state => state.purchaseStats);

  useEffect(() => {
    dispatch(userStatsRequest());
  }, []);

  useEffect(() => {
    if (userStats && userStats['companies'].length > 0) {
      setCompany(userStats['companies'][0]);
    }
  }, [userStats]);

  useEffect(() => {
    if (company) {
      dispatch(companyStatsRequest(company));
    }
  }, [company]);

  const columns = [
    {
      name: 'date',
      label: t('month', 'Month'),
      options: {
        sort: true,
        sortOrder: 'asc',
        setCellHeaderProps: () => ({style: {width: '100px'}})
      }
    },
    {
      name: 'AVU_used',
      label: t('avu_used', 'AVU Used'),
      options: {
        setCellHeaderProps: () => ({style: {width: '130px'}})
      }
    },
    {
      name: 'AVU_purchased',
      label: t('avu_purchased', 'AVU Purchased'),
      options: {
        setCellHeaderProps: () => ({style: {width: '140px'}})
      }
    },
    {
      name: 'AVU_remaining',
      label: t('avu_remaining', 'AVU Remaining'),
      options: {
        setCellHeaderProps: () => ({style: {width: '130px'}})
      }
    },
    {
      name: 'purchase_details',
      label: t('purchase_details', 'Purchase Details'),
      options: {
        sort: false,
        customBodyRender: value => value.map((s, i) => (
          <IconButton key={i} size="small" onClick={() => {
            setDetails(s);
            setShowDetails(true)
          }}>
            <InfoIcon fontSize="small"/>
          </IconButton>
        ))
      }
    }
  ];

  return (
    <>
      <Helmet><title>User Cabinet</title></Helmet>
      <Grid container spacing={1} direction="column" className={classes.mainContainer} wrap="nowrap">
        <Grid item xs>
          {userStatsLoading ? (
            <Paper><Box className={classes.statsLoading}><CircularProgress/></Box></Paper>
          ) : userStats && (
            <Paper className={classes.paperWithPadding}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h4">{userStats['username']}</Typography>
                  <Typography variant="subtitle1">{userStats['email']}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => setShowReports(true)}
                  >
                    {t('show_reports', 'Show Reports')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
        <Grid item xs>
          <Paper className={classes.paperWithPadding}>
            <Grid container direction="column" spacing={2} wrap="nowrap">
              <Grid item xs>
                <TextField
                  fullWidth
                  select
                  label={t('select_company', 'Select company')}
                  variant="outlined"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                >
                  {userStats ? userStats['companies'].map(company => (
                    <MenuItem key={company} value={company}>{company}</MenuItem>
                  )): []}
                </TextField>
              </Grid>
              {companyStatsLoading ? (
                <Box className={classes.statsLoading}><CircularProgress/></Box>
              ) : companyStats && (
                <>
                  <Grid item xs>
                    <Table>
                      <TableBody>
                        <TableRow className={classes.statsTableRow}>
                          <TableCell><strong>{t('users_count', 'Users')}</strong></TableCell>
                          <TableCell>{companyStats['users_count']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>{t('reports_count', 'Reports')}</strong></TableCell>
                          <TableCell>{companyStats['reports_count_total']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>{t('reports_created_by_you', 'Reports created by you')}</strong></TableCell>
                          <TableCell>{companyStats['created_by_user_reports_count']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>{t('avu_used_total', 'AVU used total')}</strong></TableCell>
                          <TableCell>{companyStats['avu_used_total']}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>{t('avu_remaining', 'AVU remaining')}</strong></TableCell>
                          <TableCell>{companyStats['avu_remaining']}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs className={classes.showCompanyStats}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setShowCompanyStats(true)}
                    >
                      {t('show_purchase_statistics', 'Show purchase statistics')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        <UiModal
          open={showDetails}
          onClose={() => setShowDetails(false)}
          title={t('purchase_details', 'Purchase details')}
          maxWidth="md"
        >
          <DialogContent dividers>
            {details && (
              <>
                <p><strong>{t('avu_purchased', 'AVU Purchased')}:</strong> {details['AVU_purchased']}</p>
                <p><strong>{t('date', 'Date')}:</strong> {details['created_date']}</p>
                <p><strong>{t('description', 'Description')}:</strong> {details['description']}</p>
                <p><strong>{t('term_name', 'Term name')}:</strong> {details['term_name']}</p>
              </>
            )}
          </DialogContent>
        </UiModal>
        <UiModal
          open={showCompanyStats}
          onClose={() => setShowCompanyStats(false)}
          title={t('company_purchase_statistics', "Company purchase statistics")}
          maxWidth="md"
        >
          <DialogContent dividers>
            {companyStats && (
              <MUIDataTable
                title={""}
                data={companyStats['purchase_statistics']}
                columns={columns}
                options={{
                  search: false,
                  pagination: false,
                  selectableRows: 'none',
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  elevation: 0,
                  responsive: 'standard',
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setShowCompanyStats(false)}>{t('close', 'Close')}</Button>
          </DialogActions>
        </UiModal>
        <UserReportsModal
          isOpen={showReports}
          onClose={() => setShowReports(false)}
          userStats={userStats}
        />
      </Grid>
    </>
  );
};

export default PurchaseStatistics;