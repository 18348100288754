import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "@material-ui/core";

const useStyles = makeStyles({
  link: {
    color: 'blue',
    cursor: 'pointer'
  }
});

const TableCellLink = ({children, ...props}) => {
  const classes = useStyles();

  return (
    <Link className={classes.link} {...props}>
      {children}
    </Link>
  );
};

export default TableCellLink;