import React from "react";
import {Divider, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButtonWithCircularProgress from "../IconButtonWithCircularProgress/IconButtonWithCircularProgress";

const DropdownMenu = ({icon, iconStyle, items, disabled, loading, buttonSize}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const itemsComponents = items.reduce((acc, item, i) => {
    if (!item.restrict) {
      if (item.divider) {
        acc.push(<Divider key={i} />);
      } else {
        acc.push((
          <MenuItem
            key={i}
            component={item.component}
            onClick={() => {
              item.onClick && item.onClick();
              handleClose();
            }}
            selected={item.selected}
            {...item.props}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text}/>
          </MenuItem>
        ))
      }
    }

    return acc;
  }, []);

  const menu = (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {itemsComponents}
    </Menu>
  );

  return (
    <IconButtonWithCircularProgress
      IconButtonProps={{
        variant: 'contained',
        style: iconStyle,
        onClick: handleClick,
        disabled: disabled,
        size: buttonSize,
      }}
      menu={menu}
      loading={loading}
    >
      {icon ? icon : <MoreVertIcon/>}
    </IconButtonWithCircularProgress>
  );
};

export default DropdownMenu;