import {put, select, takeEvery} from "redux-saga/effects";
import taxonomyPersistence from './taxonomy-persistence';
import {
  ADD_WORDS_REQUEST,
  addWordsDone,
  addWordsToBasket,
  clearBasket,
  CREATE_TAXONOMY_REQUEST,
  createTaxonomyDone,
  DELETE_TAXONOMY_REQUEST,
  DELETE_WORDS_REQUEST,
  deleteTaxonomyDone,
  deleteWordsFailure,
  deleteWordsSuccess,
  FETCH_EXAMPLES_REQUEST,
  FETCH_FULL_VERBATIM_REQUEST,
  FETCH_LANGUAGES_REQUEST,
  FETCH_TARGET_TAXONOMIES_REQUEST,
  FETCH_TAXONOMIES_REQUEST,
  FETCH_VALUE_SYSTEMS_REQUEST,
  fetchExamplesFailure,
  fetchExamplesSuccess,
  fetchFullVerbatimFailure,
  fetchFullVerbatimSuccess,
  fetchLanguagesFailure,
  fetchLanguagesSuccess,
  fetchTargetTaxonomiesFailure,
  fetchTargetTaxonomiesSuccess,
  fetchTaxonomiesFailure,
  fetchTaxonomiesRequest,
  fetchTaxonomiesSuccess,
  fetchValueSystemsFailure,
  fetchValueSystemsSuccess,
  replaceBasket,
  RESET,
  SAVE_REQUEST,
  saveDone,
  SEARCH_WORDS_REQUEST,
  searchWordsFailure,
  searchWordsSuccess,
  SET_CURRENT_LANGUAGE,
  SET_CURRENT_VALUE_SYSTEM,
  setAddWordsDialogOpen,
  setCurrentLanguage,
  setCurrentValueSystem,
  setDeleteTaxonomyDialogOpen,
  setDeleteWordsDialogOpen,
  setNewTaxonomyDialogOpen,
  setSearchWordsDialogOpen,
  setSelectedTaxonomies,
  setTargetTaxonomy,
  setTaxonomiesAreSelected,
  setTranslateWordsDialogOpen,
  TRANSLATE_WORDS_REQUEST,
  translateWordsDone,
  VALUE_SYSTEM_INFO_REQUEST,
  valueSystemInfoFailure,
  valueSystemInfoSuccess
} from "./taxonomy-actions";
import API from "../../API";
import {addNotification} from "../notifications/notifications-actions";
import {selectPersistentData} from "../../utilities/localStorageMiddleware";
import {intersect} from "../../utilities/functions";
import {
  TAXONOMY_ARE_SELECTED,
  TAXONOMY_LANGUAGE,
  TAXONOMY_SELECTED_TAXONOMIES,
  TAXONOMY_VALUE_SYSTEM,
  TAXONOMY_WORDS_IN_BASKET
} from "./taxonomy-constants";
import {
  denormalizeTaxonomyResponse,
  denormalizeWords,
  normalizeValueSystemInfo,
  normalizeWords
} from "./taxonomy-functions";
import i18n from "../../i18n";

const createErrorMessage = (e, message) => {
  if (e.response) {
    if (e.response.data && e.response.data['ERROR']) {
      return message + ` (${e.response.data['ERROR']})`;
    }

    return message + '(Network error)';
  }

  return message;
};

export function* fetchValueSystems() {
  try {
    const response = yield API.getValueSystemListRev2();
    const valueSystems = response.data;

    yield put(fetchValueSystemsSuccess(valueSystems));

    if (valueSystems.length > 0) {
      const persistedValueSystem = selectPersistentData(TAXONOMY_VALUE_SYSTEM);

      if (valueSystems.includes(persistedValueSystem)) {
        yield put(setCurrentValueSystem(persistedValueSystem));
      } else {
        yield put(setCurrentValueSystem(valueSystems[0]));
      }
    }
  } catch (e) {
    console.error('IN *fetchValueSystems()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_value_systems', "Could not fetch value systems"))]}))
    yield put(fetchValueSystemsFailure());
  }
}

export function* fetchLanguages() {
  try {
    const response = yield API.getLanguageList();
    const languages = response.data;
    yield put(fetchLanguagesSuccess(languages));

    if (languages.length > 0) {
      const persistedLanguage = selectPersistentData(TAXONOMY_LANGUAGE);

      if (languages.includes(persistedLanguage)) {
        yield put(setCurrentLanguage(persistedLanguage));
      } else {
        yield put(setCurrentLanguage(languages[0]));
      }
    }
  } catch (e) {
    console.error('IN *fetchLanguages()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_languages', "Could not fetch languages"))]}))
    yield put(fetchLanguagesFailure());
  }
}

export function* tryToFetchTaxonomies() {
  const {language, valueSystem} = yield select(state => state.taxonomy);

  if (language && valueSystem) {
    const selectedTaxonomies = yield select(state => state.taxonomy.selectedTaxonomies);

    if (selectedTaxonomies.length > 0) {
      // when value system or language is changed, list of selected taxonomies should be cleared
      yield put(setSelectedTaxonomies([]));
    }

    yield put(fetchTaxonomiesRequest(language, valueSystem));
  }
}

export function* fetchTaxonomies({valueSystem, language}) {
  try {
    const response = yield API.getTaxonomyListRev2(valueSystem, language);

    const {taxonomies, taxonomiesInfo} = denormalizeTaxonomyResponse(response.data);

    const persistedTaxonomies = selectPersistentData(TAXONOMY_SELECTED_TAXONOMIES);
    if (persistedTaxonomies) {
      const intersected = intersect(taxonomies, persistedTaxonomies);
      yield put(setSelectedTaxonomies(intersected));
    }

    const persistedAreSelected = selectPersistentData(TAXONOMY_ARE_SELECTED);
    if (persistedAreSelected) {
      yield put(setTaxonomiesAreSelected(persistedAreSelected));
    }

    yield put(fetchTaxonomiesSuccess({taxonomies, taxonomiesInfo}));
  } catch (e) {
    console.error('IN *fetchTaxonomies()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_taxonomies', "Could not fetch taxonomies"))]}))
    yield put(fetchTaxonomiesFailure());
  }
}

export function* createTaxonomy({taxonomyName, valueSystem, language, isTargetTaxonomy}) {
  try {
    yield API.createTaxonomy(taxonomyName, valueSystem, language);

    if (isTargetTaxonomy) {
      yield fetchTargetTaxonomies({language});
      yield put(setTargetTaxonomy(taxonomyName));
    } else {
      yield fetchTaxonomies({valueSystem, language});
      const selectedTaxonomies = yield select(state => state.taxonomy.selectedTaxonomies);
      yield put(setSelectedTaxonomies([...selectedTaxonomies, taxonomyName]));
    }

    yield put(setNewTaxonomyDialogOpen(false));
    yield put(addNotification({
      status: "Success",
      message: [i18n.t('taxonomy_name_is_created_and_selected', 'The "{{taxonomyName}}" taxonomy is created and selected', {taxonomyName})]
    }));
  } catch (e) {
    console.error('IN *createTaxonomy()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_create_taxonomy', "Could not create taxonomy"))]}));
  } finally {
    yield put(createTaxonomyDone());
  }
}

export function* deleteTaxonomy({taxonomyName}) {
  try {
    yield API.deleteTaxonomyRev2(taxonomyName);
    const {valueSystem, language, selectedTaxonomies} = yield select(state => state.taxonomy);
    yield fetchTaxonomies({valueSystem, language});

    if (selectedTaxonomies.includes(taxonomyName)) {
      yield put(setSelectedTaxonomies(selectedTaxonomies.filter(name => name !== taxonomyName)));
    }

    yield put(addNotification({status: "Success",  message: [i18n.t('taxonomy_name_is_deleted', 'The "{{taxonomyName}}" taxonomy is deleted.', {taxonomyName})]}));
    yield put(setDeleteTaxonomyDialogOpen(false));
  } catch (e) {
    console.error('IN *createTaxonomy()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_delete_taxonomy', 'Could not delete taxonomy'))]}));
  } finally {
    yield put(deleteTaxonomyDone());
  }
}

export function* resetTaxonomyManager() {
  yield put(setTaxonomiesAreSelected(false));
  yield put(clearBasket());
}

export function* addWords({taxonomyName, words, originalWord}) {
  try {
    const response = yield API.createTaxonomyRows(taxonomyName, words, originalWord);
    const newWords = normalizeWords(response.data);
    yield put(addWordsToBasket(newWords));
    yield put(addNotification({status: "Success",  message: [i18n.t('words_were_added', `Words were added and available for editing.`)]}));
    yield put(setAddWordsDialogOpen(false));
  } catch (e) {
    console.error('IN *addWords()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_add_words', "Could not add words"))]}));
  } finally {
    yield put(addWordsDone());
  }
}

export function* searchWords({taxonomyNames, searchString, isRegexp}) {
  try {
    const response = yield API.getTaxonomyRows(taxonomyNames, searchString, isRegexp);
    yield put(searchWordsSuccess(response.data));
  } catch (e) {
    console.error('IN *addWords()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_search', "Could not search"))]}));
    yield put(searchWordsFailure());
  }
}

export function* fetchValueSystemInfo() {
  try {
    const valueSystemName = yield select(state => state.taxonomy.valueSystem);
    const response = yield API.getValueSystem(valueSystemName);
    yield put(valueSystemInfoSuccess(normalizeValueSystemInfo(response.data)));

    const persistedWordsInBasket = selectPersistentData(TAXONOMY_WORDS_IN_BASKET);
    if (persistedWordsInBasket && persistedWordsInBasket.length > 0) {
      yield put(addWordsToBasket(persistedWordsInBasket));
    }
  } catch (e) {
    console.error('IN *fetchValueSystemInfo()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_value_system_info', "Could not fetch value system info"))]}));
    yield put(valueSystemInfoFailure());
  }
}

export function* save() {
  try {
    const wordsInBasket = yield select(state => state.taxonomy.wordsInBasket);
    const words = denormalizeWords(wordsInBasket);
    const response = yield API.updateTaxonomyRows(words);
    const normalized = normalizeWords(response.data);
    yield put(replaceBasket(normalized));
  } catch (e) {
    console.error('IN *save()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_save_terms', "Could not save terms"))]}));
  } finally {
    yield put(saveDone());
  }
}

export function* fetchTargetTaxonomies({language}) {
  try {
    const valueSystem = yield select(state => state.taxonomy.valueSystem);
    const response = yield API.getTaxonomyListRev2(valueSystem, language);

    const {taxonomies, taxonomiesInfo} = denormalizeTaxonomyResponse(response.data);

    yield put(fetchTargetTaxonomiesSuccess({taxonomies, taxonomiesInfo}));
  } catch (e) {
    console.error('IN *fetchTargetTaxonomies()', e);
    yield put(fetchTargetTaxonomiesFailure());
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_target_taxonomies', "Could not fetch target taxonomies"))]}));
  }
}

export function* translateWords({wordsIds, translations, targetTaxonomy, targetLanguage}) {
  try {
    const searchWords = yield select(state => state.taxonomy.searchWords);

    const createdWords = [];

    for (const id of wordsIds) {
      const originalWord = searchWords.find(w => w._id.$oid === id);
      const translation = translations[id].translation;
      const response = yield API.createTaxonomyRows(targetTaxonomy, translation, originalWord.term);
      createdWords.push(...response.data.map(w => ({
        ...w,
        contexts: originalWord.contexts.map(ctx => ({...ctx, coding: [...ctx.coding]}))
      })));
    }

    yield put(addWordsToBasket(normalizeWords(createdWords)));
    yield put(setTranslateWordsDialogOpen(false));
    yield put(setSearchWordsDialogOpen(false));
  } catch (e) {
    console.error('IN *translateWords()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_create_translated_taxonomies', 'Could not create translated taxonomies'))]}));
  } finally {
    yield put(translateWordsDone());
  }
}

export function* fetchExamples() {
  try {
    const term = yield select(state => state.taxonomy.termForExamples);
    const response = yield API.getTaxonomyTermSentencesRev2(term);
    yield put(fetchExamplesSuccess(response.data));
  } catch (e) {
    console.error('IN *fetchExamples()', e);
    yield put(fetchExamplesFailure());
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_examples', 'Could not fetch examples'))]}));
  }
}

export function* fetchFullVerbatim({coordinates}) {
  try {
    const response = yield API.getSanitizeValue(coordinates);
    yield put(fetchFullVerbatimSuccess(response.data));
  } catch (e) {
    console.error('IN *fetchFullVerbatim()', e);
    yield put(fetchFullVerbatimFailure());
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_full_verbatim', "Could not fetch full verbatim"))]}));
  }
}

export function* deleteWords() {
  try {
    const wordsToDelete = yield select(state => state.taxonomy.wordsToDelete);
    yield API.deleteTaxonomyRowsRev2(wordsToDelete);
    yield put(deleteWordsSuccess());
    yield put(setDeleteWordsDialogOpen(false));
    yield put(addNotification({status: "Success",  message: [i18n.t('terms_deleted', "Terms deleted successfully")]}));
  } catch (e) {
    console.error('IN *deleteWords()', e);
    yield put(deleteWordsFailure());
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_delete_terms', "Could not delete terms"))]}));
  }
}

export default [
  ...taxonomyPersistence,
  takeEvery(FETCH_VALUE_SYSTEMS_REQUEST, fetchValueSystems),
  takeEvery(FETCH_LANGUAGES_REQUEST, fetchLanguages),
  takeEvery(SET_CURRENT_LANGUAGE, tryToFetchTaxonomies),
  takeEvery(SET_CURRENT_VALUE_SYSTEM, tryToFetchTaxonomies),
  takeEvery(FETCH_TAXONOMIES_REQUEST, fetchTaxonomies),
  takeEvery(CREATE_TAXONOMY_REQUEST, createTaxonomy),
  takeEvery(DELETE_TAXONOMY_REQUEST, deleteTaxonomy),
  takeEvery(RESET, resetTaxonomyManager),
  takeEvery(ADD_WORDS_REQUEST, addWords),
  takeEvery(SEARCH_WORDS_REQUEST, searchWords),
  takeEvery(VALUE_SYSTEM_INFO_REQUEST, fetchValueSystemInfo),
  takeEvery(SAVE_REQUEST, save),
  takeEvery(FETCH_TARGET_TAXONOMIES_REQUEST, fetchTargetTaxonomies),
  takeEvery(TRANSLATE_WORDS_REQUEST, translateWords),
  takeEvery(FETCH_EXAMPLES_REQUEST, fetchExamples),
  takeEvery(FETCH_FULL_VERBATIM_REQUEST, fetchFullVerbatim),
  takeEvery(DELETE_WORDS_REQUEST, deleteWords),
];