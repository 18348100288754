import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ValueSystemSelector from "./ValueSystemSelector";
import LanguageSelector from "./LanguageSelector";
import TaxonomySelector from "./TaxonomySelector";
import {setTaxonomiesAreSelected} from "../taxonomy-actions";
import PaperWithPadding from "../../../components/PaperWithPadding/PaperWithPadding";
import ActivatedTaxonomiesInfo from "./ActivatedTaxonomiesInfo";
import {useTranslation} from "react-i18next";

const InitialConfig = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const selectedTaxonomies = useSelector(state => state.taxonomy.selectedTaxonomies);
  const taxonomiesAreSelected = useSelector(state => state.taxonomy.taxonomiesAreSelected);

  if (taxonomiesAreSelected) return (
    <ActivatedTaxonomiesInfo/>
  );

  return (
    <PaperWithPadding>
      <Grid container alignItems="center" spacing={2} wrap="wrap">
        <Grid item xs={8} sm={10} md={4} lg={2}>
          <ValueSystemSelector/>
        </Grid>
        <Grid item xs={4} sm={2} md={2} lg={1}>
          <LanguageSelector/>
        </Grid>
        <Grid item xs>
          <TaxonomySelector/>
        </Grid>
        <Grid item>
          <Button
            onClick={() => dispatch(setTaxonomiesAreSelected(true))}
            disabled={selectedTaxonomies.length === 0}
          >
            {t('accept', 'Accept')}
          </Button>
        </Grid>
      </Grid>
    </PaperWithPadding>
  );
};

export default InitialConfig;