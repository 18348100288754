import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";

const NotExistingFilterItem = ({flatFilter}) => {
  return (
    <MenuItem disabled>
      {flatFilter.title}
    </MenuItem>
  );
};

export default NotExistingFilterItem;