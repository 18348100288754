import {put, select, takeEvery} from "redux-saga/effects";
import {
  CREATE_ARTICLE_REQUEST,
  CREATE_IMAGE_REQUEST,
  createArticleDone,
  createImageDone,
  DELETE_ARTICLE_REQUEST,
  DELETE_IMAGE_REQUEST,
  deleteArticleDone,
  deleteImageFailure,
  deleteImageSuccess,
  EDIT_ARTICLE_REQUEST,
  editArticleDone,
  FETCH_ARTICLE_REQUEST,
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_WITH_IMAGE_REQUEST,
  FETCH_IMAGES_REQUEST,
  FETCH_IMAGES_TAGS_REQUEST,
  fetchArticleFailure,
  fetchArticlesFailure,
  fetchArticlesSuccess,
  fetchArticleSuccess,
  fetchArticlesWithImageFailure,
  fetchArticlesWithImageSuccess,
  fetchImagesFailure,
  fetchImagesRequest,
  fetchImagesSuccess,
  fetchImagesTagsSuccess,
  setCreateModalOpen,
  setImageModalOpen
} from "./acticles-actions";
import API from "../../API";
import {addNotification} from "../notifications/notifications-actions";
import {createErrorMessage} from "../../utilities/createErrorMessage";
import i18n from "../../i18n";

function* fetchArticles() {
  try {
    const response = yield API.getHbArticles();
    yield put(fetchArticlesSuccess(response.data.results));
  } catch (e) {
    console.error('IN *fetchArticles()', e);
    yield put(fetchArticlesFailure());
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_articles', 'Could not fetch articles'))]}));
  }
}

function* createArticle({payload: {name, title}}) {
  try {
    yield API.createHbArticle(name, title);
    yield *fetchArticles();
    yield put(setCreateModalOpen(false));
    yield put(addNotification({status: "Success",  message: [i18n.t('article_created_successfully', 'Article created successfully')]}));
  } catch (e) {
    console.error('IN *createArticle()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_create_article', 'Could not create article'))]}));
  } finally {
    yield put(createArticleDone());
  }
}

function* deleteArticle({payload: id}) {
  try {
    yield API.deleteHbArticle(id);
    yield *fetchArticles();
    yield put(addNotification({status: "Success",  message: [i18n.t('article_deleted_successfully', 'Article deleted successfully')]}));
  } catch (e) {
    console.error('IN *deleteArticle()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_delete_article', 'Could not delete article'))]}));
  } finally {
    yield put(deleteArticleDone(id));
  }
}

function* fetchArticle({payload: {id, name}}) {
  try {
    const response = id ? yield API.getSingleHbArticleById(id) : yield API.getSingleHbArticleByName(name);
    if (!(response.data.results && response.data.results[0])) {
      yield put(addNotification({status: "Error",  message: [i18n.t('article_not_found', 'Article not found, possibly already deleted')]}));
      return yield put(fetchArticleFailure());
    }

    yield put(fetchArticleSuccess(response.data.results[0]));
  } catch (e) {
    console.error('IN *fetchArticle()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('article_could_not_be_fetched', 'Article could not be fetched!'))]}));
    yield put(fetchArticleFailure());
  }
}

function* editArticle({payload: {articleData, redirect, history}}) {
  try {
    yield API.editHbArticle(articleData);

    if (redirect) {
      history.push('/articles');
    }

    yield put(addNotification({status: "Success",  message: [i18n.t('article_edited_successfully','Article edited successfully')]}));
  } catch (e) {
    console.error('IN *editArticle()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_edit_article', 'Could not edit article'))]}));
  } finally {
    yield put(editArticleDone());
  }
}

function* fetchImages() {
  try {
    const tag = yield select(state => state.articles.selectedTag);
    const response = yield API.getHbArticleImages(null, tag);
    yield put(fetchImagesSuccess(response.data.results));
  } catch (e) {
    console.error('IN *fetchImages()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_img', 'Could not fetch images'))]}));
    yield put(fetchImagesFailure());
  }
}

function* fetchImagesTags() {
  try {
    const response = yield API.getHbArticleImagesTags();
    yield put(fetchImagesTagsSuccess(response.data.results));
  } catch (e) {
    console.error('IN *fetchImagesTags()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_img_tags', 'Could not fetch image tags'))]}));
    yield put(fetchImagesFailure());
  }
}

function* createImage({payload: {imageData, api}}) {
  try {
    const response = yield API.createHbArticleImage(imageData);
    yield put(addNotification({status: "Success",  message: [i18n.t('img_uploaded_successfully', 'Image uploaded successfully')]}));
    yield put(setImageModalOpen(false));
    if (api) {
      const image = response.data.results;
      api.replaceSelection(`![${image.name}](${image.url})`);
    } else {
      yield put(fetchImagesRequest());
    }
  } catch (e) {
    console.error('IN *createImage()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_create_img', 'Could not create image'))]}));
  } finally {
    yield put(createImageDone());
  }
}

function* fetchArticlesWithImage({payload: id}) {
  try {
    const response = yield API.getHbArticlesWithImage(id);
    yield put(fetchArticlesWithImageSuccess(response.data.results));
  } catch (e) {
    console.error('IN *fetchArticlesWithImage()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_fetch_articles_with_img', 'Could not fetch articles with given image'))]}));
    yield put(fetchArticlesWithImageFailure());
  }
}

function* deleteImage({payload: id}) {
  try {
    yield API.deleteHbArticleImage(id);
    yield put(deleteImageSuccess(id));
    yield put(addNotification({status: "Success",  message: [i18n.t('img_deleted_successfully', 'Image deleted successfully')]}));
  } catch (e) {
    console.error('IN *deleteImage()', e);
    yield put(addNotification({status: "Error",  message: [createErrorMessage(e, i18n.t('could_not_delete_img', 'Could not delete image'))]}));
    yield put(deleteImageFailure(id));
  }
}

const articlesSagas = [
  takeEvery(FETCH_ARTICLES_REQUEST, fetchArticles),
  takeEvery(CREATE_ARTICLE_REQUEST, createArticle),
  takeEvery(DELETE_ARTICLE_REQUEST, deleteArticle),
  takeEvery(FETCH_ARTICLE_REQUEST, fetchArticle),
  takeEvery(EDIT_ARTICLE_REQUEST, editArticle),
  takeEvery(FETCH_IMAGES_REQUEST, fetchImages),
  takeEvery(FETCH_IMAGES_TAGS_REQUEST, fetchImagesTags),
  takeEvery(CREATE_IMAGE_REQUEST, createImage),
  takeEvery(FETCH_ARTICLES_WITH_IMAGE_REQUEST, fetchArticlesWithImage),
  takeEvery(DELETE_IMAGE_REQUEST, deleteImage),
];

export default articlesSagas;