import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import withFilter from "../../hoc/with-filter/with-filter-hoc";
import NgramSearchForm from "./ngram-search-form";
import {searchRequest} from "./ngram-search-actions";
import NgramSearchResults from "./ngram-search-results";
import {showResponseSingleModal, singleResponseRequest} from "../response-single/response-single-actions";
import {setSelectedText, showContextMenu} from "../context-menu/context-menu-action-creators";
import {getSelectedText} from "../context-menu/context-menu-functions";
import {Box, CircularProgress, Paper} from "@material-ui/core";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";
import './ngram-search.css';

const NgramSearchContainer = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {id, searchWord, head, tail} = useParams();
  const searchResults = useSelector(state => state.ngramSearch.searchResults);

  const doRequest = useCallback(() => {
    dispatch(searchRequest({searchWord, head, tail}));
  }, [dispatch, searchWord, head, tail]);

  useEffect(() => {
    doRequest();
    dispatch(subscribeToFilters(doRequest));

    return () => {
      dispatch(unsubscribeFromFilters(doRequest));
    };
  }, [dispatch, searchWord, head, tail]);

  return (
    <div className="ngram-search">
      <Paper component={Box} p={2} style={{width: '100%'}}>
        <h2>{t('search', 'Search')}</h2>
        <NgramSearchForm reportId={id} />
        <hr/>
        <h2>{t('results', 'Results')}</h2>
        {searchResults ?
          <NgramSearchResults
            results={searchResults}
            showSingleResponseModal={() => dispatch(showResponseSingleModal())}
            getSingleResponse={responseNumber => dispatch(singleResponseRequest(responseNumber))}
            showContextMenu={coords => dispatch(showContextMenu(coords))}
            setSelectedText={() => dispatch(setSelectedText(getSelectedText()))}
          />
          :
          <CircularProgress />
        }
      </Paper>
    </div>
  )
};

export default withFilter(NgramSearchContainer);
