export const createErrorMessage = (e, message) => {
  if (e.response) {
    if (e.response.data && e.response.data['message']) {
      return message + ` (${e.response.data['message']})`;
    }

    if (e.response.data && e.response.data['ERROR']) {
      return message + ` (${e.response.data['ERROR']})`;
    }

    return message + ' (Server error)';
  }

  return message + ' (Network error)';
};
