import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  circle: {
    width: '15px',
    height: '15px',
    borderRadius: '15px',
    display: 'inline-block',
    verticalAlign: 'text-top'
  },
  link: {
    color: 'blue',
    cursor: 'pointer'
  }
});

const ColorCircle = ({color}) => {
  const classes = useStyles();

  return (
    <div style={{background: color}} className={classes.circle} />
  );
};

export default ColorCircle;