export const DATA_APPEND_SHOW_MODAL = '[DATA APPEND] SHOW_MODAL';
export const DATA_APPEND_HIDE_MODAL = '[DATA APPEND] HIDE_MODAL';

export const DATA_APPEND_SET_DOWNLOAD_OPTIONS = '[DATA APPEND] SET_DOWNLOAD_OPTIONS';
export const DATA_APPEND_INITIATE_DOWNLOAD = '[DATA APPEND] INITIATE_DOWNLOAD';

export const DATA_APPEND_DOWNLOAD_PROGRESS = '[DATA APPEND] DOWNLOAD_PROGRESS';

export const DATA_APPEND_DOWNLOAD_COMPLETE = '[DATA APPEND] DOWNLOAD_COMPLETE';

export const dataAppendShowModal = report => ({type: DATA_APPEND_SHOW_MODAL, report});
export const dataAppendHideModal = () => ({type: DATA_APPEND_HIDE_MODAL});

export const dataAppendSetDownloadOptions = downloadOptions => ({type: DATA_APPEND_SET_DOWNLOAD_OPTIONS, downloadOptions});
export const dataAppendInitiateDownload = number => ({type: DATA_APPEND_INITIATE_DOWNLOAD, number});

export const dataAppendDownloadProgress = (number, percent) => ({type: DATA_APPEND_DOWNLOAD_PROGRESS, percent, number});
export const dataAppendDownloadComplete = number => ({type: DATA_APPEND_DOWNLOAD_COMPLETE, number});