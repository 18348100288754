import React from "react";
import Grid from "@material-ui/core/Grid";

const ReportFormHeader = ({title, children}) => (
  <Grid container align="center" justify="center" style={{position: 'relative'}}>
    <Grid item>
      <h1 style={{fontWeight: 300}}>{title}</h1>
    </Grid>
    <Grid item style={{position: 'absolute', right: 0}}>
      {children}
    </Grid>
  </Grid>
);

export default ReportFormHeader;
