export const USER_COMPANIES_REQUEST = '[PURCHASE STATS] USER_COMPANIES_REQUEST';
export const USER_COMPANIES_SUCCESS = '[PURCHASE STATS] USER_COMPANIES_SUCCESS';
export const USER_COMPANIES_FAILURE = '[PURCHASE STATS] USER_COMPANIES_FAILURE';

export const COMPANY_STATS_REQUEST = '[PURCHASE STATS] COMPANY_STATS_REQUEST';
export const COMPANY_STATS_SUCCESS = '[PURCHASE STATS] COMPANY_STATS_SUCCESS';
export const COMPANY_STATS_FAILURE = '[PURCHASE_STATS] COMPANY_STATS_FAILURE';

export const USER_STATS_REQUEST = '[PURCHASE_STATS] USER_STATS_REQUEST';
export const USER_STATS_SUCCESS = '[PURCHASE_STATS] USER_STATS_SUCCESS';
export const USER_STATS_FAILURE = '[PURCHASE_STATS] USER_STATS_FAILURE';

export const userCompaniesRequest = () => ({type: USER_COMPANIES_REQUEST});
export const userCompaniesSuccess = companies => ({type: USER_COMPANIES_SUCCESS, companies});
export const userCompaniesFailure = () => ({type: USER_COMPANIES_FAILURE});

export const companyStatsRequest = company => ({type: COMPANY_STATS_REQUEST, company});
export const companyStatsSuccess = stats => ({type: COMPANY_STATS_SUCCESS, stats});
export const companyStatsFailure = () => ({type: COMPANY_STATS_FAILURE});

export const userStatsRequest = () => ({type: USER_STATS_REQUEST});
export const userStatsSuccess = userStats => ({type: USER_STATS_SUCCESS, userStats});
export const userStatsFailure = () => ({type: USER_STATS_FAILURE});