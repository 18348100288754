import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    fontSize: '1.4rem'
  }
}));

const FilterCheckbox = ({selected, partial}) => {
  const classes = useStyles();

  let IconComponent = CheckBoxOutlineBlankIcon;

  if (selected) {
    IconComponent = CheckBoxIcon;
  }

  if (partial) {
    IconComponent = IndeterminateCheckBoxIcon;
  }

  return <IconComponent className={classes.root} color="primary" />;
};

export default FilterCheckbox;