import {put, takeEvery} from 'redux-saga/effects';
import {WHITELABEL_REQUEST, whitelabelFailure, whitelabelSuccess} from "./main-actions";
import API from "../../API";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";

const DEFAULT_WHITELABEL_VALUES = {
  logo: null,
  title: 'HX',
  ico: process.env.PUBLIC_URL + '/favicon.png',
  showFooterLogo: true,
};

export function* fetchWhitelabelInfo({domain}) {
  try {
    const response = yield API.getWhitelabelInfo(domain);
    const {logo, title, ico, show_logo_in_footer: showFooterLogo} = response.data || DEFAULT_WHITELABEL_VALUES;
    yield put(whitelabelSuccess(logo, title, ico, showFooterLogo));
  } catch (e) {
    console.error('ERROR IN FETCH LOGO', e);
    yield put(whitelabelFailure());
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_fetch_logo', 'Could not fetch logo')]}))
  }
}

export default [
  takeEvery(WHITELABEL_REQUEST, fetchWhitelabelInfo)
];