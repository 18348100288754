import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Switch,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';

import UiModal from "../../../components/UiModal/UiModal";

import {changePublicityOfReport, toggleShareModal} from "../report-actions";
import {addNotification} from "../../notifications/notifications-actions";
import {Trans, useTranslation} from "react-i18next";

const ShareModal = () => {
  const {t} = useTranslation();
  const open = useSelector(state => state.report.shareModalVisible);
  const loading = useSelector(state => state.report.changePublicityLoading);
  const reportId = useSelector(state => state.report.reportId);
  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const isPublic = useSelector(state => state.reportInfo.report.public);

  const reportUrl = window.location.origin + '/report/' + reportId;

  const toggle = () => dispatch(toggleShareModal());

  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
    dispatch(addNotification({
      status: "Success",
      message: [t('copied_public_url_to_clipboard', 'Copied public URL to clipboard!')]
    }))
  };

  return (
    <UiModal
      title={t('share', 'Share')}
      open={open}
      onClose={toggle}
    >
      <Divider/>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>{t('you_can_change_public_status_of_your_report_here', 'You can change public status of your report here.')}</Grid>
          <Grid item xs>
            <Trans i18nKey="private_status_explanation">
              <strong>Private</strong> - only members of your company can see this report
            </Trans>
          </Grid>
          <Grid item xs>
            <Trans i18nKey="public_status_explanation">
              <strong>Public</strong> - allow others to <strong>view</strong> this report by its URL
            </Trans>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={1}>
          <Grid item>{t('private', 'Private')}</Grid>
          <Grid item>
            <Switch
              checked={isPublic}
              onChange={() => dispatch(changePublicityOfReport())}
              disabled={loading}
              name="public"
              color="primary"
            />
          </Grid>
          <Grid item>{t('public', 'Public')}</Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={20}/>
            </Grid>
          )}
        </Grid>

        {isPublic && (
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Divider/>
            </Grid>
            <Grid item xs>
              {t('public_available_url', 'Public available URL')}:
            </Grid>
            <Grid item xs>
              <TextField
                inputRef={inputRef}
                fullWidth
                size="small"
                variant="outlined"
                defaultValue={reportUrl}
                InputProps={{endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={t('aria_copy_url_to_clipboard', "copy url to clipboard")}
                      onClick={copyToClipboard}
                      onMouseDown={e => e.preventDefault()}
                      edge="end"
                    >
                      <FileCopyIcon fontSize="small"/>
                    </IconButton>
                  </InputAdornment>
                  )}}
              />
            </Grid>
          </Grid>
        )}

      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button type="submit" color="secondary" onClick={toggle} >
          {t('cancel', 'Cancel')}
        </Button>
      </DialogActions>
    </UiModal>
  );
};

export default ShareModal;