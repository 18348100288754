import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";

import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import './DataAppendForm.css';

const AVAILABLE_SCHEMAS = ['json', 'csv_wide', 'csv_tall', 'csv_tall_unit_interval'];
const DEFAULT_LIMIT = 10000;
const MAX_LIMIT = 100000;
const MIN_LIMIT = 100;


const DataAppendForm = ({onSubmit, rows, display}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const sanitizedVerbatimsLabel = (
    <Fragment>
      {t('sanitized_verbatims', 'Sanitized verbatims')}{' '}
      <Tooltip
        title={t('sanitized_verbatims_explanation', 'Verbatims that were made more palatable by the removal of elements that are likely to be unacceptable or controversial such as double paragraphs, non-unicode characters etc.')}
      >
        <Chip size="small" label="?" />
      </Tooltip>
    </Fragment>
  );

  const getLimit = limit => {
    return rows < limit ? rows : limit;
  };

  const getLinksNumber = (limit) => {
    return Math.ceil(rows / limit);
  };

  const defaultLimit = getLimit(DEFAULT_LIMIT);

  const [state, setState] = useState({
    schema: AVAILABLE_SCHEMAS[0],
    limit: defaultLimit,
    sanitized: false,
    verbose: false,
    includeNotes: false,
    linksNumber: getLinksNumber(defaultLimit),
  });

  const onFormSubmit = event => {
    event.preventDefault();
    onSubmit({...state});
  };

  const onInputChange = event => {
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const onCheckboxChange = event => {
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  };

  const isLimitDisabled = () => {
    return getLimit(MAX_LIMIT) === getLimit(MIN_LIMIT);
  };

  const onLimitInputChange = event => {
    let newLimit = parseInt(event.target.value, 10);

    setState(prevState => ({
      ...prevState,
      limit: newLimit || 0,
      linksNumber: getLinksNumber(newLimit)
    }));
  };

  const onSanitizedRadioChange = (e, v) => {
    setState(prevState => ({
      ...prevState,
      sanitized: v === 'sanitized'
    }));
  };

  return (
    <form onSubmit={onFormSubmit} style={{display: display ? 'block' : 'none'}}>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('export_options', 'Export options')}</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              select
              name="schema"
              value={state.schema}
              label={t('select_format', 'Select format')}
              onChange={onInputChange}
            >
              {AVAILABLE_SCHEMAS.map(schema => (
                <MenuItem key={schema} value={schema}>{schema}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="limit"
              label={t('number_of_rows_per_file', 'Number of rows per file')}
              value={state.limit}
              disabled={isLimitDisabled()}
              helperText={isLimitDisabled() ? t('cannot_change_number_of_rows', "You can't change this because it's already below minimal allowed number.") : undefined}
              onChange={onLimitInputChange}
              inputProps={{
                max: getLimit(MAX_LIMIT),
                min: getLimit(MIN_LIMIT),
              }}
            />
          </Grid>
          <Grid item xs>
            <RadioGroup row value={state.sanitized ? 'sanitized' : 'original'} onChange={onSanitizedRadioChange}>
              <FormControlLabel control={<Radio/>} value="original" label={t('original_verbatims', 'Original verbatims')}/>
              <FormControlLabel control={<Radio/>} value="sanitized" label={sanitizedVerbatimsLabel}/>
            </RadioGroup>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={state.includeNotes} name="includeNotes" onChange={onCheckboxChange} />}
                label={t('include_notes', 'Include Notes')}
              />
              <FormControlLabel
                control={<Checkbox checked={state.verbose} name="verbose" onChange={onCheckboxChange} />}
                label={t('verbose', 'Verbose')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button type="submit" color="primary">
          {t('get_download_links', 'Get download links')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default DataAppendForm;
