import React, {useEffect, useMemo} from 'react';
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";

import InProgress from "./components/InProgress";
import Success from "./components/Success";

import {loadProgressBarRequest, progressBarCancel} from "../create-report/create-report-action-creators";
import {parseQueryString} from "../reports/reports-functions";
import {useTranslation} from "react-i18next";


const CreateReportSuccess = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const reportId = useMemo(() => {
    const parameters = parseQueryString(location.search);
    return parameters.reportId;
  }, [location.search]);

  const progress = useSelector(state => state.createReport.progress);

  useEffect(() => {
    dispatch(loadProgressBarRequest(reportId));

    return () => dispatch(progressBarCancel(reportId));
  }, [reportId]);

  return (
    <>
      <Helmet>
        <title>{t('create_report','Create Report')}</title>
      </Helmet>
      <Container maxWidth="md">
        <Paper style={{padding: '16px', width: '100%'}}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            {progress[reportId] === 100 ?
              <Success reportId={reportId}/>
              :
              <InProgress progress={progress[reportId]}/>
            }
          </Grid>
        </Paper>
      </Container>
    </>
  )
};

export default CreateReportSuccess;
