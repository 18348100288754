import axios from './axios-api';

export default {
  loadHxIndex: (reportId, filters, operator) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
    };

    return axios.post("/hx_index_hb2/", body);
  },

  loadFilters: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      },
      "get_nested_filters": true,
    };
    return axios.post("/get_report_filters_hb2/", body)
  },

  loadReportInfo: ({reportId}) => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };
    return axios.post("/get_current_report_info/", body)
  },

  loadTopWords: ({reportId, filters, operator}) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      filters,
      logical_operator: operator,
    };

    return axios.post("/get_top_words_hb2/", body)
  },

  primarySecondarySentimentDistribution: (reportId, filters, operator) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
    };

    return axios.post('/primary_secondary_sentiment_distribution/', body);
  },

  loadResponses: ({reportId, filters, operator, limit, skip}) => {
    const body = {
      report_id: {$oid: reportId},
      filters: filters,
      logical_operator: operator,
      limit: limit,
      skip: skip,
    };

    return axios.post("/get_data_rows_hb2/", body)
  },

  loadSingleResponse: ({reportId, responseNumber}) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      limit: responseNumber,
      skip: responseNumber - 1,
      via_mapping: true
    };

    return axios.post("/get_data_rows_hb2/", body);
  },

  loadReports: () => {
    return axios.get("/get_old_reports_list/")
  },

  addReport: ({reportUrl, name, password}) => {
    const body = {
      report_url: reportUrl,
      name,
      password
    };
    return axios.post("/move_report_to_hb2/", body)
  },

  loadHbIndexData: ({reportId, filters, operator, filterName}) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      filters,
      logical_operator: operator,
      score_column_name: filterName,
    };

    return axios.post("/hx_index_by_filter_hb2_rev2/", body)
  },

  getTaxonomyList: (reportId) => {
    return axios.get("/get_taxonomy_list/?report_id=" + reportId);
  },

  addWords: ({taxonomy, terms}) => {
    return axios.post("/add_new_rows/", {taxonomy, terms});
  },

  addTaxonomy: ({id}) => {
    const body = {
      _id: id,
      rows: 0,
      companies: []
    };

    return axios.post("/add_taxonomy/", body);
  },

  searchNgramInSentences: ({reportId, filters, operator, searchWord, head, tail}) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
      meta_filter: [],
      data_filter: [],
      search_word: searchWord,
      head,
      tail
    };

    return axios.post('/search_ngram_in_sentences/', body);
  },

  getDataAppend: ({reportId, schema, skip, limit, partSuffix, sanitized, verbose, includeNotes, onDownloadProgress}) => {
    const body = {
      report_id: {$oid: reportId},
      schema,
      skip,
      limit,
      part_suffix: partSuffix,
      sanitized,
      verbose,
      include_notes: includeNotes,
    };

    return axios.post('/get_data_append/', body, {
      headers: {
        'content-disposition': 'json', // need to trigger preflight request on localhost
      },
      responseType: 'blob',
      onDownloadProgress
    });
  },

  primaryEmotionsSplitChart: ({reportId, filters, operator, filterName}) => {
    const body = {
      report_id: {$oid: reportId},
      filters: filters,
      logical_operator: operator,
      split_filter_name: filterName,
    };

    return axios.post('/primary_emotions_split_chart_hb2/', body);
  },

  sentimentSplitChart: ({reportId, filters, operator, filterName}) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
      split_filter_name: filterName
    };

    return axios.post('/sentiment_split_chart_hb2/', body);
  },

  lockUnlockReport: reportId => {
    const body = {
      report_id: {$oid: reportId}
    };

    return axios.post('/lock_unlock_report/', body);
  },

  loadSingleResponseForEdit: (reportId, rowNumber) => {
    const body = {
      report_id: {$oid: reportId},
      row_number: rowNumber
    };

    return axios.post('/load_single_response/', body);
  },

  editSingleResponse: (reportId, rowNumber, editedReportRow) => {
    const body = {
      report_id: {$oid: reportId},
      row_number: rowNumber,
      edited_report_row: editedReportRow
    };

    return axios.post('/edit_single_response/', body);
  },

  requestSupport: data => {
    const body = {
      subject: data.subject,
      message: data.message,
      from_email: data.fromEmail
    };

    return axios.post('/send_email_to_support_or_curation/', body);
  },

  addColumns: (reportId, file) => {
    const payload = new FormData();
    payload.append("file", file, file.name);
    payload.append("report_id", JSON.stringify({$oid: reportId}));

    return axios.post('/upload_for_add_columns/', payload);
  },

  getTopWordsAndDownloadAsCsv: (reportId, filters, operator) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
    };

    return axios.post('/get_top_10_words_and_download_csv/', body, {responseType: 'blob'});
  },

  addNoteToReport: (reportId, rowNumber, dataHeader, tabName, noteTitle, description, boundaries, highlightedText) => {
    const body = {
      note_candidate: {
        report_id: {
          $oid: reportId
        },
        row_number: rowNumber,
        data_header: dataHeader,
        type: 'note',
        tab_name: tabName,
        note_title: noteTitle,
        description,
        boundaries,
        highlighted_text: highlightedText
      }
    };

    return axios.post('/add_note_to_report/', body);
  },

  deleteNoteFromReport: noteId => {
    const body = {
      note_id: {
        $oid: noteId
      }
    };

    return axios.post('/delete_note_from_report/', body);
  },

  addFilterForNoteTab: (reportId, tabName, threshold) => {
    const body = {
      report_id: {
        $oid: reportId
      },
      tab_name: tabName,
      threshold
    };

    return axios.post('/add_filter_for_note_tab/', body);
  },

  checkIfReportGotEditedRows: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };

    return axios.post('/check_if_report_got_edited_rows/', body);
  },

  recalculateEditedRows: reportId => {
    const body = {
      report_id: {
        $oid: reportId
      }
    };

    return axios.post('/recalculate_edited_rows/', body);
  },

  editReportInformation: (reportId, name, description, logoFile, company, domain, clearLogo) => {
    const payload = new FormData();

    payload.append('report_id', reportId);
    payload.append('report_name', name);
    payload.append('description', description);
    payload.append('company', company);
    payload.append('report_domain', domain);

    if (clearLogo) {
      payload.append('clear_logo', clearLogo);
    }

    if (logoFile) {
      payload.append('logo_file', logoFile);
    }

    return axios.post('/edit_report_information/', payload);
  },

  predictAVUCount: (reportId, config) => {
    const body = {
      report_id: {$oid: reportId},
      config
    };

    return axios.post('/predict_avu_count/', body);
  },

  changePublicityOfReport: reportId => {
    const body = {report_id: {$oid: reportId}};

    return axios.post('/change_publicity_of_report/', body);
  },

  getColumnNamesMapConfig: reportId => {
    const body = {report_id: {$oid: reportId}};

    return axios.post('/get_column_names_map_config/', body);
  },

  updateReportColumnNamesMap: (reportId, columnsMap) => {
    const body = {
      report_id: {$oid: reportId},
      column_names_map_config: columnsMap
    };

    return axios.post('/update_report_column_names_map/', body);
  },

  getEmoWordsListsByPrimaryEmotions: (reportId, filters, operator) => {
    const body = {
      report_id: {$oid: reportId},
      show_count: true,
      filters,
      logical_operator: operator,
    };

    return axios.post('/get_emo_words_lists_by_primary_emotions/', body);
  },

  duplicateReport: (reportId, name) => {
    const body = {
      report_id: {$oid: reportId},
      duplicated_name: name,
    };

    return axios.post('/duplicate_report/', body);
  },

  getMetricsForReport: (reportId, filters, operator) => {
    const body = {
      report_id: {$oid: reportId},
      filters,
      logical_operator: operator,
    };

    return axios.post('/report_metrics/', body)
  },

  getTopicsNamespaces: reportId => {
    return axios.get('/report_words/get_topics_namespaces/?report_id=' + reportId);
  },

  addTopics: (reportId, namespace) => {
    const body = {
      'report_id': reportId,
      'topics_namespace': namespace
    };

    return axios.post('/report_words/add_topics_to_report_async/', body);
  },

  getTopicsProgress: progressId => {
    const body = {
      'progress_id': {$oid: progressId}
    };

    return axios.post('/report_words/get_progress/', body);
  },
}
