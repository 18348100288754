import API from '../../API-report';
import {call, put, select} from "redux-saga/effects";
import {searchSuccess, setSearchCriteria} from "./ngram-search-actions";

export function* performNgramSearch({searchWord, head, tail}) {
  yield put(setSearchCriteria({searchWord, head, tail}));

  const reportId = yield select(state => state.report.reportId);
  const filters = yield select(state => state.filters.selectedFilters);
  const operator = yield select(state => state.filters.operator);

  try {
    const fullCriteria = {reportId, filters, operator, searchWord, head, tail};
    const results = yield call(API.searchNgramInSentences, fullCriteria);
    yield put(searchSuccess(results.data));
  } catch (e) {
    console.error('ERROR IN SEARCH', e);
  }
}
