import React from 'react';
import {useSelector} from "react-redux";
import {Link, Grid, Typography, makeStyles} from "@material-ui/core";

import footerLogo from "../../assets/images/footer-logo.png";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(8),
    padding: `0 ${theme.spacing(2)}px`,
    background: '#4f4f4f',
  },
  logo: {
    width: 120,
    transition: 'all 0.3s',
    filter: 'brightness(1)',
    '&:hover': {
      filter: 'brightness(1.4)'
    }
  },
  text: {
    fontSize: '0.8rem',
    color: '#f9fafa',
    textAlign: 'right',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(10),
  },
  hackText: {
    visibility: 'hidden',
    fontFamily: 'Patua One, sans-serif',
    position: 'absolute',
    left: '-100vw',
  }
}))

const Footer = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  const showFooterLogo = useSelector(state => state.main.showFooterLogo);

  return (
    <Grid container className={classes.container} id="footer" justify="space-between" alignItems="center">
        <Grid item>
          {showFooterLogo && (
            <Link href="https://www.heartbeatai.com/" target="_blank">
              <img className={classes.logo} src={footerLogo}  alt="HX"/>
            </Link>
          )}
        </Grid>

        <Grid item xs>
          <Typography className={classes.hackText}>{t('font_load_text', 'The quick brown fox jumps over the lazy dog')}</Typography>
          <Typography className={classes.text}>
            Copyright &copy; {new Date().getFullYear()} {t('copyright_text', 'Heartbeat Ai Technologies Inc. All rights reserved')}
            <span> | </span>
            <Link href="https://www.heartbeatai.com/privacy-policy" color="inherit" target="_blank">
              {t('privacy_policy', 'Privacy Policy')}
            </Link>
            <span> | </span>
            <Link href="https://www.heartbeatai.com/terms-of-service" color="inherit" target="_blank">
              {t('terms_of_service', 'Terms of Service')}
            </Link>
          </Typography>
        </Grid>
    </Grid>
  );
};

export default Footer;