import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Box, Checkbox, CircularProgress, FormControlLabel, FormLabel, Grid, Typography} from "@material-ui/core";

import Column from "../components/Column";
import ColumnsContainer from "../components/ColumnsContainer";
import ReportEditBottom from "../components/ReportEditBottom";

import {
  activateEditColumnType,
  changeColumnType,
  deactivateEditColumnType,
  editUpdateColumnsRequest,
  loadColumnsRequest,
  toggleDropdown
} from "../create-report-action-creators";

import {LOAD_COLUMNS_MODE_EDITING} from "../constants";


const EditColumns = ({cancelEditing}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [forceDataRecalculation, setForceDataRecalculation] = useState(false);

  const {columns, columnsLoading, updateColumnsLoading} = useSelector(state => ({
    columns: state.createReport.columns,
    columnsLoading: state.createReport.stepTwoLoading,
    updateColumnsLoading: state.createReport.editUpdateColumnsLoading
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadColumnsRequest(LOAD_COLUMNS_MODE_EDITING));
  }, [dispatch]);

  let columnsContent = <CircularProgress/>;

  if (!columnsLoading) {
    columnsContent = columns.map((col, i) => (
      <Column
        key={i}
        columnIndex={i}
        {...col}
        changeColumnType={columnTypeInfo => dispatch(changeColumnType(columnTypeInfo))}
        toggleDropdown={() => dispatch(toggleDropdown(i))}
        activateEditColumnType={() => dispatch(activateEditColumnType(i))}
        deactivateEditColumnType={() => dispatch(deactivateEditColumnType(i))}
      />
    ));
  }

  const onSubmit = () => {
    dispatch(editUpdateColumnsRequest(forceDataRecalculation, history))
  };

  return (
    <>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('review_and_update_columns', 'Review and update columns')}</Typography>
          </Grid>
          <Grid item xs>
            <ColumnsContainer>
              {columnsContent}
            </ColumnsContainer>
          </Grid>
          <Grid item xs>
            <FormLabel component="legend">{t('force_data_recalculation', 'Force data recalculation')}</FormLabel>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={forceDataRecalculation}
                  onChange={() => setForceDataRecalculation(prev => !prev)}
                />
              )}
              label={t('force_data_recalculation_help', 'Force recalculation of all your columns according to new configuration')}
            />
          </Grid>
        </Grid>
      </Box>
      <ReportEditBottom
        loading={updateColumnsLoading}
        enabled={!columnsLoading && !updateColumnsLoading}
        cancel={cancelEditing}
        submit={onSubmit}
        submitText={t('update_columns_and_recalculate', 'Update columns & recalculate')}
      />
    </>
  );
}

export default EditColumns;