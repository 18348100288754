

export const afterSearch = ({data, searchValue}) => {
  const flat = [];
  Object.keys(data).forEach(page => {
    data[page].forEach(d => flat.push(d))
  });
  const result = {};
  result[1] = flat.filter(d => d.term.startsWith(searchValue));
  return result;
};