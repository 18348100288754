import React from 'react';
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {CssBaseline, makeStyles} from "@material-ui/core";

import HideFrom from "../authorization/HideFrom";
import Nav from "../../containers/main/nav";
import ContextMenu from "../../containers/context-menu/context-menu-component";
import NgramSearchModal from "../../containers/ngram-search/ngram-search-modal";
import ResponseSingleModal from "../../containers/response-single/response-single-modal";
import DataAppendModal from "../../containers/data-append/data-append-modal";
import RequestSupportModal from "../../containers/request-support/request-support-modal";
import Footer from "../../components/Footer/Footer";
import Chat from "../../containers/chat/Chat";
import ReportSnapshotsModal from "../../containers/reports/components/ReportSnapshotsModal";
import ReportInfoModal from "../../containers/reports/components/ReportInfoModal";

const useStyles = makeStyles(theme => ({
  parentDiv: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    backgroundColor: '#e9ebee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: theme.spacing(1)
  }
}));

const Layout = ({children}) => {
  const classes = useStyles();
  const title = useSelector(state => state.main.title);
  const ico = useSelector(state => state.main.ico);

  return (
    <>
      <CssBaseline/>
      <Helmet defaultTitle={title} titleTemplate={title + "|%s"}>
        <link rel="icon shortcut" href={ico}/>
      </Helmet>
      <Nav/>
      <div className={classes.parentDiv}>
        {children}
      </div>
      <ContextMenu/>
      <NgramSearchModal/>
      <ResponseSingleModal/>
      <DataAppendModal/>
      <RequestSupportModal/>
      <ReportSnapshotsModal/>
      <ReportInfoModal/>

      <HideFrom anonymous>
        <Chat/>
      </HideFrom>

      <Footer/>
    </>
  );
};

export default Layout;
