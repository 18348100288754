import {
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
  LOAD_REPORTS_REQUEST,
  LOAD_REPORTS_SUCCESS,
  LOAD_REPORTS_ERROR,
  RECALC_REQUEST,
  RECALC_SUCCESS,
  RECALC_ERROR,
  UPDATE_SINGLE_REPORT,
  FETCH_CSV_FROM_REPORT,
  FETCH_CSV_FROM_REPORT_FINISH,
  LOCK_UNLOCK_REPORT_REQUEST,
  LOCK_UNLOCK_REPORT_SUCCESS,
  SHOW_ADVANCED_SEARCH_PANEL,
  HIDE_ADVANCED_SEARCH_PANEL,
  SEARCH_CLEAR,
  GET_TAGS_SUCCESS,
  ADVANCED_SEARCH_REQUEST,
  ADVANCED_SEARCH_DONE,
  MOVE_TO_TRASH_BIN_REQUEST,
  MOVE_TO_TRASH_BIN_SUCCESS,
  MOVE_TO_TRASH_BIN_ERROR,
  SHOW_TRASH_BIN_REQUEST,
  RESTORE_FROM_TRASH_BIN_REQUEST,
  RESTORE_FROM_TRASH_BIN_SUCCESS,
  RESTORE_FROM_TRASH_BIN_ERROR,
  SELECT_REPORT_FOR_SNAPSHOTS,
  OPEN_CREATE_SNAPSHOT_MODAL,
  CLOSE_CREATE_SNAPSHOT_MODAL,
  CREATE_SNAPSHOT_REQUEST, CREATE_SNAPSHOT_DONE, SHOW_REPORT_INFO_MODAL, HIDE_REPORT_INFO_MODAL
} from "./reports-actions";

const initialState = {
  reports: [],
  total: 0,
  limit: 30,
  tags: [],
  filteredTags: [],
  loading: false,
  searchLoading: false,
  searchLoaded: false,
  advancedSearchPanelShow: false,
  viewingArchived: false,
  snapshotsReport: null,
  createSnapshotModalOpen: false,
  createSnapshotLoading: false,
  reportInfoModalReport: null,
};

const processLoading = ({loading, targetIndex}) => {
  return (item, index) => {
    if (index === targetIndex) {

      return {
        ...item,
        loading
      }
    }
    return item;
  };
};

const processLoadingById = (state, loading, reportId) => {
  const index = state.reports.findIndex(r => r.report_id === reportId);

  return {
    ...state,
    reports: state.reports.map(processLoading({loading, targetIndex: index}))
  };
};

const processSnapshotLoading = (state, loading, parentId, reportId) => {
  return {
    ...state,
    reports: state.reports.map(report => {
      if (report.report_id === parentId) {
        return {
          ...report,
          snapshots: processLoadingById({reports: report.snapshots}, loading, reportId).reports
        };
      }

      return report;
    })
  }
};

const reportsReducer = (state = initialState, action) => {
  const { targetIndex } = action;
  switch (action.type) {

    case RECALC_REQUEST:
      return {
        ...state,
        reports: state.reports.map(processLoading({loading: true, targetIndex}))
      };

    case RECALC_SUCCESS:
    case RECALC_ERROR:
      return {
        ...state,
        reports: state.reports.map(processLoading({loading: false, targetIndex}))
      };

    case LOAD_REPORTS_REQUEST:
      return {
        ...state,
        reports: [],
        loading: true,
        viewingArchived: false,
      };

    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.reports,
        total: action.total,
        filteredTags: action.tags,
      };

    case LOAD_REPORTS_ERROR:
      return {
        ...state,
        loading: false
      };

    case DELETE_REPORT_REQUEST:
      if (action.parentId) {
        return processSnapshotLoading(state, true, action.parentId, action.reportId);
      }

      return processLoadingById(state, true, action.reportId);

    case DELETE_REPORT_SUCCESS:
      if (action.parentId) {
        return {
          ...state,
          reports: state.reports.map(r => {
            if (r.report_id === action.parentId) {
              return {...r, snapshots: r.snapshots.filter(s => s.report_id !== action.reportId)};
            }

            return r;
          }),
        }
      }

      return {
        ...state,
        reports: state.reports.filter(r => r.report_id !== action.reportId)
      };

    case DELETE_REPORT_ERROR:
      if (action.parentId) {
        return processSnapshotLoading(state, false, action.parentId, action.reportId);
      }

      return processLoadingById(state, false, action.reportId);

    case MOVE_TO_TRASH_BIN_REQUEST:
      return processLoadingById(state, true, action.reportId);

    case MOVE_TO_TRASH_BIN_SUCCESS:
      return {
        ...state,
        reports: state.reports.filter(r => r.report_id !== action.reportId)
      };

    case MOVE_TO_TRASH_BIN_ERROR:
      return processLoadingById(state, false, action.reportId);

    case RESTORE_FROM_TRASH_BIN_REQUEST:
      return processLoadingById(state, true, action.reportId);

    case RESTORE_FROM_TRASH_BIN_SUCCESS:
      return {
        ...state,
        reports: state.reports.filter(r => r.report_id !== action.reportId)
      };

    case RESTORE_FROM_TRASH_BIN_ERROR:
      return processLoadingById(state, false, action.reportId);

    case UPDATE_SINGLE_REPORT:
      const reportsCopy = [...state.reports];
      const idx = state.reports.findIndex(r => r.report_id === action.reportId);
      reportsCopy[idx] = action.reportData;

      return {
        ...state,
        reports: reportsCopy
      };

    case FETCH_CSV_FROM_REPORT:
      return processLoadingById(state, true, action.reportId);

    case FETCH_CSV_FROM_REPORT_FINISH:
      return processLoadingById(state, false, action.reportId);

    case LOCK_UNLOCK_REPORT_REQUEST:
      return processLoadingById(state, true, action.reportId);

    case LOCK_UNLOCK_REPORT_SUCCESS:
      return processLoadingById(state, false, action.reportId);

    case SHOW_ADVANCED_SEARCH_PANEL:
      return {...state, advancedSearchPanelShow: true, searchText: ''};

    case HIDE_ADVANCED_SEARCH_PANEL:
      return {...state, advancedSearchPanelShow: false};

    case SEARCH_CLEAR:
      return {...state, searchLoaded: false, loading: true, reports: []};

    case ADVANCED_SEARCH_REQUEST:
      return {...state, searchLoading: true, viewingArchived: false};

    case ADVANCED_SEARCH_DONE:
      return {...state, searchLoaded: true, searchLoading: false};

    case SHOW_TRASH_BIN_REQUEST:
      return {...state, reports: [], loading: true, viewingArchived: true};

    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags
      };

    case SELECT_REPORT_FOR_SNAPSHOTS:
      return {...state, snapshotsReport: action.report};

    case OPEN_CREATE_SNAPSHOT_MODAL:
      return {...state, createSnapshotModalOpen: true};

    case CLOSE_CREATE_SNAPSHOT_MODAL:
      return {...state, createSnapshotModalOpen: false};

    case CREATE_SNAPSHOT_REQUEST:
      return {...state, createSnapshotLoading: true};

    case CREATE_SNAPSHOT_DONE:
      return {...state, createSnapshotLoading: false};

    case SHOW_REPORT_INFO_MODAL:
      return {...state, reportInfoModalReport: action.report};

    case HIDE_REPORT_INFO_MODAL:
      return {...state, reportInfoModalReport: null};
    default:
      return state;
  }
};

export default reportsReducer;
