import {INPUT_CHANGE, INPUT_ERROR, LOGIN_END, LOGIN_REQUEST, SAVE_LOCATION_BEFORE_LOGIN} from "./user-actions";

const initialState = {
  username: {
    value: "",
    error: ""
  },
  password: {
    value: "",
    error: ""
  },
  loading: false,
  lastLocation: null,
};

const loginReducer = (state = initialState, action) => {
  const {field, value, error} = action;

  switch (action.type) {

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOGIN_END:
      return {
        ...state,
        loading: false
      };

    case INPUT_ERROR:
      return {
        ...state,
        [field]: {
          ...state[field],
          error
        }
      };

    case INPUT_CHANGE:
      return {
        ...state,
        [field]: {
          value,
          error: ""
        }
      };
    case SAVE_LOCATION_BEFORE_LOGIN:
      return {
        ...state,
        lastLocation: action.location
      };
    default:
      return state;
  }
};

export default loginReducer;
