export const NEW_CONTENT_AVAILABLE_STORE = '[MAIN] NEW_CONTENT_AVAILABLE_STORE';
export const TOGGLE_MOBILE_DRAWER = '[MAIN] TOGGLE_MOBILE_DRAWER';
export const TOGGLE_DESKTOP_DRAWER = '[MAIN] TOGGLE_DESKTOP_DRAWER';
export const CLEAR_DRAWER_STATE = '[MAIN] CLEAR_DRAWER_STATE';
export const WHITELABEL_REQUEST = '[MAIN] WHITELABEL_REQUEST';
export const WHITELABEL_SUCCESS = '[MAIN] WHITELABEL_SUCCESS';
export const WHITELABEL_FAILURE = '[MAIN] WHITELABEL_FAILURE';

export const newContentAvailableStore = () => ({type: NEW_CONTENT_AVAILABLE_STORE});
export const toggleMobileDrawer = () => ({type: TOGGLE_MOBILE_DRAWER});
export const toggleDesktopDrawer = () => ({type: TOGGLE_DESKTOP_DRAWER});
export const clearDrawerState = () => ({type: CLEAR_DRAWER_STATE});
export const whitelabelRequest = domain => ({type: WHITELABEL_REQUEST, domain});
export const whitelabelSuccess = (logoUrl, title, ico) => ({type: WHITELABEL_SUCCESS, logoUrl, title, ico});
export const whitelabelFailure = () => ({type: WHITELABEL_FAILURE});