import React, {Fragment, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

const HighlightTooltip = ({properties}) => (
  <div className="verbatim-popup">
    {Object.keys(properties).map(propertyName => (
      <span className="verbatim-popup-item" key={propertyName}>
        <strong>{propertyName}: </strong>

        {properties[propertyName].map((property, index) => (
          <Fragment key={propertyName + '_' + property.text}>
              <span
                className="emoword-popup-tag"
                style={{
                  color: property['color'] || undefined,
                  backgroundColor: property['background_color'] || undefined
                }}
              >
                {property.text}
              </span>
            {index < properties[propertyName].length - 1 && ', '}
          </Fragment>
        ))}
      </span>
    ))}
  </div>
);

const VerbatimSentenceHighlight = ({highlightedText, underlineColors, properties, otherProperties, showNoteContextMenu}) => {
  const [fakeAnchor, setFakeAnchor] = useState(null);

  const percent = Math.round(100 / underlineColors.length);
  const colors = underlineColors.map((color, i) => {
    return color + ' ' + (percent * i) + '% ,' + color + ' ' + (percent * (i + 1)) + '%';
  }).join(',');

  const background = 'linear-gradient(to right, ' + colors + ')';

  const style = {
    backgroundImage: 'linear-gradient(transparent, transparent), ' + background,
  };

  const onMouseEnter = e => {
    const width = 1;
    const height = 10;
    const x = e.clientX;
    const y = e.clientY;

    setFakeAnchor({
      clientWidth: width,
      clientHeight: height,
      getBoundingClientRect: () => ({
        left: x,
        top: y,
        right: x + width,
        bottom: y + height,
        width: width,
        height: height,
        x: x,
        y: y,
      })
    })
  };

  const onMouseLeave = e => {
    setFakeAnchor(null);
  };

  let onContextMenu = null;

  if (otherProperties['note_id']) {
    onContextMenu = e => {
      const selection = window.getSelection();

      if (selection.toString().length === 0) {
        e.preventDefault();
        e.stopPropagation();
        showNoteContextMenu({top: e.clientY, left: e.clientX}, otherProperties['note_id'].$oid);
      }
    };
  }

  return (
    <Fragment>
      <Tooltip
        arrow title={<HighlightTooltip properties={properties}/>}
        PopperProps={{anchorEl: fakeAnchor}}
        open={!!fakeAnchor}
      >
        <span
          className="response-sentence-emoword"
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onContextMenu={onContextMenu}
        >
          {highlightedText}
        </span>
      </Tooltip>
    </Fragment>
  )
};

const VerbatimSentences = ({response, selectedTab, setSelectedText, showContextMenu, showNoteContextMenu, setNoteInfo}) => {
  const tabInfo = response['tabs'].find(tab => tab.name === selectedTab);

  // Note to future me:
  // ------------------
  // Because JS can't access Unicode strings by actual characters (charAt, or [])
  // if we want emojis, need to split string into "graphemes".
  // Simplest way to do that - via iterable protocol, thus it's the spread operator.
  const text = [...response['sanitized_value'].replace(/\s/ig, ' ')];
  const textParts = [];
  let textCursor = 0;

  for (const highlightObject of tabInfo['highlight_objects']) {
    const boundaries = highlightObject['boundaries'];

    if (boundaries[0] !== textCursor) {
      const end = boundaries[0];
      const commonText = text.slice(textCursor, end).join('');

      textParts.push(<span key={`${textCursor} ${end}`}>{commonText}</span>);
    }

    const highlightedText = text.slice(boundaries[0], boundaries[1]).join('');

    textParts.push(
      <VerbatimSentenceHighlight
        key={`${boundaries[0]} ${boundaries[1]}`}
        highlightedText={highlightedText}
        properties={highlightObject['show_properties']}
        underlineColors={highlightObject['underline_colors']}
        otherProperties={highlightObject['other_properties']}
        showNoteContextMenu={showNoteContextMenu}
      />
    );

    textCursor = boundaries[1];
  }

  if (textCursor < text.length) {
    const endPart = text.slice(textCursor, text.length).join('');
    textParts.push(<span key={`${textCursor} ${text.length}`}>{endPart}</span>);
  }

  const onContextMenu = e => {
    if (window.getSelection().toString().length === 0) return;

    e.preventDefault();
    e.stopPropagation();
    setNoteInfo(response['row_number'], response['header']);
    setSelectedText();
    showContextMenu({top: e.clientY, left: e.clientX});
  };

  const tabAdditionalInfo = tabInfo['additional_row_info'];

  const additionalInfo = Object.keys(tabAdditionalInfo).map(infoKey => (
    tabAdditionalInfo[infoKey].length > 0 && (
      <Grid item xs key={infoKey} className="response_component__words">
        <strong className="key">{infoKey}:</strong>
        {tabAdditionalInfo[infoKey].map((info, i) => (
          <Fragment key={info.text + i}>
            <span className="divider">{i > 0 && ','}{' '}</span>
            <span className="tag" style={{
              backgroundColor: info['background_color'] || undefined,
              color: info['color'] || undefined,
            }}>
              {info.text}
            </span>
          </Fragment>
        ))}
      </Grid>
    )
  ));

  return (
    <Fragment>
      <Grid item xs className="verbatim-sentence" onContextMenu={onContextMenu}>
        {response['original_language_text'] && (
          <span className="response-sentence-original">{response['original_language_text']}</span>
        )}
        {textParts}
      </Grid>

      {additionalInfo}
    </Fragment>
  );

};

export default VerbatimSentences;