import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";

import ReportCard from "./ReportCard";
import UiModal from "../../../components/UiModal/UiModal";
import CreateSnapshotModal from "./CreateSnapshotModal";
import {createGrid} from "../styles";

import {openCreateSnapshotModal, selectReportForSnapshots} from "../reports-actions";

const useStyles = makeStyles(theme => ({
  ...createGrid(theme, 250),
  snapshotsGrid: {
    // minHeight: 200
  },
  alert: {
    gridColumn: '1 / 3',
  }
}));

const DisabledTooltipText = () => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      {t('you_cannot_have_more_than_3_snapshots', 'You cannot have more snapshots than 3 at any single moment.')}<br/>
      {t('please_remove_one_of_snapshots_to_add_new', 'Please, remove any one of them to add new.')}
    </React.Fragment>
  );
};

const ReportSnapshotsModal = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {reports, snapshotsReport: reportId} = useSelector(state => state.reports, shallowEqual);

  const isOpen = Boolean(reportId);

  const close = () => dispatch(selectReportForSnapshots(null));

  let content = null;

  if (reportId) {
    const report = reports.find(r => r.report_id === reportId);
    const newSnapshotsAllowed = report.snapshots.length < 3;

    let createBtn = (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => dispatch(openCreateSnapshotModal())}
        disabled={!newSnapshotsAllowed}
      >
        {t('create_snapshot', 'Create snapshot')}
      </Button>
    );

    if (!newSnapshotsAllowed) {
      createBtn = (
        <Tooltip arrow title={<DisabledTooltipText/>}>
          <span>
            {createBtn}
          </span>
        </Tooltip>
      )
    }

    content = (
      <React.Fragment>
        <DialogContent dividers>
          <div className={`${classes.reportsGrid} ${classes.snapshotsGrid}`}>
            {report.snapshots.length > 0 ? report.snapshots.map(report => (
              <ReportCard
                key={report['report_id']}
                report={report}
                parentId={reportId}
                goAction={close}
                duplicate
              />
            )) : (
              <Alert severity="info" className={classes.alert}>{t('no_snapshots_exist', 'No snapshots exist for this report')}</Alert>
            )}
            {createBtn}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t('close', 'Close')}</Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <UiModal
        title={t('manage_report_snapshots', "Manage report snapshots")}
        open={isOpen}
        onClose={close}
        maxWidth="md"
      >
        {content}
      </UiModal>
      {reportId && <CreateSnapshotModal reportId={reportId}/>}
    </React.Fragment>
  );
};

export default ReportSnapshotsModal;