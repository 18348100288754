const DEFAULT_LANGUAGE = 'en';

const tryToDetectCorrectLanguage = (language) => {
  if (!language) {
    return DEFAULT_LANGUAGE;
  }

  const parts = language.split('-');
  return parts[0] || DEFAULT_LANGUAGE;
};

export default tryToDetectCorrectLanguage;