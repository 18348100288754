import React from 'react';
import {withStyles, Dialog, DialogTitle as MuiDialogTitle, IconButton, Typography, Grid} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item component={Typography} variant="h6">
          {children}
        </Grid>
        <Grid item>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

const UiModal = ({onClose, open, title, children, maxWidth}) => {
  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={maxWidth || 'sm'} scroll="body">
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default UiModal;