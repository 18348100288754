import {put, takeEvery} from 'redux-saga/effects';
import reportAPI from "../../API-report";
import {REQUEST_SUPPORT_REQUEST, requestSupportDone, requestSupportModalHide} from "./request-support-actions";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";

export function* requestSupport({data}) {
    try {
        yield reportAPI.requestSupport(data);

        yield put(requestSupportModalHide());
        yield put(addNotification({
          status: 'Success',
          message: [i18n.t('support_request_sent', 'Your support request successfully sent. Please wait for an answer in your email inbox.')]
        }));
    } catch (e) {
        console.error('ERROR FROM REQUEST_SUPPORT', e);
        yield put(addNotification({
          status: 'Error',
          message: [i18n.t('could_not_send_support_request', 'Could not send request for support')]
        }));
    }

    yield put(requestSupportDone());
}

export default [
    takeEvery(REQUEST_SUPPORT_REQUEST, requestSupport)
]