import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  IMPERSONATE_USER_DONE,
  IMPERSONATE_USER_REQUEST
} from "./users-actions";

const initialState = {
  users: null,
  usersLoading: false,
  impersonateLoading: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {...state, usersLoading: true};
    case FETCH_USERS_SUCCESS:
      return {...state, usersLoading: false, users: action.users};
    case FETCH_USERS_FAILURE:
      return {...state, usersLoading: false};
    case IMPERSONATE_USER_REQUEST:
      return {...state, impersonateLoading: action.user};
    case IMPERSONATE_USER_DONE:
      return {...state, impersonateLoading: null};
    default:
      return state;
  }
};

export default usersReducer;