import {put, select, takeEvery} from 'redux-saga/effects';
import API from "../../API";
import {
  COMPANY_STATS_REQUEST, companyStatsFailure, companyStatsSuccess,
  USER_COMPANIES_REQUEST, USER_STATS_REQUEST,
  userCompaniesFailure,
  userCompaniesSuccess, userStatsFailure, userStatsSuccess
} from "./purchase-statistics-actions";
import {addNotification} from "../notifications/notifications-actions";
import i18n from "../../i18n";
import {normalizeUserReports} from "./helpers";

function* getUserCompanies() {
  try {
    const companiesResponse = yield API.getPurchaseStatsCompanies();
    yield put(userCompaniesSuccess(companiesResponse.data.companies));
  } catch (e) {
    console.error('ERROR IN *getUserCompanies', e);
    yield put(userCompaniesFailure());
    yield put(addNotification({status: 'Error', message: [i18n.t('failed_to_request_user_companies', 'Failed to request user companies')]}));
  }
}

function* getCompanyStats({company}) {
  try {
    const statsResponse = yield API.getCompanyStatistics(company);
    yield put(companyStatsSuccess(statsResponse.data));
  } catch (e) {
    console.error('ERROR IN *getCompanyStats', e);
    yield put(companyStatsFailure());
    yield put(addNotification({status: 'Error', message: [i18n.t('failed_to_request_company_purchase_statistics', 'Failed to request company purchase statistics')]}));
  }
}

function* getUserStats() {
  try {
    const user = yield select(state => state.user);
    const userResponse = yield API.getUserStatistics(user.username);
    const normalized = {
      ...userResponse.data,
      'reports_created_by_user': normalizeUserReports(userResponse.data['reports_created_by_user'])
    };

    yield put(userStatsSuccess(normalized));
  } catch (e) {
    console.error('ERROR IN *getUserStats', e);
    yield put(userStatsFailure());
    yield put(addNotification({status: 'Error', message: ['Failed to request user statistics']}));
  }
}

export default [
  takeEvery(USER_COMPANIES_REQUEST, getUserCompanies),
  takeEvery(COMPANY_STATS_REQUEST, getCompanyStats),
  takeEvery(USER_STATS_REQUEST, getUserStats),
];