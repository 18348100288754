import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import UiModal from "../../../components/UiModal/UiModal";
import {createTaxonomyRequest, setNewTaxonomyDialogOpen} from "../taxonomy-actions";
import Grid from "@material-ui/core/Grid";
import ButtonWithProgress from "../../../components/ButtonWithProgress/ButtonWithProgress";

const NewTaxonomyDialog = ({language, value, setValue, isTargetTaxonomy = false}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {valueSystem, isOpen, loading} = useSelector(state => ({
    valueSystem: state.taxonomy.valueSystem,
    isOpen: state.taxonomy.newTaxonomyDialogOpen,
    loading: state.taxonomy.newTaxonomyLoading,
  }), shallowEqual);

  const onSubmit = event => {
    event.preventDefault();

    dispatch(createTaxonomyRequest(value, valueSystem, language, isTargetTaxonomy));
  };

  const onClose = () => {
    dispatch(setNewTaxonomyDialogOpen(false));
    setValue('');
  }

  return (
    <UiModal
      title={t('create_new_taxonomy', 'Create new taxonomy')}
      open={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                defaultValue={valueSystem}
                label={t('value_system', 'Value System')}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                defaultValue={language}
                label={t('language', 'Language')}
              />
            </Grid>
            <Grid item>
              <TextField
                autoFocus
                variant="outlined"
                fullWidth
                label={t('taxonomy_name', 'Taxonomy name')}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonWithProgress
            type="submit"
            color="primary"
            disabled={loading}
            loading={loading}
          >
            {t('create', 'Create')}
          </ButtonWithProgress>
          <Button onClick={onClose}>{t('cancel', 'Cancel')}</Button>
        </DialogActions>
      </form>
    </UiModal>
  );
};

export default NewTaxonomyDialog;