import {
  ACCEPT_COLUMNS_ERROR,
  ACCEPT_COLUMNS_REQUEST,
  ACCEPT_COLUMNS_SUCCESS,
  ACTIVATE_EDIT_COLUMN_TYPE,
  ADD_TAXONOMY,
  CHANGE_COLUMN_NAME,
  CHANGE_COLUMN_TYPE,
  CHANGE_COMPANY,
  CHANGE_DOMAIN, CHANGE_LANGUAGE,
  CHANGE_VALUE_SYSTEM,
  CHOOSE_TAXONOMY,
  DEACTIVATE_EDIT_COLUMN_TYPE,
  DEACTIVATE_STEP_ONE,
  DEACTIVATE_STEP_TWO,
  EDIT_ADD_COLUMNS_CONFIGURE_FAILURE,
  EDIT_ADD_COLUMNS_CONFIGURE_REQUEST,
  EDIT_ADD_COLUMNS_CONFIGURE_SUCCESS,
  EDIT_ADD_COLUMNS_UPLOAD_FILE_FAILURE,
  EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST,
  EDIT_ADD_COLUMNS_UPLOAD_FILE_SUCCESS,
  EDIT_COMPANY_REQUEST,
  EDIT_COMPANY_SUCCESS,
  EDIT_REPORT_INFORMATION_DONE,
  EDIT_REPORT_INFORMATION_REQUEST,
  EDIT_TOGGLE_COMPANY,
  EDIT_UPDATE_COLUMNS_ERROR,
  EDIT_UPDATE_COLUMNS_REQUEST,
  EDIT_UPDATE_COLUMNS_SUCCESS,
  EDIT_UPDATE_TAXONOMIES_ERROR,
  EDIT_UPDATE_TAXONOMIES_REQUEST,
  EDIT_UPDATE_TAXONOMIES_SUCCESS,
  EDIT_UPLOAD_FILE_ERROR,
  EDIT_UPLOAD_FILE_REQUEST,
  EDIT_UPLOAD_FILE_SUCCESS,
  FILE_READY,
  GET_COLUMNS_NAMES_MAP_CONFIG_FAILURE,
  GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST,
  GET_COLUMNS_NAMES_MAP_CONFIG_SUCCESS,
  IGNORE_WORDS_CHANGE,
  LOAD_COLUMNS_ERROR,
  LOAD_COLUMNS_REQUEST,
  LOAD_COLUMNS_SUCCESS,
  LOAD_COMPANIES_REQUEST,
  LOAD_COMPANIES_SUCCESS,
  LOAD_DATA_FOR_STEP_THREE_ERROR,
  LOAD_DATA_FOR_STEP_THREE_REQUEST,
  LOAD_DATA_FOR_STEP_THREE_SUCCESS,
  LOAD_DOMAINS_REQUEST,
  LOAD_DOMAINS_SUCCESS, LOAD_LANGUAGES_FAILURE, LOAD_LANGUAGES_REQUEST, LOAD_LANGUAGES_SUCCESS,
  LOAD_VALUE_SYSTEMS_ERROR,
  LOAD_VALUE_SYSTEMS_REQUEST,
  LOAD_VALUE_SYSTEMS_SUCCESS,
  PRELOAD,
  PROGRESS_BAR_COMPLETE,
  PROGRESS_BAR_PROGRESS,
  REMOVE_TAXONOMY,
  RENAME_REPORT_ERROR,
  RENAME_REPORT_REQUEST,
  RENAME_REPORT_SUCCESS,
  REPORT_DESCRIPTION_CHANGE,
  REPORT_LOGO_CHANGE,
  REPORT_NAME_CHANGE,
  RESET_STATE,
  STEP_ONE_ACTIVATE,
  STEP_THREE_ACTIVATE,
  STEP_THREE_SAVE_ERROR,
  STEP_THREE_SAVE_REQUEST,
  STEP_THREE_SAVE_SUCCESS,
  STEP_TWO_ACTIVATE,
  TAGS_CHANGE,
  TOGGLE_DROPDOWN,
  TOGGLE_RENAME_EDITING,
  UPDATE_COLUMNS_NAMES_MAP_DONE,
  UPDATE_COLUMNS_NAMES_MAP_REQUEST,
  UPLOAD_ERROR,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS
} from "./create-report-action-creators";
import deepCopy from "../../utilities/deepCopy";
import {LOAD_REPORT_INFO_SUCCESS} from "../report-info/report-info-action-types";
import {STEPS} from "./containers/AddNewColumns";

const initialState = {
  file: null,
  filename: '',
  uploadLoading: false,
  stepTwoLoading: false,
  stepThreeLoading: false,
  reportName: "",
  reportId: "",
  logoFile: null,
  description: '',
  stepOneActive: true,
  stepOneVirgin: true,
  stepTwoActive: false,
  stepTwoReviewed: false,
  stepTwoVirgin: true,
  stepThreeActive: false,
  stepThreeVirgin: true,
  stepThreeError: null,
  columns: [],
  taxonomies: [],
  allTaxonomies: [],
  ignoreWords: "",
  predictedAVUCount: null,
  stepThreeDone: false,
  acceptColumnsLoading: false,
  stepThreeSaveLoading: false,
  status: "",
  companies: [],
  companiesLoading: false,
  company: '',
  valueSystems: [],
  valueSystemsLoading: false,
  valueSystem: '',
  domains: [],
  domainsLoading: false,
  domain: '',
  languages: [],
  languagesLoading: false,
  language: '',
  progress: {},
  calculationProgress: 0,
  calculationComplete: false,
  editReportInformationLoading: false,
  renameEditing: false,
  renameLoading: false,
  companyEditing: false,
  companyEditLoading: false,
  editUploadLoading: false,
  editUpdateColumnsLoading: false,
  editUpdateTaxonomiesLoading: false,
  tags: [],
  editAddColumnsStepId: STEPS[0].id,
  editAddColumnsUploadFileLoading: false,
  editAddColumnsConfigureLoading: false,
  columnsNamesMapConfigLoading: false,
  columnsNamesMapConfig: [],
  updateColumnsNamesMapLoading: false,
};

const updateProgress = (state, reportId, progressPercent) => ({
  ...state,
  progress: {
    ...state.progress,
    [reportId]: progressPercent
  }
});

const createReportReducer = (state = initialState, action) => {

  const {
    reportId,
    filename,
    file,
    reportName,
    columns,
    columnIndex,
    columnType ,
    taxonomies,
    allTaxonomies,
    ignoreWords,
    i,
    value
  } = action;

  let columnsCopy;
  let column;

  let taxonomiesCopy;

  switch (action.type) {

    case RESET_STATE:
      return {...initialState};

    case PRELOAD:
      if (action.parameters.status === "edit") {
        return {
          ...state,
          ...action.parameters,
          stepOneActive: false,
          stepTwoActive: false,
          stepThreeActive: false,
          stepTwoVirgin: false,
          stepThreeVirgin: false
        }
      }

      if (action.parameters.status === "not_started") {
        return {
          ...state,
          ...action.parameters,
          stepOneActive: false,
          stepTwoActive: true,
          stepTwoVirgin: true,
          stepThreeActive: false
        }
      }

      if (action.parameters.status === "configured") {
        return {
          ...state,
          ...action.parameters,
          stepOneActive: false,
          stepTwoActive: false,
          stepThreeActive: true,
          stepTwoVirgin: false
        }
      }

      return {
        ...state,
        ...action.parameters
      };

    case IGNORE_WORDS_CHANGE:
      return {
        ...state,
        ignoreWords: value
      };

    case STEP_THREE_SAVE_REQUEST:
      return {
        ...state,
        stepThreeSaveLoading: true
      };

    case STEP_THREE_SAVE_ERROR:
      return {
        ...state,
        stepThreeSaveLoading: false
      };

    case STEP_THREE_SAVE_SUCCESS:
      return {
        ...state,
        file: null,
        filename: '',
        uploadLoading: false,
        stepTwoLoading: false,
        stepThreeLoading: false,
        reportId: "",
        reportName: "",
        stepOneActive: true,
        stepOneVirgin: true,
        stepTwoActive: false,
        stepTwoReviewed: false,
        stepTwoVirgin: true,
        stepThreeActive: false,
        stepThreeVirgin: true,
        columns: [],
        taxonomies: [],
        allTaxonomies: [],
        tags: [],
        ignoreWords: "",
        stepThreeDone: false,
        acceptColumnsLoading: false,
        stepThreeSaveLoading: false
      };

    case ACCEPT_COLUMNS_REQUEST:
      return {
        ...state,
        acceptColumnsLoading: true
      };

    case ACCEPT_COLUMNS_SUCCESS:
    case ACCEPT_COLUMNS_ERROR:
      return {
        ...state,
        acceptColumnsLoading: false
      };

    case CHOOSE_TAXONOMY:

      taxonomiesCopy = [...state.taxonomies];
      taxonomiesCopy[columnIndex] = {
        value: columnType,
        dropdownIsShown: false
      };

      return {
        ...state,
        taxonomies: taxonomiesCopy
      };

    case REMOVE_TAXONOMY:

      taxonomiesCopy = [...state.taxonomies];
      taxonomiesCopy.splice(i, 1);
      return {
        ...state,
        taxonomies: taxonomiesCopy
      };

    case ADD_TAXONOMY:

      taxonomiesCopy = [...state.taxonomies];
      taxonomiesCopy.push({
        value: state.allTaxonomies[0]['taxonomy_name'],
        dropdownIsShown: false
      });

      return {
        ...state,
        taxonomies: taxonomiesCopy
      };

    case LOAD_DATA_FOR_STEP_THREE_SUCCESS:
      return {
        ...state,
        taxonomies,
        allTaxonomies,
        ignoreWords,
        predictedAVUCount: action.predictedAVUCount,
        stepThreeLoading: false,
      };

    case LOAD_DATA_FOR_STEP_THREE_ERROR:
      return {
        ...state,
        stepThreeLoading: false,
        stepThreeError: action.error || null,
      };

    case LOAD_DATA_FOR_STEP_THREE_REQUEST:
      return {
        ...state,
        stepThreeLoading: true
      };

    case DEACTIVATE_EDIT_COLUMN_TYPE:
      columnsCopy = deepCopy(state.columns);
      column = columnsCopy[columnIndex];
      column.editColumnTypeActive = false;
      column.column_type = column.columnTypeLimbo;
      return {
        ...state,
        columns: columnsCopy
      };

    case ACTIVATE_EDIT_COLUMN_TYPE:
      columnsCopy = deepCopy(state.columns);
      column = columnsCopy[columnIndex];
      column.editColumnTypeActive = true;
      column.columnTypeLimbo = column.column_type;
      return {
        ...state,
        columns: columnsCopy
      };

    case TOGGLE_DROPDOWN:

      columnsCopy = deepCopy(state.columns);
      columnsCopy.forEach((col, i) => {
        if (i === columnIndex) return;
        col.dropdownIsShown = false;
      });
      columnsCopy[columnIndex].dropdownIsShown = !columnsCopy[columnIndex].dropdownIsShown;
      return {
        ...state,
        columns: columnsCopy
      };

    case CHANGE_COLUMN_TYPE:
      const newColumn = {...state.columns[columnIndex]};
      newColumn.column_type = columnType;
      const newColumns = [...state.columns];
      newColumns[columnIndex] = newColumn;
      return {
        ...state,
        columns: newColumns
      };

    case LOAD_COLUMNS_REQUEST:
      return {
        ...state,
        stepTwoLoading: true
      };

    case LOAD_COLUMNS_SUCCESS:
      return {
        ...state,
        stepTwoLoading: false,
        columns
      };

    case LOAD_COLUMNS_ERROR:
      return {
        ...state,
        stepTwoLoading: false
      };

    case STEP_THREE_ACTIVATE:
      return {
        ...state,
        stepOneActive: false,
        stepTwoActive: false,
        stepThreeActive: true,
        stepTwoVirgin: false
      };

    case STEP_TWO_ACTIVATE:
      return {
        ...state,
        stepOneActive: false,
        stepTwoActive: true,
        stepThreeActive: false
      };

    case STEP_ONE_ACTIVATE:
      return {
        ...state,
        stepOneActive: true,
        stepTwoActive: false,
        stepThreeActive: false
      };

    case DEACTIVATE_STEP_ONE:
      return {
        ...state,
        stepOneActive: false
      };

    case DEACTIVATE_STEP_TWO:
      return {
        ...state,
        stepTwoActive: false,
        stepThreeActive: true
      };

    case REPORT_NAME_CHANGE:
      return {
        ...state,
        reportName
      };

    case REPORT_LOGO_CHANGE:
      return {
        ...state,
        logoFile: action.logo
      };

    case REPORT_DESCRIPTION_CHANGE:
      return {
        ...state,
        description: action.description
      };

    case UPLOAD_REQUEST:
      return {
        ...state,
        uploadLoading: true,
        error: ""
      };

    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        stepOneVirgin: false,
        file: null,
        reportId,
        filename
      };

    case UPLOAD_ERROR:
      return {
        ...state,
        uploadLoading: false
      };

    case FILE_READY:
      return {
        ...state,
        file
      };

    case LOAD_COMPANIES_REQUEST:
      return {
        ...state,
        companiesLoading: true
      };

    case LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.companies,
        companiesLoading: false
      };

    case CHANGE_COMPANY:
      return {
        ...state,
        company: action.company
      };

    case LOAD_VALUE_SYSTEMS_REQUEST:
      return {
        ...state,
        valueSystem: '',
        valueSystemsLoading: true
      };

    case LOAD_VALUE_SYSTEMS_SUCCESS:
      return {
        ...state,
        valueSystems: action.valueSystems,
        valueSystemsLoading: false
      };

    case LOAD_VALUE_SYSTEMS_ERROR:
      return {
        ...state,
        valueSystemsLoading: false
      };

    case CHANGE_VALUE_SYSTEM:
      return {
        ...state,
        valueSystem: action.valueSystem
      };

    case LOAD_DOMAINS_REQUEST:
      return {
        ...state,
        domainsLoading: true
      };

    case LOAD_DOMAINS_SUCCESS:
      return {
        ...state,
        domains: action.domains,
        domainsLoading: false
      };

    case CHANGE_DOMAIN:
      return {
        ...state,
        domain: action.domain
      };

    case LOAD_LANGUAGES_REQUEST:
      return {...state, languagesLoading: true};

    case LOAD_LANGUAGES_SUCCESS:
      return {...state, languagesLoading: false, languages: action.languages};

    case LOAD_LANGUAGES_FAILURE:
      return {...state, languagesLoading: false};

    case CHANGE_LANGUAGE:
      return {...state, language: action.language};

    case PROGRESS_BAR_PROGRESS:
      return updateProgress(state, action.reportId, action.progress);

    case PROGRESS_BAR_COMPLETE:
      return updateProgress(state, action.reportId, 100);

    case EDIT_REPORT_INFORMATION_REQUEST:
      return {...state, editReportInformationLoading: true};

    case EDIT_REPORT_INFORMATION_DONE:
      return {...state, editReportInformationLoading: false};

    case TOGGLE_RENAME_EDITING:
      return {
        ...state,
        renameEditing: !state.renameEditing
      };

    case RENAME_REPORT_REQUEST:
      return {
        ...state,
        renameLoading: true
      };

    case RENAME_REPORT_SUCCESS:
      return {
        ...state,
        renameEditing: false,
        renameLoading: false
      };

    case RENAME_REPORT_ERROR:
      return {
        ...state,
        renameLoading: false
      };

    case EDIT_TOGGLE_COMPANY:
      return {
        ...state,
        companyEditing: !state.companyEditing
      };

    case EDIT_COMPANY_REQUEST:
      return {
        ...state,
        companyEditLoading: true
      };

    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        companyEditLoading: false
      };

    case EDIT_UPLOAD_FILE_REQUEST:
      return {
        ...state,
        editUploadLoading: true
      };

    case EDIT_UPLOAD_FILE_SUCCESS:
    case EDIT_UPLOAD_FILE_ERROR:
      return {
        ...state,
        editUploadLoading: false
      };

    case EDIT_UPDATE_COLUMNS_REQUEST:
      return {
        ...state,
        editUpdateColumnsLoading: true
      };

    case EDIT_UPDATE_COLUMNS_SUCCESS:
    case EDIT_UPDATE_COLUMNS_ERROR:
      return {
        ...state,
        editUpdateColumnsLoading: false
      };

    case EDIT_UPDATE_TAXONOMIES_REQUEST:
      return {
        ...state,
        editUpdateTaxonomiesLoading: true
      };

    case EDIT_UPDATE_TAXONOMIES_SUCCESS:
    case EDIT_UPDATE_TAXONOMIES_ERROR:
      return {
        ...state,
        editUpdateTaxonomiesLoading: false
      };

    case TAGS_CHANGE:
      return {
        ...state,
        tags: action.tags
      };

    case LOAD_REPORT_INFO_SUCCESS:
      return {
        ...state,
        tags: action.reportInfo.tags
      };

    case EDIT_ADD_COLUMNS_UPLOAD_FILE_REQUEST:
      return {
        ...state,
        editAddColumnsUploadFileLoading: true
      };

    case EDIT_ADD_COLUMNS_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        editAddColumnsUploadFileLoading: false,
        editAddColumnsStepId: STEPS[1].id
      };

    case EDIT_ADD_COLUMNS_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        editAddColumnsUploadFileLoading: false
      };

    case EDIT_ADD_COLUMNS_CONFIGURE_REQUEST:
      return {
        ...state,
        editAddColumnsConfigureLoading: true
      };

    case EDIT_ADD_COLUMNS_CONFIGURE_SUCCESS:
    case EDIT_ADD_COLUMNS_CONFIGURE_FAILURE:
      return {
        ...state,
        editAddColumnsConfigureLoading: false
      };

    case GET_COLUMNS_NAMES_MAP_CONFIG_REQUEST:
      return {
        ...state,
        columnsNamesMapConfigLoading: true
      };

    case GET_COLUMNS_NAMES_MAP_CONFIG_SUCCESS:
      return {
        ...state,
        columnsNamesMapConfigLoading: false,
        columnsNamesMapConfig: action.columnsMap
      };

    case GET_COLUMNS_NAMES_MAP_CONFIG_FAILURE:
      return {
        ...state,
        columnsNamesMapConfigLoading: false
      };

    case CHANGE_COLUMN_NAME:
      return {
        ...state,
        columnsNamesMapConfig: state.columnsNamesMapConfig.map((col, i) => {
          if (i === action.i) {
            return {...col, mapped_to_name: action.name}
          }

          return col;
        })
      };

    case UPDATE_COLUMNS_NAMES_MAP_REQUEST:
      return {
        ...state,
        updateColumnsNamesMapLoading: true
      };

    case UPDATE_COLUMNS_NAMES_MAP_DONE:
      return {
        ...state,
        updateColumnsNamesMapLoading: false
      };

    default:
      return state;
  }
};



export default createReportReducer;
