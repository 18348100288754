import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {requestSupportModalHide, requestSupportRequest} from "./request-support-actions";
import UiModal from "../../components/UiModal/UiModal";
import {DialogActions, DialogContent, Grid, MenuItem, TextField} from "@material-ui/core";
import ButtonWithProgress from "../../components/ButtonWithProgress/ButtonWithProgress";


const SUBJECTS = ['curation', 'support'];

const RequestSupportModal = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [subject, setSubject] = useState(SUBJECTS[0]);
  const [message, setMessage] = useState('');

  const {report, reportId, showModal, loading} = useSelector(state => ({
    report: state.reportInfo.report,
    reportId: state.report.reportId,
    showModal: state.requestSupport.showModal,
    loading: state.requestSupport.loading
  }), shallowEqual);

  const handleFormSubmit = e => {
    e.preventDefault();

    const reportData = {subject, message};

    if (reportId) {
      reportData.message = message + '\n\n' +
        t('end_of_original_msg', 'END OF ORIGINAL MESSAGE.') + '\n' +
        t('related_information', 'Related information') + ':\n' +
        t('link_to_report', 'Link to the report') + ': ' + window.location.href + '\n' +
        t('report_id', 'Report ID') + ': ' + report.report_id.$oid + '\n' +
        t('report_name', 'Report name') + ': ' + report.report_name + '\n' +
        t('company', 'Company') + ': ' + report.company + '\n' +
        t('created_at', 'Created at') + ':' + report.date_created;
    }

    dispatch(requestSupportRequest(reportData));
  };

  return (
    <UiModal
      open={showModal}
      onClose={() => dispatch(requestSupportModalHide())}
      title={t('request_support', 'Request Support')}
    >
      <form onSubmit={handleFormSubmit}>
        <DialogContent dividers>
          <Grid container direction="column" spacing={2}>
            {report && (
              <Grid item xs>
                <TextField
                  label={t('report_name', "Report Name")}
                  variant="outlined"
                  name="report"
                  fullWidth
                  disabled
                  value={report.report_name}
                  helperText={t('support_report_name_help', "Information about which report you requested support for will be added to the request.")}
                />
              </Grid>
            )}
            <Grid item xs>
              <TextField
                label={t('subject', "Subject")}
                select
                fullWidth
                variant="outlined"
                name="subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
                style={{textTransform: 'capitalize'}}
              >
                {SUBJECTS.map(subject => (
                  <MenuItem
                    style={{textTransform: 'capitalize'}}
                    key={subject}
                    value={subject}
                  >
                    {t('support_option_' + subject, subject)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                label={t('message', 'Message')}
                variant="outlined"
                name="message"
                fullWidth
                multiline
                rows={6}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonWithProgress
            type="submit"
            color="primary"
            disabled={loading}
            loading={loading}
          >
            {t('send', 'Send')}
          </ButtonWithProgress>
        </DialogActions>
      </form>
    </UiModal>
  );
};

export default RequestSupportModal;