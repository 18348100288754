import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import FilterItem from "./FilterItem";

const useStyles = makeStyles(theme => ({
  AccordionDetails: {
    padding: theme.spacing(1),
    backgroundColor: '#f0f0f0',
    flexDirection: 'column',
  },
  lightBulb: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
}));

const ParentGroupFilterItem = ({filter, flatFilters}) => {
  const classes = useStyles();
  const flatFilter = flatFilters[filter.item.id];

  return (
    <Accordion TransitionProps={{ mountOnEnter: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {flatFilter.title}

          {!!flatFilter.partial && <EmojiObjectsIcon className={classes.lightBulb} color="primary"/> }
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        {filter.children.map(childFilter => (
          <FilterItem key={childFilter.item.id} filter={childFilter} flatFilters={flatFilters}/>
        ))}
      </AccordionDetails>
    </Accordion>
  )
};

export default ParentGroupFilterItem;