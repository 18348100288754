const MAX_LABEL_WIDTH = 250;

export const getWidthOfText = (txt, fontname, fontsize) => {
  if (getWidthOfText.c === undefined) {
    getWidthOfText.c = document.createElement('canvas');
    getWidthOfText.ctx = getWidthOfText.c.getContext('2d');
  }

  getWidthOfText.ctx.font = fontsize + ' ' + fontname;

  return getWidthOfText.ctx.measureText(txt).width;
};

export const getLongestLabel = (data, key) => {
  if (!data.length) return '';

  let longestLabel = data[0][key];

  for (let i = 0; i < data.length; i++) {
    if (data[i][key].length > longestLabel.length) {
      longestLabel = data[i][key];
    }
  }

  return longestLabel;
};

export const getLegendWidth = (data, key) => {
  const longestLabel = getLongestLabel(data, key);
  let legendWidth = getWidthOfText(longestLabel, 'Roboto', '16px') + 10;

  if (legendWidth > MAX_LABEL_WIDTH) {
    legendWidth = MAX_LABEL_WIDTH;
  }

  return legendWidth;
};