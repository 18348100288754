import React, {useCallback, useEffect, useState} from "react";
import {makeStyles, Menu, MenuItem} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    backgroundColor: ({isSubMenuOpen}) => isSubMenuOpen ? theme.palette.action.hover : "transparent",
    minWidth: "12rem"
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  name: {
    paddingRight: 6
  },
  expandIcon: {
    fontSize: 12
  }
}));

const NestedMenuItem = React.forwardRef(
  (
    {
      label,
      children,
      onClick,
      isParentOpen,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isSubMenuOpen = Boolean(anchorEl);
    const classes = useStyles({ isSubMenuOpen });
    const hasChildrenItems = Boolean(children);

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    useEffect(() => {
      if (!isParentOpen) {
        handleClose();
      }
    }, [isParentOpen, handleClose]);

    const handleMouseEnter = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClick = event => {
      event.stopPropagation();

      if (onClick) {
        onClick();
      }
    };

    return (
      <MenuItem
        ref={ref}
        disableRipple
        className={classes.menuItem}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
      >
        <div className={classes.contentContainer}>
          <span className={classes.name}>{label}</span>
          {hasChildrenItems && (
            <ArrowForwardIosIcon className={classes.expandIcon} />
          )}
        </div>
        {hasChildrenItems && (
          <>
            <Menu
              // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
              style={{pointerEvents: "none"}}
              anchorEl={anchorEl}
              open={isSubMenuOpen}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              PaperProps={{
                elevation: 4
              }}
            >
              {/* reset pointer event here so that the menu items could receive mouse events */}
              <div style={{pointerEvents: "auto"}}>
                {children}
              </div>
            </Menu>
          </>
        )}
      </MenuItem>
    );
  }
);

export default NestedMenuItem;
