import React, {Fragment} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {showReportInfoModal} from "../reports/reports-actions";

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 400
  }
}))

const ReportInfoComponent = ({report, loading}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let content = <CircularProgress/>;

  if (!loading && report) {
    const reportName = report.report_name;
    const dateCreated = report.date_created;

    content = (
      <Fragment>

        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5">{reportName}</Typography>
            <strong className={classes.bold}>{t('date_created', 'Date created')}: </strong><span>{dateCreated}</span>
          </Grid>
          <Grid item>
            <IconButton
              variant="contained"
              onClick={() => dispatch(showReportInfoModal(report))}
            >
              <InfoOutlinedIcon/>
            </IconButton>
          </Grid>
        </Grid>

        {report.tags && report.tags.length > 0 && (
          <div className="report_info__tags" style={{marginTop: '10px', fontSize: '12px'}}>
            {report.tags.map(t => (
              <Chip variant="outlined" key={t} label={t} style={{marginRight: '5px'}}/>
            ))}
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <Paper component={Box} p={2}>
      {content}
    </Paper>
  )
};

export default ReportInfoComponent;
