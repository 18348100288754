import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Box, CircularProgress, Grid, Typography} from "@material-ui/core";

import Column from "../components/Column";
import ReportEditBottom from "../components/ReportEditBottom";
import ColumnsContainer from "../components/ColumnsContainer";

import {
  activateEditColumnType,
  changeColumnType,
  deactivateEditColumnType,
  editAddColumnsConfigureRequest,
  loadColumnsRequest,
  toggleDropdown
} from "../create-report-action-creators";

import {LOAD_COLUMNS_MODE_ADDING_COLUMNS} from "../constants";


const AddNewColumnsConfigure = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {columns, columnsLoading, configureLoading} = useSelector(state => ({
    columns: state.createReport.columns,
    columnsLoading: state.createReport.stepTwoLoading,
    configureLoading: state.createReport.editAddColumnsConfigureLoading,
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadColumnsRequest(LOAD_COLUMNS_MODE_ADDING_COLUMNS));
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(editAddColumnsConfigureRequest(history))
  }

  let columnsContent = <CircularProgress/>;

  if (!columnsLoading) {
    columnsContent = columns.map((col, i) => (
      <Column
        key={i}
        columnIndex={i}
        {...col}
        changeColumnType={columnTypeInfo => dispatch(changeColumnType(columnTypeInfo))}
        toggleDropdown={() => dispatch(toggleDropdown(i))}
        activateEditColumnType={() => dispatch(activateEditColumnType(i))}
        deactivateEditColumnType={() => dispatch(deactivateEditColumnType(i))}
      />
    ));
  }

  return (
    <>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{t('review_and_update_columns', 'Review and update columns')}</Typography>
          </Grid>
          <Grid item xs>
            <ColumnsContainer>
              {columnsContent}
            </ColumnsContainer>
          </Grid>
        </Grid>
      </Box>

      <ReportEditBottom
        loading={configureLoading}
        enabled={!columnsLoading && !configureLoading}
        submit={onSubmit}
        submitText={t('set_columns_and_recalculate', 'Set columns & recalculate')}
      />
    </>
  );
}

export default AddNewColumnsConfigure;