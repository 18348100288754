import React, {Fragment, useState} from 'react';
import {connect} from "react-redux";
import Grid from '@material-ui/core/Grid';
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import {addNotification} from "../../notifications/notifications-actions";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import i18n from "../../../i18n";
import {useTranslation} from "react-i18next";

function copyTextToClipboard(text) {
  const textArea = document.createElement("textarea");

  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (!successful) {
      throw new Error(i18n.t('could_not_copy', 'Could not copy'));
    }
  } catch (err) {
    throw(err);
  } finally {
    document.body.removeChild(textArea);
  }
}

const IgnoreWords = ({ignoreWords, ignoreWordsChange, addNotificationRequest}) => {
  const {t} = useTranslation();

  const [searchFieldShown,setSearchFieldShown] = useState(false);
  const [wordToSearch,setWordToSearch] = useState('');

  let transformedWords = ignoreWords ? ignoreWords.split('\n') : [];

  transformedWords = transformedWords.filter(w => w.match(wordToSearch));

  const tryToCopy = text => {
    try {
      copyTextToClipboard(text);
      addNotificationRequest('Success', t('copied_ignore_words','Ignore words have been copied to clipboard'));
    } catch (e) {
      addNotificationRequest('Error', t('error_copy_ignore_words', 'Could not copy ignore words'));
    }
  };

  const tryToPaste = e => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const words = pastedData.split(/\r?\n/).map(w => w.toLowerCase());
    const newWords = transformedWords.concat(words);
    const newWordsSet = new Set(newWords);

    ignoreWordsChange(Array.from(newWordsSet).join('\n'));
  };

  return (
    <Fragment>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <h2>{t('set_ignore_words', 'Set ignore words')}</h2>
        </Grid>
        { searchFieldShown && (
          <Grid item style={{minWidth: '260px'}}>
            <TextField
              label= {t('search_ignore_words', 'Search ignore words')}
              variant="outlined"
              size="small"
              fullWidth
              value={wordToSearch}
              onChange={(e) => setWordToSearch(e.target.value)}
            />
          </Grid>
        )}
        <Grid item>
          <IconButton onClick={() => {
            setSearchFieldShown(!searchFieldShown);
            setWordToSearch('');
          }}>
            {searchFieldShown ? <ClearIcon/> :<SearchIcon/>}
          </IconButton>
        </Grid>
      </Grid>

      <Grid item container direction="row" justify="space-between" spacing={2}>
        <Grid item xs>
          <Autocomplete
            value={transformedWords}
            onChange={wordsArray => ignoreWordsChange(wordsArray.map(w => w.toLowerCase()).join('\n'))}
            helperText= {t('add_ignore_words_helper_text', 'Type in ignore word or sentence, then press Enter to add.')}
            label={t('ignore_words_lbl',"Ignore words")}
            addOnBlur
            TextFieldProps={{onPaste: tryToPaste}}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => tryToCopy(ignoreWords)}
          >
            <FileCopyIcon fontSize="small"/>
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  addNotificationRequest: (status, message) => dispatch(addNotification({status, message: [message]})),
});

export default connect(null, mapDispatchToProps)(IgnoreWords);