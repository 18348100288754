import {
  PASSWORD_RESET_CHECK_ERROR,
  PASSWORD_RESET_CHECK_REQUEST, PASSWORD_RESET_CHECK_SUCCESS, PASSWORD_RESET_ERROR, PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUBMIT_ERROR,
  PASSWORD_RESET_SUBMIT_REQUEST,
  PASSWORD_RESET_SUBMIT_SUCCESS, PASSWORD_RESET_SUCCESS
} from "./password-reset-actions";

const initialState = {
  submitLoading: false,
  submitMessage: '',
  submitError: '',
  checkLoading: false,
  checkSuccess: false,
  checkError: '',
  resetLoading: false,
  resetSuccess: false,
  resetError: ''
};

const passwordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_RESET_SUBMIT_REQUEST:
      return {...state, submitLoading: true, submitMessage: '', submitError: ''};
    case PASSWORD_RESET_SUBMIT_SUCCESS:
      return {...state, submitMessage: action.message, submitError: '', submitLoading: false};
    case PASSWORD_RESET_SUBMIT_ERROR:
      return {...state, submitError: action.error, submitMessage: '', submitLoading: false};
    case PASSWORD_RESET_CHECK_REQUEST:
      return {...state, checkLoading: true, checkSuccess: false, checkError: ''};
    case PASSWORD_RESET_CHECK_SUCCESS:
      return {...state, checkSuccess: true, checkLoading: false, checkError: ''};
    case PASSWORD_RESET_CHECK_ERROR:
      return {...state, checkError: action.error, checkSuccess: false, checkLoading: false};
    case PASSWORD_RESET_REQUEST:
      return {...state, resetLoading: true, resetSuccess: false, resetError: ''};
    case PASSWORD_RESET_SUCCESS:
      return {...state, resetLoading: false, resetSuccess: true, resetError: ''};
    case PASSWORD_RESET_ERROR:
      return {...state, resetLoading: false, resetSuccess: false, resetError: action.error};
    default:
      return state;
  }
};

export default passwordResetReducer;
