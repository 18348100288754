import React from 'react';
import {useTranslation} from "react-i18next";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";

import Paginator from "../../components/Paginator/Paginator";
import Verbatim from "./verbatim";

import "./responses.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    padding: theme.spacing(1)
  },
  heading: {
    fontWeight: 300,
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  inner: {
    padding: theme.spacing(1)
  },
  verbatims: {
    marginTop: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(1)}px`,
  },
}));

const ResponsesComponent = (
  {
    responses,
    page,
    totalPages,
    loading,
    loadResponses,
    error,
    showContextMenu,
    setSelectedText,
    tabs,
    selectedTab,
    changeTab,
    reportId,
    setNoteInfo,
    showNoteContextMenu,
    hiddenVerbatims
  }
) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const pagination = responses.length > 0 && (
    <Paginator
      changePage={loadResponses}
      page={page}
      totalPages={totalPages}
    />
  );

  const headingControl = (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs>
        {!!selectedTab && (
          <TextField select fullWidth variant="outlined" value={selectedTab} onChange={e => changeTab(e.target.value)}>
            {tabs.map(tabName => (
              <MenuItem key={tabName} value={tabName}>{tabName}</MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item>
        {pagination}
      </Grid>
    </Grid>
  );

  let responsesComponent = (
    <Grid container style={{height: '600px'}} alignItems="center" justify="center">
      <CircularProgress/>
    </Grid>
  );

  if (error) {
    responsesComponent = (
      <Grid container p={5}>
        {error}
      </Grid>
    );
  }

  if (!loading && !error) {
    responsesComponent = (
      <>
        {(hiddenVerbatims > 0) &&
        <Grid container justify="center">
          <Chip variant="outlined" label={<span><strong>{t('verbatims_hidden_blank', 'Verbatims hidden (blank)')}: </strong>{hiddenVerbatims}</span>}/>
        </Grid>
        }

        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.verbatims}
        >
          {responses.map(response => (
            <Grid item xs key={response['row_number'] + response['header']}>
              <Verbatim
                response={response}
                selectedTab={selectedTab}
                reportId={reportId}
                showContextMenu={showContextMenu}
                setSelectedText={setSelectedText}
                setNoteInfo={setNoteInfo}
                showNoteContextMenu={showNoteContextMenu}
              />
            </Grid>
          ))}
        </Grid>

      </>
    );
  }

  return (
    <>
      <Typography variant="h6" className={classes.heading}>
        <a id="responses">{t('verbatim_explorer', 'Verbatim Explorer')}</a>
      </Typography>

      {headingControl}
      {responsesComponent}

      <Grid container alignItems="center" justify="center">
        <Grid item>{pagination}</Grid>
      </Grid>
    </>
  )
};

export default ResponsesComponent;
