import React from 'react';
import {connect} from 'react-redux';
import SplitChartWrapper from "./split-chart-wrapper";

import {loadSplitChartSentimentRequest} from "./split-charts-actions";
import {addNotification} from "../notifications/notifications-actions";
import {useTranslation} from "react-i18next";
import SentimentsStatisticalData from "./sentiments/SentimentsStatisticalData";

const SentimentsSplitChart = props => {
  const {t} = useTranslation();

  const footer = (
    <SentimentsStatisticalData
      key={props.data}
      data={props.data}
      statisticalData={props.statisticalSignificanceData}
    />
  );

  return (
    <SplitChartWrapper
      {...props}
      rowTitle={t('filter_value', 'Filter value')}
      colTitle={t('sentiment', 'Sentiment')}
      title={t('sentiments_split_chart', 'Sentiments Split Chart')}
      footer={footer}
    />
  );
};

const mapStateToProps = state => ({
  filters: state.filters.flatFilters,
  data: state.splitCharts.sentimentData,
  statisticalSignificanceData: state.splitCharts.sentimentsStatisticalSignificanceData,
  loading: state.splitCharts.sentimentLoading,
  error: state.splitCharts.sentimentError
});

const mapDispatchToProps = dispatch => ({
  loadSplitChart: filterName => dispatch(loadSplitChartSentimentRequest(filterName)),
  addNotificationRequest: (status, message) => dispatch(addNotification({status, message: [message]})),
});

export default connect(mapStateToProps, mapDispatchToProps)(SentimentsSplitChart);
