import React, {createRef, Fragment, useEffect} from 'react';
import * as d3 from 'd3';
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

const CHART_SIZE = 280;
const RED_COLOR = '#D33234';
const GREEN_COLOR = 'rgb(128, 191, 63)';
const BACKGROUND_GRAY_COLOR = '#F4F6F8';
const GRAY_COLOR = '#D3D3D3';

const useStyles = makeStyles(theme => ({
  legend: {
    fontSize: '18px',
    padding: theme.spacing(1),
    paddingTop: 0,
    '& span': {
      fontWeight: 'bold',
      color: '#989FA4',
    }
  },
  hxIndex: {
    fontWeight: 'bold',
    fontSize: '25px',
  },
}));


const degToRad = deg => deg * (Math.PI / 180);

const getHalfPieArc = (innerRadius, outerRadius, startAngle, endAngle) => {
  return d3.arc().innerRadius(innerRadius)
    .outerRadius(outerRadius)
    .startAngle(degToRad(startAngle))
    .endAngle(degToRad(endAngle));
};

const drawChart = (hxIndex, svgNode, classes) => {
  svgNode.innerHTML = '';
  const svg = d3.select(svgNode);

  const outerRadius = CHART_SIZE / 2;
  const innerRadius = outerRadius * 0.6;

  const legendOuterRadius = outerRadius * 0.58;
  const legendInnerRadius = outerRadius * 0.55;

  const legendArcRed = getHalfPieArc(legendInnerRadius, legendOuterRadius, -90, -13.5);
  const legendArcGreen = getHalfPieArc(legendInnerRadius, legendOuterRadius, 13.5, 90);
  const legendArcGray = getHalfPieArc(legendInnerRadius, legendOuterRadius, -13.5, 13.5);

  const indexDegree = hxIndex * 90;
  const indexArc = getHalfPieArc(innerRadius, outerRadius, -90, indexDegree);
  const indexBackgroundArc = getHalfPieArc(innerRadius, outerRadius, indexDegree, 90);

  const centerX = CHART_SIZE / 2;

  const appendToChart = el => svg.append("path")
    .attr("d", el)
    .attr("transform", `translate(${centerX},${centerX})`);

  let indexColor;

  if (hxIndex < -0.15) {
    indexColor = RED_COLOR;
  } else if (hxIndex > 0.15) {
    indexColor = GREEN_COLOR;
  } else {
    indexColor = GRAY_COLOR;
  }

  appendToChart(indexArc).attr('fill', indexColor);
  appendToChart(indexBackgroundArc).attr('fill', BACKGROUND_GRAY_COLOR);
  appendToChart(legendArcRed).attr('fill', RED_COLOR);
  appendToChart(legendArcGreen).attr('fill', GREEN_COLOR);
  appendToChart(legendArcGray).attr('fill', GRAY_COLOR);

  svg.append("text").text(hxIndex.toFixed(3))
    .attr('x', centerX)
    .attr('y', centerX)
    .attr('text-anchor', 'middle')
    .attr('fill', indexColor)
    .attr('class', classes.hxIndex)

  svg.append("polygon")
    .attr('points', `-10,${centerX} ${centerX - 60},${centerX - 4} ${centerX - 60},${centerX + 4}`)
    .attr('style', `fill: rgb(242, 101, 34)`)
    .attr('transform', `rotate(${90 + indexDegree}, ${centerX}, ${centerX})`)
};

const HxIndexArcChart = ({hxIndex}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const svgRef = createRef();
  const hxTitle = useSelector(state => state.main.title);

  useEffect(() => {
    drawChart(hxIndex, svgRef.current, classes);
  }, [hxIndex]);

  return (
    <Fragment>
      <svg
        width="100%"
        viewBox={`0 0 ${CHART_SIZE} ${CHART_SIZE / 2}`}
        overflow="visible"
        preserveAspectRatio="xMidYMid meet"
        ref={svgRef}
      />
      <Grid container justify="space-between" className={classes.legend}>
        <Grid item component="span">-1.000</Grid>
        <Grid item component="span">{hxTitle} {t('index', 'Index')}</Grid>
        <Grid item component="span">1.000</Grid>
      </Grid>
    </Fragment>
  );
};

export default HxIndexArcChart;