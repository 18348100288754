
import {
  CHANGE_PAGE, CHANGE_TARGET_PAGE, DOWNLOAD_TOP_WORDS, DOWNLOAD_TOP_WORDS_DONE, LOAD_TOP_WORDS_PAGINATED,
  SEARCH_CHANGE
} from "./full-report-top-words-action-types";
import deepCopy from "../../utilities/deepCopy";
import {afterSearch} from "./functions";

const initialState = {
  page: 1,
  topWordsPaginated: null,
  limboPage: null,
  limboTopWordsPaginated: null,
  searchValue: "",
  targetPage: "",
  downloading: false
};


const fullReportTopWordsReducer = ( state = initialState, action ) => {

  const { next, previous, topWordsPaginated, searchValue, targetPage } = action;

  switch(action.type) {

    case CHANGE_TARGET_PAGE:
      return {
        ...state,
        targetPage
      };

    case SEARCH_CHANGE:
      if (!searchValue) {
        return {
          ...state,
          page: state.limboPage,
          topWordsPaginated: state.limboTopWordsPaginated,
          searchValue
        }
      }
      const dataToSearch = !state.limboTopWordsPaginated ? state.topWordsPaginated : state.limboTopWordsPaginated;
      const newData = afterSearch(
        {
          data: deepCopy(dataToSearch),
          searchValue
        }
        );
      return {
        ...state,
        topWordsPaginated: newData,
        page: 1,
        limboPage: !state.limboPage ? state.page : state.limboPage,
        limboTopWordsPaginated: !state.limboTopWordsPaginated ? state.topWordsPaginated : state.limboTopWordsPaginated,
        searchValue
      };

    case CHANGE_PAGE:

      const allPages = Object.keys(state.topWordsPaginated).length;
      const newPage = next ? state.page + 1 : state.page - 1;
      if (Number(targetPage) > 0 && Number(targetPage) <= allPages) {
        return {
          ...state,
          page: Number(targetPage)
        }
      }
      if (next) {
        if (newPage > allPages) {
          return state;
        }
        return {
          ...state,
          page: newPage,
          targetPage: ""
        }
      }
      if (previous) {
        if (newPage < 1) {
          return state;
        }
        return {
          ...state,
          page: newPage,
          targetPage: ""
        }
      }
      return state;

    case LOAD_TOP_WORDS_PAGINATED:
      return {
        ...state,
        topWordsPaginated
      };

    case DOWNLOAD_TOP_WORDS:
      return {
        ...state,
        downloading: true
      };

    case DOWNLOAD_TOP_WORDS_DONE:
      return {
        ...state,
        downloading: false
      };

    default:
      return state;

  }
};

export default fullReportTopWordsReducer;