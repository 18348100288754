import React, {useCallback} from 'react';
import {useDispatch} from "react-redux";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

import {changeFilter} from "../filters-actions";
import {makeStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
  rangeSlider: {
    width: '100%',
    padding: theme.spacing(1),
  },
  rangeSliderInner: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative'
  },
  valueChip: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: '50%',
    transform: 'translate(-50%, 0)',
  }
}));

const RangeFilterItem = ({filter, flatFilters}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flatFilter = flatFilters[filter.item.id];
  const value = flatFilter.value || [flatFilter.min, flatFilter.max];

  const onChange = useCallback((_, value) => {
    const [min, max] = value;

    if (min !== flatFilter.min || max !== flatFilter.max) {
      dispatch(changeFilter(flatFilter, {value}));
    } else {
      dispatch(changeFilter(flatFilter, {value: undefined}));
    }
  }, [flatFilter]);

  const marks = [{
    value: flatFilter.min,
    label: flatFilter.min
  }, {
    value: flatFilter.max,
    label: flatFilter.max
  }];

  return (
    <div className={classes.rangeSlider}>
      <Typography variant="body2" component="p">
        {flatFilter.title}
      </Typography>
      <div className={classes.rangeSliderInner}>
        <Slider
          value={value}
          valueLabelDisplay="auto"
          step={flatFilter.step}
          marks={marks}
          getAriaValueText={v => v}
          min={flatFilter.min}
          max={flatFilter.max}
          onChange={onChange}
        />
        <Chip
          className={classes.valueChip}
          size="small"
          color="primary"
          label={value[0] + ' ... ' + value[1]}
        />
      </div>
    </div>
  );
};

export default RangeFilterItem;