import React from 'react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button, DialogActions, DialogContent, Grid, TextField} from "@material-ui/core";
import {hideSearchModal, setSearchCriteria} from "./ngram-search-actions";

const NgramSearchForm = ({reportId}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const searchCriteria = useSelector(state => state.ngramSearch.searchCriteria);

  const handleSubmit = event => {
    event.preventDefault();
    goToSearchPage();
  };

  const goToSearchPage = () => {
    dispatch(hideSearchModal());

    history.push(
      '/report/' + reportId +
      '/search/' + searchCriteria.searchWord +
      '/' + searchCriteria.head +
      '/' + searchCriteria.tail
    );
  };

  const fieldChanged = event => {
    dispatch(setSearchCriteria({[event.target.name]: event.target.value}));
  };

  const isDisabled = () => {
    const head = parseInt(searchCriteria.head, 10);
    const tail = parseInt(searchCriteria.tail, 10);

    return !searchCriteria.searchWord ||
      isNaN(head) || isNaN(tail) ||
      head < 0 || tail < 0
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent dividers>
        <Grid container direction="column" justify="space-between">
          <Grid item xs>
            <TextField
              variant="outlined"
              type="text"
              autoFocus
              fullWidth
              label={t('search_word', 'Search word')}
              name="searchWord"
              value={searchCriteria.searchWord}
              onChange={fieldChanged}
            />
          </Grid>
          <Grid item container direction="column">
            <Grid item xs>
              <h3 style={{fontWeight:"300"}}>{t('number_of_words', 'Number of words')}</h3>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs>
                <TextField fullWidth
                           variant="outlined" type="number" name="head" min="0" label={t('before', 'Before')}
                           value={searchCriteria.head} onChange={fieldChanged}
                />
              </Grid>
              <Grid item xs>
                <TextField fullWidth
                           variant="outlined" type="number" name="tail" min="0" label={t('after', 'After')}
                           value={searchCriteria.tail} onChange={fieldChanged}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type="submit" color="primary" disabled={isDisabled()}>{t('search', 'Search')}</Button>
      </DialogActions>
    </form>
  );
};

export default NgramSearchForm;