import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import ImageIcon from "@material-ui/icons/Image";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ChartBox from "../../../components/Charts/chart-box";
import Chart from "../components/Chart";
import ChartIndex from "../../../components/Charts/pie-chart-index/chart-index";
import {loadPrimarySecondarySentimentRequest} from "../charts-action-creators";
import {subscribeToFilters, unsubscribeFromFilters} from "../../filters/filters-actions";
import {addNotification} from "../../notifications/notifications-actions";
import {createColor} from "../../../utilities/getColor";
import {createAndCopyTable} from "../functions";
import {piTransform} from "../../../utilities/domainFunctions";
import {useTranslation} from "react-i18next";

const MIN_RADIUS = 10;
const MAX_RADIUS = 50;

const createSecondaryEmotionsStats = (primaries, mode) => {
  primaries.sort((a, b) => {
    if (a.modes[mode].count > b.modes[mode].count) {
      return -1;
    }
    return 1;
  });

  return primaries.reduce((top, primary) => {
    const primaryDistribution = primary.modes[mode].distribution;
    const primaryName = primary.primary;
    top[primaryName] = [];

    const secondaries = Object.keys(primary.secondaries).map(name => ({...primary.secondaries[name], name}));
    secondaries.sort((a, b) => {
      if (a[mode].count > b[mode].count) {
        return -1;
      }

      if (a[mode].count < b[mode].count) {
        return 1;
      }

      return 0;
    });

    let currentTopIndex = 0;
    let prev = secondaries[0];

    secondaries.forEach((current, i) => {
      const secondaryCandidate = {...current[mode], name: current.name, primaryName, primaryDistribution};

      if (i === 0) {
        top[primaryName][currentTopIndex] = [secondaryCandidate];
        return;
      }

      if (prev[mode].count !== current[mode].count) {
        currentTopIndex++;
      }

      if (top[primaryName][currentTopIndex]) {
        top[primaryName][currentTopIndex].push(secondaryCandidate);
      } else {
        top[primaryName][currentTopIndex] = [secondaryCandidate];
      }

      prev = current;
    });

    return top;
  }, {});
};

const prepareChartData = (primary, mode, colors, level) => {
  const dataset = [];
  const backgroundColor = [];
  const labels = [];

  const stats = createSecondaryEmotionsStats(primary, mode);

  let minPiAdjusted = 0, maxPiAdjusted = 0;
  const levelData = [];

  Object.keys(stats).forEach((primaryName, i) => {
    const levelItems = stats[primaryName][level];
    if (!levelItems) return;

    const firstItem = levelItems[0];

    const currentPiAdjusted = firstItem.pi_adjusted;

    if (i === 0) {
      minPiAdjusted = currentPiAdjusted;
      maxPiAdjusted = currentPiAdjusted;
    } else if (currentPiAdjusted < minPiAdjusted) {
      minPiAdjusted = currentPiAdjusted;
    } else if (currentPiAdjusted > maxPiAdjusted) {
      maxPiAdjusted = currentPiAdjusted;
    }

    levelData.push({
      ...firstItem,
      name: levelItems.map(item => item.name)
    });
  });

  const rDiff = MAX_RADIUS - MIN_RADIUS;
  const piDiff = maxPiAdjusted - minPiAdjusted;

  levelData.forEach(levelItem => {
    const r = ((levelItem.pi_adjusted - minPiAdjusted) * rDiff / piDiff) + MIN_RADIUS;
    dataset.push({
      x: levelItem.primaryDistribution * 100,
      y: levelItem.power_index * 100,
      r,
      displayValue: piTransform(levelItem.pi_adjusted),
    });

    backgroundColor.push(createColor(colors[levelItem.primaryName]));
    labels.push(levelItem.name.join(', '));
  });

  return {
    datasets: [{
      data: dataset,
      backgroundColor,
    }],
    labels,
    originalData: levelData,
  };
};


const SecondaryBreakdownChart = props => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const mode = useSelector(state => state.report.mode);
  const primary = useSelector(state => state.charts.primary);
  const valueSystem = useSelector(state => state.report.valueSystem);
  const TITLE = t('secondary_emotions_breakdown', 'Secondary Emotions Breakdown');

  const chartRef = useRef();

  const onFiltersChange = useCallback(() => dispatch(loadPrimarySecondarySentimentRequest()), []);

  useEffect(() => {
    onFiltersChange();
    dispatch(subscribeToFilters(onFiltersChange));

    return () => {
      dispatch(unsubscribeFromFilters(onFiltersChange));
    }
  }, [dispatch, onFiltersChange]);

  const colors = valueSystem ? valueSystem['primary_color'] : {};

  const [level, setLevel] = useState(0);
  const [isAbsolute, setIsAbsolute] = useState(false);

  const chartData = useMemo(() => {
    return prepareChartData(primary, mode, colors, level);
  }, [primary, mode, colors, level]);

  const data = chartData.originalData;

  const columns = [
    {name: t('primary_emotion', 'Primary Emotion'), key: 'primaryName'},
    {name: t('secondary_emotions', 'Secondary Emotion(s)'), key: 'name', transform: v => v.join(', ')},
    {name: t('power_index', 'Power Index'), key: 'power_index', transform: piTransform},
    {name: t('power_index_adjusted', 'PI Adjusted'), key: 'pi_adjusted', transform: piTransform},
  ];

  const menuItems = [
    {
      text: t('download_as_png', 'Download as PNG'),
      onClick: () => {
        chartRef.current.chartInstance.canvas.toBlob(function (blob) {
          saveAs(blob, TITLE + '.png');
        });
      },
      icon: <ImageIcon fontSize="small"/>,
    },
    {
      onClick: () => {
        createAndCopyTable(data, columns);
        dispatch(addNotification({status: 'Success', message: [t('copied_to_clipboard_notification','Copied to clipboard!')]}));
      },
      icon: <FileCopyIcon fontSize="small"/>,
      text: t('copy_to_clipboard', 'Copy to clipboard')
    },
    {
      text: t('display_abs_Y_axis', "Display absolute Y axis"),
      onClick: () => setIsAbsolute(prev => !prev),
      icon: isAbsolute ? <CheckBoxIcon/> : <CheckBoxOutlineBlank fontSize="small"/>,
    },
    {
      divider: true
    },
  ];

  const getMaxXAxisValue = () => {
    if (!data[0]) return 100;
    const candidate = Math.ceil((data[0].primaryDistribution + 0.05) * 10) * 10;
    return candidate > 100 ? 100 : candidate;
  }

  const overrideOptions = {
    layout: {
      padding: {
        top: 20,
      }
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: t('secondary_emotion_power_index', 'Secondary Emotion Power Index')
        },
        ticks: {
          beginAtZero: isAbsolute,
          max: isAbsolute ? 100 : undefined,
        },
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: t('primary_emotion_distribution', 'Primary Emotion Distribution (%)')
        },
        ticks: {
          max: getMaxXAxisValue()
        }
      }]
    }
  };

  const specificOptions = {};

  if (isAbsolute) {
    specificOptions.yAxisMax = 1;
    specificOptions.yAxisBeginAtZero = true;
  }

  const indexData = data.map(d => ({
    color: createColor(colors[d.primaryName]),
    name: d.primaryName,
  }));

  return (
    <ChartBox
      title={TITLE}
      loading={props.loading}
      error={props.error}
      actionsMenuItems={menuItems}
    >
      <Chart
        height="300px"
        ref={chartRef}
        chartData={chartData}
        tooltipColumns={columns}
        chartType="bubble"
        overrideOptions={overrideOptions}
        specificOptions={specificOptions}
      />
      <ChartIndex indexData={indexData} />
      <Grid container justify="center" alignItems="center">
        <Grid item> {t('secondary_emotions_lvl_for_primary_emotions', 'Level of secondary emotions for the primary emotions:')}</Grid>
      </Grid>
      <Grid container justify="center" alignItems="center">
        <FormControlLabel
          control={
            <Radio
              color="default"
              checked={level === 0}
              onChange={() => setLevel(0)}
            />
          }
          label={t('top', "Top")}
        />
        <FormControlLabel
          control={
            <Radio
              color="default"
              checked={level === 1}
              onChange={() => setLevel(1)}
            />
          }
          label={t('second', "Second")}
        />
        <FormControlLabel
          control={
            <Radio
              color="default"
              checked={level === 2}
              onChange={() => setLevel(2)}
            />
          }
          label={t('third', "Third")}
        />
      </Grid>
    </ChartBox>
  );
};

export default SecondaryBreakdownChart;