export const createGrid = (theme, width) => ({
  reportsGrid: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${width}px, 1fr))`,
    gridAutoRows: '1fr',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(1),
    width: '100%',
    padding: theme.spacing(0.5),
  }
});
