import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchArticleRequest} from "./acticles-actions";
import {CircularProgress, Grid, Typography} from "@material-ui/core";
import PaperWithPadding from "../../components/PaperWithPadding/PaperWithPadding";
import {Alert} from "@material-ui/lab";
import MDEditor from "@uiw/react-md-editor";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ArticleView = ({match}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {name} = match.params;

  const article = useSelector(state => state.articles.singleArticle);
  const loading = useSelector(state => state.articles.fetchSingleLoading);

  useEffect(() => {
    dispatch(fetchArticleRequest({name}));
  }, [dispatch, name]);

  useEffect(() => {
    if (article && location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        element.scrollIntoView();
      }, 100);
    }
  }, [article, location.hash]);

  let articleArea;

  if (loading) {
    articleArea = (
      <Grid item>
        <PaperWithPadding>
          <CircularProgress/>
        </PaperWithPadding>
      </Grid>
    );
  } else if (article) {
    articleArea = (
      <>
        <Grid item>
          <PaperWithPadding>
            <Typography variant="h6">{article.title}</Typography>
          </PaperWithPadding>
        </Grid>
        <Grid item>
          <PaperWithPadding>
            <MDEditor.Markdown source={article.body}/>
          </PaperWithPadding>
        </Grid>
      </>
    );
  } else {
    articleArea = (
      <Grid item>
        <PaperWithPadding>
          <Alert color="error">{t('article_could_not_be_fetched', 'Article could not be fetched!')}</Alert>
        </PaperWithPadding>
      </Grid>
    )
  }

  return loading ? <CircularProgress/> : (
    <Grid container direction="column" spacing={1}>
      {articleArea}
    </Grid>
  );
};

export default ArticleView;