export const FILTERS_REQUEST = '[FILTERS] FILTERS_REQUEST';
export const FILTERS_SUCCESS = '[FILTERS] FILTERS_SUCCESS';
export const FILTERS_FAILURE = '[FILTERS] FILTERS_FAILURE';

export const LOAD_HX_INDEX_REQUEST = '[FILTERS] LOAD_HX_INDEX_REQUEST';
export const LOAD_HX_INDEX_SUCCESS = '[FILTERS] LOAD_HX_INDEX_SUCCESS';
export const LOAD_HX_INDEX_FAILURE = '[FILTERS] LOAD_HX_INDEX_FAILURE';

export const CHANGE_FILTER = '[FILTERS] CHANGE_FILTER';

export const SUBSCRIBE_TO_FILTERS = "[FILTERS] SUBSCRIBE_TO_FILTERS";
export const UNSUBSCRIBE_FROM_FILTERS = "[FILTERS] UNSUBSCRIBE_FROM_FILTERS";

export const UPDATE_SELECTED_FILTERS = "[FILTERS] UPDATE_SELECTED_FILTERS";
export const CACHE_URL_MAPPED_FILTERS = "[FILTERS] CACHE_URL_MAPPED_FILTERS";
export const RELOAD_DATA = "[FILTERS] RELOAD_DATA";
export const APPLY_FILTERS = "[FILTERS] APPLY_FILTERS";
export const CLEAN_FILTERS_STATE = "[FILTERS] CLEAN_FILTERS_STATE";
export const SET_OPERATOR = '[FILTERS] SET_OPERATOR';

export const SHOW_EXPIRED_URL_MODAL = "[FILTERS] SHOW_EXPIRED_URL_MODAL";
export const HIDE_EXPIRED_URL_MODAL = "[FILTERS] HIDE_EXPIRED_URL_MODAL";

export const filtersRequest = () => ({type: FILTERS_REQUEST});
export const filtersSuccess = (filters, flatFilters, selectedFilters, checksum, operator) => ({type: FILTERS_SUCCESS, filters, flatFilters, selectedFilters, checksum, operator});
export const filtersFailure = error => ({type: FILTERS_FAILURE, error});

export const loadHxIndexRequest = () => ({type: LOAD_HX_INDEX_REQUEST});
export const loadHxIndexSuccess = hxData => ({type: LOAD_HX_INDEX_SUCCESS, hxData});
export const loadHxIndexFailure = error => ({type: LOAD_HX_INDEX_FAILURE, error});

export const changeFilter = (filter, changes) => ({type: CHANGE_FILTER, filter, changes});

export const subscribeToFilters = subscription => ({type: SUBSCRIBE_TO_FILTERS, subscription});
export const unsubscribeFromFilters = subscription => ({type: UNSUBSCRIBE_FROM_FILTERS, subscription});

export const updateSelectedFilters = (flatFilters, selectedFilters) => ({type: UPDATE_SELECTED_FILTERS, flatFilters, selectedFilters});
export const cacheUrlMappedFilters = urlPart => ({type: CACHE_URL_MAPPED_FILTERS, urlPart});
export const reloadData = () => ({ type: RELOAD_DATA });
export const applyFilters = () => ({type: APPLY_FILTERS});
export const cleanFiltersState = () => ({type: CLEAN_FILTERS_STATE});
export const setOperator = operator => ({type: SET_OPERATOR, operator});

export const showExpiredUrlModal = () => ({type: SHOW_EXPIRED_URL_MODAL});
export const hideExpiredUrlModal = () => ({type: HIDE_EXPIRED_URL_MODAL});

