import React, {useMemo, useState} from 'react';
import MUIDataTable from "mui-datatables";
import UiModal from "../../components/UiModal/UiModal";
import {DATE_FORMAT} from "./helpers";
import IconButton from "@material-ui/core/IconButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  TextField
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  filters: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}))

const UserReportsModal = ({isOpen, onClose, userStats}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const [reportNameSearch, setReportNameSearch] = useState('');
  const [reportCompanySearch, setReportCompanySearch] = useState('');

  const filteredReports = useMemo(() => {
    if (!userStats) return [];

    return userStats['reports_created_by_user'].filter(report => {
      let reportNameMatch = true;
      if (reportNameSearch) {
        reportNameMatch = report['report_name'].includes(reportNameSearch);
      }

      let reportCompanyMatch = true;
      if (reportCompanySearch) {
        reportCompanyMatch = report['report_company'] === reportCompanySearch;
      }

      return reportNameMatch && reportCompanyMatch;
    });
  }, [userStats, reportNameSearch, reportCompanySearch]);


  const reportCompanies = useMemo(() => {
    if (!userStats) return [];

    const names = userStats['reports_created_by_user'].map(r => r['report_company']);
    return Array.from(new Set(names));
  }, [userStats]);

  const userColumns = [
    {
      name: 'report_name',
      label: t('report_name', 'Report Name'),
      options: {
        sort: true
      },
    },
    {
      name: 'report_company',
      label: t('report_company', 'Report Company'),
      options: {
        sort: true,
      }
    },
    {
      name: 'date_created',
      label: t('date_created', 'Date Created'),
      options: {
        sort: true,
        customBodyRender: value => value.format(DATE_FORMAT)
      }
    },
    {
      name: 'avu_used_total',
      label: t('avu_used', 'AVU Used'),
      options: {
        sort: true,
      }
    },
    {
      name: 'report_status',
      label: t('report_status', 'Status'),
      options: {
        sort: true,
      }
    },
    {
      name: 'report_deleted',
      label: t('deleted', 'Deleted'),
      options: {
        customBodyRender: value => value ? <CheckIcon/> : <ClearIcon/>
      }
    },
    {
      name: 'report_archived',
      label: t('archived', 'Archived'),
      options: {
        customBodyRender: value => value ? <CheckIcon/> : <ClearIcon/>
      }
    },
    {
      name: 'report_link',
      label: t('report_link', 'Report Link'),
      options: {
        sort: false,
        setCellHeaderProps: () => ({style: {width: '50px'}}),
        customBodyRender: value => value && (
          <IconButton component={Link} title={t('report_link', 'Report link')} href={value} target="_blank">
            <LinkIcon fontSize="small"/>
          </IconButton>
        )
      }
    }
  ];

  return (
    <UiModal
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
      title={t('user_reports', "User Reports")}
    >
      <DialogContent dividers className={classes.dialogContent}>
        {userStats && (
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Box className={classes.filters}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={t('type_to_search_by_name', 'Type to search by name')}
                      value={reportNameSearch}
                      onChange={e => setReportNameSearch(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      select
                      variant="outlined"
                      fullWidth
                      label={t('select_to_filter_by_company', 'Select to filter by company')}
                      value={reportCompanySearch}
                      onChange={e => setReportCompanySearch(e.target.value)}
                    >
                      {reportCompanies.map(company => (
                        <MenuItem key={company} value={company}>{company}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs>
              <MUIDataTable
                data={filteredReports}
                columns={userColumns}
                options={{
                  sortOrder: {name: 'date_created', direction: 'desc'},
                  search: false,
                  pagination: false,
                  selectableRows: 'none',
                  print: false,
                  download: false,
                  filter: false,
                  viewColumns: false,
                  elevation: 0,
                  responsive: 'standard',
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>{t('close', 'Close')}</Button>
      </DialogActions>
    </UiModal>
  );
};

export default UserReportsModal;