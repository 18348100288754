import React, {Component, createRef, Fragment} from 'react';
import cloud from 'd3-cloud';
import {max, min, select, scaleLinear} from 'd3';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import WordCloudLegendComponent from "./word-cloud-legend-component";


const WIDTH = 700;
const HEIGHT = 700;

const RED_COLOR = '#D33234';
const GREEN_COLOR = 'rgb(128, 191, 63)';
const GRAY_COLOR = '#D3D3D3';

const styles = () => ({
  wordCloud: {
    '& text': {
      cursor: 'pointer',
      '&:hover': {
        filter: `url('#brightness')`
      }
    },
  }
});

const pseudoRandom = seed => {
  return () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }
};

class CloudChartComponent extends Component {
  state = {
    checkboxNegative: true,
    checkboxPositive: true,
    checkboxVoid: true
  };

  goToWord = d => {
    this.props.history.push(`/report/${this.props.reportId}/search/${d.text}/0/0`);
  };

  svgRef = createRef();

  drawCloud = () => {
    select(this.svgRef.current).select('g').remove();

    const cloudWords = [];

    for (let page of Object.keys(this.props.words)) {
      if (page > 7) break;

      this.props.words[page].forEach(word => {
        if (this.state.checkboxNegative){
          if (word.hx_index < -0.15) {
            word.color = RED_COLOR;
            return cloudWords.push(word);
          }
        }

        if (this.state.checkboxPositive){
          if (word.hx_index > 0.15) {
            word.color = GREEN_COLOR;
            return cloudWords.push(word)
          }
        }
        if (this.state.checkboxVoid){
          if (word.hx_index >= -0.15 && word.hx_index <= 0.15){
            word.color = GRAY_COLOR;
            return cloudWords.push(word)
          }
        }
      });
    }

    const maxSize = max(cloudWords, d => d.count);
    const minSize = min(cloudWords, d => d.count);
    const coefficient = 1 - minSize / (2 *maxSize);

    const sizeScale = scaleLinear()
      .domain([0, maxSize])
      .range([15, 95]);

    const draw = words => {
      select(this.svgRef.current)
        .append("g")
        .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
        .selectAll("text")
        .data(words)
        .enter().append("text")
        .classed("word",true)
        .style("font-size", d => d.size + 'px')
        .style("fill", d => d.color)
        .style('font-family', 'Patua One')
        .attr("text-anchor", "middle")
        .attr("transform", d => "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")")
        .text(d => d.term)
        .on('click', this.goToWord);
    };

    const rotateRandom = pseudoRandom(234);

    const layout = cloud()
      .size([WIDTH, HEIGHT])
      .words(cloudWords)
      .text(d => d.term)
      .rotate((d, i) => {
        if (i < 5 && d.term.length > 5) return 0; // to prevent long words to be rotated 90 degrees if they are in top five (usually in large font)
        return ~~(rotateRandom() * 2) * 90
      })
      .font("Patua One")
      .padding(1)
      .fontSize(d => Math.ceil(sizeScale(d.count) * coefficient))
      .random(pseudoRandom(123))
      .on("end", draw);

    layout.start();
    layout.stop();
  };

  componentDidMount() {
    if (this.props.words && !this.props.loading) {
      this.drawCloud();
    }
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  toggleCheck = name => {
    this.setState({
      ['checkbox' + name]: !this.state['checkbox' + name]
    })
  };

  render() {
    return (
      <Fragment>
        <svg
          width="100%"
          height={this.props.height || null}
          viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
          preserveAspectRatio="xMidYMid meet"
          ref={this.svgRef}
          className={this.props.classes.wordCloud}
        >
          <defs>
            <filter id="brightness">
              <feComponentTransfer>
                <feFuncR type="linear" slope="1.2"/>
                <feFuncG type="linear" slope="1.2"/>
                <feFuncB type="linear" slope="1.2"/>
              </feComponentTransfer>
            </filter>
          </defs>
        </svg>
        <WordCloudLegendComponent
          Negative={this.state.checkboxNegative}
          Positive={this.state.checkboxPositive}
          Void={this.state.checkboxVoid}
          onChange={this.toggleCheck}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(CloudChartComponent));
