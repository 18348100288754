import React from 'react';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from "@material-ui/core/Chip";
import {Scrollbars} from 'rc-scrollbars';
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  item: {
    flex: '0 0 250px',
    maxWidth: '250px',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  paper: {
    padding: theme.spacing(1)
  },
  name: {},
  unique: {
    height: 400,
  },
  uniqueItem: {
    padding: '10px 0'
  },
}));

const COLUMN_TYPE_COLORS = {
  ignore: '#ebebeb',
  meta: '#A8DDD3',
  data: '#C4A2D5',
}

const Column = props => {
  const classes = useStyles();
  const {
    column_name,
    column_type,
    allowed_types,
    unique,
    columnIndex,
    changeColumnType,
  } = props;

  const allowedTypes = Array.from(new Set([...allowed_types, column_type]));

  return (
    <Grid item className={classes.item}>
      <Paper className={classes.paper}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Tooltip arrow title={column_name}>
              <Typography variant="h6" className={classes.title}>{column_name}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs>
            <TextField
              select
              fullWidth
              value={column_type}
              onChange={e => changeColumnType({columnType: e.target.value, columnIndex})}
              variant="outlined"
            >
              {allowedTypes.map(type => (
                <MenuItem key={type} value={type}>
                  <Chip style={{backgroundColor: COLUMN_TYPE_COLORS[type]}} label={type}/>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={classes.unique}>
            <Scrollbars>
              {
                unique.map((u, i) => (
                  <Typography key={i} className={classes.uniqueItem}>{u}</Typography>
                ))
              }
            </Scrollbars>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
};

export default Column;
