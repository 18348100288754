import i18n from "../../i18n";

export const paginate = (data, rowsPerPage) => {
  const dataCopy = [...data];
  const result = {};
  const pages = Math.ceil(data.length / rowsPerPage);

  for (let page = 1; page <= pages; page++) {
    result[page] = dataCopy.splice(0, rowsPerPage);
  }

  return result;
};

export const createAndCopyMultiVariableTable = (data, columns) => {
  const table = document.createElement('table');
  const headerRow = document.createElement('tr');

  const sth = document.createElement('th');
  sth.innerHTML = i18n.t('second_variable', 'Second Variable');
  headerRow.appendChild(sth);

  columns.forEach(col => {
    const th = document.createElement('th');
    th.innerHTML = col.name;
    headerRow.appendChild(th);
  });

  table.appendChild(headerRow);

  data.forEach((dataItem) => {
    dataItem.data.forEach((row, i) => {
      const tr = document.createElement('tr');

      if (i === 0) {
        const std = document.createElement('td');
        std.setAttribute('rowspan', dataItem.data.length);
        std.innerText = dataItem.filterName;
        tr.appendChild(std);
      }

      columns.forEach(col => {
        const colTd = document.createElement('td');

        let value = row[col.key];

        if (col.transform) {
          value = col.transform(value);
        }

        colTd.innerHTML = value;
        tr.appendChild(colTd);
      });

      table.appendChild(tr);
    });
  });

  document.body.appendChild(table);

  selectAndCopyElement(table);

  table.remove();
}

export const createAndCopyTable = (data, columns) => {
  const table = document.createElement('table');
  const headerRow = document.createElement('tr');

  columns.forEach(col => {
    const th = document.createElement('th');
    th.innerHTML = col.name;
    headerRow.appendChild(th);
  });

  table.appendChild(headerRow);

  data.forEach(row => {
    let rowValue = row;

    const tr = document.createElement('tr');

    columns.forEach(col => {
      const colTd = document.createElement('td');

      let value = rowValue[col.key];

      if (col.transform) {
        value = col.transform(value);
      }

      colTd.innerHTML = value;
      tr.appendChild(colTd);
    });

    table.appendChild(tr);
  });

  document.body.appendChild(table);

  selectAndCopyElement(table);

  table.remove();
};

export const selectAndCopyElement = el => {
  const range = document.createRange();
  const sel = window.getSelection();
  sel.removeAllRanges();

  try {
    range.selectNodeContents(el);
    sel.addRange(range);
  } catch (e) {
    range.selectNode(el);
    sel.addRange(range);
  }

  document.execCommand("copy");
  sel.removeAllRanges();
};

export const formatPercent = p => (p * 100).toFixed(1) + '%';