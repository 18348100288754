import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import {fetchLanguagesRequest, setCurrentLanguage} from "../taxonomy-actions";

const LanguageSelector = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {languages, languagesLoading, language} = useSelector(state => ({
    languages: state.taxonomy.languages,
    languagesLoading: state.taxonomy.languagesLoading,
    language: state.taxonomy.language
  }), shallowEqual);

  useEffect(() => {
    dispatch(fetchLanguagesRequest());
  }, [dispatch]);

  return (
    <TextField
      select
      variant="outlined"
      fullWidth
      onChange={e => dispatch(setCurrentLanguage(e.target.value))}
      disabled={languagesLoading}
      InputProps={{
        startAdornment: languagesLoading && (
          <InputAdornment position="start"><CircularProgress size="small"/></InputAdornment>
        )
      }}
      label={t('language', 'Language')}
      value={languagesLoading ? '' : language}
    >
      {languagesLoading ? (
        <MenuItem>
          {t('loading', 'Loading')}...
        </MenuItem>
      ) : languages.map(language => (
        <MenuItem key={language} value={language}>{language}</MenuItem>
      ))}
    </TextField>
  );
};

export default LanguageSelector;