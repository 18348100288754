import React from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {showResponseSingleModal, singleResponseRequest} from "../../response-single/response-single-actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  metricItemHeading: {
    marginBottom: theme.spacing(2)
  },
  metricTableColumnHeader: {
    width: 200
  },
  metricTableColumnLength: {
    width: 40
  },
  metricTableColumnRowNumber: {
    width: 40,
    whiteSpace: 'nowrap'
  },
  actionsColumn: {
    width: 30
  }
}));

const MetricListOfResponses = ({metricItem}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const showAndRequestSingleResponse = (rowNumber) => {
    dispatch(showResponseSingleModal());
    dispatch(singleResponseRequest(rowNumber));
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.metricTableColumnHeader}>{t('column_header', 'Column header')}</TableCell>
          <TableCell className={classes.metricTableColumnRowNumber} align="right">{t('row_n', 'Row #')}</TableCell>
          <TableCell className={classes.metricTableColumnLength} align="right">{t('length', 'Length')}</TableCell>
          <TableCell>{t('value_sample', 'Value sample')}</TableCell>
          <TableCell className={classes.actionsColumn}>{t('actions', 'Actions')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {metricItem.data.map((row, i) => (
          <TableRow key={i}>
            <TableCell component="th" scope="row">
              {row['column_header']}
            </TableCell>
            <TableCell align="right">{row['row_number']}</TableCell>
            <TableCell align="right">{row['len_in_characters']}</TableCell>
            <TableCell>{row['value_sample']}</TableCell>
            <TableCell align="right">
              <Button size="small" onClick={() => showAndRequestSingleResponse(row['row_number'])}>{t('show', 'Show')}</Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MetricListOfResponses;