import {lightGreen, red, yellow} from "@material-ui/core/colors";

export const TAXONOMY_VALUE_SYSTEM = 'TAXONOMY_VALUE_SYSTEM';
export const TAXONOMY_LANGUAGE = 'TAXONOMY_LANGUAGE';
export const TAXONOMY_SELECTED_TAXONOMIES = 'TAXONOMY_SELECTED_TAXONOMIES';
export const TAXONOMY_ARE_SELECTED = 'TAXONOMY_ARE_SELECTED';
export const TAXONOMY_WORDS_IN_BASKET = 'TAXONOMY_WORDS_IN_BASKET';

export const HEADER_ROW_HEIGHT = 163;
export const TABLE_ROW_HEIGHT = 90;

export const CONTEXTS = {
  'general': {label: 'General', bgColors: [lightGreen[100], lightGreen[50]], borderColor: lightGreen[200]},
  'negation': {label: 'Negation', bgColors: [red[100], red[50]], borderColor: red[200]},
};

export const SEARCH_TABLE_PER_PAGE = 30;
export const EXAMPLES_TABLE_PER_PAGE = 30;
export const EXAMPLE_HIGHLIGHT = yellow[200];