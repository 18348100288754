import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Helmet} from "react-helmet";
import {Box, Button, Divider, Grid, Paper} from "@material-ui/core";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import ReportFormHeader from "./components/ReportFormHeader";
import MuiResponsiveDiv from "../../components/MuiResponsiveDiv/MuiResponsiveDiv";
import {preLoadCreateReportState, resetState} from "./create-report-action-creators";
import {parseQueryString} from "../reports/reports-functions";

const CreateReportComponent = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {stepTwoActive, stepThreeActive} = useSelector(state => ({
    stepTwoActive: state.createReport.stepTwoActive,
    stepThreeActive: state.createReport.stepThreeActive,
  }), shallowEqual);

  useEffect(() => {
    const parameters = parseQueryString(location.search);

    if (parameters.reportId) {
      dispatch(preLoadCreateReportState(parameters));
    }

    return () => {
      dispatch(resetState());
    }
  }, [location.search]);

  const finishLater = () => {
    history.push("/reports")
  };

  return (
    <MuiResponsiveDiv>
      <Helmet>
        <title>{t('create_report', 'Create Report')}</title>
      </Helmet>
      <Box mt={1} mb={3} p={2} style={{width: '100%'}}>
        <Grid container direction="column" component={Paper} spacing={4}>
          <Grid item xs>
            <ReportFormHeader title={t('create_report', 'Create Report')}>
              {(stepTwoActive || stepThreeActive) && (
                <Button variant="text" onClick={finishLater}>
                  {t('finish_later', 'Finish later')}
                </Button>
              )}
            </ReportFormHeader>
          </Grid>

          <Divider/>

          <StepOne/>

          <Divider/>

          <StepTwo/>

          <Divider/>

          <StepThree/>
        </Grid>
      </Box>
    </MuiResponsiveDiv>
  );
}

export default CreateReportComponent;