import React from 'react';
import {Helmet} from "react-helmet";
import {Grid, makeStyles} from "@material-ui/core";
import InitialConfig from "./components/InitialConfig";
import ActionsBar from "./components/ActionsBar";
import WordsBasket from "./components/WordsBasket";
import ExamplesDialog from "./components/ExamplesDialog";
import DeleteWordsDialog from "./components/DeleteWordsDialog";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  withPadding2: {
    padding: theme.spacing(2)
  },
  flexItem: {
    width: '100%'
  }
}));

const Taxonomy = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{t('taxonomy_management', 'Taxonomy Management')}</title>
      </Helmet>

      <Grid container direction="column" spacing={1} alignItems="flex-start" className={classes.root}>
        <Grid item className={classes.flexItem}>
          <InitialConfig/>
        </Grid>
        <Grid item className={classes.flexItem}>
          <ActionsBar/>
        </Grid>
        <Grid item xs className={classes.flexItem}>
          <WordsBasket/>
        </Grid>
      </Grid>
      <ExamplesDialog/>
      <DeleteWordsDialog/>
    </>
  );
};

export default Taxonomy;