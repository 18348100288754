import {eventChannel, END} from "redux-saga";
import API from "../../API-report";
import axios from 'axios';

export const DOWNLOAD_FILE_EVENT_PROGRESS = 'PROGRESS';
export const DOWNLOAD_FILE_EVENT_COMPLETE = 'COMPLETE';

export const downloadFileSagaHelper = (options) => {
  return eventChannel(emit => {
    const source = axios.CancelToken.source();

    API.getDataAppend({
      ...options,
      onDownloadProgress: function(event) {
        const percent = Math.ceil(event.loaded / event.total * 100);

        emit({type: DOWNLOAD_FILE_EVENT_PROGRESS, percent});
      },
      cancelToken: source.token
    }).then(response => {
      emit({type: DOWNLOAD_FILE_EVENT_COMPLETE, response});
      emit(END);
    }).catch(error => {
      throw error;
    });

    return () => {
      source.cancel();
    };
  });
};

export const getFilename = function(response, options) {
  let fileName;

  try {
    const contentDisposition = response.headers['content-disposition'];
    const headerParts = contentDisposition.split(';');
    const filenameParts = headerParts[1].split('=');
    fileName = filenameParts[1].replace(/['"]/ig, '');
  } catch (e) {
    const fileExt = options.schema.split('_')[0];
    fileName = 'data_append_' + options.partSuffix + '.' + fileExt;
  }

  return fileName;
};
