import {put, takeEvery, select} from 'redux-saga/effects';
import {DOWNLOAD_TOP_WORDS} from "./full-report-top-words-action-types";
import {addNotification} from "../notifications/notifications-actions";
import reportAPI from '../../API-report';
import saveAs from "file-saver";
import {downloadTopWordsDone} from "./full-report-top-words-action-creators";
import i18n from "../../i18n";

function* downloadTopWords() {
  try {
    const reportId = yield select(state => state.report.reportId);
    const reportInfo = yield select(state => state.reportInfo.report);
    const filters = yield select(state => state.filters.selectedFilters);
    const operator = yield select(state => state.filters.operator);
    const response = yield reportAPI.getTopWordsAndDownloadAsCsv(reportId, filters, operator);
    saveAs(response.data, reportInfo.report_name + '_top_words.csv');
  } catch (e) {
    yield put(addNotification({status: "Error", message: [i18n.t("could_not_download_file", "Could not download the file. Please try again")]}));
  }

  yield put(downloadTopWordsDone());
}

export default [
  takeEvery(DOWNLOAD_TOP_WORDS, downloadTopWords)
];