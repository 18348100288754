import React, {useEffect, useMemo} from 'react';
import {useDispatch} from "react-redux";
import Charts from "../charts/charts-container";
import Responses from "../responses/responses-container";
import withFilter from "../../hoc/with-filter/with-filter-hoc";
import {showResponseSingleModal, singleResponseRequest} from "../response-single/response-single-actions";
import {parseQueryString} from "../reports/reports-functions";
import TwoColumnsStickyLayout from "./components/TwoColumnsStickyLayout";


const ReportContainer = ({location}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = parseQueryString(location.search);
    const number = parseInt(queryParams.v, 10);

    if (!isNaN(number)) {
      dispatch(singleResponseRequest(number));
      dispatch(showResponseSingleModal());
    }
  }, []);

  return useMemo(() => (
    <TwoColumnsStickyLayout
      firstColumn={<Charts/>}
      secondColumn={<Responses/>}
    />
  ), []);
}

export default withFilter(ReportContainer);
