import {cancel, delay, fork, select, take, put, takeEvery} from "redux-saga/effects";
import reportApi from "../../API-report";
import {
  RECALCULATE_EDITED_ROWS_REQUEST, recalculateEditedRowsDone,
  reportGotEditedRowsSuccess, setTopicsProgress,
  START_REGULAR_CHECKS,
  STOP_REGULAR_CHECKS
} from "./report-router-actions";
import {addNotification} from "../notifications/notifications-actions";
import {loadReportInfoRequest} from "../report-info/report-info-action-creators";
import {filtersRequest} from "../filters/filters-actions";
import i18n from "../../i18n";

const REGULAR_CHECK_INTERVAL = 10000; // 10 seconds

export function* startRegularChecks() {
  const reportId = yield select(state => state.report.reportId);
  const task = yield fork(runRegularChecks, reportId);

  yield take(STOP_REGULAR_CHECKS);
  yield cancel(task);
}

function* runRegularChecks(reportId) {
  try {
    while (true) {
      yield checkIfReportGotEditedRows(reportId);
      yield checkTopicsProgress();
      yield delay(REGULAR_CHECK_INTERVAL);
    }
  } catch (e) {
    console.error('ERROR WHILE RUNNING REGULAR CHECKS', e);
  }
}

export function* checkIfReportGotEditedRows(reportId) {
  try {
    const response = yield reportApi.checkIfReportGotEditedRows(reportId);

    const editedRows = response.data;
    yield put(reportGotEditedRowsSuccess(editedRows))
  } catch (e) {
    console.error('ERROR IN CHECKING IF REPORT GOT EDITED ROWS.', e);
  }
}

export function* checkTopicsProgress() {
  try {
    const report = yield select(state => state.reportInfo.report);
    const topicsInProgress = yield select(state => state.reportRouter.topicsInProgress);

    const progressId = (report && report['topics_progress_id']) || null;

    if (progressId) {
      yield *updateTopicsProgress(progressId);
    } else if (topicsInProgress) {
      yield put(setTopicsProgress(false, 100));
    }
  } catch (e) {
    console.error('ERROR IN *checkTopicsProgress()', e);
  }
}

function* updateTopicsProgress(progressId) {
  const response = yield reportApi.getTopicsProgress(progressId);
  const finished = response.data.results.finished;

  if (finished) {
    yield put(setTopicsProgress(false, 100));
    yield put(loadReportInfoRequest());
    yield put(filtersRequest());
  } else {
    const bars = response.data.results.bars;
    let totalProgress = 0;
    let barsNum = 0;

    Object.keys(bars).forEach(key => {
      const bar = bars[key];
      barsNum++;
      totalProgress += (bar.current / bar.total) * 100;
    });

    if (barsNum === 0) {
      throw new Error(i18n.t('topics_process_not_finished_no_progress_bars_available', 'Topics process is not finished, though no progress bars are available'));
    }

    yield put(setTopicsProgress(true, totalProgress / barsNum));
  }
}

export function* recalculateEditedRows({history}) {
  try {
    const reportId = yield select(state => state.report.reportId);
    yield reportApi.recalculateEditedRows(reportId);
    history.push('/create-report/success?reportId=' + reportId);
    yield put(addNotification({status: "Success", message: [i18n.t('save_successful_wait_for_recalc', 'Save successful, please wait for recalculation')]}));
  } catch (e) {
    console.error('ERROR IN RECALCULATE_EDIT_ROWS', e);
    yield put(addNotification({status: "Error", message: [i18n.t('could_not_recalculate', 'Could not recalculate, try again later')]}))
  } finally {
    yield put(recalculateEditedRowsDone());
  }
}

export default [
  takeEvery(START_REGULAR_CHECKS, startRegularChecks),
  takeEvery(RECALCULATE_EDITED_ROWS_REQUEST, recalculateEditedRows),
];