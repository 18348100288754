import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";


const PrimaryEmotionWords = ({reportId, data, isSearch}) => {
  const {t} = useTranslation();
  const terms = Object.keys(data);

  if (terms.length === 0) {
    if (isSearch) {
      return <Alert severity="info">{t("no_words_found_by_search", "No words found by your search")}</Alert>
    } else {
      return <Alert severity="info">{t("no_words_for_this_primary_emotion", "No words exist for this primary emotion")}</Alert>
    }
  }

  return terms.map((term, i) => (
    <Fragment key={i}>
      {i > 0 && ', '}
      <Link to={`/report/${reportId}/search/${term}/0/0`} title={t("search_by_this_word", "Search by this word")}>
        {term}
      </Link>
    </Fragment>
  ));
};

export default PrimaryEmotionWords;