import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Box, CircularProgress} from "@material-ui/core";

import Taxonomies from "../components/Taxonomies";
import ReportEditBottom from "../components/ReportEditBottom";

import {editUpdateTaxonomiesRequest, loadDataForStepThreeRequest} from "../create-report-action-creators";
import {MODE_EDITING} from "../constants";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const EditTaxonomies = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {loading, saveLoading} = useSelector(state => ({
    loading: state.createReport.stepThreeLoading,
    saveLoading: state.createReport.editUpdateTaxonomiesLoading
  }), shallowEqual);

  useEffect(() => {
    dispatch(loadDataForStepThreeRequest(MODE_EDITING));
  }, [dispatch]);

  const submit = () => {
    dispatch(editUpdateTaxonomiesRequest(history));
  };

  return (
    <>
      <Box p={2}>
        {loading ? <CircularProgress/>: <Taxonomies/>}
      </Box>
      <ReportEditBottom
        loading={saveLoading}
        enabled={!loading && !saveLoading}
        submit={submit}
        submitText={t('update_and_recalculate', "Update & recalculate")}
      />
    </>
  );
}

export default EditTaxonomies;