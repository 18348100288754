import {
  CREATE_FILTERS_FROM_NOTES_DONE,
  CREATE_FILTERS_FROM_NOTES_REQUEST,
  DELETE_NOTE_DONE,
  DELETE_NOTE_REQUEST, HIDE_CREATE_FILTERS_FROM_NOTES_MODAL, HIDE_DELETE_NOTE_CONFIRMATION, HIDE_NOTE_CONTEXT_MENU,
  HIDE_NOTE_MODAL,
  SET_NOTE_INFO, SHOW_CREATE_FILTERS_FROM_NOTES_MODAL, SHOW_DELETE_NOTE_CONFIRMATION,
  SHOW_NOTE_CONTEXT_MENU,
  SHOW_NOTE_MODAL
} from "./notes-actions";

const initialState = {
  rowNumber: null,
  dataHeader: null,
  modalShown: false,
  createFiltersModalShown: false,
  notesMenuMousePosition: undefined,
  noteId: null,
  deleteNoteConfirmationOpen: false,
  deleteNoteLoading: false,
  createFiltersLoading: false,
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTE_INFO:
      return {
        ...state,
        rowNumber: action.rowNumber,
        dataHeader: action.dataHeader,
      };
    case SHOW_NOTE_MODAL:
      return {
        ...state,
        modalShown: action.modalType
      };
    case HIDE_NOTE_MODAL:
      return {
        ...state,
        modalShown: false
      };
    case SHOW_NOTE_CONTEXT_MENU:
      return {
        ...state,
        noteId: action.noteId,
        notesMenuMousePosition: action.mousePosition,
      };
    case HIDE_NOTE_CONTEXT_MENU:
      return {
        ...state,
        notesMenuMousePosition: undefined
      };
    case DELETE_NOTE_REQUEST:
      return {
        ...state,
        deleteNoteLoading: true
      };
    case DELETE_NOTE_DONE:
      return {
        ...state,
        deleteNoteLoading: false,
        deleteNoteConfirmationOpen: false
      };
    case SHOW_DELETE_NOTE_CONFIRMATION:
      return {
        ...state,
        deleteNoteConfirmationOpen: true
      };
    case HIDE_DELETE_NOTE_CONFIRMATION:
      return {
        ...state,
        deleteNoteConfirmationOpen: false
      };
    case SHOW_CREATE_FILTERS_FROM_NOTES_MODAL:
      return {
        ...state,
        createFiltersModalShown: true
      };
    case HIDE_CREATE_FILTERS_FROM_NOTES_MODAL:
      return {
        ...state,
        createFiltersModalShown: false
      };
    case CREATE_FILTERS_FROM_NOTES_REQUEST:
      return {
        ...state,
        createFiltersLoading: true
      };
    case CREATE_FILTERS_FROM_NOTES_DONE:
      return {
        ...state,
        createFiltersLoading: false
      };
    default:
      return state;
  }
};

export default notesReducer;