import {CHECKSUM_SEPARATOR, FILTERS_URL_PARAM_KEY} from "./filters-constants";

export const toggleSelectedUp = (flatFilters, id, parentId) => {
  const counts = Object.values(flatFilters).reduce((counts, filter) => {
    if (filter.parentId === parentId) {
      if (filter.selected) {
        counts.selected++;
      }

      if (filter.partial || filter.value) {
        counts.partial++;
      }
    }

    return counts;
  }, {selected: 0, partial: 0});

  const parentItem = flatFilters[parentId];
  let partial, selected;

  if (counts.selected === 0) {
    selected = false;
  }

  if (counts.partial > 0 || counts.selected > 0) {
    partial = true;
  }

  if (counts.selected === parentItem.childrenCount) {
    partial = false;
    selected = true;
  }

  flatFilters[parentId] = {
    ...flatFilters[parentId],
    selected,
    partial
  };

  if (parentItem.parentId) {
    toggleSelectedUp(flatFilters, parentItem.id, parentItem.parentId);
  }
};

export const toggleSelectedDown = (flatFilters, id) => {
  const parentFlatFilter = flatFilters[id];
  const intId = parseInt(id);

  Object.keys(flatFilters).forEach(cId => {
    const flatFilter = flatFilters[cId];

    if (flatFilter.parentId === intId) {
      flatFilters[cId] = {
        ...flatFilters[cId],
        selected: Boolean(parentFlatFilter.selected),
        partial: Boolean(parentFlatFilter.partial),
      };

      toggleSelectedDown(flatFilters, cId);
    }
  });
};

export const buildFiltersUrlPart = (filters, flatFilters, checksum, operator) => {
  const collected = collectFilters(filters, flatFilters);

  if (collected.length === 0) return '';

  const parts = collected.map((filter) => {
    switch (filter.type) {
      case 'single_value':
        return filter.id;
      case 'range':
        return filter.id + ':' + JSON.stringify(filter.value)
      default:
        return filter.id;
    }
  });

  return checksum + CHECKSUM_SEPARATOR + parts.join('|') + CHECKSUM_SEPARATOR + operator;
};

export const parseFiltersUrlPart = urlPart => {
  return urlPart.split('|').reduce((selected, part) => {
    let [id, value] = part.split(':');

    selected[id] = {
      id,
      value: value && JSON.parse(value)
    };

    return selected;
  }, {});
};

export const getFiltersUrlPart = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(FILTERS_URL_PARAM_KEY);
};

export const collectFilters = (filters, flatFilters) => {
  const collectedFilters = [];

  filters.forEach(filter => {
    const flatFilter = flatFilters[filter.item.id];

    if (flatFilter.type === 'range' && flatFilter.value) {
      collectedFilters.push(flatFilter);
    } else if (flatFilter.selected && flatFilter.type !== 'parent_group') {
      collectedFilters.push(flatFilter);
    } else if (flatFilter.partial) {
      const collectedChildren = collectFilters(filter.children, flatFilters);
      collectedFilters.push(...collectedChildren);
    }
  });

  return collectedFilters;
};

export const groupSelectedFiltersByParent = (selectedFilters, flatFilters) => {
  const grouped = {};

  selectedFilters.forEach(filter => {
    const topParentFilter = recursivelyFindParent(filter, flatFilters);
    if (grouped[topParentFilter.title]) {
      grouped[topParentFilter.title].push(filter);
    } else {
      grouped[topParentFilter.title] = [filter];
    }
  });

  return grouped;
};

const recursivelyFindParent = (filter, flatFilters) => {
  if (!filter.parentId) return filter;

  return recursivelyFindParent(flatFilters[filter.parentId], flatFilters);
}