import React, {Fragment, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Button, CircularProgress, Grid} from "@material-ui/core";

import Column from "./components/Column";
import ColumnsContainer from "./components/ColumnsContainer";
import StepContainer from "./components/StepContainer";

import {
  acceptColumnsRequest,
  activateEditColumnType,
  changeColumnType,
  deactivateEditColumnType,
  deactivateStepTwo,
  loadColumnsRequest,
  stepTwoActivate,
  toggleDropdown
} from "./create-report-action-creators";
import {LOAD_COLUMNS_MODE_CREATING} from "./constants";

const StepTwo = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {active, reviewed, loading, columns, virgin, acceptColumnsLoading} = useSelector(state => ({
    active: state.createReport.stepTwoActive,
    reviewed: state.createReport.stepTwoReviewed,
    loading: state.createReport.stepTwoLoading,
    columns: state.createReport.columns,
    virgin: state.createReport.stepTwoVirgin,
    acceptColumnsLoading: state.createReport.acceptColumnsLoading,
  }), shallowEqual);

  useEffect(() => {
    if (active) {
      dispatch(loadColumnsRequest(LOAD_COLUMNS_MODE_CREATING));
    }
  }, [dispatch, active]);

  const acceptColumns = () => dispatch(acceptColumnsRequest());
  const activate = () => dispatch(stepTwoActivate());
  const deactivate = () => dispatch(deactivateStepTwo());

  let columnsComponent = virgin ? (
    <h2>{t('step_two_explanation', 'Here you will review columns from your .csv file')}</h2>
  ) : (
    <h2>{t('step_two_reviewed', 'Columns are reviewed')}</h2>
  );

  if (active) {
    columnsComponent = (
      <Fragment>
        <h2 style={{margin: '10px 0'}}>
          {t('review_columns', 'Review columns')}
        </h2>
        <ColumnsContainer>
          {columns.map((col, i) => (
            <Column
              key={i}
              columnIndex={i}
              {...col}
              changeColumnType={columnInfo => dispatch(changeColumnType(columnInfo))}
              toggleDropdown={() => dispatch(toggleDropdown(i))}
              activateEditColumnType={() => dispatch(activateEditColumnType(i))}
              deactivateEditColumnType={() => dispatch(deactivateEditColumnType(i))}
            />
          ))}
        </ColumnsContainer>
      </Fragment>
    );
  }

  let buttonLabel = active ? t('accept', 'Accept') : t('review_again', 'Review again');

  if (acceptColumnsLoading) {
    buttonLabel = <CircularProgress color="inherit" size="25px" />;
  }

  const footer = (active || !virgin) && (
    <Fragment>
      <Button
        color={active ? 'primary' : 'default'}
        onClick={active ? acceptColumns : activate}
        disabled={acceptColumnsLoading}
        variant="contained"
        size="large"
      >
        {buttonLabel}
      </Button>

      {!virgin && active && (
        <Button
          color="default"
          variant="text"
          size="large"
          onClick={deactivate}
          style={{marginLeft: '10px'}}
        >
          {t('cancel', 'Cancel')}
        </Button>
      )}
    </Fragment>
  );

  return (
    <StepContainer active={virgin}>
      <Grid item xs={11} md={11} container direction="column" spacing={2}>
        <Grid item xs>{columnsComponent}</Grid>
        <Grid item xs>{footer}</Grid>
      </Grid>
    </StepContainer>
  )
}

export default StepTwo;