import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  tooltipText: {
    fontSize: '13px'
  }
});

const TermHelpTooltipTitle = ({term, children}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h6">{term}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.tooltipText}>
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermHelpTooltipTitle;