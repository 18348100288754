import React, {useCallback, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from '@material-ui/icons/FilterList';

import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {liveReportStart, liveReportStop} from "./live-report-actions";
import PrimaryEmotionsBreakdownChart from '../charts/containers/PrimaryEmotionsBreakdownChart';
import SentimentBreakdownChart from "../charts/containers/SentimentBreakdownChart";
import TopWordsChart from "../charts/top-words-chart";
import Verbatim from "../responses/verbatim";
import DrawerWithContent from "../../components/Drawer/DrawerWithContent";
import {toggleMobileDrawer} from "../main/main-actions";
import FiltersContainer from "../filters/filters-container";
import {subscribeToFilters, unsubscribeFromFilters} from "../filters/filters-actions";
import './live-report.css';

const LiveReport = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {responses, responsesTotal, hxIndex, reportId, hxTitle, reportInfo} = useSelector(state => ({
    responses: state.live.responses,
    responsesTotal: state.live.responsesTotal,
    hxIndex: state.live.hxIndex,
    reportId: state.report.reportId,
    hxTitle: state.main.title,
    reportInfo: state.reportInfo.report,
  }), shallowEqual);

  const restartLiveReport = useCallback(() => {
    dispatch(liveReportStop());
    dispatch(liveReportStart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(subscribeToFilters(restartLiveReport));
    dispatch(liveReportStart());

    return () => {
      dispatch(liveReportStop());
      dispatch(unsubscribeFromFilters(restartLiveReport));
    }
  }, [dispatch, restartLiveReport]);

  const hxIndexDisplayed = Number(hxIndex).toFixed(3);

  const emotionsTab = reportInfo['emotions_tab_name'] || 'Emotions';

  return (
    <DrawerWithContent drawerContent={<FiltersContainer/>} fullscreen>
      <div className="live-report-container">
        <div className="live-report-left">
          <div className="live-report-feed reports-round-shadow">
            <div className="live-report-feed-header">
              <h2>
                {t('live_feed', 'Live feed')}
                <IconButton onClick={() => dispatch(toggleMobileDrawer())}>
                  <FilterListIcon/>
                </IconButton>
              </h2>
              {!isNaN(responsesTotal) &&
                <p>
                  <strong>{t('verbatims_total', 'Verbatims total')}:</strong> {responsesTotal}
                </p>
              }
              {!isNaN(hxIndexDisplayed) &&
                <p>
                  <strong>{hxTitle} {t('index', 'Index')}:</strong> {hxIndexDisplayed}
                </p>
              }
            </div>
            <div className="live-report-feed-responses">
              <TransitionGroup exit={false}>
                {responses.map(response => (
                  <CSSTransition classNames="live-report-feed-response" key={response.row_number} timeout={100}>
                    <div className="live-report-feed-response">
                      <Verbatim
                        selectedTab={emotionsTab}
                        response={response}
                        reportId={reportId}
                      />
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </div>
        </div>
        <div className="live-report-right">
          <div className="live-report-graphs">
            <div className="live-report-graphs-row">
              <div className="live-report-graphs-col">
                <PrimaryEmotionsBreakdownChart hideLegend/>
              </div>
              <div className="live-report-graphs-col">
                <SentimentBreakdownChart hideLegend/>
              </div>
            </div>
            <div className="live-report-graphs-row">
              <div className="live-report-graphs-col">
                <TopWordsChart hideLegend/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DrawerWithContent>
  );
};

export default LiveReport;