import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PaperWithPadding from "../../../components/PaperWithPadding/PaperWithPadding";
import {reset} from "../taxonomy-actions";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  infoItem: {
    marginRight: theme.spacing(2)
  }
}));

const ActivatedTaxonomiesInfo = () => {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {language, valueSystem, selectedTaxonomies} = useSelector(state => ({
    language: state.taxonomy.language,
    valueSystem: state.taxonomy.valueSystem,
    selectedTaxonomies: state.taxonomy.selectedTaxonomies
  }), shallowEqual);

  const doReset = () => {
    dispatch(reset());
  };

  return (
    <PaperWithPadding>
      <Grid container alignItems="center">
        <Grid item xs container spacing={2}>
          <Grid item>
            <strong>{t('value_system', 'Value system')}:</strong> {valueSystem}
          </Grid>
          <Grid item>
            <strong>{t('language', 'Language')}:</strong> {language}
          </Grid>
          <Grid item>
            <strong>{t('taxonomies', 'Taxonomies')}:</strong> {selectedTaxonomies.join(', ')}
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={doReset}
            color="secondary"
          >
            {t('reset', 'Reset')}
          </Button>
        </Grid>
      </Grid>
    </PaperWithPadding>
  );
};

export default ActivatedTaxonomiesInfo;