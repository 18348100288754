export const GET_TAXONOMY_LIST_REQUEST = "[ADD WORDS] GET TAXONOMY LIST REQUEST";
export const GET_TAXONOMY_LIST_SUCCESS = "[ADD WORDS] GET TAXONOMY LIST SUCCESS";
export const GET_TAXONOMY_LIST_ERROR = "[ADD WORDS] GET TAXONOMY LIST ERROR";

export const WORD_CHANGE = "[ADD WORDS] WORD_CHANGE";
export const ADD_INPUT = "[ADD WORDS] ADD INPUT";
export const REMOVE_INPUT = "[ADD WORDS] REMOVE INPUT";

export const TAXONOMY_CHANGE = "[ADD WORDS] TAXONOMY CHANGE";

export const ADD_WORDS_REQUEST = "[ADD WORDS] ADD WORDS REQUEST";
export const ADD_WORDS_SUCCESS = "[ADD WORDS] ADD WORDS SUCCESS";
export const ADD_WORDS_ERROR = "[ADD WORDS] ADD WORDS ERROR";

export const REMOVE_WHITESPACES = "[ADD WORDS] REMOVE_WHITESPACES";
export const ERASE_ADD_WORDS_INPUTS = "[ADD WORDS] ERASE_ADD_WORDS_INPUTS";

export const WRONG_VALIDATION = "[ADD WORDS] WRONG_VALIDATION";


export const TEXT_AREA_WORDS_CHANGE = "[ADD WORDS] TEXT AREA WORDS CHANGE";
export const SHOW_ADD_WORDS_MODAL = "[ADD WORDS] SHOW_ADD_WORDS_MODAL";
export const CLOSE_ADD_WORDS_MODAL = "[ADD WORDS] CLOSE_ADD_WORDS_MODAL";

export const SET_WORDS_ARE_EMPTY = "[ADD WORDS] SET_WORDS_ARE_EMPTY";
export const COMMENT_CHANGE = "[ADD WORDS] COMMENT_CHANGE";