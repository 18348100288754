import {
  HB_INDEX_SET_SELECTED_FILTER_NAME, HB_INDEX_CHART_CLEAR_DATA,
  LOAD_HB_INDEX_CHART_DATA_ERROR,
  LOAD_HB_INDEX_CHART_DATA_REQUEST, LOAD_HB_INDEX_CHART_DATA_SUCCESS, HB_INDEX_SET_SECOND_VARIABLE_NAME
} from "./hb-index-chart-action-creators";

const initialState = {
  data: null,
  loading: true,
  selectedFilterName: undefined,
  secondVariableName: undefined,
  error: ""
};


const hbIndexChartReducer = ( state = initialState, action ) => {
  switch(action.type) {
    case HB_INDEX_CHART_CLEAR_DATA:
      return initialState;

    case HB_INDEX_SET_SELECTED_FILTER_NAME:
      return {
        ...state,
        selectedFilterName: action.filterName
      };

    case HB_INDEX_SET_SECOND_VARIABLE_NAME:
      return {...state, secondVariableName: action.secondVariableName};

    case LOAD_HB_INDEX_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };

    case LOAD_HB_INDEX_CHART_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case LOAD_HB_INDEX_CHART_DATA_SUCCESS:
      return {
        ...state,
        error: "",
        data: action.data,
        selectedFilterName: action.filterName,
        loading: false
      };


    default:
      return state;
  }
};

export default hbIndexChartReducer;
